import { Routes } from '@angular/router';
import { CustomerComponent } from './customer-page.component';
import { CustomerSearchComponent } from './customer-search/customer-search.component';
import { CustomerResultsMainViewComponent } from './customer-search/results-main-view/results-main-view.component';
import { CustomerDetailsViewMainComponent } from './customer-search/details-main-view/details-main-view.component';
import { CustomerHistoryMainViewComponent } from './customer-search/history-main-view/history-main-view.component';
import { CustomerFilterMainViewComponent } from './customer-search/filter-main-view/filter-main-view.component';
import { CustomerCreateGuard } from './guards/customer-create.guard';
import {
  CreateB2BCustomerComponent,
  CreateGuestCustomerComponent,
  CreateP4MCustomerComponent,
  CreateStoreCustomerComponent,
  CreateWebshopCustomerComponent,
} from './create-customer';
import { UpdateP4MWebshopCustomerComponent } from './create-customer/update-p4m-webshop-customer';
import { CreateCustomerComponent } from './create-customer/create-customer.component';
import { CustomerDataEditB2BComponent } from './customer-search/edit-main-view/customer-data-edit-b2b.component';
import { CustomerDataEditB2CComponent } from './customer-search/edit-main-view/customer-data-edit-b2c.component';
import { AddBillingAddressMainViewComponent } from './customer-search/add-billing-address-main-view/add-billing-address-main-view.component';
import { AddShippingAddressMainViewComponent } from './customer-search/add-shipping-address-main-view/add-shipping-address-main-view.component';
import { EditBillingAddressMainViewComponent } from './customer-search/edit-billing-address-main-view/edit-billing-address-main-view.component';
import { EditShippingAddressMainViewComponent } from './customer-search/edit-shipping-address-main-view/edit-shipping-address-main-view.component';
import { CustomerOrdersMainViewComponent } from './customer-search/orders-main-view/orders-main-view.component';
import { OrderDetailsMainViewComponent } from './customer-search/order-details-main-view/order-details-main-view.component';
import { KundenkarteMainViewComponent } from './customer-search/kundenkarte-main-view/kundenkarte-main-view.component';
import { CustomerOrderDetailsHistoryMainViewComponent } from './customer-search/order-details-history-main-view/order-details-history-main-view.component';
import { CustomerMainViewComponent } from './customer-search/main-view/main-view.component';
import { MainSideViewComponent } from './customer-search/main-side-view/main-side-view.component';
import { CustomerResultsSideViewComponent } from './customer-search/results-side-view/results-side-view.component';
import { OrderDetailsSideViewComponent } from './customer-search/order-details-side-view/order-details-side-view.component';
import { CustomerCreateSideViewComponent } from './create-customer/customer-create-side-view';

export const routes: Routes = [
  {
    path: '',
    component: CustomerComponent,
    children: [
      {
        path: '',
        component: CustomerSearchComponent,
        children: [
          { path: 'search', component: CustomerMainViewComponent, data: { side: 'main', breadcrumb: 'main' } },
          { path: 'search/list', component: CustomerResultsMainViewComponent, data: { breadcrumb: 'search' } },
          {
            path: 'search/filter',
            component: CustomerFilterMainViewComponent,
            data: { side: 'results', breadcrumb: 'filter' },
          },
          {
            path: 'search/:customerId',
            component: CustomerDetailsViewMainComponent,
            data: { side: 'results', breadcrumb: 'details' },
          },
          {
            path: 'search/:customerId/history',
            component: CustomerHistoryMainViewComponent,
            data: { side: 'results', breadcrumb: 'history' },
          },
          {
            path: 'search/:customerId/kundenkarte',
            component: KundenkarteMainViewComponent,
            data: { side: 'results', breadcrumb: 'kundenkarte' },
          },
          {
            path: 'search/:customerId/orders',
            component: CustomerOrdersMainViewComponent,
            data: { side: 'results', breadcrumb: 'orders' },
          },
          {
            path: 'search/:customerId/orders/:orderId',
            component: OrderDetailsMainViewComponent,
            data: { side: 'order-details', breadcrumb: 'order-details' },
          },
          {
            path: 'search/:customerId/orders/:orderId/:orderItemId',
            component: OrderDetailsMainViewComponent,
            data: { side: 'order-details', breadcrumb: 'order-details' },
          },
          {
            path: 'search/:customerId/orders/:orderId/:orderItemId/history',
            component: CustomerOrderDetailsHistoryMainViewComponent,
            data: { side: 'order-details', breadcrumb: 'order-details-history' },
          },
          {
            path: 'search/:customerId/edit/b2b',
            component: CustomerDataEditB2BComponent,
            data: { side: 'results', breadcrumb: 'edit' },
          },
          {
            path: 'search/:customerId/edit',
            component: CustomerDataEditB2CComponent,
            data: { side: 'results', breadcrumb: 'edit' },
          },
          {
            path: 'search/:customerId/billingaddress/add',
            component: AddBillingAddressMainViewComponent,
            data: { side: 'results', breadcrumb: 'add-billing-address' },
          },
          {
            path: 'search/:customerId/billingaddress/:payerId/edit',
            component: EditBillingAddressMainViewComponent,
            data: { side: 'results', breadcrumb: 'edit-billing-address' },
          },
          {
            path: 'search/:customerId/shippingaddress/add',
            component: AddShippingAddressMainViewComponent,
            data: { side: 'results', breadcrumb: 'add-shipping-address' },
          },
          {
            path: 'search/:customerId/shippingaddress/:shippingAddressId/edit',
            component: EditShippingAddressMainViewComponent,
            data: { side: 'results', breadcrumb: 'edit-shipping-address' },
          },
          {
            path: 'search-customer-main',
            outlet: 'side',
            component: MainSideViewComponent,
          },
          {
            path: 'results',
            outlet: 'side',
            component: CustomerResultsSideViewComponent,
          },
          {
            path: 'order-details',
            outlet: 'side',
            component: OrderDetailsSideViewComponent,
          },
        ],
      },
      {
        path: '',
        component: CreateCustomerComponent,
        canActivate: [CustomerCreateGuard],
        canActivateChild: [CustomerCreateGuard],
        children: [
          { path: 'create', component: CreateStoreCustomerComponent },
          { path: 'create/store', component: CreateStoreCustomerComponent },
          { path: 'create/webshop', component: CreateWebshopCustomerComponent },
          { path: 'create/b2b', component: CreateB2BCustomerComponent },
          { path: 'create/guest', component: CreateGuestCustomerComponent },
          { path: 'create/webshop-p4m', component: CreateP4MCustomerComponent, data: { customerType: 'webshop' } },
          { path: 'create/store-p4m', component: CreateP4MCustomerComponent, data: { customerType: 'store' } },
          {
            path: 'create/webshop-p4m/update',
            component: UpdateP4MWebshopCustomerComponent,
            data: { customerType: 'webshop' },
          },
          {
            path: 'create-customer-main',
            outlet: 'side',
            component: CustomerCreateSideViewComponent,
          },
        ],
      },
    ],
  },
];
