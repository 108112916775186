import { b, a as a$4, c, f as f$1 } from './chunk-MSPQDVWJ.js';
import { a as a$5 } from './chunk-3A4KLILV.js';
import { a as a$2 } from './chunk-U6BTTEV6.js';
import { a as a$1 } from './chunk-EV4OEANA.js';
import { a as a$3 } from './chunk-GE4CBD4E.js';
var h = "scandit-progress-bar",
  n = "aria-valuenow",
  f = "aria-valuemin",
  w = "aria-valuemax",
  a = class a extends a$1 {
    constructor() {
      super();
      let e = this.attachShadow({
        mode: "open"
      });
      e.innerHTML = a$2`
      <div id="scandit-progress" role="progressbar" aria-valuemin="0" aria-valuemax="100" />
    `, e.append(a.createStyleElement().cloneNode(!0)), this.progress = e.querySelector("#scandit-progress");
    }
    static get observedAttributes() {
      return ["value", "min", "max"];
    }
    static createStyleElement() {
      return a$3`
      :host {
        display: inline-block;
        --indeterminate-bubble-width: 35%;
        --accent-color: #${b.toJSON()};
        --start-gradient-color: #${a$4.toJSON()};
        --end-gradient-color: #${c.toJSON()};
        --secondary-color: #${f$1.toJSON()};
        width: 100%;
      }

      :host [role="progressbar"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        position: relative;
        overflow: hidden;
        border: none;
        height: 0.5rem;
      }

      :host [role="progressbar"]::before,
      :host [role="progressbar"]::after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        background: var(--accent-color);
        height: 0.5rem;
        border-radius: 0.5rem;
      }

      :host([variant="gradient"]) [role="progressbar"]::before,
      :host([variant="gradient"]) [role="progressbar"]::after {
        background: linear-gradient(90deg, var(--start-gradient-color) 0%, var(--end-gradient-color) 100%);
      }

      :host(:focus-visible) {
        outline-color: var(--accent-color);
        outline-offset: 5px;
        border-radius: 0.5rem;
      }

      :host [role="progressbar"]:not([aria-valuenow])::after {
        width: var(--indeterminate-bubble-width);
        animation: indeterminate-progress-bar 2.5s ease infinite;
        z-index: 1;
      }

      :host [role="progressbar"][aria-valuenow]::after {
        width: 0;
        animation: none;
      }

      :host [role="progressbar"][aria-valuenow]::before {
        width: calc(var(--aria-valuenow) * 1%);
        background-color: var(--accent-color);
        border-radius: 0.5rem;
        transition: width 0.3s linear;
      }

      :host [role="progressbar"] {
        background-color: var(--secondary-color);
        border-radius: 0.5rem;
      }

      @keyframes indeterminate-progress-bar {
        0% {
          left: 0%;
        }
        50% {
          left: calc(100% - var(--indeterminate-bubble-width));
        }
      }
    `;
    }
    get min() {
      var r;
      let e = (r = this.getAttribute("min")) != null ? r : 0;
      return Number(e);
    }
    get max() {
      var r;
      let e = (r = this.getAttribute("max")) != null ? r : 1;
      return Number(e);
    }
    set max(e) {
      e !== null && this.setAttribute("max", String(e));
    }
    get value() {
      let e = this.getAttribute("value");
      return e == null || e === "" ? null : Number(e);
    }
    set value(e) {
      if (e == null) {
        this.removeAttribute("value");
        return;
      }
      e >= this.min && e <= this.max && this.setAttribute("value", String(e));
    }
    static create() {
      return document.createElement(h);
    }
    static register() {
      a$5({
        [a.tag]: a
      });
    }
    attributeChangedCallback() {
      this.update();
    }
    connectedCallback() {
      this.update();
    }
    update() {
      let {
        min: e,
        max: r,
        value: i,
        progress: t
      } = this;
      if (t == null || t.setAttribute(f, e.toString()), t == null || t.setAttribute(w, r.toString()), i == null) {
        t == null || t.removeAttribute(n), t == null || t.removeAttribute("style");
        return;
      }
      if (i >= e && i <= r) {
        let v = (i - e) / (r - e) * 100,
          l = String(v);
        t == null || t.setAttribute(n, l), t == null || t.setAttribute("style", `--${n}: ${l}`);
      }
    }
  };
a.tag = h;
var o = a;
o.register();
export { o as a };