var A = {
  CONTAINER_CLASS_NAME: "scandit-container",
  PAINTBOARDS_CONTAINER_CLASS_NAME: "scandit-paintboards",
  PAINTBOARD_CLASS_NAME: "scandit-paintboard",
  PAINTBOARD_CLASS_NAME_STATE_ON: "scandit-paintboard--camera-on",
  CONTROLS_CLASS_NAME: "scandit-controls",
  CONTROL_WIDGET_CLASS_NAME: "scandit-control-widget",
  MIRRORED_CLASS_NAME: "scandit-mirrored",
  CAMERA_RECOVERY_CLASS_NAME: "scandit-camera-recovery",
  ERROR_CLASS_NAME: "scandit-error",
  HINT_CONTAINER_CLASS_NAME: "scandit-hint-container",
  HINT_CLASS_NAME: "scandit-hint",
  SINGLE_IMAGE_UPLOADER_CONTAINER_CLASS_NAME: "scandit-single-image-uploader-container",
  CANVAS_VIDEO_PREVIEW_CLASS_NAME: "scandit-canvas-video-preview",
  TORCH_SWITCH_CONTROL_CLASS_NAME: "torch",
  CAMERA_SWITCH_CONTROL_CLASS_NAME: "camera",
  CAMERA_FOV_SWITCH_CONTROL_CLASS_NAME: "camera-fov",
  SHUTTER_BUTTON_CONTROL_CLASS_NAME: "shutter",
  FROZEN_FRAME: "frozen-frame"
};
export { A as a };