<a
  *ngIf="orderDetailsRoute$ | async; let orderDetailsRoute"
  [routerLink]="orderDetailsRoute?.path"
  [queryParams]="orderDetailsRoute?.queryParams"
  [queryParamsHandling]="'merge'"
  class="page-customer-order-list-item_wrapper"
>
  <div class="grid grid-flow-row gap-2">
    <div class="page-customer-order-list-item_label-value">
      <div class="page-customer-order-list-item_label-value-label">Bestelldatum</div>
      <div class="page-customer-order-list-item_label-value-value">
        {{ orderListItem?.orderDate | date: 'dd.MM.yyyy' }}
      </div>
    </div>
    <div class="page-customer-order-list-item_label-value">
      <div class="page-customer-order-list-item_label-value-label">Bestellkanal</div>
      <div class="page-customer-order-list-item_label-value-value">
        {{ orderListItem?.features?.orderSource }}
      </div>
    </div>
    <div class="page-customer-order-list-item_label-value" *ngIf="orderListItem?.targetBranchName">
      <div class="page-customer-order-list-item_label-value-label">Zielfiliale</div>
      <div class="page-customer-order-list-item_label-value-value">
        {{ orderListItem?.targetBranchName }}
      </div>
    </div>
    <div class="page-customer-order-list-item_label-value" *ngIf="orderListItem?.shipping">
      <div class="page-customer-order-list-item_label-value-label">Versand</div>
      <div class="page-customer-order-list-item_label-value-value">
        {{ orderListItem?.shipping | address }}
      </div>
    </div>
  </div>
  <div class="grid grid-flow-row text-right gap-2">
    <div class="font-bold text-2xl">
      {{ orderListItem?.orderNumber }}
    </div>
    <div class="font-bold">
      {{ orderListItem?.processingStatus | orderProcessingStatus }}
    </div>
    <div>
      {{ orderListItem.orderValue | currency: orderListItem.currency : 'code' }}
      <span class="font-normal">| {{ orderListItem.itemsCount }} Artikel</span>
    </div>
  </div>
</a>
