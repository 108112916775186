import { Component, ChangeDetectionStrategy, Input, TemplateRef } from '@angular/core';
import { IInputGroup, FilterInput, InputGroup } from '../../tree';
import { FilterComponent } from '../../filter.component';

@Component({
  selector: 'shared-filter-filter-group-filter',
  templateUrl: 'filter-filter-group-filter.component.html',
  styleUrls: ['filter-filter-group-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FilterFilterGroupFilterComponent {
  private _inputGroup: InputGroup;

  @Input()
  set inputGroup(value: IInputGroup) {
    if (value instanceof InputGroup) {
      this._inputGroup = value;
    } else {
      this._inputGroup = InputGroup.create(value);
    }
  }

  get uiInputGroup() {
    return this._inputGroup;
  }

  private _activeInput: FilterInput;

  get activeInput() {
    return (
      this.uiInputGroup?.input?.find((f) => f?.key === this._activeInput?.key) ||
      this.uiInputGroup?.input?.find((f) => f)
    );
  }

  get isFirstInputSelected() {
    return this.activeInput === this.uiInputGroup?.input?.find((f) => f);
  }

  get inputTemplate(): TemplateRef<any> {
    return this._hostComponent.customInputs?.find((f) => f.key === this.activeInput?.key)?.templateRef;
  }

  constructor(private _hostComponent: FilterComponent) {}

  setActiveInput(input: FilterInput) {
    this._activeInput = input;
  }
}
