import { a as a$1 } from './chunk-J4VFWBJ7.js';
var a = class o {
  get barcodeSetId() {
    return this._barcodeSetId;
  }
  get completeData() {
    return this._completeData;
  }
  get encodingRanges() {
    return this._encodingRanges;
  }
  get isComplete() {
    return this._complete;
  }
  get rawCompleteData() {
    return this._rawCompleteData;
  }
  get scannedSegmentCount() {
    return this._scannedSegmentCount;
  }
  get totalSegmentCount() {
    return this._totalSegmentCount;
  }
  static fromJSON(e) {
    if (e == null) return null;
    let t = new o();
    return t._complete = e.complete, t._barcodeSetId = e.barcodeSetId, t._scannedSegmentCount = e.scannedSegmentCount, t._totalSegmentCount = e.totalSegmentCount, t._encodingRanges = e.completeDataEncodings.map(r => a$1.fromJSON(r)), t._completeData = e.completeDataUtf8String, t._rawCompleteData = e.completeDataRaw, t;
  }
  toJSONObject() {
    return {
      complete: this._complete,
      barcodeSetId: this.barcodeSetId,
      scannedSegmentCount: this.scannedSegmentCount,
      totalSegmentCount: this.totalSegmentCount,
      completeDataEncodings: this._encodingRanges.map(e => e.toJSONObject()),
      completeDataUtf8String: this.completeData,
      completeDataRaw: this.rawCompleteData
    };
  }
};
export { a };