import { Pipe, PipeTransform } from '@angular/core';
import { OrderType } from '@generated/swagger/oms-api';

@Pipe({
  name: 'orderType',
  standalone: false,
})
export class OrderTypePipe implements PipeTransform {
  orderTypeText = {
    0: '',
    1: 'Filiale',
    2: 'Online',
    3: 'Download',
    4: 'Filiale und Download',
    5: 'Online und Download',
  };

  transform(value: OrderType): string {
    return this.orderTypeText[value] || '';
  }
}
