<div class="page-customer-order-item-list-item__card-content grid grid-cols-[6rem_1fr] gap-4">
  <div>
    <img class="rounded shadow mx-auto w-[5.9rem]" [src]="orderItem?.product?.ean | productImage" [alt]="orderItem?.product?.name" />
  </div>
  <div class="grid grid-flow-row gap-2">
    <div class="grid grid-flow-col justify-between items-end">
      <span>{{ orderItem.product?.contributors }}</span>
      <a
        *ngIf="orderDetailsHistoryRoute$ | async; let orderDetailsHistoryRoute"
        [routerLink]="orderDetailsHistoryRoute.path"
        [queryParams]="orderDetailsHistoryRoute.urlTree.queryParams"
        [queryParamsHandling]="'merge'"
        class="text-brand font-bold text-xl"
      >
        Historie
      </a>
    </div>
    <div class="font-bold text-lg">
      {{ orderItem?.product?.name }}
    </div>
    <div>
      <span class="isa-label">
        {{ processingStatus$ | async | orderItemProcessingStatus }}
      </span>
    </div>
    <div class="grid grid-flow-row gap-2" *ngIf="orderItemSubsetItem$ | async; let orderItemSubsetItem">
      <div class="col-data">
        <div class="col-label">Menge</div>
        <div class="col-value">{{ orderItem?.quantity?.quantity }}x</div>
      </div>
      <div class="col-data">
        <div class="col-label">Format</div>
        <div class="col-value grid-flow-col grid gap-3 items-center justify-start">
          <shared-icon [icon]="orderItem?.product?.format"></shared-icon>
          <span>{{ orderItem?.product?.formatDetail }}</span>
        </div>
      </div>
      <div class="col-data">
        <div class="col-label">ISBN/EAN</div>
        <div class="col-value">{{ orderItem?.product?.ean }}</div>
      </div>
      <div class="col-data">
        <div class="col-label">Preis</div>
        <div class="col-value">{{ orderItem?.unitPrice?.value?.value | currency: orderItem?.unitPrice?.value?.currency : 'code' }}</div>
      </div>
      <div class="col-data">
        <div class="col-label">MwSt</div>
        <div class="col-value">{{ orderItem?.unitPrice?.vat?.inPercent }}%</div>
      </div>
      <hr />
      <div class="col-data">
        <div class="col-label">Lieferant</div>
        <div class="col-value">
          {{ orderItemSubsetItem?.supplier?.data?.name }}
        </div>
      </div>
      <div class="col-data">
        <div class="col-label">Meldenummer</div>
        <div class="col-value">{{ orderItemSubsetItem?.ssc }} - {{ orderItemSubsetItem?.sscText }}</div>
      </div>
      <div class="col-data">
        <div class="col-label">Vsl. Lieferdatum</div>
        <div class="col-value">
          {{ orderItemSubsetItem?.estimatedShippingDate | date: 'dd.MM.yyyy' }}
        </div>
      </div>
      <div class="col-data" *ngIf="orderItemSubsetItem?.preferredPickUpDate">
        <div class="col-label">Zurücklegen bis</div>
        <div class="col-value">
          {{ orderItemSubsetItem?.preferredPickUpDate | date: 'dd.MM.yyyy' }}
        </div>
      </div>
      <hr />
      <div class="col-data" *ngIf="orderItemSubsetItem?.compartmentCode">
        <div class="col-label">Abholfachnummer</div>
        <div class="col-value">
          <span>{{ orderItemSubsetItem?.compartmentCode }}</span>
          <span *ngIf="orderItemSubsetItem?.compartmentInfo">_{{ orderItemSubsetItem?.compartmentInfo }}</span>
        </div>
      </div>
      <div class="col-data">
        <div class="col-label">Vormerker</div>
        <div class="col-value">{{ isPrebooked$ | async }}</div>
      </div>
      <hr />
      <div class="col-data">
        <div class="col-label">Zahlungsweg</div>
        <div class="col-value">-</div>
      </div>
      <div class="col-data">
        <div class="col-label">Zahlungsart</div>
        <div class="col-value">
          {{ orderPaymentType$ | async | paymentType }}
        </div>
      </div>
      <div class="col-data">
        <div class="col-label">Anmerkung</div>
        <div class="col-value">
          {{ orderItemSubsetItem?.specialComment || '-' }}
        </div>
      </div>
    </div>
  </div>
</div>
