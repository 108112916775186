import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ui-searchbox-warning',
  templateUrl: 'ui-searchbox-warning.component.html',
  styleUrls: ['ui-searchbox-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UiSearchboxWarningComponent {
  constructor() {}
}
