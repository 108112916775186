import { b, a } from './chunk-N4NOODAT.js';
var m = class h extends b {
  constructor(e, o, n, r, i, c, s, P, p) {
    super(e, o, n, r), this._centerLeft = i, this._topCenter = c, this._centerRight = s, this._bottomCenter = P, this._center = p;
  }
  get centerRight() {
    return this._centerRight;
  }
  get center() {
    return this._center;
  }
  get centerLeft() {
    return this._centerLeft;
  }
  get topCenter() {
    return this._topCenter;
  }
  get bottomCenter() {
    return this._bottomCenter;
  }
  static fromJSON(e) {
    let {
        topLeft: o,
        topRight: n,
        bottomRight: r,
        bottomLeft: i,
        centerLeft: c,
        topCenter: s,
        centerRight: P,
        bottomCenter: p,
        center: S
      } = e,
      b = a.fromJSON(o),
      a$1 = a.fromJSON(n),
      J = a.fromJSON(r),
      N = a.fromJSON(i),
      f = a.fromJSON(c),
      l = a.fromJSON(s),
      u = a.fromJSON(P),
      C = a.fromJSON(p),
      _ = a.fromJSON(S);
    return new h(b, a$1, J, N, f, l, u, C, _);
  }
  toJSONObject() {
    return {
      ...super.toJSONObject(),
      center: this._center.toJSONObject(),
      bottomCenter: this._bottomCenter.toJSONObject(),
      topCenter: this._topCenter.toJSONObject(),
      centerLeft: this._centerLeft.toJSONObject(),
      centerRight: this._centerRight.toJSONObject()
    };
  }
};
export { m as a };