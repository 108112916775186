import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { CustomerInfoDTO } from '@generated/swagger/crm-api';
import { BooleanInput, NumberInput, coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { CustomerCreateNavigation, CustomerSearchNavigation } from '@shared/services/navigation';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { asapScheduler } from 'rxjs';

@Component({
  selector: 'page-customer-result-list',
  templateUrl: 'customer-result-list.component.html',
  styleUrls: ['customer-result-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CustomerResultListComponent {
  public customerSearchNavigation = inject(CustomerSearchNavigation);
  public customerCreateNavigation = inject(CustomerCreateNavigation);

  private _compact: boolean;
  @Input()
  get compact() {
    return this._compact;
  }
  set compact(value: BooleanInput) {
    this._compact = coerceBooleanProperty(value);
  }

  @Input()
  customers: CustomerInfoDTO[];

  @Input()
  selected: CustomerInfoDTO;

  @Input('hits')
  _hits: NumberInput;

  get hits() {
    return coerceNumberProperty(this._hits);
  }

  @Input('fetching')
  _fetching: BooleanInput;

  get fetching() {
    return coerceBooleanProperty(this._fetching);
  }

  @Output()
  selectedChange = new EventEmitter<CustomerInfoDTO>();

  @Output()
  endReached = new EventEmitter<void>();

  private _processId: NumberInput;
  @Input()
  get processId() {
    return this._processId;
  }
  set processId(value: NumberInput) {
    this._processId = coerceNumberProperty(value);
  }

  trackByFn = (_: number, item: CustomerInfoDTO) => item?.id;

  @ViewChild(CdkVirtualScrollViewport, { static: false })
  viewport: CdkVirtualScrollViewport;

  @Output()
  scrollIndexChange = new EventEmitter<number>();

  scrolledIndexChange(index: number) {
    if (index && this.customers.length <= this.viewport?.getRenderedRange()?.end) {
      this.endReached.emit();
    }

    this.scrollIndexChange.emit(index);
  }

  scrollToIndex(index: number, behavior?: ScrollBehavior) {
    asapScheduler.schedule(() => {
      this.viewport.scrollToIndex(index, behavior);
    });
  }
}
