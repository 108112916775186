import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NavigationService } from './navigation.service';

@Injectable({ providedIn: 'root' })
export class ProductCatalogNavigationService extends NavigationService {
  constructor(_router: Router) {
    super(_router);
  }

  getArticleSearchBasePath(processId?: number): {
    path: any[];
    extras?: NavigationExtras;
    navigate: () => Promise<boolean>;
  } {
    const path = ['/kunde', processId, 'product', { outlets: { primary: ['filter'], side: ['search'] } }].filter(
      (x) => !!x,
    );
    return { path, navigate: this._createNavigationFn(path) };
  }

  getArticleSearchResultsPath(
    processId?: number,
    extras?: NavigationExtras,
  ): { path: any[]; extras?: NavigationExtras; navigate: () => Promise<boolean> } {
    const path = ['/kunde', processId, 'product', { outlets: { primary: ['results'], side: null } }].filter((x) => !!x);
    return {
      path,
      navigate: this._createNavigationFn(path, extras),
    };
  }

  getArticleSearchResultsAndFilterPath({
    processId,
    itemId,
    extras,
  }: {
    processId?: number;
    itemId?: number;
    extras?: NavigationExtras;
  }): {
    path: any[];
    extras?: NavigationExtras;
    navigate: () => Promise<boolean>;
  } {
    const path = [
      '/kunde',
      processId,
      'product',
      { outlets: { primary: ['filter', itemId].filter((x) => !!x), side: ['results'] } },
    ].filter((x) => !!x);
    return {
      path,
      navigate: this._createNavigationFn(path, extras),
    };
  }

  getArticleDetailsPath({
    processId,
    itemId,
    extras,
  }: {
    processId: number;
    itemId?: number;
    ean?: string;
    extras?: NavigationExtras;
  }): {
    path: any[];
    extras?: NavigationExtras;
    navigate: () => Promise<boolean>;
  } {
    const path = [
      '/kunde',
      processId,
      'product',
      { outlets: { primary: ['details', itemId].filter((x) => !!x), side: ['results', itemId].filter((x) => !!x) } },
    ].filter((x) => !!x);
    return {
      path,
      navigate: this._createNavigationFn(path, extras),
    };
  }

  getArticleDetailsPathByEan({
    processId,
    ean,
    extras,
  }: {
    processId: number;
    itemId?: number;
    ean?: string;
    extras?: NavigationExtras;
  }): {
    path: any[];
    extras?: NavigationExtras;
    navigate: () => Promise<boolean>;
  } {
    const path = [
      '/kunde',
      processId,
      'product',
      {
        outlets: {
          primary: ['details', ean, 'ean'].filter((x) => !!x),
          side: ['results', ean, 'ean'].filter((x) => !!x),
        },
      },
    ].filter((x) => !!x);
    return {
      path,
      navigate: this._createNavigationFn(path, extras),
    };
  }
}
