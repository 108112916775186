import { NgModule } from '@angular/core';

import { UiDatepickerComponent } from './datepicker.component';
import { CommonModule } from '@angular/common';
import { UiDatepickerBodyComponent, UiDatepickerCellDirective, GetCellNamePipe } from './body';
import { UiDatepickerHeaderComponent } from './header';
import { UiIconModule } from '@ui/icon';
import { MatomoModule } from 'ngx-matomo-client';

@NgModule({
  imports: [CommonModule, UiIconModule, MatomoModule],
  exports: [UiDatepickerComponent],
  declarations: [
    UiDatepickerComponent,
    UiDatepickerBodyComponent,
    UiDatepickerHeaderComponent,
    UiDatepickerCellDirective,
    GetCellNamePipe,
  ],
  providers: [],
})
export class UiDatepickerModule {}
