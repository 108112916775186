import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'page-customer-create-side-view',
  templateUrl: 'customer-create-side-view.component.html',
  styleUrls: ['customer-create-side-view.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterModule],
})
export class CustomerCreateSideViewComponent {
  constructor() {}
}
