var h = (e => (e[e.AddLine = 0] = "AddLine", e[e.AddArc = 1] = "AddArc", e[e.AddCircle = 2] = "AddCircle", e[e.AddRect = 3] = "AddRect", e[e.AddRoundedRect = 4] = "AddRoundedRect", e[e.BeginPath = 5] = "BeginPath", e[e.ClosePath = 6] = "ClosePath", e[e.Fill = 7] = "Fill", e[e.Stroke = 8] = "Stroke", e[e.Clear = 9] = "Clear", e[e.SetStrokeWidth = 10] = "SetStrokeWidth", e[e.SetFillColor = 11] = "SetFillColor", e[e.SetStrokeColor = 12] = "SetStrokeColor", e[e.MoveTo = 13] = "MoveTo", e[e.LineTo = 14] = "LineTo", e[e.SaveState = 15] = "SaveState", e[e.RestoreState = 16] = "RestoreState", e[e.Translate = 17] = "Translate", e[e.ScaleAroundPoint = 18] = "ScaleAroundPoint", e[e.BezierTo = 19] = "BezierTo", e[e.Transform = 20] = "Transform", e[e.Scale = 21] = "Scale", e[e.AddPathWinding = 22] = "AddPathWinding", e))(h || {}),
  i = class {
    constructor(t) {
      this._index = 0;
      this._view = new DataView(t.buffer), this._index = 0;
    }
    extractCommandType() {
      let t = this._view.getUint8(this._index);
      return this._index += 1, t;
    }
    extractFloat() {
      let t = this._view.getFloat32(this._index, !0);
      return this._index += 4, t;
    }
    extractBoolean() {
      let t = this._view.getUint8(this._index);
      return this._index += 1, t !== 0;
    }
    extractColor() {
      let t = this._view.getUint8(this._index),
        s = this._view.getUint8(this._index + 1),
        l = this._view.getUint8(this._index + 2),
        c = this._view.getUint8(this._index + 3) / 256;
      return this._index += 4, {
        r: t,
        g: s,
        b: l,
        a: c
      };
    }
    isConsumed() {
      return this._index >= this._view.byteLength;
    }
  };
export { h as a, i as b };