import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { HistoryDTO as CrmHistoryDTO } from '@generated/swagger/crm-api';
import { HistoryDTO as OmsHistoryDTO } from '@generated/swagger/oms-api';

@Component({
  selector: 'shared-history-log-entry',
  templateUrl: 'history-log-entry.component.html',
  styleUrls: ['history-log-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SharedHistoryLogEntryComponent {
  @Input() historyLogEntryData: CrmHistoryDTO | OmsHistoryDTO;
  constructor() {}
}
