<h3>Woher wollen Sie den Artikel beziehen?</h3>

<hr />

<ng-container *ngIf="orderItem$ | async; let orderItem">
  <div class="header">
    <img
      class="thumbnail"
      loading="lazy"
      *ngIf="orderItem?.product?.ean | productImage; let productImage"
      [src]="productImage"
      [alt]="orderItem?.product?.name"
    />

    <div class="details">
      <div class="product-name">{{ orderItem.product?.name }}</div>
      <div *ngIf="orderItem.product?.format && orderItem.product.formatDetail" class="product-format">
        <img class="format-icon" [src]="'/assets/images/Icon_' + orderItem.product?.format + '.svg'" alt="format icon" />
        {{ orderItem.product.formatDetail }}
      </div>
      <div class="product-ean">
        {{ orderItem.product?.ean }}
      </div>
      <div class="quantity">{{ orderItem.quantity }}x</div>
    </div>
  </div>

  <ng-container *ngIf="availabilities$ | async; let availabilities; else: showLoadingSpinner">
    <div class="supplier-grid">
      <span></span>
      <span class="number">Bestand</span>
      <span>MS</span>
      <span title="Vormerker">VM</span>
      <span>vsl. Lieferdatum</span>
      <span class="number">Preis</span>
      <span></span>

      <ng-container *ngFor="let availability of availabilities; let i = index">
        <ng-container *ngIf="availability">
          <span class="first-cell">{{ availability.supplier | supplierName }}</span>
          <span class="number">{{ availability.qty || 0 }}</span>
          <span>{{ availability.ssc }}</span>
          <span>
            <ui-checkbox *ngIf="availability.supplier !== 'F'" [(ngModel)]="availability.isPrebooked"></ui-checkbox>
          </span>
          <span>{{ availability.at | date: 'dd.MM.yy' }}</span>
          <span class="number">{{ availability.price?.value?.value | currency: 'EUR' : 'code' }}</span>
          <span>
            <ui-select-bullet
              *ngIf="availability.supplier !== 'F' || availability.qty > 0"
              [(ngModel)]="checkedSupplier"
              [value]="availability.supplier"
              (ngModelChange)="checked($event, availability)"
            ></ui-select-bullet>
          </span>
        </ng-container>
      </ng-container>
    </div>

    <ng-container *ngIf="storeAvailabilityError$ | async">
      <div class="availability-error">Lieferantenbestand nicht verfügbar</div>
      <hr />
    </ng-container>

    <ng-container *ngIf="takeAwayAvailabilityError$ | async">
      <div class="availability-error">Filialbestand nicht verfügbar</div>
      <hr />
    </ng-container>

    <div class="reason" *ngIf="showReasons$ | async">
      <button class="reason-dropdown" [uiOverlayTrigger]="statusDropdown" #dropdown="uiOverlayTrigger">
        {{ selectedReason || 'Warum wird nachbestellt?' }}
        <ui-icon [rotate]="dropdown.opened ? '270deg' : '90deg'" icon="arrow_head"></ui-icon>
      </button>
      <ui-dropdown #statusDropdown yPosition="above" xPosition="after" [xOffset]="8">
        <button uiDropdownItem *ngFor="let reason of reorderReasons$ | async" (click)="selectedReason = reason.value; dropdown.close()">
          {{ reason.value }}
        </button>
      </ui-dropdown>

      <span *ngIf="showReasonError$ | async" class="error">Bitte wählen Sie einen Grund für das nachbestellen</span>
    </div>

    <div class="actions">
      <button class="cta-not-available cta-action-secondary" [disabled]="ctaDisabled$ | async" (click)="notAvailable()">
        <ui-spinner [show]="ctaDisabled$ | async">Nicht lieferbar</ui-spinner>
      </button>
      <button class="cta-reorder cta-action-primary" [disabled]="ctaReorderDisabled$ | async" (click)="reorder()">
        <ui-spinner [show]="ctaDisabled$ | async">Bestellen</ui-spinner>
      </button>
    </div>
  </ng-container>

  <ng-template #showLoadingSpinner>
    <ui-spinner class="load-spinner" [show]="true"></ui-spinner>
  </ng-template>
</ng-container>
