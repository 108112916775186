async function m(e, r) {
  let t = document.createElement("canvas");
  t.width = this.width, t.height = this.height;
  let a = t.getContext("2d");
  if (!a) throw new Error("Could not get 2d context from an HTMLCanvasElement");
  let n = await this.getData();
  return n == null ? null : (a.putImageData(new ImageData(n, this.width, this.height), 0, 0), new Promise((i, l) => {
    t.toBlob(o => {
      if (t = null, a = null, o === null) {
        l(new Error("Could not create Blob object from canvas"));
        return;
      }
      i(o);
    }, e, r);
  }));
}
function s(e, r) {
  let t = {
    width: e.width,
    height: e.height,
    isFrameSourceMirrored: e.isFrameSourceMirrored,
    getData: async () => (await r.requestFrameData(e.frameId)).data
  };
  return t.toBlob = m.bind(t), t;
}
export { s as a };