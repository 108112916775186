import { a } from './chunk-JKL2W65E.js';
var S = {
  CONTAINER_CLASS_NAME: a.CONTAINER_CLASS_NAME,
  PAINTBOARD_CLASS_NAME: a.PAINTBOARD_CLASS_NAME,
  PAINTBOARD_CLASS_NAME_STATE_ON: a.PAINTBOARD_CLASS_NAME_STATE_ON,
  CONTROLS_CLASS_NAME: a.CONTROLS_CLASS_NAME,
  CONTROL_WIDGET_CLASS_NAME: a.CONTROL_WIDGET_CLASS_NAME,
  MIRRORED_CLASS_NAME: a.MIRRORED_CLASS_NAME,
  CAMERA_RECOVERY_CLASS_NAME: a.CAMERA_RECOVERY_CLASS_NAME,
  ERROR_CLASS_NAME: a.ERROR_CLASS_NAME,
  HINT_CONTAINER_CLASS_NAME: a.HINT_CONTAINER_CLASS_NAME,
  HINT_CLASS_NAME: a.HINT_CLASS_NAME,
  PAINTBOARDS_CONTAINER_CLASS_NAME: a.PAINTBOARDS_CONTAINER_CLASS_NAME,
  SINGLE_IMAGE_UPLOADER_CONTAINER_CLASS_NAME: a.SINGLE_IMAGE_UPLOADER_CONTAINER_CLASS_NAME,
  CANVAS_VIDEO_PREVIEW_CLASS_NAME: a.CANVAS_VIDEO_PREVIEW_CLASS_NAME,
  TORCH_SWITCH_CONTROL_CLASS_NAME: a.TORCH_SWITCH_CONTROL_CLASS_NAME,
  CAMERA_SWITCH_CONTROL_CLASS_NAME: a.CAMERA_SWITCH_CONTROL_CLASS_NAME,
  CAMERA_FOV_SWITCH_CONTROL_CLASS_NAME: a.CAMERA_FOV_SWITCH_CONTROL_CLASS_NAME,
  SHUTTER_BUTTON_CONTROL_CLASS_NAME: a.SHUTTER_BUTTON_CONTROL_CLASS_NAME,
  FROZEN_FRAME: a.FROZEN_FRAME
};
export { S as a };