import { Component, ChangeDetectionStrategy, AfterViewInit, inject } from '@angular/core';
import { CrmCustomerService } from '@domain/crm';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';

import { HistoryDTO } from '@generated/swagger/crm-api';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CustomerSearchStore } from '../store';
import { CustomerSearchNavigation } from '@shared/services/navigation';

export interface CustomerHistoryViewMainState {
  history?: HistoryDTO[];
  fetching?: boolean;
}

@Component({
  selector: 'page-customer-history-main-view',
  templateUrl: 'history-main-view.component.html',
  styleUrls: ['history-main-view.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CustomerHistoryMainViewComponent
  extends ComponentStore<CustomerHistoryViewMainState>
  implements AfterViewInit
{
  private _store = inject(CustomerSearchStore);
  private _customerService = inject(CrmCustomerService);
  private _navigation = inject(CustomerSearchNavigation);

  fetching$ = this.select((s) => s.fetching);

  history$ = this.select((s) => s.history);

  processId$ = this._store.processId$;

  customerId$ = this._store.customerId$;

  hasKundenkarte$ = combineLatest([this._store.isKundenkarte$, this._store.isOnlineKontoMitKundenkarte$]).pipe(
    map(([isKundenkarte, isOnlineKontoMitKundenkarte]) => isKundenkarte || isOnlineKontoMitKundenkarte),
  );

  customer$ = this._store.customer$;

  detailsRoute$ = combineLatest([this.processId$, this.customerId$]).pipe(
    map(([processId, customerId]) => this._navigation.detailsRoute({ processId, customerId })),
  );

  customerName$ = this.customer$.pipe(map((customer) => `${customer?.lastName}, ${customer?.firstName}`));

  customerNumber$ = this.customer$.pipe(map((customer) => customer?.customerNumber));

  constructor() {
    super({});
  }

  ngAfterViewInit(): void {
    this.fetchHistory(this.customerId$);
  }

  fetchHistory = this.effect((customerId$: Observable<number>) =>
    customerId$.pipe(
      tap(() => this.patchState({ fetching: true })),
      switchMap((customerId) =>
        this._customerService
          .getCustomerHistory(customerId)
          .pipe(
            tapResponse(this.handleFetchHistoryResponse, this.handleFetchHistoryError, this.handleFetchHistoryComplete),
          ),
      ),
    ),
  );

  handleFetchHistoryResponse = (history: HistoryDTO[]) => {
    this.patchState({ history });
  };

  handleFetchHistoryError = (err: any) => {
    this.patchState({ fetching: false });
    console.error(err);
  };

  handleFetchHistoryComplete = () => {
    this.patchState({ fetching: false });
  };
}
