import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HistoryDTO as CrmHistoryDTO } from '@generated/swagger/crm-api';
import { HistoryDTO as OmsHistoryDTO } from '@generated/swagger/oms-api';

@Component({
  selector: 'shared-history-list',
  templateUrl: 'history-list.component.html',
  styleUrls: ['history-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SharedHistoryListComponent {
  @Input() history: CrmHistoryDTO[] | OmsHistoryDTO[];
  constructor() {}
}
