import { a } from './chunk-SP3HSP4K.js';
var p = (a => (a.Auto = "auto", a.Off = "off", a.On = "on", a))(p || {}),
  n = class {
    constructor(e) {
      this.overrideThreadsSupport = "auto";
      this.overrideSimdSupport = "auto";
      this.workerCommandId = 1;
      this.workerTasks = new Map();
      this.workerListeners = [];
      var r;
      this.libraryLocation = e.libraryLocation, this.workerMessageListener = this.onWorkerMessage.bind(this), this.dataCaptureWorker.addEventListener("message", this.workerMessageListener), this.overrideThreadsSupport = e.overrideThreadsSupport, this.overrideSimdSupport = e.overrideSimdSupport, this.verifyResponseHash = e.verifyResponseHash, this.highEndBlurryRecognition = (r = e.highEndBlurryRecognition) != null ? r : !1, typeof e.loadProgressNotifier == "function" && this.addWorkerListener(a => {
        var o;
        a.type === "loadLibraryProgress" && ((o = e.loadProgressNotifier) == null || o.call(e, a.payload));
      });
    }
    get dataCaptureWorker() {
      return this._dataCaptureWorker != null || (this._dataCaptureWorker = void 0), this._dataCaptureWorker;
    }
    get name() {
      return "CoreLoader";
    }
    get fixedWasmMemory() {
      return null;
    }
    static async create(e) {
      var a$1;
      let r = new this(e);
      return await r.workerCommand("setLogLevel", {
        level: (a$1 = e.logLevel) != null ? a$1 : a.Level.Debug
      }), await r.load(), r;
    }
    async load() {
      var e;
      try {
        await this.workerCommand("loadLibrary", {
          libraryLocation: this.libraryLocation,
          locationPath: window.location.pathname,
          referredOrigin: window.location.origin,
          writableDataPathOverride: (e = localStorage.getItem("scanditWritableDataPathOverride")) != null ? e : void 0,
          overrideThreadsSupport: this.overrideThreadsSupport,
          overrideSimdSupport: this.overrideSimdSupport,
          verifyResponseHash: this.verifyResponseHash,
          fixedWasmMemory: this.fixedWasmMemory
        });
      } catch (r) {
        if (r != null && typeof r == "object" && typeof r.error == "string" && r.error.includes("BlockedIndexedDB")) return await this.terminateDataCaptureWorker(!1), this.dataCaptureWorker.addEventListener("message", this.workerMessageListener), localStorage.setItem("scanditWritableDataPathOverride", `/scandit_sync_folder_${[...crypto.getRandomValues(new Uint32Array(2))].map(a => a.toString(32)).join("")}`), this.load();
        throw r;
      }
    }
    async workerCommand(e, r, a) {
      return new Promise((o, s) => {
        let t = this.workerCommandId++;
        this.workerTasks.set(t, {
          resolve: o,
          reject: s,
          command: e
        });
        let d = {
          ...r,
          command: e,
          id: t
        };
        this.dataCaptureWorker.postMessage(d, a);
      });
    }
    async terminateDataCaptureWorker(e = !0) {
      var r, a;
      e && (await this.workerCommand("dispose", {})), (r = this._dataCaptureWorker) == null || r.removeEventListener("message", this.workerMessageListener), (a = this._dataCaptureWorker) == null || a.terminate(), this._dataCaptureWorker = void 0;
    }
    addWorkerListener(e) {
      this.workerListeners.push(e);
    }
    removeWorkerListener(e) {
      this.workerListeners.includes(e) && this.workerListeners.splice(this.workerListeners.indexOf(e), 1);
    }
    getOptions() {
      return {
        libraryLocation: this.libraryLocation,
        overrideThreadsSupport: this.overrideThreadsSupport,
        overrideSimdSupport: this.overrideSimdSupport,
        verifyResponseHash: this.verifyResponseHash
      };
    }
    onWorkerMessage(e) {
      let r = e.data;
      if (r.type === "workerTaskId" && typeof r.id == "number") {
        let {
            id: a,
            error: o,
            payload: s
          } = r,
          t = this.workerTasks.get(a);
        o == null ? t == null || t.resolve(s) : t == null || t.reject(r), this.workerTasks.delete(a);
      } else for (let a of this.workerListeners) a(r);
    }
  };
export { p as a, n as b };