import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'page-customer',
  templateUrl: 'customer-page.component.html',
  styleUrls: ['customer-page.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CustomerComponent {
  processId$ = this._activatedRoute.data.pipe(map((data) => data.processId));

  constructor(private _activatedRoute: ActivatedRoute) {}
}
