import { a as a$1 } from './chunk-XVLYCDJL.js';
import { b as b$1, a } from './chunk-KYNYQBNF.js';
import { i, a as a$2 } from './chunk-N4NOODAT.js';
var b = {
    bottomLeft: "bottomRight",
    bottomRight: "bottomLeft",
    topLeft: "topRight",
    topRight: "topLeft",
    centerLeft: "centerRight",
    centerRight: "centerLeft",
    center: "center",
    topCenter: "topCenter",
    bottomCenter: "bottomCenter"
  },
  s = class s {
    constructor() {
      this.listener = null;
      this._mounted = !1;
      this._mirrored = !1;
      this._cachedSize = new i(0, 0);
      this.resizeObserver = null;
      this.mapViewAnchorPositionsForFramePoint = e => e;
    }
    get size() {
      return this._cachedSize;
    }
    setMirrored(e) {
      this._mounted && (this._mirrored = e);
    }
    mount(e) {
      var o;
      if (this._mounted) return;
      if (!document.head.querySelector(`[data-component=${s.componentName}]`)) {
        let t = document.createElement("style");
        t.dataset.component = s.componentName, t.append(document.createTextNode(b$1)), document.head.append(t);
      }
      let n = document.createElement("div");
      n.classList.add(a.CUSTOM_VIEW_CONTAINER), (o = e.parentNode) == null || o.replaceChild(n, e), this._htmlRoot = n, this.gestureRecognizer = new a$1(this._htmlRoot), this.gestureRecognizer.addListener({
        onTap: (t, r) => {
          var l, i;
          let m = (l = r.target.dataset.identifier) != null ? l : "";
          m !== "" && ((i = this.listener) == null || i.didTapView(Number.parseInt(m, 10)));
        }
      }), this.resizeObserver = new ResizeObserver(([t]) => {
        this._cachedSize = new i(t.contentRect.width, t.contentRect.height);
      }), this.resizeObserver.observe(this._htmlRoot), this._cachedSize = new i(this._htmlRoot.clientWidth, this._htmlRoot.clientHeight), this._mounted = !0;
    }
    unmount() {
      var e, n, o, t;
      this._mounted && (this.removeChildren(), (e = this.gestureRecognizer) == null || e.removeAllListeners(), (n = this.resizeObserver) == null || n.disconnect(), (o = this._htmlRoot) == null || o.remove(), this._htmlRoot = null, this.gestureRecognizer = null, (t = document.head.querySelector(`[data-component=${s.componentName}]`)) == null || t.remove(), this.listener = null, this._mounted = !1);
    }
    render(e) {
      var o, t, r, m, l;
      if (e.elements.length === 0) {
        this.removeChildren();
        return;
      }
      for (let i of e.removedIds) (t = (o = this._htmlRoot) == null ? void 0 : o.querySelector(`#${s.elementSelectorPrefix}${i}`)) == null || t.remove();
      let n = document.createDocumentFragment();
      for (let i of e.elements) {
        let u = `${s.elementSelectorPrefix}${i.identifier}`,
          f = (r = this._htmlRoot) == null ? void 0 : r.querySelector(`#${u}`),
          a = f;
        if (f == null && (a = new Image(i.view.options.size.width, i.view.options.size.height), a.id = u, a.dataset.identifier = String(i.identifier), n.append(a)), a) {
          a.src = i.view.data;
          let d = this.mapViewAnchorPositionsForFramePoint(i.anchorPositions)[this._mirrored ? b[i.anchor] : i.anchor];
          this._mirrored && (d = a$2.mirrorX(d, this.size));
          let {
              x: S,
              y: w
            } = this.adjustCoordinatesWithOffset(d, {
              elementSize: i.view.options.size,
              offset: i.offset
            }),
            p = (m = i.view.options.scale) != null ? m : 1;
          a.style.scale = `${p}`, a.style.transform = `translate3d(${S / p}px, ${w / p}px, 0px)`;
        }
      }
      (l = this._htmlRoot) == null || l.append(n);
    }
    removeChildren() {
      var e;
      for (; (e = this._htmlRoot) != null && e.firstChild;) this._htmlRoot.firstChild.remove();
    }
    adjustCoordinatesWithOffset(e, n) {
      let o = e,
        {
          elementSize: t,
          offset: r
        } = n,
        m = e.x - t.width / 2,
        l = e.y - t.height / 2;
      return r.x.unit === "pixel" && (o = new a$2(m + r.x.value, l + r.y.value)), r.x.unit === "fraction" && (o = new a$2(m + t.width * r.x.value, l + t.height * r.y.value)), o;
    }
  };
s.componentName = "CustomLocationsView", s.elementSelectorPrefix = "scandit-view-";
var R = s;
export { R as a };