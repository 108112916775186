import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterInputOptionBoolComponent } from './filter-input-option-bool.component';
import { UiCheckboxModule } from '@ui/checkbox';
import { FormsModule } from '@angular/forms';
import { UiIconModule } from '@ui/icon';
import { MatomoModule } from 'ngx-matomo-client';

@NgModule({
  imports: [CommonModule, FormsModule, UiCheckboxModule, UiIconModule, MatomoModule],
  exports: [FilterInputOptionBoolComponent],
  declarations: [FilterInputOptionBoolComponent],
})
export class FilterInputOptionBoolModule {}
