export { b as DataCaptureLoader, a as OverrideState } from './chunks/chunk-2QPETYTY.js';
import { a as a$1 } from './chunks/chunk-VJB527QZ.js';
export { a as WorkerMain } from './chunks/chunk-T7MTTUD3.js';
import { a as a$3 } from './chunks/chunk-ZAOFG2OQ.js';
export { c as CameraFOVSwitchControl, b as CameraSwitchControl, a as TorchSwitchControl } from './chunks/chunk-T5EUB6UG.js';
export { a as NoViewfinder } from './chunks/chunk-4ZMCJXZS.js';
export { a as convertToPublicFrameData } from './chunks/chunk-UHFABUTI.js';
export { a as ImageFrameSource } from './chunks/chunk-VRVSELOR.js';
export { a as LaserlineViewfinder } from './chunks/chunk-ONUQJGRK.js';
export { a as PrivateNoneLocationSelection, b as RadiusLocationSelection, c as RectangularLocationSelection } from './chunks/chunk-KW3YSGJX.js';
export { a as RectangularViewfinder } from './chunks/chunk-ANTPRJPM.js';
export { a as SingleImageUploader } from './chunks/chunk-CCNVXFEG.js';
export { a as SingleImageUploaderSettings } from './chunks/chunk-BTXDPF7O.js';
export { a as DataCaptureVersion } from './chunks/chunk-2RBWWPAD.js';
export { a as DataCaptureView } from './chunks/chunk-V4B2NHK3.js';
import { a as a$5 } from './chunks/chunk-N5YZEC56.js';
export { a as AnchorPositions } from './chunks/chunk-N5F43TUJ.js';
import { a } from './chunks/chunk-DTSV5FYV.js';
export { a as Localization } from './chunks/chunk-DTSV5FYV.js';
export { c as Feedback, b as Sound, a as Vibration } from './chunks/chunk-AWSARCBG.js';
export { a as AimerViewfinder } from './chunks/chunk-VXLIGYOC.js';
export { a as Brush } from './chunks/chunk-G7A5SQZE.js';
export { a as Camera } from './chunks/chunk-45EBHQYM.js';
export { a as CameraAccess } from './chunks/chunk-IWMYUNZ3.js';
export { a as DataCaptureContext } from './chunks/chunk-EGMJHFGI.js';
import { a as a$6, c } from './chunks/chunk-F3A3WWOG.js';
import { a as a$2 } from './chunks/chunk-EMHHC6NB.js';
import { b as b$1 } from './chunks/chunk-6H6FKGVE.js';
export { b as loadingStatus } from './chunks/chunk-6H6FKGVE.js';
export { a as DataCaptureContextSettings } from './chunks/chunk-7SW2PEHB.js';
export { a as throttle } from './chunks/chunk-T3AOBMAS.js';
export { a as Timeout } from './chunks/chunk-Q7JWDX4U.js';
export { a as wait } from './chunks/chunk-UZWBB65W.js';
export { a as waitTransitionEndForElement } from './chunks/chunk-72DAONJI.js';
export { a as defineCustomElements } from './chunks/chunk-3A4KLILV.js';
export { a as html } from './chunks/chunk-U6BTTEV6.js';
export { a as match } from './chunks/chunk-ZVBD3EW5.js';
export { a as ScanditHTMLElement } from './chunks/chunk-EV4OEANA.js';
export { a as css } from './chunks/chunk-GE4CBD4E.js';
export { a as unknownErrorToString } from './chunks/chunk-AIRWHLJH.js';
export { a as isObject } from './chunks/chunk-FGO2SSRS.js';
export { a as isWorkerScope } from './chunks/chunk-QCRFVRSS.js';
export { a as Deferred } from './chunks/chunk-O7TZTIHO.js';
export { a as debounce } from './chunks/chunk-R6E4CT22.js';
export { a as assert, b as assertUnreachable, c as assertUnreachableThrowException } from './chunks/chunk-FTD535WI.js';
import { a as a$9 } from './chunks/chunk-GPJEB376.js';
import { a as a$4 } from './chunks/chunk-SP3HSP4K.js';
export { a as Logger } from './chunks/chunk-SP3HSP4K.js';
import { a as a$7 } from './chunks/chunk-QCZSSQAQ.js';
export { a as DataCaptureError } from './chunks/chunk-QCZSSQAQ.js';
import { a as a$8 } from './chunks/chunk-PSQ6LAQA.js';
export { a as BrowserHelper } from './chunks/chunk-PSQ6LAQA.js';
export { a as Feature } from './chunks/chunk-5LIWL2C4.js';
export { c as CameraPosition, g as CameraSettings, e as FocusGestureStrategy, a as FrameSourceState, b as TorchState, d as VideoResolution, f as isCameraFrameSource } from './chunks/chunk-36CGJKPG.js';
export { c as LaserlineViewfinderStyle, d as RectangularViewfinderAnimation, b as RectangularViewfinderLineStyle, a as RectangularViewfinderStyle } from './chunks/chunk-JUG6FO4O.js';
export { a as LogoStyle, c as SwipeToZoom, b as TapToFocus } from './chunks/chunk-GX4YCYCH.js';
export { q as Anchor, n as Color, p as Direction, m as MarginsWithUnit, c as MeasureUnit, d as NumberWithUnit, o as Orientation, a as Point, e as PointWithUnit, b as Quadrilateral, f as Rect, g as RectWithUnit, i as Size, j as SizeWithAspect, h as SizeWithUnit, l as SizeWithUnitAndAspect, k as SizingMode } from './chunks/chunk-N4NOODAT.js';
export { a as ContextStatus, b as PrivateMirrorAxis } from './chunks/chunk-WUHKODFA.js';
var p = a$2;
a.getInstance().updateIfMissing(a$1);
async function w() {
  var r;
  a$2.configurePhase = "unconfigured", (r = p.mainDataCaptureLoader) != null && r.terminateDataCaptureWorker && (await p.mainDataCaptureLoader.terminateDataCaptureWorker(!0));
}
function l({
  percentage: r
}) {
  for (let e of a$5.values()) e.setProgressBarPercentage(r);
}
function h() {
  var o, u;
  function r(x) {
    return ["auto", "off", "on"].includes(x);
  }
  let e = new URLSearchParams(window.location.search),
    [i = "auto", a = "auto"] = [(o = e.get("pthreads")) == null ? void 0 : o.toLowerCase(), (u = e.get("simd")) == null ? void 0 : u.toLowerCase()];
  return {
    overrideThreadsSupport: r(i) ? i : "auto",
    overrideSimdSupport: r(a) ? a : "auto"
  };
}
async function S(r) {
  if (a$6() && typeof r.licenseDataPath != "string") throw new a$7({
    name: "MisconfigurationError",
    message: '"licenseDataPath" string is missing or not valid'
  });
  if (a$6() && typeof r.licenseDataPath == "string") {
    let e = await c({
      licenseDataPath: r.licenseDataPath
    });
    if (e == null) throw new Error("Cannot retrieve license key for Electron");
    r.licenseKey = e.licenseKey;
  }
  if (r.licenseKey == null || r.licenseKey.trim().length < 64) throw new a$7({
    name: "NoLicenseKeyError",
    message: "No license key provided"
  });
  if (typeof r.libraryLocation != "string") throw new a$7({
    name: "MisconfigurationError",
    message: 'Option "libraryLocation" was not provided'
  });
  if (!Array.isArray(r.moduleLoaders) || r.moduleLoaders.length === 0) throw new a$7({
    name: "MisconfigurationError",
    message: 'Option "moduleLoaders" is missing or empty'
  });
}
async function b(r, e) {
  let i = new Map(),
    a = new Map();
  for (let o of r) a.set(o.moduleName, o.load(e));
  await Promise.all(a.values());
  for (let [o, u] of a) i.set(o, await u);
  return i;
}
async function ne(r) {
  var a;
  if (p.configurePhase !== "unconfigured" && p.configurePromise != null) return p.configurePromise;
  await S(r);
  let e = {
    ...h(),
    ...r,
    libraryLocation: a$3(r.libraryLocation),
    logLevel: (a = r.logLevel) != null ? a : a$4.Level.Debug,
    verifyResponseHash: !0,
    loadProgressNotifier: b$1.notify.bind(b$1)
  };
  a$2.userLicenseKey = e.licenseKey, a$4.setLevel(e.logLevel);
  async function i() {
    a$2.configurePhase = "started", b$1.subscribe(l);
    let o = a$8.checkBrowserCompatibility();
    if (!o.fullSupport && !o.scannerSupport) throw new a$9(o);
    a$8.isSupportedIOSVersion() || a$4.log(a$4.Level.Warn, `Warning: Minimum supported iOS version is 14.6.
Please check the minimum system requirements here:
https://docs.scandit.com/data-capture-sdk/web/requirements.html`), a$2.dataCaptureLoaders = await b(e.moduleLoaders, e), a$2.mainDataCaptureLoader = [...a$2.dataCaptureLoaders.entries()][0][1], a$2.configurePhase = "done", b$1.unsubscribe(l);
  }
  return a$2.configurePromise = i().catch(o => {
    throw w(), b$1.unsubscribe(l), o;
  }), a$2.configurePromise;
}
function me(r) {
  a$2.mainDataCaptureLoader = r;
}
export { ne as configure, w as resetConfigure, me as setMainDataCaptureLoader };