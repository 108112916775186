import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-spinner',
  templateUrl: 'ui-spinner.component.html',
  styleUrls: ['./ui-spinner.component.scss'],
  standalone: false,
})
export class SpinnerComponent implements OnInit {
  @Input()
  show: boolean;

  constructor() {}

  ngOnInit(): void {}
}
