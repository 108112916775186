import { createAction, props } from '@ngrx/store';
import { ApplicationProcess } from '..';

const prefix = '[CORE-APPLICATION]';

export const setTitle = createAction(`${prefix} Set Title`, props<{ title: string }>());

export const setSection = createAction(`${prefix} Set Section`, props<{ section: 'customer' | 'branch' }>());

export const addProcess = createAction(`${prefix} Add Process`, props<{ process: ApplicationProcess }>());

export const removeProcess = createAction(`${prefix} Remove Process`, props<{ processId: number }>());

export const setActivatedProcess = createAction(
  `${prefix} Set Activated Process`,
  props<{ activatedProcessId: number }>(),
);

export const patchProcess = createAction(
  `${prefix} Patch Process`,
  props<{ processId: number; changes: Partial<ApplicationProcess> }>(),
);

export const patchProcessData = createAction(
  `${prefix} Patch Process Data`,
  props<{ processId: number; data: Record<string, any> }>(),
);
