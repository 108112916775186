var t;
(u => {
  (e => (e.Debug = "debug", e.Info = "info", e.Warn = "warn", e.Error = "error", e.Quiet = "quiet"))(u.Level || (u.Level = {}));
  let n = new Map([["debug", 1], ["info", 2], ["warn", 3], ["error", 4], ["quiet", 5]]),
    l = "debug";
  function a(r) {
    l = r;
  }
  u.setLevel = a;
  function v(r, ...o) {
    if (!(n.get(l) > n.get(r))) switch (r) {
      case "debug":
        console.debug(...o);
        break;
      case "info":
        console.log(...o);
        break;
      case "warn":
        console.warn(...o);
        break;
      case "error":
        console.error(...o);
        break;
    }
  }
  u.log = v;
})(t || (t = {}));
export { t as a };