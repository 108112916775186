import { CustomerDTO, CustomerInfoDTO } from '@generated/swagger/crm-api';
import {
  AddressFormBlockData,
  DeviatingAddressFormBlockData,
  PhoneNumbersFormBlockData,
} from '../components/form-blocks';
import { InterestsFormBlockData } from '../components/form-blocks/interests/interests-form-block-data';
import { NameFormBlockData } from '../components/form-blocks/name/name-form-block-data';
import { OrganisationFormBlockData } from '../components/form-blocks/organisation/organisation-form-block-data';

export interface CustomerCreateFormData {
  _meta?: {
    customerDto?: CustomerDTO;
    customerInfoDto?: CustomerInfoDTO;
    p4mRequired?: boolean;
    hasLocalityCard: boolean;
    customerType: string;
  };
  agb?: boolean;
  address?: AddressFormBlockData;
  birthDate?: string;
  billingAddress?: DeviatingAddressFormBlockData;
  deviatingDeliveryAddress?: DeviatingAddressFormBlockData;
  email?: string;
  interests?: InterestsFormBlockData;
  name?: NameFormBlockData;
  newsletter?: boolean;
  organisation?: OrganisationFormBlockData;
  p4m?: string;
  phoneNumbers?: PhoneNumbersFormBlockData;
}

export function mapCustomerDtoToCustomerCreateFormData(customerDto: CustomerDTO): CustomerCreateFormData {
  let p4m = customerDto.attributes?.find((attr) => attr.data.key === 'p4mUser')?.data.value;

  if (!p4m) {
    p4m = customerDto.features?.find((f) => f.key === 'p4mUser')?.value;
  }

  const customerType = customerDto.features?.find((f) => f.key === 'customerType')?.value;

  const hasLocalityCard = !!p4m;

  return {
    _meta: {
      customerDto,
      p4mRequired: !!p4m,
      hasLocalityCard,
      customerType,
    },
    p4m,
    address: customerDto.address,
    birthDate: customerDto.dateOfBirth,
    email: customerDto?.communicationDetails?.email,
    name: {
      gender: customerDto?.gender,
      title: customerDto?.title,
      firstName: customerDto?.firstName,
      lastName: customerDto?.lastName,
    },
    organisation: customerDto.organisation,
    phoneNumbers: {
      mobile: customerDto?.communicationDetails?.mobile,
      phone: customerDto?.communicationDetails?.phone,
    },
    agb: !!p4m,
  };
}

export function mapCustomerInfoDtoToCustomerCreateFormData(customerInfoDto: CustomerInfoDTO): CustomerCreateFormData {
  const p4m = customerInfoDto.features?.find((f) => f.key === 'p4mUser')?.value;

  const interests = customerInfoDto.features
    ?.filter((f) => f.group === 'KUBI_INTERESSEN')
    .reduce<Record<string, boolean>>((dic, f) => {
      dic[f.key] = true;
      return dic;
    }, {});

  const newsletter = !!customerInfoDto.features?.find(
    (f) => f.key === 'kubi_newsletter' && f.group === 'KUBI_NEWSLETTER',
  );

  const hasLocalityCard = !!p4m;

  const customerType = customerInfoDto.features?.find((f) => f.key === 'customerType')?.value;

  return {
    _meta: {
      customerInfoDto,
      p4mRequired: !!p4m,
      hasLocalityCard,
      customerType,
    },
    p4m,
    address: customerInfoDto.address,
    birthDate: customerInfoDto.dateOfBirth,
    email: customerInfoDto?.communicationDetails?.email,
    name: {
      gender: customerInfoDto?.gender,
      title: customerInfoDto?.title,
      firstName: customerInfoDto?.firstName,
      lastName: customerInfoDto?.lastName,
    },
    organisation: customerInfoDto.organisation,
    phoneNumbers: {
      mobile: customerInfoDto?.communicationDetails?.mobile,
      phone: customerInfoDto?.communicationDetails?.phone,
    },
    agb: !!p4m,
    interests,
    newsletter,
  };
}

export function encodeFormData(data: CustomerCreateFormData): string {
  return encodeURIComponent(JSON.stringify(data));
}

export function decodeFormData(data: string): CustomerCreateFormData {
  return JSON.parse(decodeURIComponent(data));
}
