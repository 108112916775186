import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'substr',
  standalone: false,
})
export class SubstrPipe implements PipeTransform {
  transform(value: string, length: number, placeholder = '...'): any {
    if (typeof value === 'string' && value.length > length + placeholder.length) {
      return `${value.substr(0, length)}${placeholder}`;
    }
    return value;
  }
}
