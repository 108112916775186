<div class="quantity-control-wrapper" *ngIf="quantity">
  <ui-spinner [show]="showSpinner">
    <button class="current-quantity" type="button" [disabled]="disabled" [uiOverlayTrigger]="quantityControl" *ngIf="!customInput">
      {{ quantity }}{{ suffix }}
      <ui-icon class="r90deg" size="14px" icon="arrow_head"></ui-icon>
    </button>
  </ui-spinner>
  <input
    #quantity
    class="current-quantity"
    [(ngModel)]="quantityInputValue"
    type="number"
    [min]="minQuantity"
    [max]="range"
    *ngIf="customInput"
    placeholder="..."
  />

  <ui-quantity-dropdown-content
    (quantityChange)="setQuantity($event); quantityControl.close()"
    (enableCustomInput)="enableCustomeInput()"
    [range]="range"
    [showTrash]="showTrash"
    yPosition="below"
    xPosition="after"
    #quantityControl
  ></ui-quantity-dropdown-content>
</div>
<button
  *ngIf="!!customInput"
  class="action"
  [disabled]="!quantityInputValue || quantityInputValue < minQuantity || quantityInputValue > range"
  type="button"
  (click)="setQuantity(quantityInputValue)"
>
  Aktualisieren
</button>
