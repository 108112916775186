import { inject, Injectable } from '@angular/core';
import { ActionHandler } from '@core/command';
import { OrderItemsContext } from './order-items.context';
import { DomainPrinterService } from '@domain/printer';
import { ConfirmModalData, UiConfirmModalComponent, UiErrorModalComponent, UiModalService } from '@ui/modal';
import { PriceQRCodeDTO } from '@generated/swagger/print-api';
import { PrintModalComponent } from '@modal/printer';

@Injectable()
export class PrintPriceDiffQrCodeLabelActionHandler extends ActionHandler<OrderItemsContext> {
  private uiModal = inject(UiModalService);
  private domainPrinterService = inject(DomainPrinterService);

  constructor() {
    super('PRINT_PRICEDIFFQRCODELABEL');
  }

  async handler(data: OrderItemsContext): Promise<OrderItemsContext> {
    await this.print(data);

    const result = await this.uiModal
      .open({
        content: UiConfirmModalComponent,
        title: 'Bestellpreis abweichend zum Kassenpreis',
        data: {
          message:
            'Der gedruckte QR Code enthält den richtigen\nBestellpreis. Bitte überkleben Sie mit dem Ausdruck\ndas Etikett auf dem Artikel.',
          confirmLabel: 'In Ordnung',
          rejectLabel: 'QR Code erneut drucken',
        } as ConfirmModalData,
      })
      .afterClosed$.toPromise();

    if (result?.data === false) {
      await this.print(data);
    }

    return data;
  }

  private async print(data: OrderItemsContext) {
    await this.uiModal
      .open({
        content: PrintModalComponent,
        config: { showScrollbarY: false },
        data: {
          printImmediately: true,
          printerType: 'Label',
          print: async (printer) => {
            return await this.printQrCode(data, printer);
          },
        },
      })
      .afterClosed$.toPromise();
  }

  private async printQrCode(data: OrderItemsContext, printer: string) {
    try {
      const payload = data.items.map((item) => {
        return {
          compartmentCode: data.compartmentCode,
          compartmentInfo: data.compartmentInfo,
          ean: item.product?.ean,
          price: item.retailPrice?.value,
          title: item.product?.name,
          copies: data.itemQuantity?.get(item.orderItemSubsetId) || item?.quantity,
        } as PriceQRCodeDTO;
      });

      const response = await this.domainPrinterService.printQrCode({ printer, data: payload }).toPromise();
      if (response?.error) {
        this.uiModal.open({
          content: UiErrorModalComponent,
          title: 'Fehler beim Drucken des QR Code',
          data: { message: response?.message },
        });
      }
      return response;
    } catch (err) {
      this.uiModal.open({ content: UiErrorModalComponent, title: 'Fehler beim Drucken des QR Code', data: err });
    }
  }
}
