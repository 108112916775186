import { a } from './chunk-MAMZS5CH.js';
import { a as a$1 } from './chunk-3A4KLILV.js';
import { a as a$2 } from './chunk-U6BTTEV6.js';
var e = class e extends a {
  static create() {
    return document.createElement(e.tag);
  }
  static register() {
    a$1({
      [e.tag]: e
    });
  }
  render() {
    return a$2`
      <style>
        ${e.tag} {
          display: flex;
        }
      </style>
      <svg width="${this.size}" height="${this.heightForViewbox(24, 24)}" viewBox="0 0 24 24" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 7C11.4477 7 11 7.44772 11 8V13C11 13.5523 11.4477 14 12 14C12.5523 14 13 13.5523 13 13V8C13 7.44772 12.5523 7 12 7ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
          fill="${this.fill}"
        />
      </svg>
    `;
  }
};
e.tag = "scandit-rounded-exclamation-mark-icon";
var t = e;
t.register();
export { t as a };