import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { IUiOption, UiOption } from '../../../tree';

@Component({
  selector: 'ui-input-option-date-range',
  templateUrl: 'filter-input-option-date-range.component.html',
  styleUrls: ['filter-input-option-date-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UiFilterInputOptionDateRangeComponent {
  private _options: UiOption[];

  @Input()
  set options(value: IUiOption[]) {
    this._options = value?.map((option) => (option instanceof UiOption ? option : UiOption.create(option)));
    this.subscribeChanges();
  }

  get uiOptions() {
    return this._options;
  }

  get uiStartOption() {
    return this.uiOptions?.find((o) => o.key === 'start');
  }

  get uiStopOption() {
    return this.uiOptions?.find((o) => o.key === 'stop');
  }

  get uiStopOptionValue() {
    const stopDate = new Date(this.uiStopOption?.value);
    stopDate?.setDate(stopDate?.getDate() - 1); // to update the view correctly after setStopValue() gets called !
    return stopDate?.toJSON();
  }

  optionChangeSubscription: Subscription;

  constructor(private cdr: ChangeDetectorRef) {}

  subscribeChanges() {
    this.unsubscribeChanges();
    if (this.uiStartOption) {
      this.optionChangeSubscription.add(
        this.uiStartOption.changes.subscribe(() => {
          this.cdr.markForCheck();
        }),
      );
    }
    if (this.uiStopOption) {
      this.optionChangeSubscription.add(
        this.uiStopOption.changes.subscribe(() => {
          this.cdr.markForCheck();
        }),
      );
    }
  }

  unsubscribeChanges() {
    this.optionChangeSubscription?.unsubscribe();
    this.optionChangeSubscription = new Subscription();
  }

  setStopValue(date: Date) {
    const stopDate = date;
    stopDate?.setDate(stopDate?.getDate() + 1); // to include the selected stop date !
    this.uiStopOption?.setValue(stopDate);
  }
}
