<shared-form-control label="Geburtsdatum (TT.MM.JJJJ)">
  <input
    placeholder="Geburtsdatum"
    class="input-control"
    uiDateInput
    type="text"
    [formControl]="control"
    [tabindex]="tabIndexStart"
   
    [readonly]="readonly"
  />
</shared-form-control>
