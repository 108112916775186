import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[uiDropdownItem]',
  standalone: false,
})
export class UiDropdownItemDirective {
  @Input()
  @HostBinding('disabled')
  disabled: boolean;
  focused: boolean;

  constructor(private elementRef: ElementRef) {}

  @HostListener('focus')
  onFocus() {
    this.focused = true;
  }

  @HostListener('blur')
  onBlur() {
    this.focused = false;
  }

  private _onClose: () => void = () => {};

  registerOnClose(fn: () => void) {
    this._onClose = fn;
  }

  @HostListener('click')
  close() {
    this._onClose();
  }

  focus() {
    setTimeout(() => {
      this.elementRef.nativeElement.focus();
    }, 1);
  }
}
