import { Pipe, PipeTransform } from '@angular/core';
import { BranchDTO } from '@generated/swagger/checkout-api';

export type BranchNameFormat = 'key' | 'name' | 'key-name' | 'name-address';

@Pipe({
  name: 'branchName',
  standalone: true,
  pure: true,
})
export class BranchNamePipe implements PipeTransform {
  transform(value: BranchDTO, format: BranchNameFormat = 'key-name'): any {
    if (!value) return 'Filiale auswählen';

    if (format === 'key-name') {
      return `${value.key} - ${value.name}`;
    }

    if (format === 'name') {
      return value.name;
    }

    if (format === 'key') {
      return value.key;
    }

    if (format === 'name-address') {
      return `${value.name} | ${value.address?.street} ${value.address?.streetNumber}, ${value.address?.zipCode} ${value.address?.city}`;
    }

    return '';
  }
}
