import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { isResponseArgs } from '@utils/object';
import { isEmpty } from 'lodash';
import { UiModalRef } from '../defs';

@Component({
  selector: 'ui-error-modal',
  template: `
    <p class="message">
      {{ errorMessage }}
    </p>
    <div class="actions">
      <button (click)="modalRef.close()">OK</button>
    </div>
  `,
  styleUrls: ['./error-modal.component.scss'],
  standalone: false,
})
export class UiErrorModalComponent implements OnInit {
  get errorMessage() {
    if (!navigator.onLine || (this.error instanceof HttpErrorResponse && this.error?.status === 0)) {
      return this.getOfflineMessage();
    }
    if (this.error instanceof HttpErrorResponse) {
      if (isResponseArgs(this.error?.error)) {
        return (
          this.getMessageFromInvalidProperties(this.error?.error?.invalidProperties) ||
          this.error?.error?.message ||
          this.error?.message
        );
      }
      return this.getMessageFromHttpErrorResponse(this.error);
    } else if (isResponseArgs(this.error)) {
      return this.getMessageFromInvalidProperties(this.error?.invalidProperties) || this.error?.message;
    }

    return this.error?.message;
  }

  get error() {
    return this.modalRef.data;
  }

  constructor(public modalRef: UiModalRef<undefined, Error>) {}

  ngOnInit() {}

  getOfflineMessage() {
    return 'Bereits geladene Inhalte werden angezeigt. Interaktionen sind aktuell nicht möglich.';
  }

  getMessageFromInvalidProperties(invalidProperties: Record<string, string>): string {
    if (!isEmpty(invalidProperties)) {
      return Object.values(invalidProperties).join('\n');
    }
  }

  getMessageFromHttpErrorResponse(error: HttpErrorResponse) {
    return `HTTP Status: ${error.status} - ${error.statusText}
      Type: ${error.type}
      URL: ${error.url}
      Message: ${error.message}
    `;
  }
}
