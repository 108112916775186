import { OnInit } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { Optional, Directive, Self } from '@angular/core';
import { AbstractFormGroupDirective, NgControl, ValidationErrors } from '@angular/forms';
import { SharedInputControlStatus } from './types';

@Directive({
  selector: 'input[sharedInputControlInput]:not([type=radio]):not([type=checkbox])',
  host: { class: 'shared-input-control-input' },
  standalone: false,
})
export class InputDirective implements OnInit, OnDestroy {
  get control(): NgControl | AbstractFormGroupDirective | null {
    return this._ngControl;
  }

  get errors(): ValidationErrors | null {
    return this.control?.errors;
  }

  get status(): SharedInputControlStatus {
    return this.control?.status as SharedInputControlStatus;
  }

  constructor(@Self() @Optional() private _ngControl: NgControl) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  private _getControlStatus({
    status,
    dirty,
    touched,
  }: {
    status: string;
    dirty: boolean;
    touched: boolean;
  }): SharedInputControlStatus[] {
    const result: SharedInputControlStatus[] = [];

    if (status === 'VALID') {
      result.push('valid');
    } else if (status === 'INVALID') {
      result.push('invalid');
    } else if (status === 'DISABLED') {
      result.push('disabled');
    } else if (status === 'PENDING') {
      result.push('pending');
    }

    if (dirty) {
      result.push('dirty');
    } else {
      result.push('pristine');
    }

    if (touched) {
      result.push('touched');
    } else {
      result.push('untouched');
    }

    return result;
  }
}
