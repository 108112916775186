var n = class i {
  constructor() {
    this.translations = new Map();
  }
  static getInstance() {
    return i.instance == null && (i.instance = new i()), i.instance;
  }
  get(t) {
    var s;
    return (s = this.translations.get(t)) != null ? s : "";
  }
  getAll() {
    return {
      ...this.translations
    };
  }
  update(t) {
    this.translations = new Map(Object.entries({
      ...Object.fromEntries(this.translations),
      ...t
    }));
  }
  updateIfMissing(t) {
    for (let [s, e] of Object.entries(t)) this.translations.has(s) || this.translations.set(s, e);
  }
};
export { n as a };