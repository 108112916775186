import { FocusOrigin, FocusableOption } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, ElementRef, ViewEncapsulation, HostListener } from '@angular/core';

@Component({
  selector: 'shared-select-option',
  templateUrl: 'select-option.component.html',
  styleUrls: ['select-option.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'shared-select-option' },
  imports: [CommonModule],
})
export class SelectOptionComponent<T = any> implements FocusableOption {
  @Input()
  value: T;

  @Input('disabled')
  get disabledInput(): BooleanInput {
    return this.disabled;
  }
  set disabledInput(value: BooleanInput) {
    this.disabled = coerceBooleanProperty(value);
  }

  disabled = false;

  constructor(private _elementRef: ElementRef<HTMLElement>) {}
  focus(origin?: FocusOrigin): void {
    throw new Error('Method not implemented.');
  }

  getLabel(): string {
    return this._elementRef.nativeElement?.textContent?.trim();
  }

  private _onSelect: (value: T) => void = () => {};

  registerOnSelect(fn: (value: T) => void): void {
    this._onSelect = fn;
  }

  @HostListener('click', ['$event'])
  select(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this._onSelect(this.value);
  }
}
