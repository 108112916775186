import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Toast } from './toast.model';
import { ToasterService } from './toaster.service';
import { NgIf } from '@angular/common';
import { IconComponent } from '@shared/components/icon';

@Component({
  selector: 'shell-toast',
  templateUrl: 'toast.component.html',
  styleUrls: ['toast.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'shell-toast' },
  imports: [NgIf, IconComponent],
})
export class ToastComponent {
  @Input()
  toast: Toast;

  get closeable() {
    if (typeof this.toast.closeable === 'boolean') {
      return this.toast.closeable;
    }
    return true;
  }

  get toastClass() {
    return `toast-${this.toast.type ?? 'info'}`;
  }

  constructor(private _toastService: ToasterService) {}

  closeToast(): void {
    this._toastService.close(this.toast.id);
  }
}
