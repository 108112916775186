import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterInputChipComponent } from './filter-input-chip.component';
import { MatomoModule } from 'ngx-matomo-client';

@NgModule({
  imports: [CommonModule, MatomoModule],
  exports: [FilterInputChipComponent],
  declarations: [FilterInputChipComponent],
})
export class FilterInputChipModule {}
