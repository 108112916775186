import { b } from './chunk-457L5N2F.js';
import { a } from './chunk-SP3HSP4K.js';
var d = {
    processedFramesCount: {
      text: "Frames processed by engine",
      valueSuffix: "/sec"
    },
    frameDataPoolSize: {
      text: "Frame data pool size",
      valueSuffix: ""
    },
    redrawRequestsCount: {
      text: "Engine redraw requests",
      valueSuffix: "/sec"
    },
    actualRedrawsCount: {
      text: "Actual redraws",
      valueSuffix: "/sec"
    },
    FPS: {
      text: "Overlay FPS ",
      valueSuffix: "/sec"
    }
  },
  p = class extends b {
    constructor(e) {
      var r;
      super(e);
      this.drawPerformanceTimer = null;
      this.drawPerformanceFPSCounter = 0;
      this.lastDrawPerformanceFPSCounter = 0;
      this.performanceCanvasLayer = document.createElement("canvas"), (r = e.parentElement) == null || r.insertBefore(this.performanceCanvasLayer, e);
    }
    get performanceLayerContext() {
      if (!this._performanceLayerContext && (this._performanceLayerContext = this.performanceCanvasLayer.getContext("2d"), !this._performanceLayerContext)) throw new TypeError("CanvasRenderingContext cannot be retrieved.");
      return this._performanceLayerContext;
    }
    setupPerformanceLayer() {}
    setPerformanceMetrics(e) {
      this.performanceMetrics = e, this.drawPerformanceMetrics();
    }
    updateCanvasSizeAttributes(e, r, t = window.devicePixelRatio) {
      super.updateCanvasSizeAttributes(e, r, t), this.performanceCanvasLayer.width = this.canvas.width, this.performanceCanvasLayer.height = this.canvas.height, this.performanceLayerContext.scale(t, t);
    }
    doDraw() {
      super.doDraw();
    }
    drawPerformanceMetrics() {
      this.drawPerformanceTimer == null && (a.log(a.Level.Debug, "Drawing performance monitoring enabled, FPS will be drawn into canvas"), this.drawPerformanceTimer = setInterval(() => {
        this.lastDrawPerformanceFPSCounter = this.drawPerformanceFPSCounter, this.drawPerformanceFPSCounter = 0;
      }, 1e3)), this.drawPerformanceFPSCounter++, this.drawFullPerformanceMetrics();
    }
    drawFullPerformanceMetrics() {
      let e = {
        ...this.performanceMetrics,
        FPS: this.lastDrawPerformanceFPSCounter
      };
      this.performanceLayerContext.lineWidth = 1, this.performanceLayerContext.fillStyle = "white", this.performanceLayerContext.strokeStyle = "black";
      let r = Math.min(Math.max(window.innerWidth / 35, 13), 20),
        t = 1.5 * r,
        s = 1.5;
      this.performanceLayerContext.font = `bold ${r}px sans-serif`;
      let l = 0,
        n = 0,
        i = [];
      for (let [a, u] of Object.entries(e)) {
        let c = d[a],
          f = `${c.text}: ${u}${c.valueSuffix}`;
        n = Math.max(this.performanceLayerContext.measureText(f).width, n), i.push([f, t, t + r * s * l++]);
      }
      this.performanceLayerContext.clearRect(t, t - r, n, Object.values(e).length * r * s);
      for (let a of i) this.performanceLayerContext.fillText(...a), this.performanceLayerContext.strokeText(...a);
    }
  };
export { p as a };