import { a as a$1 } from './chunk-45EBHQYM.js';
import { d } from './chunk-SK45SCLY.js';
import { a } from './chunk-SP3HSP4K.js';
import { a as a$2 } from './chunk-PSQ6LAQA.js';
import { f, g as g$1 } from './chunk-36CGJKPG.js';
var g = (t => (t.Idle = "idle", t.Pressed = "pressed", t))(g || {}),
  c = class {
    constructor(e, t) {
      this.state = "idle";
      this.isHover = !1;
      this.control = e, this.domHost = t, this.onTouchStartListener = this.onTouchStart.bind(this), this.onMouseEnterListener = this.onMouseEnter.bind(this), this.onMouseLeaveListener = this.onMouseLeave.bind(this), this.onClickListener = this.onClick.bind(this), this.setup();
    }
    get frameSource() {
      var e, t;
      return (t = (e = this.control.view) == null ? void 0 : e.getContext()) == null ? void 0 : t.frameSource;
    }
    get context() {
      var e;
      return (e = this.control.view) == null ? void 0 : e.getContext();
    }
    async install() {
      this.button || this.setup();
      let e = await this.canShow();
      return e ? (this.updateButtonBackground(this.button, this.getImageFromState()), this.show()) : this.hide(), e;
    }
    remove(e = !1) {
      e && this.button ? (this.button.removeEventListener("mouseenter", this.onMouseEnterListener), this.button.removeEventListener("mouseleave", this.onMouseLeaveListener), this.button.removeEventListener("touchstart", this.onTouchStartListener), this.button.removeEventListener("click", this.onClickListener), this.button.remove(), this.isHover = !1, this.button = void 0) : this.hide();
    }
    hide() {
      this.button && (this.button.style.display = "none");
    }
    show() {
      this.button && (this.button.style.display = "block");
    }
    setupButton() {
      let e = document.createElement("button");
      return e.type = "button", e.style.display = "none", e.className = `scandit-control-widget scandit-control-widget-${this.control.type}`, this.updateButtonBackground(e, this.getImageFromState()), this.domHost.append(e), e.addEventListener("mouseenter", this.onMouseEnterListener), e.addEventListener("mouseleave", this.onMouseLeaveListener), a$2.isDesktopDevice() || e.addEventListener("touchstart", this.onTouchStartListener), e.addEventListener("click", this.onClickListener), e.setAttribute("aria-pressed", String(this.state === "pressed")), this.button = e, this.button;
    }
    setup() {
      this.setupButton();
    }
    onTouchStart() {
      var e, t;
      (e = this.button) == null || e.removeEventListener("mouseenter", this.onMouseEnterListener), (t = this.button) == null || t.removeEventListener("mouseleave", this.onMouseLeaveListener);
    }
    onMouseEnter() {
      this.isHover = !0, this.updateButtonBackground(this.button, this.getImageFromState());
    }
    onMouseLeave() {
      this.isHover = !1, this.updateButtonBackground(this.button, this.getImageFromState());
    }
    onClick() {
      var e;
      this.state = this.state === "pressed" ? "idle" : "pressed", this.buttonClicked(), this.updateButtonBackground(this.button, this.getImageFromState()), (e = this.button) == null || e.setAttribute("aria-pressed", String(this.state === "pressed"));
    }
    updateButtonBackground(e, t) {
      t != null && e != null && (e.style.backgroundImage = `url(${JSON.stringify(t)})`);
    }
  },
  p = class extends c {
    constructor(e, t) {
      super(e, t);
    }
    async buttonClicked() {
      await this.frameSource.setDesiredTorchState(this.retrieveTorchState() === "on" ? "off" : "on");
    }
    async canShow() {
      return this.isTorchAvailable();
    }
    getImageFromState() {
      return this.retrieveTorchState() === "on" ? this.isHover ? this.control.torchOnPressedImage : this.control.torchOnImage : this.isHover ? this.control.torchOffPressedImage : this.control.torchOffImage;
    }
    setup() {
      if (this.control.torchOffImage == null || this.control.torchOnImage == null) {
        a.log(a.Level.Warn, "TorchSwitchControl icon is not set or is empty, the control will not be rendered.");
        return;
      }
      super.setup();
    }
    async isTorchAvailable() {
      return f(this.frameSource) ? this.frameSource.isTorchAvailable() : !1;
    }
    retrieveTorchState() {
      return f(this.frameSource) ? this.frameSource.getDesiredTorchState() : "off";
    }
  },
  v = class s extends c {
    constructor(e, t) {
      super(e, t);
    }
    static get Camera() {
      return a$1;
    }
    static get CameraAccess() {
      return d;
    }
    async canShow() {
      return (await s.CameraAccess.getCameras()).length > 1;
    }
    getImageFromState() {
      return this.isHover ? this.control.pressedImage : this.control.idleImage;
    }
    async buttonClicked() {
      if (this.isTransitioning) return;
      let e = await this.getNextDeviceCamera();
      e && (await this.switchCameras(e));
    }
    setup() {
      if (this.control.idleImage == null) {
        a.log(a.Level.Warn, "CameraSwitchControl idle icon is not set or is empty, the control will not be rendered.");
        return;
      }
      super.setup();
    }
    async getNextDeviceCamera() {
      var l, h;
      let e = await s.CameraAccess.getCameras(),
        t = this.frameSource ? this.frameSource : null,
        r = (l = t == null ? void 0 : t.cameraManager.activeCamera) == null ? void 0 : l.deviceId,
        a = e.findIndex(f => f.deviceId === r);
      return a > -1 ? (h = e[a + 1]) != null ? h : e[0] : e.length > 0 ? e[0] : null;
    }
    async switchCameras(e) {
      var a;
      this.isTransitioning = !0;
      let t = this.frameSource;
      t != null && (await t.switchToDesiredState("off"));
      let r = new s.Camera();
      r.deviceId = e.deviceId, t != null && (await r.applySettings(new g$1(t.settings))), await r.switchToDesiredState("on"), await ((a = this.context) == null ? void 0 : a.setFrameSource(r)), this.isTransitioning = !1;
    }
  },
  C = class s extends c {
    constructor(e, t) {
      super(e, t);
    }
    static get Camera() {
      return a$1;
    }
    static get CameraAccess() {
      return d;
    }
    async install() {
      let e = await super.install();
      return e && (await this.setInitialCamera()), e;
    }
    async canShow() {
      return !a$2.isIOSDeviceWithExtendedCameraAccess() || !f(this.frameSource) ? !1 : d.isIOSBackDualWideCameraLabel(this.frameSource.label) || d.isIOSBackCameraLabel(this.frameSource.label) || d.isIOSUltraWideBackCameraLabel(this.frameSource.label);
    }
    getImageFromState() {
      return this.isUltraWideBackCamera() ? this.isHover ? this.control.cameraFOVUltraWidePressedImage : this.control.cameraFOVUltraWideImage : this.isHover ? this.control.cameraFOVWidePressedImage : this.control.cameraFOVWideImage;
    }
    async buttonClicked() {
      if (this.isTransitioning) return;
      let e = await this.getAlternativeDeviceCamera();
      e && (await this.switchCameras(e));
    }
    async setInitialCamera() {
      var e, t;
      if (!this.isTransitioning && !d.isIOSBackCameraLabel((t = (e = this.frameSource) == null ? void 0 : e.label) != null ? t : "")) {
        let a = (await s.CameraAccess.getCameras()).find(l => d.isIOSBackCameraLabel(l.label));
        a && (await this.switchCameras(a));
      }
    }
    setup() {
      if (this.control.cameraFOVUltraWideImage == null || this.control.cameraFOVWideImage == null) {
        a.log(a.Level.Warn, "CameraFOVSwitchControl icon is not set or is empty, the control will not be rendered.");
        return;
      }
      super.setup();
    }
    isUltraWideBackCamera() {
      return f(this.frameSource) ? d.isIOSUltraWideBackCameraLabel(this.frameSource.label) : !1;
    }
    async getAlternativeDeviceCamera() {
      return (await s.CameraAccess.getCameras()).find(t => this.isUltraWideBackCamera() ? d.isIOSBackCameraLabel(t.label) : d.isIOSUltraWideBackCameraLabel(t.label));
    }
    async switchCameras(e) {
      var a;
      this.isTransitioning = !0;
      let t = this.frameSource;
      t != null && (await t.switchToDesiredState("off"));
      let r = new s.Camera();
      r.deviceId = e.deviceId, t != null && (await r.applySettings(new g$1(t.settings))), await r.switchToDesiredState("on"), await ((a = this.context) == null ? void 0 : a.setFrameSource(r)), this.isTransitioning = !1;
    }
  };
export { g as a, p as b, v as c, C as d };