import { a } from './chunk-MAMZS5CH.js';
import { a as a$1 } from './chunk-3A4KLILV.js';
import { a as a$2 } from './chunk-U6BTTEV6.js';
var e = class e extends a {
  static create() {
    return document.createElement(e.tag);
  }
  static register() {
    a$1({
      [e.tag]: e
    });
  }
  render() {
    return a$2`
      <style>
        ${e.tag} {
          display: flex;
        }
      </style>
      <svg width="${this.size}" height="${this.heightForViewbox(24, 24)}" viewBox="0 0 24 24" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.7337 10.4937C17.0888 10.152 17.0888 9.59799 16.7337 9.25628C16.3787 8.91457 15.8031 8.91457 15.4481 9.25628L10.6364 13.8876L8.55192 11.8813C8.19689 11.5396 7.62129 11.5396 7.26627 11.8813C6.91124 12.223 6.91124 12.777 7.26627 13.1187L9.99354 15.7437C10.3486 16.0854 10.9242 16.0854 11.2792 15.7437L16.7337 10.4937Z"
          fill="${this.fill}"
        />
      </svg>
    `;
  }
};
e.tag = "scandit-rounded-check-icon";
var t = e;
t.register();
export { t as a };