<div
  class="ui-option"
  [attr.data-label]="uiOption?.label"
  [attr.data-value]="uiOption?.value"
  [attr.data-key]="uiOption?.key"
  [attr.data-selected]="uiOption?.selected"
  [attr.data-target]="uiOption?.expanded"
>
  <ui-checkbox
    [ngModel]="uiOption?.selected"
    (ngModelChange)="
      uiOption?.setSelected($event);
      tracker.trackEvent({ category: 'filter-option-checkbox', action: 'click', name: uiOption?.label, value: $event ? 1 : 0 })
    "
    [indeterminate]="hasPartiallyCheckedChildren"
    matomoTracker
    #tracker="matomo"
  >
    {{ uiOption?.label }}
  </ui-checkbox>
  <button
    class="btn-expand"
    (click)="uiOption.setExpanded(!uiOption?.expanded)"
    [class.expanded]="uiOption?.expanded"
    type="button"
    *ngIf="uiOption?.values?.length"
  >
    <ui-icon icon="arrow_head" size="1em"></ui-icon>
  </button>
</div>
<ng-container *ngIf="uiOption?.expanded">
  <shared-input-option-bool class="ml-10" *ngFor="let subOption of uiOption?.values" [option]="subOption"></shared-input-option-bool>
</ng-container>
