var r = async () => WebAssembly.validate(new Uint8Array([0, 97, 115, 109, 1, 0, 0, 0, 1, 5, 1, 96, 0, 1, 123, 3, 2, 1, 0, 10, 10, 1, 8, 0, 65, 0, 253, 15, 253, 98, 11]));
var t = () => (async n => {
  try {
    return typeof MessageChannel < "u" && new MessageChannel().port1.postMessage(new SharedArrayBuffer(1)), WebAssembly.validate(n);
  } catch (e) {
    return !1;
  }
})(new Uint8Array([0, 97, 115, 109, 1, 0, 0, 0, 1, 4, 1, 96, 0, 0, 3, 2, 1, 0, 5, 4, 1, 3, 1, 1, 10, 11, 1, 9, 0, 65, 0, 254, 16, 2, 0, 26, 11]));
function i() {
  return typeof crossOriginIsolated > "u" ? !1 : crossOriginIsolated;
}
function l() {
  try {
    return new WebAssembly.Memory({
      initial: 1,
      maximum: 1,
      shared: !0
    }).buffer.constructor.name === "SharedArrayBuffer";
  } catch (n) {
    return !1;
  }
}
async function b() {
  return r();
}
async function o() {
  if (!(await t())) return !1;
  let e = null;
  try {
    e = new Worker(URL.createObjectURL(new Blob(['postMessage("Worker" in self);'], {
      type: "text/javascript"
    })), {
      name: "nestedWorkerTest"
    });
  } catch (a) {
    return !1;
  }
  return new Promise(a => {
    if (e == null) {
      a(!1);
      return;
    }
    e.onmessage = s => {
      e == null || e.terminate(), a(s.data);
    };
  });
}
async function m() {
  let n = await o();
  return i() && l() && n;
}
export { i as a, l as b, b as c, o as d, m as e };