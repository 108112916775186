import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UiValidators } from '@ui/validators';
import { FormBlockControl } from '../form-block';

@Component({
  selector: 'app-birth-date-form-block',
  templateUrl: 'birth-date-form-block.component.html',
  styleUrls: ['birth-date-form-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BirthDateFormBlockComponent extends FormBlockControl<Date | string> {
  get tabIndexEnd() {
    return this.tabIndexStart;
  }

  initializeControl(data?: Date): void {
    this.control = new UntypedFormControl(data, [...this.getValidatorFn(), UiValidators.date]);
  }

  _patchValue(update: { previous: Date; current: Date }): void {
    this.control.patchValue(update.current);
  }

  updateValidators(): void {
    this.control.setValidators([...this.getValidatorFn(), UiValidators.date]);
    this.control.updateValueAndValidity();
  }
}
