import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerDTO } from '@generated/swagger/crm-api';
import { UiModalRef } from '@ui/modal';
import { WebshopCustomnerAlreadyExistsModalData } from './webshop-customer-already-exists-modal.data';

@Component({
  selector: 'page-webshop-customer-already-exists-modal',
  templateUrl: 'webshop-customer-already-exists-modal.component.html',
  styleUrls: ['webshop-customer-already-exists-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class WebshopCustomnerAlreadyExistsModalComponent {
  get customer(): CustomerDTO {
    return this._dialogRef.data?.customer;
  }

  get processId(): number {
    return this._dialogRef.data?.processId;
  }

  get isWebshopCustomer() {
    return this.customer?.features?.some((feature) => feature.key === 'webshop') ?? false;
  }

  get hasP4MCard() {
    return this.customer?.features?.some((feature) => feature.key === 'p4mUser') ?? false;
  }

  get isWebshopWithP4M(): boolean {
    return this.isWebshopCustomer && this.hasP4MCard;
  }

  constructor(
    private _dialogRef: UiModalRef<boolean, WebshopCustomnerAlreadyExistsModalData>,
    private _router: Router,
  ) {
    if (this.isWebshopWithP4M) {
      this._dialogRef.title = 'Es ist bereits eine Kundenkarte bei diesem Onlinekonto hinterlegt:';
      this._dialogRef.markChanged();
    }
  }

  close(val: boolean) {
    this._dialogRef.close(val);
  }

  async selectCustomer() {
    await this._router.navigate(['/kunde', this.processId, 'customer', this.customer.id]);
    this.close(false);
  }
}
