export function containsElement(parentElement: Element, targetElement: Element): boolean {
  if (parentElement === targetElement) {
    return true;
  } else if (parentElement.hasChildNodes()) {
    const children = Array.from(parentElement.children);
    return children.some(
      (childElement) => childElement === targetElement || containsElement(childElement, targetElement),
    );
  }
  return false;
}
