import { coerceNumberProperty } from '@angular/cdk/coercion';
import { CustomerSearchState } from './customer-search.state';
import { Filter } from '@shared/components/filter';
import { isEmpty } from 'lodash';

export function selectProcessId(s: CustomerSearchState): number {
  return coerceNumberProperty(s.processId);
}

export function selectFetchingCustomer(s: CustomerSearchState) {
  return s.fetchingCustomer;
}

export function selectCustomer(s: CustomerSearchState) {
  return s.customer;
}

export function selectMessage(s: CustomerSearchState) {
  return s.message;
}

export function selectCustomerId(s: CustomerSearchState) {
  return +selectCustomer(s)?.id;
}

export function selecthasB2bFeature(s: CustomerSearchState) {
  return !!selectCustomer(s)?.features?.some((c) => c.key === 'b2b');
}

export function selectHasGuestFeature(s: CustomerSearchState) {
  return !!selectCustomer(s)?.features?.some((c) => c.key === 'guest');
}

export function selectHasWebshopFeature(s: CustomerSearchState) {
  return !!selectCustomer(s)?.features?.some((c) => c.key === 'webshop');
}

export function selectHasP4mUserFeature(s: CustomerSearchState) {
  return !!selectCustomer(s)?.features?.some((c) => c.key === 'p4mUser');
}

export function selectHasStaffFeature(s: CustomerSearchState) {
  return !!selectCustomer(s)?.features?.some((c) => c.key === 'staff');
}

export function selectHasStoreFeature(s: CustomerSearchState) {
  return !!selectCustomer(s)?.features?.some((c) => c.key === 'store');
}

export function selectIsBestellungOhneKonto(s: CustomerSearchState) {
  return (
    (selectHasWebshopFeature(s) && selectHasGuestFeature(s)) || (selectHasStoreFeature(s) && selectHasGuestFeature(s))
  );
}

export function selectIsOnlinekonto(s: CustomerSearchState) {
  return selectHasWebshopFeature(s) && !selectHasGuestFeature(s);
}

export function selectIsOnlinekontoMitKundenkundenkarte(s: CustomerSearchState) {
  return selectHasWebshopFeature(s) && selectHasP4mUserFeature(s);
}

export function selectIsKundenkarte(s: CustomerSearchState) {
  return selectHasP4mUserFeature(s) && selectHasStoreFeature(s);
}

export function selectIsBusinessKonto(s: CustomerSearchState) {
  return selecthasB2bFeature(s) && !selectHasStaffFeature(s);
}

export function selectIsMitarbeiter(s: CustomerSearchState) {
  return selectHasStaffFeature(s);
}

export function selectCustomerOrders(s: CustomerSearchState) {
  return s.customerOrders;
}

export function selectFetchingCustomerOrders(s: CustomerSearchState) {
  return s.fetchingCustomerOrders;
}

export function selectOrder(s: CustomerSearchState) {
  return s.order;
}

export function selectFilter(s: CustomerSearchState) {
  if (!s.defaultFilter) return undefined;

  const queryParams = s.queryParams;

  const filter = Filter.create(s.defaultFilter);

  if (!isEmpty(queryParams)) {
    filter.fromQueryParams(queryParams);
  }

  return filter;
}

export function selectFetchingFilter(s: CustomerSearchState) {
  return s.fetchingFilter;
}

export function selectDefaultFilter(s: CustomerSearchState) {
  return s.defaultFilter;
}

export function selectFetchingCustomerList(s: CustomerSearchState) {
  return s.fetchingCustomerList;
}

export function selectCustomerList(s: CustomerSearchState) {
  return s.customerList;
}

export function selectCustomerListCount(s: CustomerSearchState) {
  return s.customerListCount;
}

export function selectQueryParams(s: CustomerSearchState) {
  return s.queryParams;
}

export function selectSelectedOrderItemId(s: CustomerSearchState) {
  const order = selectOrder(s);

  if (!order || !order?.items?.length) return undefined;

  const hasOrderItemId = order.items?.some((item) => item.id === s.selectedOrderItemId);

  if (!hasOrderItemId) {
    return order.items?.[0]?.id;
  }

  return s.selectedOrderItemId;
}

export function selectSelectedOrderItem(s: CustomerSearchState) {
  const order = selectOrder(s);

  if (!order) return undefined;

  return order?.items?.map((item) => item.data)?.find((item) => item.id === selectSelectedOrderItemId(s));
}
