<button
  *ngIf="!uiInput?.hasOptions()"
  type="button"
  class="ui-filter-chip"
  [class.selected]="uiInput?.selected"
  (click)="uiInput?.setSelected(!uiInput.selected)"
  [attr.data-label]="uiInput?.label"
  [attr.data-value]="uiInput?.value"
  [attr.data-key]="uiInput?.key"
  [attr.data-type]="uiInput?.type"
  [attr.data-selected]="uiInput?.selected"
  matomoClickCategory="filter-chip"
  matomoClickAction="click"
  [matomoClickName]="uiInput?.label"
  [matomoClickValue]="uiInput?.selected ? 0 : 1"
>
  {{ uiInput?.label }}
</button>
<ng-container *ngIf="uiInput?.hasOptions()">
  <button
    *ngFor="let option of uiInput?.options?.values"
    type="button"
    class="ui-filter-chip"
    [class.selected]="option?.selected"
    (click)="option?.setSelected(!option.selected)"
    [attr.data-label]="uiInput?.label"
    [attr.data-value]="uiInput?.value"
    [attr.data-key]="uiInput?.key"
    [attr.data-type]="uiInput?.type"
    [attr.data-selected]="uiInput?.selected"
    matomoClickCategory="filter-chip"
    matomoClickAction="click"
    [matomoClickName]="uiInput?.label"
    [matomoClickValue]="uiInput?.selected ? 0 : 1"
  >
    {{ option?.label }}
  </button>
</ng-container>
