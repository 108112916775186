import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { CustomerDTO } from '@generated/swagger/crm-api';
import { map } from 'rxjs/operators';
import {
  AddressFormBlockComponent,
  AddressFormBlockData,
  DeviatingAddressFormBlockComponent,
} from '../../components/form-blocks';
import { NameFormBlockData } from '../../components/form-blocks/name/name-form-block-data';
import { validateEmail } from '../../validators/email-validator';
import { AbstractCreateCustomer } from '../abstract-create-customer';
import { CreateP4MCustomerComponent } from '../create-p4m-customer';
import { zipCodeValidator } from '../../validators/zip-code-validator';

@Component({
  selector: 'app-create-webshop-customer',
  templateUrl: 'create-webshop-customer.component.html',
  styleUrls: ['../create-customer.scss', 'create-webshop-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CreateWebshopCustomerComponent extends AbstractCreateCustomer {
  customerType = 'webshop';

  validateAddress = true;
  validateShippingAddress = true;

  nameRequiredMarks: (keyof NameFormBlockData)[] = ['gender', 'firstName', 'lastName'];

  nameValidationFns: Record<keyof NameFormBlockData, ValidatorFn[]> = {
    firstName: [Validators.required],
    lastName: [Validators.required],
    gender: [Validators.required],
    title: [],
  };

  addressRequiredMarks: (keyof AddressFormBlockData)[] = ['street', 'streetNumber', 'zipCode', 'city', 'country'];

  addressValidators: Record<string, ValidatorFn[]> = {
    street: [Validators.required],
    streetNumber: [Validators.required],
    zipCode: [Validators.required, zipCodeValidator()],
    city: [Validators.required],
    country: [Validators.required],
  };

  communicationDetailsValidators: Record<string, ValidatorFn[]> = {
    email: [Validators.required, Validators.email, validateEmail],
  };

  emailValidatorFns = [Validators.required, Validators.email, validateEmail];

  asyncEmailValidatorFns = [this.emailExistsValidator];

  @ViewChild(AddressFormBlockComponent, { static: false })
  addressFormBlock: AddressFormBlockComponent;

  @ViewChild(DeviatingAddressFormBlockComponent, { static: false })
  deviatingDeliveryAddressFormBlock: DeviatingAddressFormBlockComponent;

  async saveCustomer(customer: CustomerDTO): Promise<CustomerDTO> {
    const { customerDto, customerInfoDto } = this.formData?._meta ?? {};

    const isUpgrade = !!(customerDto || customerInfoDto)?.id;

    if (isUpgrade) {
      if (customerDto) {
        customer = { ...customerDto, ...customer };
      } else if (customerInfoDto) {
        customer = { ...CreateP4MCustomerComponent.MapCustomerInfoDtoToCustomerDto(customerInfoDto), ...customer };
      }

      const res = await this.customerService.updateToOnlineCustomer(customer);
      return res.result;
    } else {
      return this.customerService
        .createOnlineCustomer(customer)
        .pipe(map((res) => res.result))
        .toPromise();
    }
  }
}
