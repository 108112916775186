import { NumberInput, coerceNumberProperty } from '@angular/cdk/coercion';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerInfoDTO } from '@generated/swagger/crm-api';
import { NavigationRoute } from './defs/navigation-route';
import { encodeFormData, mapCustomerInfoDtoToCustomerCreateFormData } from 'apps/isa-app/src/page/customer';

@Injectable({ providedIn: 'root' })
export class CustomerCreateNavigation {
  constructor(private _router: Router) {}

  defaultRoute(params: { processId?: NumberInput }): NavigationRoute {
    const path = [
      '/kunde',
      params.processId ? coerceNumberProperty(params.processId) : undefined,
      'customer',
      {
        outlets: {
          primary: ['create', 'store'],
          side: 'create-customer-main',
        },
      },
    ].filter((x) => !!x);

    const urlTree = this._router.createUrlTree(path, { queryParams: {} });

    return {
      path,
      urlTree,
      queryParams: {},
    };
  }

  navigateToDefault(params: { processId: NumberInput }): Promise<boolean> {
    const route = this.defaultRoute(params);
    return this._router.navigate(route.path, { queryParams: route.queryParams });
  }

  createCustomerRoute(params: {
    processId: NumberInput;
    customerType?: string;
    customerInfo?: CustomerInfoDTO;
  }): NavigationRoute {
    const path = [
      '/kunde',
      coerceNumberProperty(params.processId),
      'customer',
      {
        outlets: {
          primary: ['create', params.customerType].filter((x) => !!x),
          side: 'create-customer-main',
        },
      },
    ];

    let formData = params?.customerInfo
      ? encodeFormData(mapCustomerInfoDtoToCustomerCreateFormData(params.customerInfo))
      : undefined;

    const urlTree = this._router.createUrlTree(path, {
      queryParams: {
        formData,
      },
    });

    return {
      path,
      urlTree,
      queryParams: {
        formData,
      },
    };
  }

  upgradeCustomerRoute({
    processId,
    customerInfo,
  }: {
    processId: NumberInput;
    customerInfo: CustomerInfoDTO;
  }): NavigationRoute {
    const formData = encodeFormData(mapCustomerInfoDtoToCustomerCreateFormData(customerInfo));
    const path = [
      '/kunde',
      coerceNumberProperty(processId),
      'customer',
      {
        outlets: {
          primary: [
            'create',
            customerInfo?.features?.find((feature) => feature.key === 'webshop') ? 'webshop-p4m' : 'store-p4m',
          ],
          side: 'create-customer-main',
        },
      },
    ];

    const urlTree = this._router.createUrlTree(path, { queryParams: { formData } });

    return {
      path,
      queryParams: { formData },
      urlTree,
    };
  }
}
