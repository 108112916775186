import { PackageArrivalStatusDTO } from '@generated/swagger/wws-api';

export abstract class PackageInspectionEvent {
  constructor(public readonly type: string) {}
}

export class PackageStatusChangedEvent extends PackageInspectionEvent {
  constructor(
    public readonly packageId: string,
    public readonly status: PackageArrivalStatusDTO,
  ) {
    super('PackageStatusChangedEvent');
  }
}
