import { Injectable } from '@angular/core';
import { ApplicationService } from '@core/application';
import {
  AutocompleteTokenDTO,
  PromotionService,
  QueryTokenDTO,
  SearchService,
} from '@generated/swagger/cat-search-api';
import { memorize } from '@utils/common';
import { map, share, shareReplay } from 'rxjs/operators';

@Injectable()
export class DomainCatalogService {
  constructor(
    private searchService: SearchService,
    private promotionService: PromotionService,
    private applicationService: ApplicationService,
  ) {}

  @memorize()
  getFilters() {
    return this.searchService.SearchSearchFilter().pipe(
      map((res) => res.result),
      shareReplay(),
    );
  }

  @memorize()
  getOrderBy() {
    return this.searchService.SearchSearchSort().pipe(
      map((res) => res.result),
      shareReplay(),
    );
  }

  getSearchHistory({ take }: { take: number }) {
    return this.searchService.SearchHistory(take ?? 5).pipe(map((res) => res.result));
  }

  @memorize({ ttl: 120000 })
  search({ queryToken }: { queryToken: QueryTokenDTO }) {
    return this.searchService
      .SearchSearch({
        ...queryToken,
        stockId: null,
      })
      .pipe(share());
  }

  @memorize({ ttl: 120000 })
  searchWithStockId({ queryToken }: { queryToken: QueryTokenDTO }) {
    return this.searchService
      .SearchSearch2({
        queryToken,
        stockId: queryToken?.stockId ?? null,
      })
      .pipe(share());
  }

  getDetailsById({ id }: { id: number }) {
    return this.searchService.SearchDetail({
      id,
    });
  }

  getDetailsByEan({ ean }: { ean: string }) {
    return this.searchService.SearchDetailByEAN(ean);
  }

  searchByIds({ ids }: { ids: number[] }) {
    return this.searchService.SearchById(ids);
  }

  searchByEans({ eans }: { eans: string[] }) {
    return this.searchService.SearchByEAN(eans);
  }

  searchTop({ queryToken }: { queryToken: QueryTokenDTO }) {
    return this.searchService.SearchTop(queryToken);
  }

  searchComplete({ queryToken }: { queryToken: AutocompleteTokenDTO }) {
    return this.searchService.SearchAutocomplete(queryToken);
  }

  @memorize()
  getPromotionPoints({ items }: { items: { id: number; quantity: number; price?: number }[] }) {
    return this.promotionService.PromotionLesepunkte(items).pipe(shareReplay());
  }

  @memorize()
  getSettings() {
    return this.searchService.SearchSettings().pipe(
      map((res) => res.result),
      shareReplay(),
    );
  }

  getRecommendations({ digId }: { digId: number }) {
    return this.searchService.SearchGetRecommendations({
      digId: digId + '',
      sessionId: this.applicationService.activatedProcessId + '',
    });
  }
}
