var t = (i => (i.Rounded = "rounded", i.Square = "square", i))(t || {}),
  o = (i => (i.Light = "light", i.Bold = "bold", i))(o || {}),
  a = (e => (e.Animated = "animated", e))(a || {}),
  r = class n {
    constructor(e) {
      this._isLooping = !1;
      this._isLooping = e;
    }
    get isLooping() {
      return this._isLooping;
    }
    static fromJSON(e) {
      return e === null ? null : new n(e.looping);
    }
    toJSONObject() {
      return {
        looping: this.isLooping
      };
    }
  };
export { t as a, o as b, a as c, r as d };