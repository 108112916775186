import { Color } from 'scandit-web-datacapture-core';
var e = class i {
  constructor(o, n, l, r) {
    this.inListItemColor = o, this.notInListItemColor = n, this.hapticEnabled = r, this.soundEnabled = l;
  }
  static fromJSON(o) {
    return new i(Color.fromJSON(o.inListItemColor), Color.fromJSON(o.notInListItemColor), o.soundEnabled, o.hapticEnabled);
  }
  toJSONObject() {
    return {
      inListItemColor: this.inListItemColor.toJSON(),
      notInListItemColor: this.notInListItemColor.toJSON(),
      soundEnabled: this.soundEnabled,
      hapticEnabled: this.hapticEnabled
    };
  }
};
export { e as a };