<div
  class="ui-option"
  [attr.data-label]="uiOption?.label"
  [attr.data-value]="uiOption?.value"
  [attr.data-key]="uiOption?.key"
  [attr.data-selected]="uiOption?.selected"
>
  <div>
    <ui-switch
      [ngModel]="uiOption?.selected"
      (ngModelChange)="
        uiOption?.setSelected($event);
        tracker.trackEvent({
          category: 'filter-option-tri-state',
          action: 'click',
          name: uiOption?.label,
          value: $event === true ? 1 : $event === false ? -1 : 0,
        })
      "
      labelOn="mit"
      labelOff="ohne"
      matomoTracker
      #tracker="matomo"
    ></ui-switch>
    {{ uiOption?.label }}
  </div>
  <button
    class="btn-expand"
    (click)="uiOption.setExpanded(!uiOption?.expanded)"
    [class.expanded]="uiOption?.expanded"
    type="button"
    *ngIf="uiOption?.values?.length"
  >
    <ui-icon icon="arrow_head" size="1em"></ui-icon>
  </button>
</div>
<ng-container *ngIf="uiOption?.expanded">
  <shared-input-option-tri-state *ngFor="let subOption of uiOption?.values" [option]="subOption"></shared-input-option-tri-state>
</ng-container>
