<ng-container *ngIf="fetching$ | async; else historyTemplate"></ng-container>

<ng-template #historyTemplate>
  <div>
    <div class="customer-history-header">
      <div class="customer-history-header-actions flex flex-row justify-end pt-1 px-1">
        <a *ngIf="detailsRoute$ | async; let route" [routerLink]="route.path" [queryParams]="route.queryParams" class="btn btn-label">
          <shared-icon [icon]="'close'" [size]="32"></shared-icon>
        </a>
      </div>
      <div class="customer-history-header-body text-center -mt-3">
        <h1 class="text-[1.625rem] font-bold">Historie</h1>
      </div>
      <div class="customer-history-header-info flex flex-row justify-evenly items-center my-5">
        <div class="flex flex-row">
          <div class="w-36">Kundenname</div>
          <div class="grow font-bold">{{ customerName$ | async }}</div>
        </div>
        <div class="flex flex-row">
          <div class="w-36">Kundennummer</div>
          <div class="grow font-bold">{{ customerNumber$ | async }}</div>
        </div>
      </div>
    </div>
    <div class="px-3 bg-surface-2 text-surface-2-content">
      <shared-history-list [history]="history$ | async"></shared-history-list>
    </div>
  </div>
</ng-template>
