import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { IInputGroup, InputGroup } from '../../tree';

@Component({
  selector: 'shared-filter-filter-group-main',
  templateUrl: 'filter-filter-group-main.component.html',
  styleUrls: ['filter-filter-group-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FilterFilterGroupMainComponent {
  private _inputGroup: InputGroup;

  @Input()
  set inputGroup(value: IInputGroup) {
    if (value instanceof InputGroup) {
      this._inputGroup = value;
    } else {
      this._inputGroup = InputGroup.create(value);
    }
  }

  get uiInputGroup() {
    return this._inputGroup;
  }

  constructor() {}
}
