import { a as a$3, b as b$1 } from './chunk-F3A3WWOG.js';
import { a as a$2 } from './chunk-EMHHC6NB.js';
import { a as a$1 } from './chunk-7SW2PEHB.js';
import { a as a$4 } from './chunk-FGO2SSRS.js';
import { d } from './chunk-XLX4QIMT.js';
import { a as a$5 } from './chunk-SP3HSP4K.js';
import { a } from './chunk-PSQ6LAQA.js';
import { f as f$1 } from './chunk-36CGJKPG.js';
import { a as a$6 } from './chunk-WUHKODFA.js';
var f,
  w,
  b,
  o = class o {
    constructor(e, t) {
      this.framework = "web";
      this.runtimeEnvironment = {
        deviceOS: (f = a.getUserAgentInfo().getOS().name) != null ? f : "",
        browser: (w = a.getUserAgentInfo().getBrowser().name) != null ? w : "",
        browserVersion: (b = a.getUserAgentInfo().getBrowser().version) != null ? b : "",
        get deviceModelName() {
          let {
            model: e,
            vendor: t
          } = a.getUserAgentInfo().getDevice();
          return (t == null || t === "") && (t = "Unknown"), (e == null || e === "") && (e = "Unknown"), [t, e].join(" ");
        }
      };
      this.settings = new a$1();
      this._frameSource = null;
      this._view = null;
      this.modes = new Set();
      this.components = [];
      this.listeners = [];
      this.updateListeners = new Set();
      this.cameraPropertiesReportListener = this.reportCameraProperties.bind(this);
      this.cameraAccessErrorListener = this.onCameraAccessError.bind(this);
      this.onWorkerMessageListener = this.onWorkerMessage.bind(this);
      this.onVisibilityChangeListener = this.onVisibilityChange.bind(this);
      this._frameHandlers = [];
      var a$3, r, p, l, c, C;
      this.licenseKey = e, this.deviceName = (a$3 = t.deviceName) != null ? a$3 : "", this.dataCaptureInstance = (r = t.dataCaptureInstance) != null ? r : a$2.mainDataCaptureLoader, this.delayedRegistration = (p = t.delayedRegistration) != null ? p : !1, this.highEndBlurryRecognition = (C = (c = (l = t.dataCaptureInstance) == null ? void 0 : l.highEndBlurryRecognition) != null ? c : a$2.mainDataCaptureLoader.highEndBlurryRecognition) != null ? C : !1;
    }
    get frameSource() {
      return this._frameSource;
    }
    get workerCommand() {
      return this.dataCaptureInstance.workerCommand.bind(this.dataCaptureInstance);
    }
    static async create() {
      return o.createWithOptions({});
    }
    static async createWithOptions(e) {
      var a;
      let t = new o((a = e.licenseKey) != null ? a : a$2.userLicenseKey, {
        deviceName: e.deviceName
      });
      return e.settings != null && (await t.applySettings(e.settings)), await t.initialize(), t;
    }
    async setFrameSource(e) {
      let t = e !== this._frameSource;
      if (this._frameSource !== null && (this._frameSource.context = null), this._frameSource = e != null ? e : null, e && (e.context = this, await this.workerCommand("setFrameSource", {
        mirrorAxis: this.getMirrorAxisForFrameSource(e),
        isCameraFrameSource: f$1(e)
      })), await this.update([{
        type: "frameSourceState",
        newValue: this.frameSource
      }]), t) for (let a of this.listeners) a.didChangeFrameSource && a.didChangeFrameSource(this, this._frameSource);
    }
    addListener(e) {
      this.listeners.includes(e) || this.listeners.push(e);
    }
    async flushAnalytics() {
      await this.workerCommand("flushAnalytics", {});
    }
    removeListener(e) {
      this.listeners.includes(e) && this.listeners.splice(this.listeners.indexOf(e), 1);
    }
    async addMode(e) {
      this.modes.add(e), await e.attachedToContext(this), this.updateCameraFrameFlow(), await this.update();
    }
    async removeMode(e) {
      this.modes.delete(e), await e.detachedFromContext(), this.updateCameraFrameFlow(), await this.update();
    }
    async removeAllModes() {
      for (let e of this.modes) await this.removeMode(e);
    }
    async dispose() {
      d.instance().removeListener("cameraProperties", this.cameraPropertiesReportListener), d.instance().removeListener("cameraAccessError", this.cameraAccessErrorListener), this.unsubscribeToVisibilityChange(this.onVisibilityChangeListener), await this.workerCommand("dispose", {});
    }
    async applySettings(e) {
      this.settings = e, await this.update();
    }
    toJSONObject() {
      var e, t;
      return {
        licenseKey: this.licenseKey,
        framework: this.framework,
        deviceName: this.deviceName,
        ...this.runtimeEnvironment,
        modes: [...this.modes].filter(a => !a.skipSerialization).map(a => a.toJSONObject()),
        components: this.components.map(a => a.toJSONObject()),
        frameSource: this.frameSource ? this.frameSource.toJSONObject() : null,
        settings: this.settings.toJSONObject(),
        view: (t = (e = this._view) == null ? void 0 : e.toJSONObject()) != null ? t : null
      };
    }
    getView() {
      return this._view;
    }
    async setView(e) {
      return this._view = e, this.update([{
        type: "viewSet",
        newValue: e
      }]);
    }
    async getAppName() {
      let e = null;
      if (a$3()) {
        let t = await b$1();
        if ((t == null ? void 0 : t.appName) == null || t.appName === "") throw new Error("Cannot retrieve electron appName. Did you set it up in package.json? https://www.electronjs.org/docs/latest/api/app#appgetname");
        e = t.appName;
      }
      return e;
    }
    urlToHostname(e) {
      try {
        return new URL(e != null ? e : "").hostname;
      } catch (t) {
        return "";
      }
    }
    getParentDomain() {
      var t;
      let e = "";
      return parent !== window && (e || (e = this.urlToHostname((t = location.ancestorOrigins) == null ? void 0 : t[0])), e || (e = this.urlToHostname(document.referrer)), e || (e = "")), e.startsWith("[") && e.at(-1) === "]" && (e = e.slice(1, -1)), e;
    }
    async initialize(e = !0) {
      await this.workerCommand("createContext", {
        context: this.toJSONObject(),
        deviceId: o.deviceID,
        delayedRegistration: this.delayedRegistration,
        highEndBlurryRecognition: this.highEndBlurryRecognition,
        appName: await this.getAppName(),
        parentDomain: this.getParentDomain()
      }), this.subscribeToWorkerMessages(this.onWorkerMessageListener), e && (this.subscribeToCameraManagerEvents(), this.subscribeToVisibilityChange(this.onVisibilityChangeListener));
    }
    subscribeToVisibilityChange(e) {
      document.addEventListener("visibilitychange", e);
    }
    unsubscribeToVisibilityChange(e) {
      document.removeEventListener("visibilitychange", e);
    }
    async requestFrameData(e) {
      return this.workerCommand("requestFrameData", {
        frameId: e
      });
    }
    performanceMark(e) {
      a$4(globalThis.performance) && typeof globalThis.performance.mark == "function" && performance.mark(e);
    }
    async sendFrameToProcessor(e) {
      let t = e instanceof ImageData ? {
        data: e.data,
        width: e.width,
        height: e.height
      } : e;
      return (await this.sendFrameToHandlers(t)) ? (this.performanceMark("processFrameBeforeSDC"), this.sendFrameToSDC(t)) : e;
    }
    async sendFrameToHandlers(e) {
      for (let t of this._frameHandlers) if ((await t(e)) === "skip") return !1;
      return !0;
    }
    async sendFrameToSDC(e) {
      return this.workerCommand("processFrame", e, [e.data.buffer]);
    }
    registerFrameHandler(e) {
      this._frameHandlers.includes(e) || this._frameHandlers.push(e);
    }
    unregisterFrameHandler(e) {
      this._frameHandlers.includes(e) && this._frameHandlers.splice(this._frameHandlers.indexOf(e), 1);
    }
    onVisibilityChange() {
      this.workerCommand("documentVisibility", {
        state: document.visibilityState
      });
    }
    onWorkerMessage(e) {
      if (e.type === "contextDidChangeStatus") {
        e.payload.isValid || a$5.log(a$5.Level.Error, e.payload);
        for (let t of this.listeners) t.didChangeStatus && t.didChangeStatus(this, a$6.fromJSON(e.payload));
      }
      if (e.type === "didStartObservingContext") for (let t of this.listeners) t.didStartObservingContext && t.didStartObservingContext(this);
      e.type === "onFrameProcessingFinished" && this.performanceMark("processFrameAfterSDC");
    }
    subscribeToCameraManagerEvents() {
      d.instance().addListener("cameraProperties", this.cameraPropertiesReportListener), d.instance().addListener("cameraAccessError", this.cameraAccessErrorListener);
    }
    async reportCameraProperties(e) {
      return this.workerCommand("reportCameraProperties", e);
    }
    onCameraAccessError(e) {
      for (let t of this.listeners) t.didChangeStatus && t.didChangeStatus(this, a$6.fromJSON({
        code: 33794,
        isValid: !0,
        message: e.toString()
      }));
    }
    async update(e = []) {
      var t, a;
      await this.updateContext();
      for (let r of e) {
        switch (r.type) {
          case "frameSourceState":
            {
              await ((t = this._view) == null ? void 0 : t.onFrameSourceChange(this.frameSource));
              break;
            }
          case "singleImageModeUploaderSettings":
            {
              (a = this._view) == null || a.onSingleImageUploaderSettingsChange(r.newValue);
              break;
            }
        }
        for (let p of this.updateListeners) p(r);
      }
    }
    async updateContext() {
      await this.workerCommand("updateContext", {
        context: this.toJSONObject(),
        ...this.getViewInfo()
      }).catch(e => {
        a$5.log(a$5.Level.Warn, "Error while updating context:", e);
      });
    }
    getViewInfo() {
      return this._view != null ? {
        view: {
          width: this._view.width,
          height: this._view.height,
          visible: this._view.width > 0 && this._view.height > 0,
          orientation: this._view.orientation
        }
      } : {
        view: null
      };
    }
    getMirrorAxisForFrameSource(e) {
      let t = "None";
      return f$1(e) && e.getMirrorImageEnabled() && (t = "Y"), t;
    }
    async addComponent(e) {
      if (!this.components.includes(e)) return this.components.push(e), e._context = this, this.update();
    }
    subscribeToWorkerMessages(e) {
      this.dataCaptureInstance.addWorkerListener(e);
    }
    unsubscribeToWorkerMessages(e) {
      this.dataCaptureInstance.removeWorkerListener(e);
    }
    hasEnabledMode() {
      return [...this.modes].some(e => e.isEnabled());
    }
    updateCameraFrameFlow() {
      let e = [...this.modes].some(t => t._synchronousFrameFlow === !0);
      d.instance().setFrameHandling(e);
    }
    async isFeatureSupported(e) {
      return (await this.workerCommand("isFeatureSupported", {
        feature: e
      })).supported;
    }
  };
o.deviceID = a.getDeviceId();
var S = o;
export { S as a };