import { a as a$2 } from './chunk-3UG34CP2.js';
import { a } from './chunk-KBO3YYBN.js';
import { a as a$1 } from './chunk-LBAL6OCN.js';
import { Color, MeasureUnit, FocusGestureStrategy, VideoResolution, TorchState, CameraSettings } from 'scandit-web-datacapture-core';
var S = e => ({
    SymbologySettings: Object.keys(e.SymbologySettings).reduce((t, o) => (t[o] = a$1.fromJSON(JSON.parse(e.SymbologySettings[o])), t), {}),
    SymbologyDescriptions: e.SymbologyDescriptions.map(t => a.fromJSON(JSON.parse(t))),
    CompositeTypeDescriptions: e.CompositeTypeDescriptions.map(t => JSON.parse(t)),
    BarcodeCapture: {
      BarcodeCaptureOverlay: {
        defaultStyle: e.BarcodeCapture.BarcodeCaptureOverlay.defaultStyle,
        DefaultBrush: {
          fillColor: Color.fromJSON(e.BarcodeCapture.BarcodeCaptureOverlay.DefaultBrush.fillColor),
          strokeColor: Color.fromJSON(e.BarcodeCapture.BarcodeCaptureOverlay.DefaultBrush.strokeColor),
          strokeWidth: e.BarcodeCapture.BarcodeCaptureOverlay.DefaultBrush.strokeWidth
        },
        styles: Object.fromEntries(Object.keys(e.BarcodeCapture.BarcodeCaptureOverlay.styles).map(t => [t, {
          DefaultBrush: {
            fillColor: Color.fromJSON(e.BarcodeCapture.BarcodeCaptureOverlay.styles[t].DefaultBrush.fillColor),
            strokeColor: Color.fromJSON(e.BarcodeCapture.BarcodeCaptureOverlay.styles[t].DefaultBrush.strokeColor),
            strokeWidth: e.BarcodeCapture.BarcodeCaptureOverlay.styles[t].DefaultBrush.strokeWidth
          }
        }]))
      },
      BarcodeCaptureSettings: {
        codeDuplicateFilter: e.BarcodeCapture.BarcodeCaptureSettings.codeDuplicateFilter
      },
      RecommendedCameraSettings: CameraSettings.fromJSON(e.BarcodeCapture.RecommendedCameraSettings)
    },
    BarcodeTracking: {
      BarcodeTrackingBasicOverlay: {
        defaultStyle: e.BarcodeTracking.BarcodeTrackingBasicOverlay.defaultStyle,
        DefaultBrush: {
          fillColor: Color.fromJSON(e.BarcodeTracking.BarcodeTrackingBasicOverlay.DefaultBrush.fillColor),
          strokeColor: Color.fromJSON(e.BarcodeTracking.BarcodeTrackingBasicOverlay.DefaultBrush.strokeColor),
          strokeWidth: e.BarcodeTracking.BarcodeTrackingBasicOverlay.DefaultBrush.strokeWidth
        },
        styles: Object.fromEntries(Object.keys(e.BarcodeTracking.BarcodeTrackingBasicOverlay.styles).map(t => [t, {
          DefaultBrush: {
            fillColor: Color.fromJSON(e.BarcodeTracking.BarcodeTrackingBasicOverlay.styles[t].DefaultBrush.fillColor),
            strokeColor: Color.fromJSON(e.BarcodeTracking.BarcodeTrackingBasicOverlay.styles[t].DefaultBrush.strokeColor),
            strokeWidth: e.BarcodeTracking.BarcodeTrackingBasicOverlay.styles[t].DefaultBrush.strokeWidth
          }
        }]))
      },
      RecommendedCameraSettings: CameraSettings.fromJSON(e.BarcodeTracking.RecommendedCameraSettings)
    },
    BarcodeFind: {
      CameraSettings: {
        hiddenProperties: {
          exposureTargetBias: e.BarcodeFind.CameraSettings.hiddenProperties.exposureTargetBias,
          grayScaleFrameReader: e.BarcodeFind.CameraSettings.hiddenProperties.grayScaleFrameReader
        },
        settings: CameraSettings.fromJSON(e.BarcodeFind.CameraSettings.settings)
      },
      BarcodeFindViewSettings: {
        settings: a$2.fromJSON(e.BarcodeFind.BarcodeFindViewSettings.settings)
      }
    },
    SparkScan: {
      SparkScanFeedback: {
        errorVibrationPattern: e.SparkScan.SparkScanFeedback.errorVibrationPattern
      },
      SparkScanSettings: {
        codeDuplicateFilter: e.SparkScan.SparkScanSettings.codeDuplicateFilter,
        locationSelection: {
          radius: e.SparkScan.SparkScanSettings.locationSelection.radius,
          unit: e.SparkScan.SparkScanSettings.locationSelection.unit
        },
        targetModeLocationSelection: {
          radius: e.SparkScan.SparkScanSettings.targetModeLocationSelection.radius,
          unit: e.SparkScan.SparkScanSettings.targetModeLocationSelection.unit
        },
        hiddenProperties: {
          zoom1x: {
            motion_scan_suppression_mode: e.SparkScan.SparkScanSettings.hiddenProperties.zoom1x.motion_scan_suppression_mode
          },
          zoom2x: {
            motion_scan_suppression_mode: e.SparkScan.SparkScanSettings.hiddenProperties.zoom2x.motion_scan_suppression_mode
          }
        }
      },
      SparkScanOverlay: {
        DefaultBrush: {
          fillColor: Color.fromJSON(e.SparkScan.SparkScanOverlay.DefaultBrush.fillColor),
          strokeColor: Color.fromJSON(e.SparkScan.SparkScanOverlay.DefaultBrush.strokeColor),
          strokeWidth: e.SparkScan.SparkScanOverlay.DefaultBrush.strokeWidth
        },
        defaultStyle: "frame"
      },
      CameraSettings: {
        hiddenProperties: {
          exposureTargetBias: e.SparkScan.CameraSettings.hiddenProperties.exposureTargetBias,
          grayScaleFrameReader: e.SparkScan.CameraSettings.hiddenProperties.grayScaleFrameReader,
          repeatedTriggerInterval: e.SparkScan.CameraSettings.hiddenProperties.repeatedTriggerInterval
        },
        settings: CameraSettings.fromJSON(e.SparkScan.CameraSettings.settings)
      },
      SparkScanViewSettings: {
        triggerButtonCollapseTimeout: e.SparkScan.SparkScanViewSettings.triggerButtonCollapseTimeout,
        defaultTorchState: e.SparkScan.SparkScanViewSettings.defaultTorchState,
        soundEnabled: e.SparkScan.SparkScanViewSettings.soundEnabled,
        hapticEnabled: e.SparkScan.SparkScanViewSettings.hapticEnabled,
        defaultHandMode: e.SparkScan.SparkScanViewSettings.defaultHandMode,
        holdToScanEnabled: e.SparkScan.SparkScanViewSettings.holdToScanEnabled,
        visualFeedbackEnabled: e.SparkScan.SparkScanViewSettings.visualFeedbackEnabled,
        inactiveStateTimeout: e.SparkScan.SparkScanViewSettings.inactiveStateTimeout,
        zoomFactorOut: e.SparkScan.SparkScanViewSettings.zoomFactorOut,
        zoomFactorIn: e.SparkScan.SparkScanViewSettings.zoomFactorIn
      }
    },
    SparkCapture: {
      SparkCaptureSettings: {
        codeDuplicateFilter: e.SparkCapture.SparkCaptureSettings.codeDuplicateFilter
      }
    }
  }),
  m = S({
    BarcodeCapture: {
      BarcodeCaptureSettings: {
        codeDuplicateFilter: 0
      },
      BarcodeCaptureOverlay: {
        defaultStyle: "frame",
        DefaultBrush: {
          fillColor: "#00000000",
          strokeColor: "#FFFFFFFF",
          strokeWidth: 3
        },
        styles: {
          frame: {
            DefaultBrush: {
              fillColor: "#00000000",
              strokeColor: "#FFFFFFFF",
              strokeWidth: 3
            }
          }
        }
      },
      RecommendedCameraSettings: {
        zoomFactor: 1,
        zoomGestureZoomFactor: 2,
        focusGestureStrategy: "manualUntilCapture",
        preferredResolution: "auto"
      }
    },
    SymbologySettings: {
      ean13Upca: JSON.stringify({
        activeSymbolCounts: [12],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      ean8: JSON.stringify({
        activeSymbolCounts: [8],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      upce: JSON.stringify({
        activeSymbolCounts: [6],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      code128: JSON.stringify({
        activeSymbolCounts: [40, 39, 38, 37, 36, 35, 34, 33, 32, 31, 30, 29, 28, 27, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: ["strip_leading_fnc1"]
      }),
      code39: JSON.stringify({
        activeSymbolCounts: [40, 39, 38, 37, 36, 35, 34, 33, 32, 31, 30, 29, 28, 27, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      code93: JSON.stringify({
        activeSymbolCounts: [40, 39, 38, 37, 36, 35, 34, 33, 32, 31, 30, 29, 28, 27, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      interleavedTwoOfFive: JSON.stringify({
        activeSymbolCounts: [40, 38, 36, 34, 32, 30, 28, 26, 24, 22, 20, 18, 16, 14, 12, 10, 8, 6],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      qr: JSON.stringify({
        activeSymbolCounts: [],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      dataMatrix: JSON.stringify({
        activeSymbolCounts: [],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: ["strip_leading_fnc1"]
      }),
      pdf417: JSON.stringify({
        activeSymbolCounts: [],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      msiPlessey: JSON.stringify({
        activeSymbolCounts: [32, 31, 30, 29, 28, 27, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      databar: JSON.stringify({
        activeSymbolCounts: [2],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      databarExpanded: JSON.stringify({
        activeSymbolCounts: [],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      codabar: JSON.stringify({
        activeSymbolCounts: [20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      aztec: JSON.stringify({
        activeSymbolCounts: [],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      maxicode: JSON.stringify({
        activeSymbolCounts: [],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      code11: JSON.stringify({
        activeSymbolCounts: [20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7],
        checksums: ["mod11"],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      databarLimited: JSON.stringify({
        activeSymbolCounts: [1],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      code25: JSON.stringify({
        activeSymbolCounts: [20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      microPdf417: JSON.stringify({
        activeSymbolCounts: [],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      rm4scc: JSON.stringify({
        activeSymbolCounts: [24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      kix: JSON.stringify({
        activeSymbolCounts: [24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      dotcode: JSON.stringify({
        activeSymbolCounts: [],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      microQr: JSON.stringify({
        activeSymbolCounts: [],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      code32: JSON.stringify({
        activeSymbolCounts: [8],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      lapa4sc: JSON.stringify({
        activeSymbolCounts: [16],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      iata2of5: JSON.stringify({
        activeSymbolCounts: [20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      matrix2of5: JSON.stringify({
        activeSymbolCounts: [20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      }),
      uspsIntelligentMail: JSON.stringify({
        activeSymbolCounts: [65],
        checksums: [],
        colorInvertedEnabled: !1,
        enabled: !1,
        extensions: []
      })
    },
    BarcodeTracking: {
      BarcodeTrackingBasicOverlay: {
        defaultStyle: "frame",
        DefaultBrush: {
          fillColor: "#00000000",
          strokeColor: "#FFFFFFFF",
          strokeWidth: 3
        },
        styles: {
          frame: {
            DefaultBrush: {
              fillColor: "#00000000",
              strokeColor: "#FFFFFFFF",
              strokeWidth: 3
            }
          },
          dot: {
            DefaultBrush: {
              fillColor: "#FFFFFFFF",
              strokeColor: "#FFFFFFFF",
              strokeWidth: 1
            }
          },
          legacy: {
            DefaultBrush: {
              fillColor: "#00000000",
              strokeColor: "#FFFFFFFF",
              strokeWidth: 1
            }
          }
        }
      },
      RecommendedCameraSettings: {
        zoomFactor: 1,
        zoomGestureZoomFactor: 2,
        focusGestureStrategy: "manualUntilCapture",
        preferredResolution: "fullHd"
      }
    },
    BarcodeFind: {
      CameraSettings: {
        hiddenProperties: {
          exposureTargetBias: -1,
          grayScaleFrameReader: !0
        },
        settings: {
          zoomFactor: 1,
          zoomGestureZoomFactor: 1,
          focusGestureStrategy: "manualUntilCapture",
          preferredResolution: "fullHd"
        }
      },
      BarcodeFindViewSettings: {
        settings: {
          inListItemColor: Color.fromHex("#2EC1CE").toJSON(),
          notInListItemColor: Color.fromHex("#FFFFFF").toJSON(),
          hapticEnabled: !0,
          soundEnabled: !0
        }
      }
    },
    SparkScan: {
      SparkScanFeedback: {
        errorVibrationPattern: [600]
      },
      SparkScanSettings: {
        codeDuplicateFilter: 1e3,
        locationSelection: {
          radius: .5,
          unit: MeasureUnit.Fraction
        },
        targetModeLocationSelection: {
          radius: .07,
          unit: MeasureUnit.Fraction
        },
        hiddenProperties: {
          zoom1x: {
            motion_scan_suppression_mode: 1
          },
          zoom2x: {
            motion_scan_suppression_mode: 2
          }
        }
      },
      SparkScanOverlay: {
        DefaultBrush: {
          fillColor: "#00000000",
          strokeColor: "#FFFFFFFF",
          strokeWidth: 3
        },
        defaultStyle: "frame"
      },
      CameraSettings: {
        hiddenProperties: {
          exposureTargetBias: -1,
          grayScaleFrameReader: !0,
          repeatedTriggerInterval: 1
        },
        settings: {
          focus: {
            focusGestureStrategy: FocusGestureStrategy.None
          },
          preferredResolution: VideoResolution.FullHD,
          zoomFactor: 1,
          zoomGestureZoomFactor: 2
        }
      },
      SparkScanViewSettings: {
        defaultHandMode: "right",
        defaultTorchState: TorchState.Off,
        hapticEnabled: !0,
        holdToScanEnabled: !0,
        soundEnabled: !0,
        triggerButtonCollapseTimeout: Number.POSITIVE_INFINITY,
        visualFeedbackEnabled: !0,
        inactiveStateTimeout: 1e4,
        zoomFactorOut: 1,
        zoomFactorIn: 2
      }
    },
    CompositeTypeDescriptions: [JSON.stringify({
      symbologies: ["ean8", "ean13Upca", "microPdf417", "databarLimited", "upce", "databar", "databarExpanded"],
      types: ["A"]
    }), JSON.stringify({
      symbologies: ["ean8", "ean13Upca", "microPdf417", "databarLimited", "upce", "databar", "databarExpanded"],
      types: ["B"]
    }), JSON.stringify({
      symbologies: ["code128", "pdf417"],
      types: ["C"]
    })],
    SparkCapture: {
      SparkCaptureSettings: {
        codeDuplicateFilter: 1e3
      }
    },
    SymbologyDescriptions: [JSON.stringify({
      activeSymbolCountRange: {
        maximum: 12,
        minimum: 12,
        step: 1
      },
      defaultSymbolCountRange: {
        maximum: 12,
        minimum: 12,
        step: 1
      },
      identifier: "ean13Upca",
      isAvailable: !0,
      isColorInvertible: !0,
      readableName: "EAN-13/UPC-A",
      supportedChecksums: [],
      supportedExtensions: ["two_digit_add_on", "strict", "remove_leading_upca_zero", "relaxed_sharp_quiet_zone_check", "five_digit_add_on"]
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 8,
        minimum: 8,
        step: 1
      },
      defaultSymbolCountRange: {
        maximum: 8,
        minimum: 8,
        step: 1
      },
      identifier: "ean8",
      isAvailable: !0,
      isColorInvertible: !0,
      readableName: "EAN-8",
      supportedChecksums: [],
      supportedExtensions: ["two_digit_add_on", "strict", "relaxed_sharp_quiet_zone_check", "five_digit_add_on"]
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 6,
        minimum: 6,
        step: 1
      },
      defaultSymbolCountRange: {
        maximum: 6,
        minimum: 6,
        step: 1
      },
      identifier: "upce",
      isAvailable: !0,
      isColorInvertible: !0,
      readableName: "UPC-E",
      supportedChecksums: [],
      supportedExtensions: ["two_digit_add_on", "strict", "return_as_upca", "remove_leading_upca_zero", "five_digit_add_on"]
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 50,
        minimum: 4,
        step: 1
      },
      defaultSymbolCountRange: {
        maximum: 40,
        minimum: 6,
        step: 1
      },
      identifier: "code128",
      isAvailable: !0,
      isColorInvertible: !0,
      readableName: "Code 128",
      supportedChecksums: [],
      supportedExtensions: ["strict"]
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 50,
        minimum: 3,
        step: 1
      },
      defaultSymbolCountRange: {
        maximum: 40,
        minimum: 6,
        step: 1
      },
      identifier: "code39",
      isAvailable: !0,
      isColorInvertible: !0,
      readableName: "Code 39",
      supportedChecksums: ["mod43"],
      supportedExtensions: ["strict", "relaxed_sharp_quiet_zone_check", "full_ascii"]
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 60,
        minimum: 5,
        step: 1
      },
      defaultSymbolCountRange: {
        maximum: 40,
        minimum: 6,
        step: 1
      },
      identifier: "code93",
      isAvailable: !0,
      isColorInvertible: !1,
      readableName: "Code 93",
      supportedChecksums: [],
      supportedExtensions: ["strict"]
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 50,
        minimum: 4,
        step: 2
      },
      defaultSymbolCountRange: {
        maximum: 40,
        minimum: 6,
        step: 2
      },
      identifier: "interleavedTwoOfFive",
      isAvailable: !0,
      isColorInvertible: !1,
      readableName: "Interleaved Two of Five",
      supportedChecksums: [],
      supportedExtensions: ["strict"]
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 0,
        minimum: 0,
        step: 0
      },
      defaultSymbolCountRange: {
        maximum: 0,
        minimum: 0,
        step: 0
      },
      identifier: "qr",
      isAvailable: !0,
      isColorInvertible: !0,
      readableName: "QR Code",
      supportedChecksums: [],
      supportedExtensions: ["guess_encoding"]
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 0,
        minimum: 0,
        step: 0
      },
      defaultSymbolCountRange: {
        maximum: 0,
        minimum: 0,
        step: 0
      },
      identifier: "dataMatrix",
      isAvailable: !0,
      isColorInvertible: !0,
      readableName: "Data Matrix",
      supportedChecksums: [],
      supportedExtensions: ["direct_part_marking_mode"]
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 0,
        minimum: 0,
        step: 0
      },
      defaultSymbolCountRange: {
        maximum: 0,
        minimum: 0,
        step: 0
      },
      identifier: "pdf417",
      isAvailable: !0,
      isColorInvertible: !1,
      readableName: "PDF417",
      supportedChecksums: [],
      supportedExtensions: []
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 32,
        minimum: 3,
        step: 1
      },
      defaultSymbolCountRange: {
        maximum: 32,
        minimum: 6,
        step: 1
      },
      identifier: "msiPlessey",
      isAvailable: !0,
      isColorInvertible: !1,
      readableName: "MSI Plessey",
      supportedChecksums: ["mod11", "mod1010", "mod1110"],
      supportedExtensions: ["strict"]
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 2,
        minimum: 2,
        step: 1
      },
      defaultSymbolCountRange: {
        maximum: 2,
        minimum: 2,
        step: 1
      },
      identifier: "databar",
      isAvailable: !0,
      isColorInvertible: !1,
      readableName: "GS1 DataBar 14",
      supportedChecksums: [],
      supportedExtensions: ["strict"]
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 0,
        minimum: 0,
        step: 0
      },
      defaultSymbolCountRange: {
        maximum: 0,
        minimum: 0,
        step: 0
      },
      identifier: "databarExpanded",
      isAvailable: !0,
      isColorInvertible: !1,
      readableName: "GS1 DataBar Expanded",
      supportedChecksums: [],
      supportedExtensions: ["strict"]
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 34,
        minimum: 3,
        step: 1
      },
      defaultSymbolCountRange: {
        maximum: 20,
        minimum: 7,
        step: 1
      },
      identifier: "codabar",
      isAvailable: !0,
      isColorInvertible: !1,
      readableName: "Codabar",
      supportedChecksums: ["mod11", "mod16"],
      supportedExtensions: ["strict"]
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 0,
        minimum: 0,
        step: 0
      },
      defaultSymbolCountRange: {
        maximum: 0,
        minimum: 0,
        step: 0
      },
      identifier: "aztec",
      isAvailable: !0,
      isColorInvertible: !1,
      readableName: "Aztec",
      supportedChecksums: [],
      supportedExtensions: []
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 0,
        minimum: 0,
        step: 0
      },
      defaultSymbolCountRange: {
        maximum: 0,
        minimum: 0,
        step: 0
      },
      identifier: "maxicode",
      isAvailable: !0,
      isColorInvertible: !1,
      readableName: "MaxiCode",
      supportedChecksums: [],
      supportedExtensions: []
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 34,
        minimum: 5,
        step: 1
      },
      defaultSymbolCountRange: {
        maximum: 20,
        minimum: 7,
        step: 1
      },
      identifier: "code11",
      isAvailable: !0,
      isColorInvertible: !1,
      readableName: "Code 11",
      supportedChecksums: ["mod11"],
      supportedExtensions: ["strict"]
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 1,
        minimum: 1,
        step: 1
      },
      defaultSymbolCountRange: {
        maximum: 1,
        minimum: 1,
        step: 1
      },
      identifier: "databarLimited",
      isAvailable: !0,
      isColorInvertible: !1,
      readableName: "GS1 DataBar Limited",
      supportedChecksums: [],
      supportedExtensions: ["strict"]
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 32,
        minimum: 3,
        step: 1
      },
      defaultSymbolCountRange: {
        maximum: 20,
        minimum: 7,
        step: 1
      },
      identifier: "code25",
      isAvailable: !0,
      isColorInvertible: !1,
      readableName: "Code 25",
      supportedChecksums: [],
      supportedExtensions: ["strict"]
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 0,
        minimum: 0,
        step: 0
      },
      defaultSymbolCountRange: {
        maximum: 0,
        minimum: 0,
        step: 0
      },
      identifier: "microPdf417",
      isAvailable: !0,
      isColorInvertible: !1,
      readableName: "MicroPDF417",
      supportedChecksums: [],
      supportedExtensions: []
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 50,
        minimum: 4,
        step: 1
      },
      defaultSymbolCountRange: {
        maximum: 24,
        minimum: 7,
        step: 1
      },
      identifier: "rm4scc",
      isAvailable: !0,
      isColorInvertible: !1,
      readableName: "RM4SCC",
      supportedChecksums: [],
      supportedExtensions: []
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 50,
        minimum: 4,
        step: 1
      },
      defaultSymbolCountRange: {
        maximum: 24,
        minimum: 7,
        step: 1
      },
      identifier: "kix",
      isAvailable: !0,
      isColorInvertible: !1,
      readableName: "KIX",
      supportedChecksums: [],
      supportedExtensions: []
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 0,
        minimum: 0,
        step: 0
      },
      defaultSymbolCountRange: {
        maximum: 0,
        minimum: 0,
        step: 0
      },
      identifier: "dotcode",
      isAvailable: !0,
      isColorInvertible: !0,
      readableName: "DotCode",
      supportedChecksums: [],
      supportedExtensions: []
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 0,
        minimum: 0,
        step: 0
      },
      defaultSymbolCountRange: {
        maximum: 0,
        minimum: 0,
        step: 0
      },
      identifier: "microQr",
      isAvailable: !0,
      isColorInvertible: !0,
      readableName: "Micro QR",
      supportedChecksums: [],
      supportedExtensions: []
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 8,
        minimum: 8,
        step: 1
      },
      defaultSymbolCountRange: {
        maximum: 8,
        minimum: 8,
        step: 1
      },
      identifier: "code32",
      isAvailable: !0,
      isColorInvertible: !1,
      readableName: "Code 32",
      supportedChecksums: [],
      supportedExtensions: ["strict"]
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 16,
        minimum: 16,
        step: 1
      },
      defaultSymbolCountRange: {
        maximum: 16,
        minimum: 16,
        step: 1
      },
      identifier: "lapa4sc",
      isAvailable: !0,
      isColorInvertible: !1,
      readableName: "LAPA4SC",
      supportedChecksums: [],
      supportedExtensions: []
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 32,
        minimum: 3,
        step: 1
      },
      defaultSymbolCountRange: {
        maximum: 20,
        minimum: 7,
        step: 1
      },
      identifier: "iata2of5",
      isAvailable: !0,
      isColorInvertible: !1,
      readableName: "IATA 2 of 5",
      supportedChecksums: ["mod1010"],
      supportedExtensions: ["strict"]
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 32,
        minimum: 3,
        step: 1
      },
      defaultSymbolCountRange: {
        maximum: 20,
        minimum: 7,
        step: 1
      },
      identifier: "matrix2of5",
      isAvailable: !0,
      isColorInvertible: !1,
      readableName: "Matrix 2 of 5",
      supportedChecksums: [],
      supportedExtensions: ["strict"]
    }), JSON.stringify({
      activeSymbolCountRange: {
        maximum: 65,
        minimum: 65,
        step: 1
      },
      defaultSymbolCountRange: {
        maximum: 65,
        minimum: 65,
        step: 1
      },
      identifier: "uspsIntelligentMail",
      isAvailable: !0,
      isColorInvertible: !1,
      readableName: "USPS Intelligent Mail",
      supportedChecksums: [],
      supportedExtensions: []
    })]
  });
function C(e) {
  m = e;
}
a.defaults = () => ({
  SymbologyDescriptions: m.SymbologyDescriptions
});
export { S as a, m as b, C as c };