import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderStatus',
  standalone: false,
})
export class OrderStatusPipe implements PipeTransform {
  orderStatusText = {
    1: 'neu',
    2: '',
    4: '',
    8: 'geparkt',
    16: 'bestellt',
    32: 'Vorbereitung Versand',
    64: 'versendet',
    128: 'eingetroffen',
    256: 'abgeholt',
    512: 'storniert (Kunde)',
    1024: 'storniert',
    2048: 'storniert (Lieferant)',
    4096: 'nicht lieferbar',
    8192: 'nachbestellt',
    16384: 'zurückgegeben',
    32768: 'steht zum Download zur Verfügung',
    65536: 'downloaded',
    131072: 'nicht abgeholt',
    262144: 'ans Lager (nicht abgeholt)',
    524288: 'angefragt',
    1048576: 'weitergeleitet intern',
    2097152: 'überfällig',
    4194304: 'zugestellt',
    8388608: 'Lieferant ermittelt',
    16777216: 'derzeit nicht lieferbar',
    33554432: 'reserviert',
    67108864: 'zusammengestellt',
    134217728: 'verpackt',
    268435456: 'Lieferschein erzeugt',
  };

  transform(value: any): any {
    return this.orderStatusText[value] || '';
  }
}
