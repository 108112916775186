<div class="side-menu-group">
  <span class="side-menu-group-label">Kunden</span>
  <nav class="side-menu-group-nav">
    <a
      class="side-menu-group-item"
      (click)="closeSideMenu(); resetBranch(); focusSearchBox()"
      [routerLink]="productRoutePath$ | async"
      sharedRegexRouterLinkActive="active"
      sharedRegexRouterLinkActiveTest="^\/kunde\/\d*\/product"
      (isActiveChange)="focusSearchBox()"
    >
      <div class="side-menu-group-item-icon">
        <shared-icon icon="import-contacts"></shared-icon>
      </div>
      <span class="side-menu-group-item-label">Artikelsuche</span>
    </a>

    <div class="side-menu-group-sub-item-wrapper">
      <a
        *ngIf="customerSearchRoute$ | async; let customerSearchRoute"
        class="side-menu-group-item"
        (click)="closeSideMenu(); focusSearchBox()"
        [routerLink]="customerSearchRoute.path"
        [queryParams]="customerSearchRoute.queryParams"
        sharedRegexRouterLinkActive="active"
        sharedRegexRouterLinkActiveTest="^\/kunde\/\d*\/customer"
        (isActiveChange)="customerActive($event); focusSearchBox()"
      >
        <span class="side-menu-group-item-icon">
          <shared-icon icon="person"></shared-icon>
        </span>
        <span class="side-menu-group-item-label">Kunden</span>
        <button
          class="side-menu-group-arrow"
          [class.side-menu-item-rotate]="customerExpanded"
          (click)="
            $event.stopPropagation(); $event.preventDefault(); customerExpanded = !customerExpanded
          "
        >
          <shared-icon icon="keyboard-arrow-down"></shared-icon>
        </button>
      </a>

      <div class="side-menu-group-sub-items" [class.hidden]="!customerExpanded">
        <a
          *ngIf="customerSearchRoute$ | async; let customerSearchRoute"
          class="side-menu-group-item"
          (click)="closeSideMenu(); focusSearchBox()"
          [routerLink]="customerSearchRoute.path"
          [queryParams]="customerSearchRoute.queryParams"
          sharedRegexRouterLinkActive="active"
          sharedRegexRouterLinkActiveTest="^\/kunde\/\d*\/customer\/(\(search|search)"
          (isActiveChange)="focusSearchBox()"
        >
          <span class="side-menu-group-item-icon"></span>
          <span class="side-menu-group-item-label">Suchen</span>
        </a>
        <a
          *ngIf="customerCreateRoute$ | async; let customerCreateRoute"
          class="side-menu-group-item"
          (click)="closeSideMenu()"
          [routerLink]="customerCreateRoute.path"
          [queryParams]="customerCreateRoute.queryParams"
          sharedRegexRouterLinkActive="active"
          sharedRegexRouterLinkActiveTest="^\/kunde\/\d*\/customer\/(\(create|create)"
        >
          <span class="side-menu-group-item-icon"></span>
          <span class="side-menu-group-item-label">Erfassen</span>
        </a>
      </div>
    </div>

    <a
      *ifRole="'Store'"
      class="side-menu-group-item"
      (click)="closeSideMenu(); focusSearchBox()"
      [routerLink]="pickUpShelfOutRoutePath$ | async"
      sharedRegexRouterLinkActive="active"
      sharedRegexRouterLinkActiveTest="^\/kunde\/\d*\/pickup-shelf"
      (isActiveChange)="focusSearchBox()"
    >
      <span class="side-menu-group-item-icon">
        <shared-icon icon="unarchive"></shared-icon>
      </span>
      <span class="side-menu-group-item-label">Warenausgabe</span>
    </a>
    <a
      *ifRole="'Store'"
      class="side-menu-group-item"
      (click)="closeSideMenu(); focusSearchBox()"
      [routerLink]="[
        '/',
        processService.activatedProcess()?.id || processService.nextId(),
        'return',
      ]"
      (isActiveChange)="focusSearchBox()"
    >
      <span class="side-menu-group-item-icon w-[2.375rem] h-12">
        <ng-icon name="isaNavigationReturn"></ng-icon>
      </span>
      <span class="side-menu-group-item-label">Retoure</span>
    </a>

    <a
      *ifRole="'CallCenter'"
      class="side-menu-group-item"
      (click)="closeSideMenu(); resetBranch(); focusSearchBox()"
      [routerLink]="customerOrdersRoutePath$ | async"
      sharedRegexRouterLinkActive="active"
      sharedRegexRouterLinkActiveTest="^\/kunde\/\d*\/order"
      (isActiveChange)="focusSearchBox()"
    >
      <span class="side-menu-group-item-icon">
        <shared-icon icon="deployed-code"></shared-icon>
      </span>
      <span class="side-menu-group-item-label">Bestellungen</span>
    </a>
  </nav>
</div>

<div class="side-menu-group" *ifRole="'Store'">
  <span class="side-menu-group-label">Filiale</span>
  <nav class="side-menu-group-nav">
    <a
      *ngIf="taskCalenderNavigation$ | async; let taskCalenderNavigation"
      class="side-menu-group-item"
      (click)="closeSideMenu(); focusSearchBox()"
      [routerLink]="taskCalenderNavigation.path"
      [queryParams]="taskCalenderNavigation.queryParams"
      routerLinkActive="active"
      (isActiveChange)="focusSearchBox()"
    >
      <span class="side-menu-group-item-icon">
        <shared-icon icon="event-available"></shared-icon>
      </span>
      <span class="side-menu-group-item-label">Kalender</span>
    </a>
    <a
      class="side-menu-group-item"
      (click)="closeSideMenu()"
      *ngIf="assortmentNavigation$ | async; let assortmentNavigation"
      [routerLink]="assortmentNavigation.path"
      [queryParams]="assortmentNavigation.queryParams"
      routerLinkActive="active"
    >
      <span class="side-menu-group-item-icon">
        <shared-icon icon="shape-outline"></shared-icon>
      </span>
      <span class="side-menu-group-item-label">Sortiment</span>
    </a>

    <div class="side-menu-group-sub-item-wrapper">
      <a
        class="side-menu-group-item"
        (click)="closeSideMenu(); focusSearchBox()"
        *ngIf="pickUpShelfInRoutePath$ | async; let pickUpShelfInNavigation"
        [routerLink]="pickUpShelfInNavigation.path"
        [queryParams]="pickUpShelfInNavigation.queryParams"
        sharedRegexRouterLinkActive="active"
        sharedRegexRouterLinkActiveTest="^\/filiale\/(pickup-shelf|goods\/in)"
        (isActiveChange)="shelfActive($event); focusSearchBox()"
      >
        <span class="side-menu-group-item-icon">
          <shared-icon icon="isa-abholfach"></shared-icon>
        </span>
        <span class="side-menu-group-item-label">Abholfach</span>
        <button
          class="side-menu-group-arrow"
          [class.side-menu-item-rotate]="shelfExpanded"
          (click)="
            $event.stopPropagation(); $event.preventDefault(); shelfExpanded = !shelfExpanded
          "
        >
          <shared-icon icon="keyboard-arrow-down"></shared-icon>
        </button>
      </a>

      <div class="side-menu-group-sub-items" [class.hidden]="!shelfExpanded">
        <a
          class="side-menu-group-item"
          *ngIf="pickUpShelfInRoutePath$ | async; let pickUpShelfInListNavigation"
          (click)="closeSideMenu(); focusSearchBox()"
          [routerLink]="pickUpShelfInListNavigation.path"
          [queryParams]="pickUpShelfInListNavigation.queryParams"
          [class.has-child-view]="currentShelfView$ | async"
          sharedRegexRouterLinkActive="active"
          [sharedRegexRouterLinkActiveTest]="'^\/filiale\/pickup-shelf'"
          (isActiveChange)="shelfActive($event); focusSearchBox()"
        >
          <span class="side-menu-group-item-icon"></span>
          <span class="side-menu-group-item-label">Einbuchen</span>
        </a>
        <a
          class="side-menu-group-item"
          (click)="closeSideMenu()"
          [routerLink]="['/filiale', 'goods', 'in', 'reservation']"
          [queryParams]="{ view: 'reservation' }"
          [class.active-child]="(currentShelfView$ | async) === 'reservation'"
          routerLinkActive="active"
          (isActiveChange)="shelfActive($event)"
        >
          <span class="side-menu-group-item-icon"></span>
          <span class="side-menu-group-item-label">Reservierung</span>
        </a>
        <a
          class="side-menu-group-item"
          (click)="closeSideMenu()"
          [routerLink]="['/filiale', 'goods', 'in', 'cleanup']"
          [queryParams]="{ view: 'cleanup' }"
          [class.active-child]="(currentShelfView$ | async) === 'cleanup'"
          routerLinkActive="active"
          (isActiveChange)="shelfActive($event)"
        >
          <span class="side-menu-group-item-icon"></span>
          <span class="side-menu-group-item-label">Ausräumen</span>
        </a>
        <a
          class="side-menu-group-item"
          (click)="closeSideMenu()"
          [routerLink]="['/filiale', 'goods', 'in', 'preview']"
          [queryParams]="{ view: 'remission' }"
          [class.active-child]="(currentShelfView$ | async) === 'remission'"
          routerLinkActive="active"
          (isActiveChange)="shelfActive($event)"
        >
          <span class="side-menu-group-item-icon"></span>
          <span class="side-menu-group-item-label">Remi-Vorschau</span>
        </a>
        <a
          class="side-menu-group-item"
          (click)="closeSideMenu()"
          [routerLink]="['/filiale', 'goods', 'in', 'list']"
          [queryParams]="{ view: 'wareneingangsliste' }"
          [class.active-child]="(currentShelfView$ | async) === 'wareneingangsliste'"
          routerLinkActive="active"
          (isActiveChange)="shelfActive($event)"
        >
          <span class="side-menu-group-item-icon"></span>
          <span class="side-menu-group-item-label">Fehlende</span>
        </a>
      </div>
    </div>

    <a
      class="side-menu-group-item"
      (click)="closeSideMenu()"
      *ngIf="remissionNavigation$ | async; let remissionNavigation"
      [routerLink]="remissionNavigation.path"
      [queryParams]="remissionNavigation.queryParams"
      routerLinkActive="active"
    >
      <span class="side-menu-group-item-icon">
        <shared-icon icon="assignment-return"></shared-icon>
      </span>
      <span class="side-menu-group-item-label">Remission</span>
    </a>

    <a
      class="side-menu-group-item"
      *ngIf="packageInspectionNavigation$ | async; let packageInspectionNavigation"
      (click)="closeSideMenu(); fetchAndOpenPackages()"
      [routerLink]="packageInspectionNavigation.path"
      [queryParams]="packageInspectionNavigation.queryParams"
      routerLinkActive="active"
    >
      <span class="side-menu-group-item-icon">
        <shared-icon icon="clipboard-check-outline"></shared-icon>
      </span>
      <span class="side-menu-group-item-label">Wareneingang</span>
    </a>
  </nav>
</div>
