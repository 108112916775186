<button
  type="button"
  class="px-2 py-3 bg-[#C6CBD0] rounded flex flex-row items-center open:bg-[#596470] open:text-white z-dropdown"
  [cdkMenuTriggerFor]="navMenu"
  #menuTrigger="cdkMenuTriggerFor"
  [class.open]="menuTrigger.isOpen()"
>
  <shared-icon icon="apps" [size]="24"></shared-icon>
  <shared-icon [icon]="menuTrigger.isOpen() ? 'arrow-drop-up' : 'arrow-drop-down'" [size]="24"></shared-icon>
</button>

<ng-template #navMenu>
  <div class="pt-1">
    <shared-menu>
      <a
        sharedMenuItem
        *ngIf="customerDetailsRoute$ | async; let customerDetailsRoute"
        [routerLink]="customerDetailsRoute.path"
        [queryParams]="customerDetailsRoute.queryParams"
        [queryParamsHandling]="'merge'"
      >
        Kundendetails
      </a>
      <a
        sharedMenuItem
        *ngIf="ordersRoute$ | async; let ordersRoute"
        [routerLink]="ordersRoute.path"
        [queryParams]="ordersRoute.queryParams"
        [queryParamsHandling]="'merge'"
      >
        Bestellungen
      </a>
      <a
        sharedMenuItem
        *ngIf="kundenkarteRoute$ | async; let kundenkarteRoute"
        [routerLink]="kundenkarteRoute.path"
        [queryParams]="kundenkarteRoute.queryParams"
        [queryParamsHandling]="'merge'"
      >
        Kundenkarte
      </a>
      <a
        sharedMenuItem
        *ngIf="historyRoute$ | async; let historyRoute"
        [routerLink]="historyRoute.path"
        [queryParams]="historyRoute.queryParams"
        [queryParamsHandling]="'merge'"
      >
        Historie
      </a>
    </shared-menu>
  </div>
</ng-template>
