import { AfterViewInit, Directive, ElementRef, Input, Self } from '@angular/core';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { UiSelectComponent } from '@ui/select';

@Directive()
export abstract class UiAutocompleteBase implements AfterViewInit {
  private _autofocus = false;

  @Input()
  get autofocus() {
    return this._autofocus;
  }
  set autofocus(value: BooleanInput) {
    this._autofocus = coerceBooleanProperty(value);
  }

  ngAfterViewInit(): void {
    if (this.autofocus) {
      setTimeout(() => {
        this.focus();
      }, 1);
    }
  }

  abstract focus(): void;
}

@Directive({
  selector: 'input[autofocus], textarea[autofocus]',
  exportAs: 'uiAutofocus',
  standalone: false,
})
export class UiAutofocusDirective extends UiAutocompleteBase {
  get nativeElement() {
    return this.elementRef.nativeElement as HTMLElement;
  }

  constructor(@Self() private elementRef: ElementRef) {
    super();
  }

  focus() {
    this.nativeElement.focus();
  }
}

@Directive({
  selector: 'ui-checkbox[autofocus]',
  exportAs: 'uiAutofocus',
  standalone: false,
})
export class UiCheckboxAutofocusDirective extends UiAutocompleteBase {
  get nativeElement() {
    return this.elementRef.nativeElement as HTMLElement;
  }

  constructor(@Self() private elementRef: ElementRef) {
    super();
  }

  focus() {
    this.nativeElement.focus();
  }
}

@Directive({
  selector: 'ui-select[autofocus]',
  exportAs: 'uiAutofocus',
  standalone: false,
})
export class UiSelectAutofocusDirective extends UiAutocompleteBase {
  constructor(@Self() private selectComponent: UiSelectComponent) {
    super();
  }

  focus() {
    this.selectComponent.focus();
  }
}
