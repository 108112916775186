import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DiffDTO as CrmDiffDTO } from '@generated/swagger/crm-api';
import { DiffDTO as OmsDiffDTO } from '@generated/swagger/oms-api';

@Component({
  selector: 'shared-history-log-entry-values',
  templateUrl: 'history-log-entry-values.component.html',
  styleUrls: ['history-log-entry-values.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SharedHistoryLogEntryValuesComponent {
  @Input() historyLogEntryValues: CrmDiffDTO | OmsDiffDTO;
  constructor() {}
}
