import { Pipe, PipeTransform } from '@angular/core';
import { OrderType } from '@generated/swagger/oms-api';

@Pipe({
  name: 'clientChannel',
  standalone: false,
})
export class ClientChannelPipe implements PipeTransform {
  clientChannelText = {
    0: '',
    1: '',
    2: 'Filiale',
    4: 'HSC',
    8: 'Online',
    16: 'App',
    24: 'Mobile Shop',
    32: '',
  };

  transform(value: OrderType): string {
    const v: number = value;
    if (v === 8 || v === 16 || v === 24) {
      return 'hugendubel.de';
    }
    return this.clientChannelText[v] || '';
  }
}
