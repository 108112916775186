import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { Location } from '@angular/common';
import { CustomerSearchStore } from '../store';
import { Filter } from '@shared/components/filter';
import { CustomerSearchNavigation } from '@shared/services/navigation';
import { Router } from '@angular/router';
import { injectCancelSearch } from '@shared/services/cancel-subject';

@Component({
  selector: 'page-customer-filter-main-view',
  templateUrl: 'filter-main-view.component.html',
  styleUrls: ['filter-main-view.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'page-customer-filter-main-view' },
  standalone: false,
})
export class CustomerFilterMainViewComponent {
  private _store = inject(CustomerSearchStore);
  private _location = inject(Location);
  private _router = inject(Router);
  private _customerSearchNavigation = inject(CustomerSearchNavigation);

  cancelSearch = injectCancelSearch();

  fetchingFilterSettings$ = this._store.fetchingFilter$;

  fetching$ = this._store.fetchingCustomerList$;

  filter$ = this._store.filter$;

  message$ = this._store.message$;

  get mainRoute() {
    return this._customerSearchNavigation.defaultRoute({ processId: this._store.processId });
  }

  get isOnMainRoute() {
    return this._router.isActive(this.mainRoute.urlTree, {
      queryParams: 'ignored',
      paths: 'exact',
      fragment: 'exact',
      matrixParams: 'exact',
    });
  }

  constructor() {
    this.cancelSearch();
  }

  search(filter: Filter) {
    this._store.setFilter(filter);
    this._store.search({ resetScrollIndex: true, ignoreRestore: true });
  }

  hasSelectedOptions(filter: Filter) {
    // Is Query available
    const hasInputOptions = !!filter.input.find(
      (input) => !!input.input.find((fi) => fi.hasFilterInputOptionsSelected()),
    );

    // Are filter or filterChips selected
    const hasFilterOptions = !!filter.filter.find(
      (input) => !!input.input.find((fi) => fi.hasFilterInputOptionsSelected()),
    );
    return hasInputOptions || hasFilterOptions;
  }

  reset() {
    this._store.setFilter(this._store.defaultFilter);
  }

  clearFilter(value: Filter) {
    value.unselectAllFilterOptions();
  }

  close() {
    try {
      this._location.back();
    } catch (error) {
      console.log('close', { error });
    }
  }
}
