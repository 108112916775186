import { Pipe, PipeTransform } from '@angular/core';
import { IInputGroup } from '../tree';

@Pipe({
  name: 'group',
  standalone: false,
})
export class InputGroupSelectorPipe implements PipeTransform {
  transform(value: IInputGroup[], group: string): any {
    return value?.find((f) => f?.group === group);
  }
}
