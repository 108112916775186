import { n as n$1 } from './chunk-N4NOODAT.js';
var e = n$1.fromRGBA(46, 193, 206, 1),
  n = n$1.fromRGBA(98, 209, 218, 1),
  t = n$1.fromRGBA(151, 224, 230, 1),
  c = n$1.fromRGBA(203, 240, 243, 1),
  a = n$1.fromRGBA(18, 22, 25, 1),
  m = n$1.fromRGBA(218, 225, 231, 1),
  p = n$1.fromRGBA(40, 211, 128, 1),
  G = n$1.fromRGBA(83, 220, 153, 1),
  R = n$1.fromRGBA(126, 229, 179, 1),
  f = n$1.fromRGBA(169, 237, 204, 1),
  s = n$1.fromRGBA(212, 246, 230, 1),
  x = n$1.fromRGBA(251, 192, 44, 1),
  y = n$1.fromRGBA(252, 205, 86, 1),
  A = n$1.fromRGBA(253, 217, 128, 1),
  B = n$1.fromRGBA(254, 230, 171, 1),
  d = n$1.fromRGBA(254, 242, 213, 1),
  S = n$1.fromRGBA(250, 68, 70, 1),
  g = n$1.fromRGBA(251, 105, 107, 1),
  i = n$1.fromRGBA(252, 143, 144, 1),
  l = n$1.fromRGBA(253, 180, 181, 1),
  O = n$1.fromRGBA(254, 218, 218, 1);
export { e as a, n as b, t as c, c as d, a as e, m as f, p as g, G as h, R as i, f as j, s as k, x as l, y as m, A as n, B as o, d as p, S as q, g as r, i as s, l as t, O as u };