import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[uiClickOutside]',
  standalone: false,
})
export class UiClickOutsideDirective {
  @Input()
  touchEvent = true;

  @Input()
  mouseEvent = true;

  @Input('uiClickOutside')
  container: ElementRef | HTMLElement;

  get containerElement(): HTMLElement {
    const ele = this.container || this.elementRef;
    return ele instanceof ElementRef ? ele.nativeElement : ele;
  }

  @Output()
  clicked = new EventEmitter<MouseEvent | TouchEvent>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event', '$event.target'])
  @HostListener('document:touchstart', ['$event', '$event.target'])
  onClick(event: MouseEvent | TouchEvent, eventTarget: HTMLElement) {
    if (!this.mouseEvent && event instanceof MouseEvent) {
      return;
    }

    if (!this.touchEvent && event instanceof TouchEvent) {
      return;
    }

    if (!this.containerElement.contains(eventTarget)) {
      this.clicked.emit(event);
    }
  }
}
