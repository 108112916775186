import { ModuleWithProviders, NgModule, Provider, Type } from '@angular/core';
import { ActionHandler } from './action-handler.interface';
import { CommandService } from './command.service';
import { FEATURE_ACTION_HANDLERS, ROOT_ACTION_HANDLERS } from './tokens';

export function provideActionHandlers(actionHandlers: Type<ActionHandler>[]): Provider[] {
  return [
    CommandService,
    actionHandlers.map((handler) => ({ provide: FEATURE_ACTION_HANDLERS, useClass: handler, multi: true })),
  ];
}

@NgModule({})
export class CoreCommandModule {
  static forRoot(actionHandlers: Type<ActionHandler>[]): ModuleWithProviders<CoreCommandModule> {
    return {
      ngModule: CoreCommandModule,
      providers: [
        CommandService,
        actionHandlers.map((handler) => ({ provide: ROOT_ACTION_HANDLERS, useClass: handler, multi: true })),
      ],
    };
  }

  static forChild(actionHandlers: Type<ActionHandler>[]): ModuleWithProviders<CoreCommandModule> {
    return {
      ngModule: CoreCommandModule,
      providers: [
        CommandService,
        actionHandlers.map((handler) => ({ provide: FEATURE_ACTION_HANDLERS, useClass: handler, multi: true })),
      ],
    };
  }
}
