import { d } from './chunk-5Y4OYXNX.js';
var u = (o => (o.On = "on", o.Off = "off", o.Standby = "standby", o.Starting = "starting", o.Stopping = "stopping", o.BootingUp = "bootingUp", o.WakingUp = "wakingUp", o.GoingToSleep = "goingToSleep", o.ShuttingDown = "shuttingDown", o))(u || {}),
  c = (t => (t.On = "on", t.Off = "off", t))(c || {}),
  m = (t => (t.WorldFacing = "worldFacing", t.UserFacing = "userFacing", t))(m || {}),
  p = (r => (r.Auto = "auto", r.HD = "hd", r.FullHD = "fullHd", r.UHD4K = "uhd4k", r))(p || {}),
  g = (r => (r.None = "none", r.Manual = "manual", r.ManualUntilCapture = "manualUntilCapture", r.AutoOnLocation = "autoOnLocation", r))(g || {});
function f(a) {
  var e;
  return ((e = a == null ? void 0 : a.toJSONObject()) == null ? void 0 : e.type) === "camera";
}
var i = class a {
  constructor(e) {
    this.preferredResolution = d.Camera.Settings.preferredResolution;
    this.zoomFactor = d.Camera.Settings.zoomFactor;
    this.zoomGestureZoomFactor = d.Camera.Settings.zoomGestureZoomFactor;
    this.focus = {
      focusGestureStrategy: d.Camera.Settings.focusGestureStrategy
    };
    if (e != null) for (let t of Object.getOwnPropertyNames(e)) this[t] = e[t];
  }
  get focusGestureStrategy() {
    return this.focus.focusGestureStrategy;
  }
  set focusGestureStrategy(e) {
    this.focus.focusGestureStrategy = e;
  }
  static fromJSON(e) {
    let t = new a();
    return t.preferredResolution = e.preferredResolution, t.zoomFactor = e.zoomFactor, t.zoomGestureZoomFactor = e.zoomGestureZoomFactor, t.focusGestureStrategy = e.focusGestureStrategy, t;
  }
  setProperty(e, t) {
    this[e] = t;
  }
  getProperty(e) {
    return this[e];
  }
  toJSONObject() {
    let e = {
        preferredResolution: this.preferredResolution,
        zoomFactor: this.zoomFactor,
        zoomGestureZoomFactor: this.zoomGestureZoomFactor,
        focus: {
          focusGestureStrategy: this.focus.focusGestureStrategy
        }
      },
      t = {};
    for (let n of Object.keys(this)) [...Object.keys(e), "focusGestureStrategy"].includes(n) || (t[n] = this[n]);
    return {
      ...e,
      ...t
    };
  }
};
export { u as a, c as b, m as c, p as d, g as e, f, i as g };