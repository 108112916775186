<form *ngIf="formData$ | async; let data" (keydown.enter)="$event.preventDefault()">
  <h1 class="title flex flex-row items-center justify-center">
    Kundendaten erfassen
    <!-- <span
      class="rounded-full ml-4 h-8 w-8 text-xl text-center border-2 border-solid border-brand text-brand">i</span> -->
  </h1>

  <p class="description">
    Um Sie als Kunde beim nächsten
    <br />
    Einkauf wieder zu erkennen, legen
    <br />
    wir Ihnen gerne eine Kundenkarte an.
  </p>

  <app-customer-type-selector
    [processId]="processId$ | async"
    [p4mUser]="true"
    [customerType]="_customerType"
    (valueChanges)="customerTypeChanged($event)"
    [p4mReadonly]="data?._meta?.p4mRequired"
  ></app-customer-type-selector>

  <app-p4m-number-form-block
    #p4mBlock
    [tabIndexStart]="1"
    (onInit)="addFormBlock('p4m', $event)"
    [data]="data.p4m"
    (dataChanges)="patchFormData('p4m', $event)"
    [readonly]="data?._meta?.p4mRequired"
    [focusAfterInit]="!data?._meta?.p4mRequired"
    [asyncValidatorFns]="asyncLoyaltyCardValidatorFn"
  ></app-p4m-number-form-block>

  <app-accept-agb-form-block
    [tabIndexStart]="inBlock.tabIndexEnd + 1"
    (onInit)="addFormBlock('agb', $event)"
    [data]="data.agb"
    (dataChanges)="patchFormData('agb', $event)"
    [requiredMark]="true"
    [validatorFns]="agbValidatorFns"
  ></app-accept-agb-form-block>

  <app-newsletter-form-block
    class="mb-4"
    #newsletterBlock
    [tabIndexStart]="p4mBlock.tabIndexEnd + 1"
    (onInit)="addFormBlock('newsletter', $event)"
    [data]="data.newsletter"
    (dataChanges)="patchFormData('newsletter', $event)"
    [focusAfterInit]="data?._meta?.p4mRequired"
  ></app-newsletter-form-block>

  <app-name-form-block
    #nameBlock
    [tabIndexStart]="newsletterBlock.tabIndexEnd + 1"
    (onInit)="addFormBlock('name', $event)"
    [data]="data.name"
    [requiredMarks]="nameRequiredMarks"
    [validatorFns]="nameValidationFns"
    (dataChanges)="patchFormData('name', $event)"
  ></app-name-form-block>

  <p class="info" *ngIf="customerType === 'webshop-p4m'">
    Wir werden Ihnen Werbung zu ähnlichen Produkten oder Dienstleistungen aus unserem Sortiment per E-Mail zusenden. Sie können der
    Verwendung Ihrer Daten jederzeit z.B. mittels der in den E-Mails enthaltenen Abmeldelinks widersprechen, ohne dass hierfür andere als
    die Übermittlungskosten nach den Basistarifen entstehen.
  </p>

  <app-email-form-block
    class="flex-grow"
    #email
    [tabIndexStart]="nameBlock.tabIndexEnd + 1"
    [requiredMark]="emailRequiredMark"
    (onInit)="addFormBlock('email', $event)"
    [data]="data.email"
    (dataChanges)="patchFormData('email', $event)"
    [validatorFns]="emailValidatorFn"
    [asyncValidatorFns]="asyncEmailVlaidtorFn"
  ></app-email-form-block>

  <app-organisation-form-block
    #orgBlock
    [tabIndexStart]="email.tabIndexEnd + 1"
    appearence="compact"
    (onInit)="addFormBlock('organisation', $event)"
    [data]="data.organisation"
    (dataChanges)="patchFormData('organisation', $event)"
  ></app-organisation-form-block>

  <app-address-form-block
    [defaults]="{ country: 'DEU' }"
    #addressBlock
    [tabIndexStart]="orgBlock.tabIndexEnd + 1"
    (onInit)="addFormBlock('address', $event)"
    [data]="data.address"
    (dataChanges)="patchFormData('address', $event)"
    [requiredMarks]="addressRequiredMarks"
    [validatorFns]="addressValidatorFns"
  ></app-address-form-block>

  <app-deviating-address-form-block
    #ddaBlock
    [defaults]="{ address: { country: 'DEU' } }"
    [tabIndexStart]="addressBlock.tabIndexEnd + 1"
    (onInit)="addFormBlock('deviatingDeliveryAddress', $event)"
    [data]="data.deviatingDeliveryAddress"
    (dataChanges)="patchFormData('deviatingDeliveryAddress', $event)"
    [nameRequiredMarks]="nameRequiredMarks"
    [nameValidatorFns]="nameValidationFns"
    [addressRequiredMarks]="shippingAddressRequiredMarks"
    [addressValidatorFns]="shippingAddressValidators"
  >
    Die Lieferadresse weicht von der Rechnungsadresse ab
  </app-deviating-address-form-block>

  <app-phone-numbers-form-block
    #phoneNumbers
    [tabIndexStart]="ddaBlock.tabIndexEnd + 1"
    (onInit)="addFormBlock('phoneNumbers', $event)"
    [data]="data.phoneNumbers"
    (dataChanges)="patchFormData('phoneNumbers', $event)"
  ></app-phone-numbers-form-block>

  <app-birth-date-form-block
    #bdBlock
    [tabIndexStart]="phoneNumbers.tabIndexEnd + 1"
    (onInit)="addFormBlock('birthDate', $event)"
    [data]="data.birthDate"
    (dataChanges)="patchFormData('birthDate', $event)"
    [requiredMark]="true"
    [validatorFns]="birthDateValidatorFns"
  ></app-birth-date-form-block>

  <app-interests-form-block
    #inBlock
    [tabIndexStart]="bdBlock.tabIndexEnd + 1"
    (onInit)="addFormBlock('interests', $event)"
    [data]="data.interests"
    (dataChanges)="patchFormData('interests', $event)"
  ></app-interests-form-block>

  <div class="spacer"></div>

  <div class="sticky w-full flex items-center justify-center">
    <button class="cta-submit" type="button" [disabled]="form.invalid || form.pending" (click)="save()">
      <ui-spinner [show]="busy$ | async">Speichern</ui-spinner>
    </button>
  </div>
</form>
