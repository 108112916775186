import { Directive } from '@angular/core';

@Directive({
  selector: 'shared-input-control-suffix',
  host: { class: 'shared-input-control-suffix' },
  standalone: false,
})
export class SuffixDirective {
  constructor() {}
}
