import { a as a$1 } from './chunk-R6E4CT22.js';
import { a as a$5 } from './chunk-FSH6LHLS.js';
import { b as b$1 } from './chunk-SD5C67QU.js';
import { b } from './chunk-FTD535WI.js';
import { a } from './chunk-JKL2W65E.js';
import { d } from './chunk-SK45SCLY.js';
import { a as a$3 } from './chunk-SP3HSP4K.js';
import { a as a$4 } from './chunk-QCZSSQAQ.js';
import { a as a$2 } from './chunk-PSQ6LAQA.js';
var w = (r => (r.CONTINUOUS = "continuous", r.MANUAL = "manual", r.NONE = "none", r.SINGLE_SHOT = "single-shot", r))(w || {}),
  R = (s => (s[s.ULTRA_HD = 0] = "ULTRA_HD", s[s.FULL_HD = 1] = "FULL_HD", s[s.HD = 2] = "HD", s[s.SD = 3] = "SD", s[s.NONE = 4] = "NONE", s))(R || {}),
  L = (t => (t.CAMERA_PROPERTIES = "cameraProperties", t.CAMERA_ACCESS_ERROR = "cameraAccessError", t))(L || {}),
  n = class n {
    constructor() {
      this.checkCameraVideoStreamAccessIfVisibleListener = this.checkCameraVideoStreamAccessIfVisible.bind(this);
      this.handleWebGLContextLostListener = this.handleWebGLContextLost.bind(this);
      this.listeners = new Map();
      this.mirrorImageOverrides = new Map();
      this.postStreamInitializationListener = this.postStreamInitialization.bind(this);
      this.triggerManualFocusListener = this.triggerManualFocus.bind(this);
      this.triggerZoomMoveListener = this.triggerZoomMove.bind(this);
      this.triggerZoomStartListener = this.triggerZoomStart.bind(this);
      this.videoResizeListener = a$1(this.handleVideoResize.bind(this), 500);
      this.videoTrackEndedListener = this.videoTrackEndedRecovery.bind(this);
      this.videoTrackMuteListener = this.videoTrackMuteRecovery.bind(this);
      this._synchronousFrameHandling = !1;
      this._glFrameReaders = {
        GRAYSCALE: null,
        RGBA: null
      };
      this._frameReaderType = "RGBA";
      this.cameraPosition = "worldFacing", this.gui = {
        isCameraRecoveryVisible: () => !1,
        setCameraRecoveryVisible: () => {}
      }, this.isWebGLSupported = !a$2.checkBrowserCompatibility().missingFeatures.includes("webGL"), this.videoElement = document.createElement("video"), this.canvas = document.createElement("canvas"), this.canvas.addEventListener("webglcontextlost", this.handleWebGLContextLostListener);
    }
    get mediaStream() {
      return this._mediaStream;
    }
    set mediaStream(e) {
      this._mediaStream = e, e && (this.mediaTrackCapabilitiesPromise = new Promise(t => {
        this.mediaTrackCapabilitiesPromiseResolver = t;
      }));
    }
    get synchronousFrameHandling() {
      return this._synchronousFrameHandling;
    }
    get canvas2dContext() {
      return this._canvas2dContext || (this._canvas2dContext = this.canvas.getContext("2d", {
        willReadFrequently: !0
      }), this._canvas2dContext == null && a$3.log(a$3.Level.Warn, "Unable to get 2d canvas context"), this.handleVideoResize()), this._canvas2dContext;
    }
    get canvasWebGLContext() {
      var e;
      if (!this._canvas2dContext && !this._canvasWebGLContext) {
        if (!this.isWebGLSupported) {
          a$3.log(a$3.Level.Warn, "WebGL context not supported, falling back to 2d canvas context. This may impact the scanning performance.");
          return;
        }
        let t = (e = this.canvas.getContext("webgl", {
          alpha: !1,
          antialias: !1
        })) != null ? e : this.canvas.getContext("experimental-webgl", {
          alpha: !1,
          antialias: !1
        });
        t != null && (this._canvasWebGLContext = t, this.handleVideoResize());
      }
      return this._canvasWebGLContext;
    }
    static instance() {
      return n._instance == null && (n._instance = new n()), n._instance;
    }
    recycle(e) {
      var t;
      (t = this.getFrameReader()) == null || t.recycle(e);
    }
    getCurrentFrame() {
      var e;
      return (e = this._glFrameReaders.RGBA) == null ? void 0 : e.readFromSource(this.videoElement);
    }
    requestVideoFrame(e) {
      return "requestVideoFrameCallback" in HTMLVideoElement.prototype ? this.videoElement.requestVideoFrameCallback(e) : requestAnimationFrame(e);
    }
    cancelVideoFrame(e) {
      if ("cancelVideoFrameCallback" in HTMLVideoElement.prototype) {
        this.videoElement.cancelVideoFrameCallback(e);
        return;
      }
      cancelAnimationFrame(e);
    }
    addListener(e, t) {
      var r;
      let i = (r = this.listeners.get(e)) != null ? r : [];
      i.includes(t) || this.listeners.set(e, [...i, t]);
    }
    async applyCameraSettings(e) {
      if (this.selectedCameraSettings = e, this.activeCamera == null) throw new a$4(n.noCameraErrorParameters);
      return this.initializeCameraWithSettings(this.activeCamera, e);
    }
    captureImage() {
      var e;
      if (((e = this.mediaStream) == null ? void 0 : e.active) != null) {
        if (this.canvasWebGLContext != null) return this.captureImageForWebGLContext();
        if (this.canvas2dContext != null) return this.captureImageFor2dContext();
      }
      return null;
    }
    async initializeCameraWithSettings(e, t) {
      if (this.cameraInitializationPromise && (await this.cameraInitializationPromise), e == null) throw new a$4(n.noCameraErrorParameters);
      return this.setSelectedCamera(e), this.selectedCameraSettings = t, this.activeCameraSettings = t, this.cameraInitializationPromise = this.initializeCameraAndCheckUpdatedSettings(e), this.cameraInitializationPromise;
    }
    isMirrorImageEnabled() {
      if (this.selectedCamera && this.activeCamera) {
        let e = this.mirrorImageOverrides.get(this.activeCamera);
        return e != null ? e : this.activeCamera.position === "userFacing";
      }
      return !1;
    }
    isPinchToZoomEnabled() {
      return this.pinchToZoomEnabled;
    }
    isTapToFocusEnabled() {
      return this.tapToFocusEnabled;
    }
    async isTorchAvailable() {
      var e;
      return this.mediaStream ? (await this.waitForCapabilities(), ((e = this.mediaTrackCapabilities) == null ? void 0 : e.torch) === !0) : !1;
    }
    async playVideo() {
      return new Promise(e => {
        this._canvas2dContext && this._canvas2dContext.clearRect(0, 0, this.canvas.width, this.canvas.height);
        let t = this.videoElement.play();
        t == null ? e() : t.then(e).catch(() => {
          e();
        });
      });
    }
    async reinitializeCamera() {
      if (this.activeCamera == null) a$3.log(a$3.Level.Debug, "Camera reinitialization delayed");else {
        a$3.log(a$3.Level.Debug, "Reinitialize camera:", this.activeCamera);
        try {
          let e = this.activeCamera;
          await this.stopStream(), await this.initializeCameraWithSettings(e, this.activeCameraSettings);
        } catch (e) {
          throw a$3.log(a$3.Level.Warn, "Couldn't access camera:", this.activeCamera, e), this.emit("cameraAccessError", e), e;
        }
      }
    }
    removeListener(e, t) {
      var s;
      let r = ((s = this.listeners.get(e)) != null ? s : []).filter(o => o !== t);
      this.listeners.set(e, r);
    }
    async setCameraPosition(e) {
      var i;
      this.setInitialCameraPosition(e);
      let t = d.getMainCameraForPosition(await d.getCameras(), e);
      if (t && t.deviceId !== ((i = this.selectedCamera) == null ? void 0 : i.deviceId)) return this.initializeCameraWithSettings(t, this.selectedCameraSettings);
    }
    async setExposure(e) {
      var r, s, o, m, d, l, v, p;
      let t = (s = (r = this.mediaStream) == null ? void 0 : r.getVideoTracks()) != null ? s : [];
      if (t.length === 0 || typeof t[0].applyConstraints != "function") return;
      await this.waitForCapabilities();
      let i = e.time != null && e.time > 0 ? "manual" : "continuous";
      if (((m = (o = this.mediaTrackCapabilities) == null ? void 0 : o.exposureMode) == null ? void 0 : m.includes(i)) === !0) {
        if (await t[0].applyConstraints({
          advanced: [{
            exposureMode: i
          }]
        }), e.time != null && e.time > 0 && this.mediaTrackCapabilities.exposureTime) {
          let u = Math.max((d = this.mediaTrackCapabilities.exposureTime.min) != null ? d : .01, Math.min(e.time, (l = this.mediaTrackCapabilities.exposureTime.max) != null ? l : 1e5));
          await t[0].applyConstraints({
            advanced: [{
              exposureTime: u
            }]
          });
        } else if (e.compensation != null && i === "continuous" && this.mediaTrackCapabilities.exposureCompensation) {
          let u = Math.max((v = this.mediaTrackCapabilities.exposureCompensation.min) != null ? v : -10, Math.min(e.compensation, (p = this.mediaTrackCapabilities.exposureCompensation.max) != null ? p : 10));
          await t[0].applyConstraints({
            advanced: [{
              exposureCompensation: u
            }]
          });
        }
      }
    }
    async setFocus(e) {
      var i, r, s, o, m;
      if (!this.mediaStream) return;
      let t = this.mediaStream.getVideoTracks();
      if (t.length > 0 && typeof t[0].applyConstraints == "function") {
        await this.waitForCapabilities();
        let d = e >= 0 ? "manual" : "continuous";
        if (((r = (i = this.mediaTrackCapabilities) == null ? void 0 : i.focusMode) == null ? void 0 : r.includes(d)) === !0 && (await t[0].applyConstraints({
          advanced: [{
            focusMode: d
          }]
        }), d === "manual" && this.mediaTrackCapabilities.focusDistance)) {
          let l = Math.max(0, Math.min(e, 1)) * (((s = this.mediaTrackCapabilities.focusDistance.max) != null ? s : 1e3) - ((o = this.mediaTrackCapabilities.focusDistance.min) != null ? o : .01)) + ((m = this.mediaTrackCapabilities.focusDistance.min) != null ? m : .01);
          await t[0].applyConstraints({
            advanced: [{
              focusDistance: l
            }]
          });
        }
      }
    }
    async setFrameRate(e) {
      var t, i, r;
      if (this.mediaStream && (await this.waitForCapabilities(), (t = this.mediaTrackCapabilities) != null && t.frameRate)) {
        let s = this.mediaStream.getVideoTracks();
        if (s.length > 0 && typeof s[0].applyConstraints == "function") {
          let o = Math.min((i = this.mediaTrackCapabilities.frameRate.max) != null ? i : 240, (r = e.max) != null ? r : 240);
          await s[0].applyConstraints({
            advanced: [{
              frameRate: o
            }]
          });
        }
      }
    }
    setInitialCameraPosition(e) {
      this.cameraPosition = e;
    }
    setInteractionOptions(e, t, i) {
      this.torchToggleEnabled = e, this.tapToFocusEnabled = t, this.pinchToZoomEnabled = i;
    }
    setMirrorImageEnabled(e, t) {
      this.selectedCamera && (e ? (this.videoElement.classList.add(n.MIRRORED_CLASS_NAME), this.canvas.classList.add(n.MIRRORED_CLASS_NAME)) : (this.videoElement.classList.remove(n.MIRRORED_CLASS_NAME), this.canvas.classList.remove(n.MIRRORED_CLASS_NAME)), t && this.mirrorImageOverrides.set(this.selectedCamera, e));
    }
    setPinchToZoomEnabled(e) {
      this.pinchToZoomEnabled = e, this.mediaStream && (this.pinchToZoomEnabled ? this.enablePinchToZoomListeners() : this.disablePinchToZoomListeners());
    }
    setSelectedCamera(e) {
      this.selectedCamera = e;
    }
    setSelectedCameraSettings(e) {
      this.selectedCameraSettings = e;
    }
    setTapToFocusEnabled(e) {
      this.tapToFocusEnabled = e, this.mediaStream && (this.tapToFocusEnabled ? this.enableTapToFocusListeners() : this.disableTapToFocusListeners());
    }
    async setTorchEnabled(e) {
      var t;
      if (this.mediaStream && ((t = this.mediaTrackCapabilities) == null ? void 0 : t.torch) === !0) {
        this.torchEnabled = e;
        let i = this.mediaStream.getVideoTracks();
        i.length > 0 && typeof i[0].applyConstraints == "function" && (await i[0].applyConstraints({
          advanced: [{
            torch: e
          }]
        }));
      }
    }
    async setZoom(e) {
      var t, i, r;
      if (this.mediaStream && (await this.waitForCapabilities(), (t = this.mediaTrackCapabilities) != null && t.zoom)) {
        let s = this.mediaStream.getVideoTracks();
        if (s.length > 0 && typeof s[0].applyConstraints == "function") {
          let o = Math.max((i = this.mediaTrackCapabilities.zoom.min) != null ? i : 1, Math.min(e, (r = this.mediaTrackCapabilities.zoom.max) != null ? r : 16));
          await s[0].applyConstraints({
            advanced: [{
              zoom: o
            }]
          });
        }
      }
    }
    async setupCameras() {
      return this.cameraSetupPromise ? this.cameraSetupPromise : (this.cameraSetupPromise = this.setupCamerasAndStream(), this.cameraSetupPromise);
    }
    pauseStream() {
      if (!this.mediaStream) return;
      let e = this.mediaStream.getVideoTracks()[0];
      e.enabled = !1, a$3.log(a$3.Level.Debug, "Pause camera video stream", this.mediaStream);
    }
    resumeStream() {
      if (!this.mediaStream) return;
      let e = this.mediaStream.getVideoTracks()[0];
      e.enabled = !0, a$3.log(a$3.Level.Debug, "Resume camera video stream", this.mediaStream);
    }
    async stopStream(e = !1) {
      this.activeCamera && (this.activeCamera.currentResolution = void 0), this.activeCamera = void 0, this.torchEnabled = !1, this.mediaStream && (a$3.log(a$3.Level.Debug, `Stop camera video stream access${e ? " (abort access detection)" : ""}:`, this.mediaStream), document.removeEventListener("visibilitychange", this.checkCameraVideoStreamAccessIfVisibleListener), window.clearTimeout(this.cameraAccessTimeout), window.clearInterval(this.videoMetadataCheckInterval), window.clearTimeout(this.getCapabilitiesTimeout), window.clearTimeout(this.manualFocusWaitTimeout), window.clearTimeout(this.manualToAutofocusResumeTimeout), window.clearInterval(this.autofocusInterval), this.videoElement.pause(), await new Promise(t => {
        setTimeout(() => {
          var i, r, s;
          for (let o of (r = (i = this.mediaStream) == null ? void 0 : i.getVideoTracks()) != null ? r : []) o.removeEventListener("ended", this.videoTrackEndedListener), o.stop();
          this.videoElement.srcObject = null, this._canvas2dContext && this._canvas2dContext.clearRect(0, 0, this.canvas.width, this.canvas.height), this.mediaStream = void 0, this.mediaTrackCapabilities = void 0, this.mediaTrackCapabilitiesPromise = void 0, this.mediaTrackCapabilitiesPromiseResolver = void 0, e || (s = this.abortedCameraInitializationResolveCallback) == null || s.call(this), t();
        }, 0);
      }));
    }
    stopVideoTracks() {
      if (this.mediaStream != null) for (let e of this.mediaStream.getVideoTracks()) e.stop();
    }
    async toggleTorch() {
      this.torchEnabled = !this.torchEnabled, await this.setTorchEnabled(this.torchEnabled);
    }
    updateCanvasVideoImage() {
      this.canvas2dContext.drawImage(this.videoElement, 0, 0);
    }
    async waitForCapabilities() {
      var e;
      return (e = this.mediaTrackCapabilitiesPromise) != null ? e : Promise.resolve();
    }
    setFrameReaderType(e) {
      e !== this._frameReaderType && (this._frameReaderType = e, a$3.log(a$3.Level.Debug, `Change frame reader type to ${this._frameReaderType} mode`));
    }
    async allowPictureInPicture(e) {
      e ? (this.videoElement.disablePictureInPicture = !1, this.videoElement.removeAttribute("controlslist"), this.videoElement.removeAttribute("disablepictureinpicture")) : (this.videoElement.disablePictureInPicture = !0, this.videoElement.setAttribute("controlslist", "nodownload"), this.videoElement.setAttribute("disablepictureinpicture", "true")), await this.reinitializeCamera();
    }
    setFrameHandling(e) {
      var t;
      this._synchronousFrameHandling = e, this._frameReaderOptions = this.getFrameReaderOptions(e), (t = this._glFrameReaders[this._frameReaderType]) == null || t.dispose(), delete this._glFrameReaders[this._frameReaderType];
    }
    async accessAutoselectedCamera(e) {
      let t = d.sortCamerasForCameraPosition(e, this.cameraPosition),
        i = t.shift();
      for (; i;) try {
        await this.initializeCameraWithSettings(i, this.selectedCameraSettings);
        return;
      } catch (r) {
        if (this.setSelectedCamera(), t.length > 0) {
          a$3.log(a$3.Level.Warn, "Couldn't access camera:", i, r), i = t.shift();
          continue;
        }
        throw r;
      }
      throw new a$4(n.noCameraErrorParameters);
    }
    getFrameReaderOptions(e) {
      return e ? {
        minPoolCapacity: 0,
        maxPoolCapacity: 1
      } : a$2.isDesktopDevice() ? {
        maxPoolCapacity: 2,
        minPoolCapacity: 1
      } : {
        maxPoolCapacity: 10,
        minPoolCapacity: 3
      };
    }
    getFrameReader() {
      var t;
      if (this._glFrameReaders[this._frameReaderType]) return this._glFrameReaders[this._frameReaderType];
      if (!this._canvasWebGLContext) return null;
      let e = (t = this._frameReaderOptions) != null ? t : this.getFrameReaderOptions(this._synchronousFrameHandling);
      switch (this._frameReaderType) {
        case "GRAYSCALE":
          {
            this._glFrameReaders.GRAYSCALE = new a$5(this._canvasWebGLContext, e), this._glFrameReaders.RGBA = new b$1(this._canvasWebGLContext, {
              minPoolCapacity: 1,
              maxPoolCapacity: 2
            });
            break;
          }
        case "RGBA":
          {
            this._glFrameReaders.RGBA = new b$1(this._canvasWebGLContext, e);
            break;
          }
        default:
          b(this._frameReaderType);
      }
      return a$3.log(a$3.Level.Debug, `Created frame reader ${this._frameReaderType}`, e), this._glFrameReaders[this._frameReaderType];
    }
    async accessInitialCamera() {
      let e = {
        position: this.cameraPosition,
        deviceId: "",
        label: ""
      };
      try {
        await this.initializeCameraWithSettings(e, this.selectedCameraSettings);
      } catch (t) {} finally {
        this.setSelectedCamera();
      }
      return e;
    }
    recreateCanvas() {
      this.canvas.removeEventListener("webglcontextlost", this.handleWebGLContextLostListener), this._canvasWebGLContext = void 0, this._canvas2dContext = void 0;
      for (let t of Object.values(this._glFrameReaders)) t == null || t.dispose();
      let e = document.createElement("canvas");
      e.addEventListener("webglcontextlost", this.handleWebGLContextLostListener), e.width = this.canvas.width, e.height = this.canvas.height, e.className = this.canvas.className, this.canvas = e;
    }
    captureImageFor2dContext() {
      if (!this.isVideoAndContextStateValid()) return null;
      let {
        width: e,
        height: t
      } = this.canvas;
      return {
        colorType: "RGBA",
        data: this.canvas2dContext.getImageData(0, 0, e, t).data,
        width: e,
        height: t
      };
    }
    areVideoAndWebGLSizeMismatching() {
      return this.canvasWebGLContext ? this.canvasWebGLContext.drawingBufferWidth !== this.videoElement.videoWidth || this.canvasWebGLContext.drawingBufferHeight !== this.videoElement.videoHeight : !0;
    }
    captureImageForWebGLContext() {
      if (!this.isVideoAndContextStateValid() || !this.canvasWebGLContext || this.canvasWebGLContext.drawingBufferWidth <= 2 || this.canvasWebGLContext.drawingBufferHeight <= 2 || this.areVideoAndWebGLSizeMismatching() || this.getFrameReader() == null) return null;
      let e = this.getFrameReader().readFromSource(this.videoElement);
      if (e.data[3] !== 255 && e.colorType === "RGBA") {
        if (a$3.log(a$3.Level.Warn, "Detected incorrect GPU accelerated WebGL image processing, switching to canvas mode"), this.recreateCanvas(), this.canvas2dContext == null) throw new Error("Unable to get 2d canvas context");
        return this.captureImage();
      }
      return e;
    }
    async checkCameraAccess(e) {
      return new Promise((t, i) => {
        this.cameraAccessRejectCallback = r => {
          a$3.log(a$3.Level.Debug, "Camera video stream access failure (video data load timeout):", e), this.gui.setCameraRecoveryVisible(!0), i(r);
        }, this.setCameraAccessTimeout();
      });
    }
    checkCameraVideoStreamAccessIfVisible() {
      document.visibilityState === "visible" && (a$3.log(a$3.Level.Debug, "Page is visible again, waiting for camera video stream start..."), document.removeEventListener("visibilitychange", this.checkCameraVideoStreamAccessIfVisibleListener), this.setCameraAccessTimeout());
    }
    async checkVideoMetadata(e) {
      return new Promise((t, i) => {
        this.videoElement.onloadeddata = () => {
          if (this.videoElement.onloadeddata = null, window.clearTimeout(this.cameraAccessTimeout), this.videoElement.videoWidth > 2 && this.videoElement.videoHeight > 2 && this.videoElement.currentTime > 0) {
            this.updateActiveCameraCurrentResolution(e), a$3.log(a$3.Level.Debug, "Camera video stream access success:", e), t();
            return;
          }
          let r = performance.now();
          window.clearInterval(this.videoMetadataCheckInterval), this.videoMetadataCheckInterval = window.setInterval(async () => {
            if (this.videoElement.videoWidth <= 2 || this.videoElement.videoHeight <= 2 || this.videoElement.currentTime === 0) {
              if (performance.now() - r > n.videoMetadataCheckTimeoutMs) {
                a$3.log(a$3.Level.Warn, "Camera video stream access failure (valid video metadata timeout):", e), window.clearInterval(this.videoMetadataCheckInterval), await this.stopStream(!0), i(new a$4(n.notReadableErrorParameters));
                return;
              }
              return;
            }
            window.clearInterval(this.videoMetadataCheckInterval), this.updateActiveCameraCurrentResolution(e), a$3.log(a$3.Level.Debug, "Camera video stream access success:", e), t();
          }, n.videoMetadataCheckIntervalMs);
        };
      });
    }
    disablePinchToZoomListeners() {
      this.videoElement.removeEventListener("touchstart", this.triggerZoomStartListener), this.videoElement.removeEventListener("touchmove", this.triggerZoomMoveListener);
    }
    disableTapToFocusListeners() {
      for (let e of ["touchend", "mousedown"]) this.videoElement.removeEventListener(e, this.triggerManualFocusListener);
    }
    emit(e, t) {
      var r;
      let i = (r = this.listeners.get(e)) != null ? r : [];
      for (let s of i) s(t);
    }
    enablePinchToZoomListeners() {
      this.videoElement.addEventListener("touchstart", this.triggerZoomStartListener), this.videoElement.addEventListener("touchmove", this.triggerZoomMoveListener);
    }
    enableTapToFocusListeners() {
      for (let e of ["touchend", "mousedown"]) this.videoElement.addEventListener(e, this.triggerManualFocusListener);
    }
    getActiveCamera(e, t, i) {
      return e.length === 1 ? e[0] : e.find(r => r.deviceId === i || r.label !== "" && r.label === t);
    }
    getInitialCameraResolutionConstraint(e) {
      var i;
      let t;
      switch ((i = this.activeCameraSettings) == null ? void 0 : i.preferredResolution) {
        case "uhd4k":
          {
            t = 0;
            break;
          }
        case "fullHd":
          {
            t = 1;
            break;
          }
        case "hd":
        default:
          {
            t = 2;
            break;
          }
      }
      return t;
    }
    async handleCameraInitializationError(e, t, i) {
      if (!["OverconstrainedError", "NotReadableError"].includes(i.name) || i.name === "NotReadableError" && t === 4) throw a$3.log(a$3.Level.Debug, "Camera video stream access failure (unrecoverable error)", e, i), i.name !== "NotAllowedError" && d.markCameraAsInaccessible(e), i;
      if (i.name === "OverconstrainedError" && t === 4) {
        if (e.deviceId === "") throw a$3.log(a$3.Level.Warn, "Camera video stream access failure (no camera with such type error)", e, i), i;
        a$3.log(a$3.Level.Warn, "Detected non-existent deviceId error, attempt to find and reaccess updated camera", e, i);
        let r = e.deviceId;
        if (await d.getCameras(!0), r === e.deviceId) throw a$3.log(a$3.Level.Warn, "Camera video stream access failure (updated camera not found after non-existent deviceId error)", e, i), d.markCameraAsInaccessible(e), i;
        return a$3.log(a$3.Level.Warn, "Updated camera found (recovered from non-existent deviceId error), attempt to access it", e), this.initializeCameraForResolution(e);
      }
      return this.initializeCameraForResolution(e, t + 1);
    }
    handleVideoResize() {
      if (!(this.videoElement.videoWidth <= 2 || this.videoElement.videoHeight <= 2)) {
        if (this.activeCamera && this.updateActiveCameraCurrentResolution(this.activeCamera), this.canvasWebGLContext != null) {
          if (this.canvas.width === this.videoElement.videoWidth && this.canvas.height === this.videoElement.videoHeight) return;
          this.canvas.width = this.videoElement.videoWidth, this.canvas.height = this.videoElement.videoHeight, this.canvasWebGLContext.viewport(0, 0, this.canvasWebGLContext.drawingBufferWidth, this.canvasWebGLContext.drawingBufferHeight);
        } else if (this.canvas2dContext != null) {
          if (this.canvas.width === this.videoElement.videoWidth && this.canvas.height === this.videoElement.videoHeight) return;
          this.canvas.width = this.videoElement.videoWidth, this.canvas.height = this.videoElement.videoHeight;
        }
      }
    }
    handleWebGLContextLost() {
      a$3.log(a$3.Level.Warn, "WebGL context has been lost, restoring..."), this._canvasWebGLContext = void 0, this.canvasWebGLContext ? (this.handleVideoResize(), a$3.log(a$3.Level.Warn, "WebGL context restored")) : a$3.log(a$3.Level.Error, "WebGL context restore failed");
    }
    async initializeCameraAndCheckUpdatedSettings(e) {
      var t, i, r, s, o;
      try {
        if (await this.initializeCameraForResolution(e), this.selectedCameraSettings !== this.activeCameraSettings && (this.selectedCameraSettings == null || this.activeCameraSettings == null || Object.keys(this.selectedCameraSettings).some(u => {
          var b, f;
          return ((b = this.selectedCameraSettings) == null ? void 0 : b[u]) !== ((f = this.activeCameraSettings) == null ? void 0 : f[u]);
        }))) {
          this.activeCameraSettings = this.selectedCameraSettings, await this.initializeCameraAndCheckUpdatedSettings(e);
          return;
        }
        this.activeCameraSettings && this.activeCameraSettings.zoomFactor > 1 && (await this.setZoom(this.activeCameraSettings.zoomFactor));
        let m = (t = this.activeCameraSettings) == null ? void 0 : t.getProperty("minFrameRate"),
          d = (i = this.activeCameraSettings) == null ? void 0 : i.getProperty("maxFrameRate");
        (m != null || d != null) && (await this.setFrameRate({
          min: m,
          max: d
        }));
        let l = (r = this.activeCameraSettings) == null ? void 0 : r.getProperty("manualLensPosition");
        l != null && (await this.setFocus(l));
        let v = (s = this.activeCameraSettings) == null ? void 0 : s.getProperty("exposureTargetBias"),
          p = (o = this.activeCameraSettings) == null ? void 0 : o.getProperty("exposureDuration");
        (v != null || p != null) && (await this.setExposure({
          compensation: v,
          time: p
        }));
      } finally {
        this.cameraInitializationPromise = void 0;
      }
    }
    async initializeCameraForResolution(e, t) {
      var r;
      this.gui.setCameraRecoveryVisible(!1);
      let i = t != null ? t : this.getInitialCameraResolutionConstraint(e);
      this.mediaStream != null && e.deviceId !== "" && ((r = this.activeCamera) == null ? void 0 : r.deviceId) === e.deviceId ? await (a$2.isIPhone() ? this.initializeStreamForResolution(e, i) : this.updateStreamForResolution(e, i)) : await this.initializeStreamForResolution(e, i);
    }
    async initializeStreamForResolution(e, t) {
      var i;
      await this.stopStream();
      try {
        let r = (i = this.activeCameraSettings) == null ? void 0 : i.getProperty("preferredAspectRatio"),
          s = await d.accessCameraStream(e, t, r);
        if (a$3.log(a$3.Level.Debug, "Camera accessed, waiting for camera video stream start..."), typeof s.getVideoTracks()[0].getSettings == "function") {
          let o = s.getVideoTracks()[0].getSettings();
          if (o.width != null && o.height != null && (o.width === 2 || o.height === 2)) {
            if (a$3.log(a$3.Level.Debug, "Camera video stream access failure (invalid video metadata):", e), t === 4) throw new a$4(n.notReadableErrorParameters);
            return await this.stopStream(), this.initializeStreamForResolution(e, t + 1);
          }
        }
        this.mediaStream = s;
        for (let o of this.mediaStream.getVideoTracks()) o.addEventListener("ended", this.videoTrackEndedListener), o.addEventListener("mute", this.videoTrackMuteListener), o.addEventListener("unmute", this.videoTrackMuteListener);
        try {
          await this.setupCameraStreamVideo(e, s);
        } catch (o) {
          if (t === 4) throw o;
          return this.initializeStreamForResolution(e, t + 1);
        }
      } catch (r) {
        return this.handleCameraInitializationError(e, t, r);
      }
    }
    isVideoAndContextStateValid() {
      return this.videoElement.readyState === 4 && this.videoElement.videoWidth > 2 && this.videoElement.videoHeight > 2 && this.canvas.width > 2 && this.canvas.height > 2;
    }
    postStreamInitialization() {
      window.clearTimeout(this.getCapabilitiesTimeout), this.getCapabilitiesTimeout = window.setTimeout(() => {
        this.storeStreamCapabilities(), this.setupAutofocus();
      }, n.getCapabilitiesTimeoutMs);
    }
    async recoverStreamIfNeeded() {
      var t, i;
      let e = (t = this.mediaStream) == null ? void 0 : t.getVideoTracks();
      ((i = e == null ? void 0 : e[0]) == null ? void 0 : i.readyState) === "ended" && (await this.reinitializeCamera());
    }
    reportCameraProperties(e, t, i = !0) {
      this.emit("cameraProperties", {
        deviceId: e,
        isFrontFacing: t === "userFacing",
        hasAutofocus: i
      });
    }
    setCameraAccessTimeout() {
      window.clearTimeout(this.cameraAccessTimeout), this.cameraAccessTimeout = window.setTimeout(async () => {
        var e;
        document.visibilityState === "visible" ? (await this.stopStream(!0), (e = this.cameraAccessRejectCallback) == null || e.call(this, new a$4(n.notReadableErrorParameters))) : (a$3.log(a$3.Level.Debug, "Page is currently not visible, delay camera video stream access detection"), document.addEventListener("visibilitychange", this.checkCameraVideoStreamAccessIfVisibleListener));
      }, n.cameraAccessTimeoutMs);
    }
    setupAutofocus() {
      if (window.clearTimeout(this.manualFocusWaitTimeout), window.clearTimeout(this.manualToAutofocusResumeTimeout), this.mediaStream && this.mediaTrackCapabilities) {
        let e = this.mediaTrackCapabilities.focusMode;
        Array.isArray(e) && !e.includes("continuous") && e.includes("single-shot") && (window.clearInterval(this.autofocusInterval), this.autofocusInterval = window.setInterval(this.triggerAutoFocus.bind(this), n.autofocusIntervalMs));
      }
    }
    async setupCameraStreamVideo(e, t) {
      this.videoElement.addEventListener("loadedmetadata", this.postStreamInitializationListener), this.videoElement.addEventListener("resize", this.videoResizeListener), this.tapToFocusEnabled && this.enableTapToFocusListeners(), this.pinchToZoomEnabled && this.enablePinchToZoomListeners();
      let i = Promise.race([this.checkCameraAccess(e), this.checkVideoMetadata(e), new Promise(r => {
        this.abortedCameraInitializationResolveCallback = r;
      })]);
      return this.videoElement.srcObject = t, this.videoElement.load(), await this.playVideo(), this.handleVideoResize(), this.reportCameraProperties(e.deviceId, e.position), i;
    }
    async setupCamerasAndStream() {
      var e, t;
      try {
        let i;
        this.selectedCamera == null && (i = await this.accessInitialCamera());
        let r = await d.getCameras(!1, !0),
          s = (t = (e = this.mediaStream) == null ? void 0 : e.getVideoTracks()[0]) == null ? void 0 : t.getSettings().deviceId;
        if (this.mediaStream && i) {
          let o = this.getActiveCamera(r, i.label, s);
          if (o) {
            if (d.adjustCameraFromMediaStream(this.mediaStream, o), a$2.isDesktopDevice() && (d.setMainCameraForPositionOverridesOnDesktop(this.cameraPosition, o), d.setMainCameraForPositionOverridesOnDesktop(o.position, o)), r.length === 1 || d.getMainCameraForPosition(r, this.cameraPosition) === o) {
              a$3.log(a$3.Level.Debug, "Initial camera access was correct (main camera), keep camera:", o), this.setSelectedCamera(o), this.updateActiveCameraCurrentResolution(o), await this.recoverStreamIfNeeded();
              return;
            }
            a$3.log(a$3.Level.Debug, "Initial camera access was incorrect (not main camera), change camera", {
              ...i,
              deviceId: s
            });
          } else a$3.log(a$3.Level.Debug, "Initial camera access was incorrect (unknown camera), change camera", {
            ...i,
            deviceId: s
          });
        }
        if (this.selectedCamera == null) {
          await this.accessAutoselectedCamera(r);
          return;
        }
        await this.initializeCameraWithSettings(this.selectedCamera, this.selectedCameraSettings);
      } finally {
        this.cameraSetupPromise = void 0;
      }
    }
    storeStreamCapabilities() {
      var e;
      if (this.mediaStream) {
        let t = this.mediaStream.getVideoTracks();
        t.length > 0 && typeof t[0].getCapabilities == "function" && (this.mediaTrackCapabilities = t[0].getCapabilities()), this.mediaTrackCapabilitiesPromiseResolver && this.mediaTrackCapabilitiesPromiseResolver();
      }
      this.activeCamera && this.reportCameraProperties(this.activeCamera.deviceId, this.activeCamera.position, ((e = this.mediaTrackCapabilities) == null ? void 0 : e.focusMode) == null || this.mediaTrackCapabilities.focusMode.includes("continuous"));
    }
    async triggerAutoFocus() {
      await this.triggerFocusMode("single-shot");
    }
    async triggerFocusMode(e) {
      if (this.mediaStream) {
        let t = this.mediaStream.getVideoTracks();
        if (t.length > 0 && typeof t[0].applyConstraints == "function") try {
          await t[0].applyConstraints({
            advanced: [{
              focusMode: e
            }]
          });
        } catch (i) {}
      }
    }
    async triggerManualFocus(e) {
      if (e) {
        if (e.preventDefault(), e.type === "touchend" && e.touches.length > 0) return;
        if (this.pinchToZoomDistance != null) {
          this.pinchToZoomDistance = void 0;
          return;
        }
      }
      if (window.clearTimeout(this.manualFocusWaitTimeout), window.clearTimeout(this.manualToAutofocusResumeTimeout), !this.mediaStream || !this.mediaTrackCapabilities) return;
      let t = this.mediaTrackCapabilities.focusMode;
      Array.isArray(t) && t.includes("single-shot") && (t.includes("continuous") && t.includes("manual") ? await this.triggerManualFocusForContinuous() : t.includes("continuous") || (await this.triggerManualFocusForSingleShot()));
    }
    async triggerManualFocusForContinuous() {
      var i;
      if (!this.mediaStream) return;
      this.manualToAutofocusResumeTimeout = window.setTimeout(async () => {
        await this.triggerFocusMode("continuous");
      }, n.manualToAutofocusResumeTimeoutMs);
      let e = !0,
        t = this.mediaStream.getVideoTracks();
      t.length > 0 && typeof t[0].getConstraints == "function" && (e = ((i = t[0].getConstraints().advanced) == null ? void 0 : i.some(r => r.focusMode === "manual")) === !0), e ? (await this.triggerFocusMode("continuous"), this.manualFocusWaitTimeout = window.setTimeout(async () => {
        await this.triggerFocusMode("manual");
      }, n.manualFocusWaitTimeoutMs)) : await this.triggerFocusMode("manual");
    }
    async triggerManualFocusForSingleShot() {
      window.clearInterval(this.autofocusInterval), this.manualToAutofocusResumeTimeout = window.setTimeout(() => {
        this.autofocusInterval = window.setInterval(this.triggerAutoFocus.bind(this), n.autofocusIntervalMs);
      }, n.manualToAutofocusResumeTimeoutMs);
      try {
        await this.triggerFocusMode("single-shot");
      } catch (e) {}
    }
    async triggerZoomMove(e) {
      this.pinchToZoomDistance == null || (e == null ? void 0 : e.touches.length) !== 2 || e.preventDefault();
    }
    triggerZoomStart(e) {
      var r, s, o, m;
      if ((e == null ? void 0 : e.touches.length) !== 2 || (e.preventDefault(), this.pinchToZoomDistance = Math.hypot((e.touches[1].screenX - e.touches[0].screenX) / screen.width, (e.touches[1].screenY - e.touches[0].screenY) / screen.height), !this.mediaStream || !((r = this.mediaTrackCapabilities) != null && r.zoom))) return;
      let t = this.mediaStream.getVideoTracks();
      if (t.length === 0 || typeof t[0].getConstraints != "function") return;
      this.pinchToZoomInitialZoom = (s = this.mediaTrackCapabilities.zoom.min) != null ? s : 1;
      let i = t[0].getConstraints();
      if (i.advanced) {
        let d = (o = i.advanced.find(l => "zoom" in l)) == null ? void 0 : o.zoom;
        d != null && (this.pinchToZoomInitialZoom = typeof d == "number" ? d : (m = d.ideal) != null ? m : 1);
      }
    }
    updateActiveCameraCurrentResolution(e) {
      this.videoElement.videoWidth > 2 && this.videoElement.videoHeight > 2 && (e.currentResolution = {
        width: this.videoElement.videoWidth,
        height: this.videoElement.videoHeight
      }), e.deviceId !== "" && (this.activeCamera = e, this.setMirrorImageEnabled(this.isMirrorImageEnabled(), !1));
    }
    async updateStreamForResolution(e, t) {
      var i;
      if (this.mediaStream == null) await this.initializeCameraForResolution(e, t);else try {
        let r = (i = this.activeCameraSettings) == null ? void 0 : i.getProperty("preferredAspectRatio");
        a$3.log(a$3.Level.Debug, "Setting new resolution for active camera video stream..."), await this.mediaStream.getVideoTracks()[0].applyConstraints(d.getUserMediaVideoParameters(t, r)), await new Promise(o => {
          this.videoElement.addEventListener("timeupdate", () => {
            o();
          }, {
            once: !0
          });
        });
        let s = this.mediaStream.getVideoTracks()[0].getSettings();
        a$3.log(a$3.Level.Debug, "New active camera video stream resolution set", {
          width: s.width,
          height: s.height
        });
      } catch (r) {
        if (t === 4) throw r;
        await this.updateStreamForResolution(e, t + 1);
      }
    }
    async videoTrackEndedRecovery() {
      if (document.visibilityState === "visible") try {
        a$3.log(a$3.Level.Debug, 'Detected video track "ended" event, try to reinitialize camera'), await this.reinitializeCamera();
      } catch (e) {} else a$3.log(a$3.Level.Debug, "Page is currently not visible, delay camera reinitialization until visible"), document.addEventListener("visibilitychange", this.checkCameraVideoStreamAccessIfVisibleListener);
    }
    videoTrackMuteRecovery(e) {
      if (this.videoElement.onloadeddata != null) {
        a$3.log(a$3.Level.Debug, `Detected video track "${e.type}" event, delay camera video stream access detection`), this.setCameraAccessTimeout();
        return;
      }
      let t = e.type === "mute";
      t !== this.gui.isCameraRecoveryVisible() && (a$3.log(a$3.Level.Debug, `Detected video track "${e.type}" event, ${t ? "enable" : "disable"} camera recovery`), this.gui.setCameraRecoveryVisible(t));
    }
  };
n.autofocusIntervalMs = 1500, n.cameraAccessTimeoutMs = 4e3, n.getCapabilitiesTimeoutMs = 500, n.manualFocusWaitTimeoutMs = 400, n.manualToAutofocusResumeTimeoutMs = 5e3, n.noCameraErrorParameters = {
  name: "NoCameraAvailableError",
  message: "No camera available"
}, n.notReadableErrorParameters = {
  name: "NotReadableError",
  message: "Could not initialize camera correctly"
}, n.videoMetadataCheckIntervalMs = 50, n.videoMetadataCheckTimeoutMs = 4e3, n.MIRRORED_CLASS_NAME = a.MIRRORED_CLASS_NAME, n._instance = null;
var y = n;
export { w as a, R as b, L as c, y as d };