import { AsyncPipe, CommonModule, NgIf } from '@angular/common';
import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Filter, FilterModule } from '@shared/components/filter';
import { CustomerSearchStore } from '../store';
import { map } from 'rxjs/operators';
import { CustomerCreateNavigation } from '@shared/services/navigation';
import { combineLatest } from 'rxjs';
import { CustomerInfoDTO } from '@generated/swagger/crm-api';

@Component({
  selector: 'page-customer-main-side-view',
  templateUrl: 'main-side-view.component.html',
  styleUrls: ['main-side-view.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterModule, FilterModule, AsyncPipe, NgIf],
})
export class MainSideViewComponent {
  private _store = inject(CustomerSearchStore);
  private _customerCreateNavigation = inject(CustomerCreateNavigation);

  message$ = this._store.message$;

  filter$ = this._store.filter$;

  fetching$ = this._store.fetchingCustomerList$;

  createRoute$ = combineLatest(this.filter$, this._store.processId$).pipe(
    map(([filter, processId]) => {
      const queryParams = filter?.getQueryParams();

      let customerInfo: CustomerInfoDTO;

      if (queryParams?.main_qs) {
        const isMail = queryParams.main_qs.includes('@');
        customerInfo = {
          lastName: !isMail ? queryParams.main_qs : undefined,
          communicationDetails: isMail
            ? {
                email: queryParams.main_qs,
              }
            : undefined,
        };
      }

      return this._customerCreateNavigation.createCustomerRoute({ processId, customerInfo });
    }),
  );

  search(filter: Filter) {
    this._store.setFilter(filter);
    this._store.search({ resetScrollIndex: true, ignoreRestore: true });
  }
}
