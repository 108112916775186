<ng-container *ngIf="fetchingFilterSettings$ | async; else filterTemplate"></ng-container>

<ng-template #filterTemplate>
  <div *ngIf="filter$ | async; let filter">
    <div class="customer-filter-header">
      <div class="customer-filter-header-actions flex flex-row justify-end pt-1 px-1">
        <button (click)="clearFilter(filter)" class="text-[#0556B4] mr-2 h-12">Alle Filter entfernen</button>
        <button (click)="close()" class="btn btn-label" *ngIf="!isOnMainRoute">
          <ui-icon [icon]="'close'"></ui-icon>
        </button>
      </div>
      <div class="customer-filter-header-body text-center -mt-3">
        <h1 class="text-[1.625rem] font-bold">Filter</h1>
      </div>
    </div>
    <div class="px-3 bg-surface text-surface-content">
      <shared-filter
        [filter]="filter"
        [loading]="fetching$ | async"
        (search)="search(filter)"
        [hint]="message$ | async"
        [scanner]="true"
      ></shared-filter>
    </div>

    <div class="grid grid-flow-col gap-6 items-center justify-center mt-6">
      <button
        type="button"
        (click)="reset()"
        class="bg-white border-2 border-solid border-brand text-brand text-xl font-bold rounded-full px-6 py-3 disabled:border-components-button-disabled-content disabled:text-components-button-disabled-content"
        [disabled]="fetching$ | async"
      >
        <shared-loader [loading]="fetching$ | async">Filter zurücksetzen</shared-loader>
      </button>

      <button
        type="button"
        (click)="search(filter)"
        class="bg-brand border-2 border-solid border-brand text-white text-xl font-bold rounded-full px-6 py-3 disabled:bg-components-button-disabled-content disabled:border-components-button-disabled-content"
        [disabled]="(fetching$ | async) || !hasSelectedOptions(filter)"
      >
        <shared-loader [loading]="fetching$ | async">Filter anwenden</shared-loader>
      </button>
    </div>
  </div>
</ng-template>
