import { b } from './chunk-TRWFJYCT.js';
import { PrivateNoneLocationSelection } from 'scandit-web-datacapture-core';
var p = class {
  constructor() {
    this.codeDuplicateFilter = b.BarcodeCapture.BarcodeCaptureSettings.codeDuplicateFilter;
    this.locationSelection = null;
    this.enabledCompositeTypes = [];
    this.properties = {};
    this.symbologies = {};
  }
  get enabledSymbologies() {
    return Object.keys(this.symbologies).filter(e => {
      var o;
      return (o = this.symbologies[e]) == null ? void 0 : o.isEnabled;
    });
  }
  get compositeTypeDescriptions() {
    return b.CompositeTypeDescriptions.reduce((e, o) => (e[o.types[0]] = o, e), {});
  }
  settingsForSymbology(e) {
    if (!this.symbologies[e]) {
      let o = b.SymbologySettings[e];
      o._symbology = e, this.symbologies[e] = o;
    }
    return this.symbologies[e];
  }
  setProperty(e, o) {
    this.properties[e] = o;
  }
  getProperty(e) {
    return this.properties[e];
  }
  enableSymbologies(e) {
    for (let o of e) this.enableSymbology(o, !0);
  }
  enableSymbology(e, o) {
    this.settingsForSymbology(e).isEnabled = o;
  }
  enableSymbologiesForCompositeTypes(e) {
    for (let o of e) this.enableSymbologies(this.compositeTypeDescriptions[o].symbologies);
  }
  toJSONObject() {
    var o, i, s;
    let e = {};
    return {
      codeDuplicateFilter: this.codeDuplicateFilter,
      enabledCompositeTypes: [...this.enabledCompositeTypes],
      locationSelection: (i = (o = this.locationSelection) == null ? void 0 : o.toJSONObject()) != null ? i : PrivateNoneLocationSelection,
      properties: {
        ...this.properties
      },
      symbologies: Object.keys(this.symbologies).reduce((n, l) => {
        var y;
        let r = (y = this.symbologies[l]) == null ? void 0 : y.toJSONObject();
        return r && (n[l] = r), n;
      }, e),
      scanIntention: (s = this.scanIntention) != null ? s : null
    };
  }
};
export { p as a };