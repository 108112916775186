import { Component, ChangeDetectionStrategy, TrackByFunction } from '@angular/core';
import { ToasterService } from './toaster.service';
import { ToastComponent } from './toast.component';
import { Toast } from './toast.model';
import { AsyncPipe, NgFor } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'shell-toaster-outlet',
  templateUrl: 'toaster-outlet.component.html',
  styleUrls: ['toaster-outlet.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'shell-toaster-outlet' },
  animations: [
    trigger('toastAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-100%)' }),
        animate('250ms ease-in-out', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [animate('250ms ease-in-out', style({ opacity: 0, transform: 'scaleY(0)' }))]),
    ]),
  ],
  imports: [ToastComponent, AsyncPipe, NgFor],
})
export class ToasterOutletComponent {
  toasts$ = this._toasterService.toasts$;

  trackById: TrackByFunction<Toast> = (_, toast) => toast.id;

  constructor(private _toasterService: ToasterService) {}
}
