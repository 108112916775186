import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormBlock } from '../form-block';
import { InterestsFormBlockData } from './interests-form-block-data';
import { LoyaltyCardService } from '@generated/swagger/crm-api';
import { shareReplay } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { memorize } from '@utils/common';

@Component({
  selector: 'app-interests-form-block',
  templateUrl: 'interests-form-block.component.html',
  styleUrls: ['interests-form-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class InterestsFormBlockComponent extends FormBlock<InterestsFormBlockData, UntypedFormGroup> {
  private _interests: Map<string, string>;

  get interests(): Map<string, string> {
    return this._interests;
  }
  set interests(value: Map<string, string>) {
    if (!isEqual(this._interests, value)) {
      this._interests = value;
      if (this.control) {
        this.updateInterestControls();
      }
    }
  }

  get tabIndexEnd() {
    return this.tabIndexStart + this.interests?.keys.length;
  }

  constructor(
    private _fb: UntypedFormBuilder,
    private _LoyaltyCardService: LoyaltyCardService,
  ) {
    super();

    this.getInterests().subscribe({
      next: (response) => {
        const interests = new Map<string, string>();
        response.result.forEach((preference) => {
          interests.set(preference.key, preference.value);
        });
        this.interests = interests;
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  @memorize({ ttl: 28800000 })
  getInterests() {
    return this._LoyaltyCardService.LoyaltyCardListInteressen().pipe(shareReplay(1));
  }

  updateInterestControls() {
    const fData = this.data ?? {};
    this.interests?.forEach((value, key) => {
      if (!this.control.contains(key)) {
        this.control.addControl(key, new UntypedFormControl(fData[key] ?? false));
      }
    });

    Object.keys(this.control.controls).forEach((key) => {
      if (!this.interests.has(key)) {
        this.control.removeControl(key);
      }
    });
  }

  initializeControl(data?: InterestsFormBlockData): void {
    const fData = data ?? {};
    this.control = this._fb.group({});

    this.interests?.forEach((value, key) => {
      this.control.addControl(key, new UntypedFormControl(fData[key] ?? false));
    });
  }

  _patchValue(update: { previous: InterestsFormBlockData; current: InterestsFormBlockData }): void {
    const fData = update.current ?? {};

    this.interests?.forEach((value, key) => {
      this.control.get(key).patchValue(fData[key] ?? false);
    });
  }
}
