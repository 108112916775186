import { a as a$1 } from './chunk-IWMYUNZ3.js';
import { d } from './chunk-XLX4QIMT.js';
import { a } from './chunk-SP3HSP4K.js';
import { a as a$2 } from './chunk-PSQ6LAQA.js';
import { g as g$1 } from './chunk-36CGJKPG.js';
import { d as d$1 } from './chunk-5Y4OYXNX.js';
var g = class n {
  constructor(e) {
    this.deviceId = "";
    this.type = "camera";
    this._currentState = "off";
    this._settings = new g$1();
    this._desiredTorchState = "off";
    this._desiredState = "off";
    this.listeners = [];
    this._context = null;
    this.stateTransitionStrategyMap = {
      off: {
        on: this.transitionFromStateOffToOn.bind(this),
        standby: this.transitionFromStateOffToStandby.bind(this)
      },
      on: {
        off: this.transitionFromStateOnToOff.bind(this),
        standby: this.transitionFromStateOnToStandby.bind(this)
      },
      standby: {
        off: this.transitionFromStateStandbyToOff.bind(this),
        on: this.transitionFromStateStandbyToOn.bind(this)
      }
    };
    this.cameraManager = e != null ? e : d.instance(), this.updateCanvasVideoImage = this.updateCanvasVideoImage.bind(this), this.captureAndSend = this.captureAndSend.bind(this);
  }
  static get default() {
    let e = new n();
    return e.position = "worldFacing", e;
  }
  get desiredState() {
    return this._desiredState;
  }
  get settings() {
    return this._settings;
  }
  get currentResolution() {
    var e;
    return (e = this._currentResolution) != null ? e : null;
  }
  get context() {
    return this._context;
  }
  set context(e) {
    this._context = e, this._context && this.currentState === "on" && this.startSendingCapturesToWorker();
  }
  get currentState() {
    return this._currentState;
  }
  static atPosition(e) {
    if (d$1.Camera.availablePositions.includes(e)) {
      let t = new n();
      return t.position = e, t;
    }
    return a.log(a.Level.Warn, `invalid CameraPosition: "${e}"`), null;
  }
  static fromDeviceCamera(e) {
    let t = n.atPosition(e.position);
    return t.deviceId = e.deviceId, t.label = e.label, t;
  }
  async switchToDesiredState(e) {
    var t, i;
    return this.currentTransitionStrategyPromise && (await this.currentTransitionStrategyPromise), this.currentTransitionStrategyPromise = (i = (t = this.stateTransitionStrategyMap[this.currentState]) == null ? void 0 : t[e]) == null ? void 0 : i.call(t).catch(r => {
      if (r instanceof DOMException && r.message === "The associated Track is in an invalid state") {
        a.log(a.Level.Warn, r);
        return;
      }
      throw r;
    }), this.currentTransitionStrategyPromise;
  }
  getDesiredTorchState() {
    return this._desiredTorchState;
  }
  async setDesiredTorchState(e) {
    this._desiredTorchState = e, await this.cameraManager.setTorchEnabled(this._desiredTorchState === "on"), await this.notifyContext({
      type: "torchState",
      newValue: this._desiredTorchState
    });
  }
  async isTorchAvailable() {
    return this.cameraManager.isTorchAvailable();
  }
  addListener(e) {
    e != null && (this.listeners.includes(e) || this.listeners.push(e));
  }
  removeListener(e) {
    e != null && this.listeners.includes(e) && this.listeners.splice(this.listeners.indexOf(e), 1);
  }
  async applySettings(e) {
    var i;
    this._settings = new g$1(e);
    let t = this.settings.getProperty("grayScaleFrameReader");
    if (this.cameraManager.setFrameReaderType(t === !0 ? "GRAYSCALE" : "RGBA"), this.currentState === "on" || this.currentState === "standby") {
      let r = this.cameraManager.activeCameraSettings;
      (r == null ? void 0 : r.preferredResolution) !== this.settings.preferredResolution && (await this.cameraManager.applyCameraSettings(this.settings), (i = this.cameraManager.activeCamera) != null && i.currentResolution && (this._currentResolution = {
        width: this.cameraManager.activeCamera.currentResolution.width,
        height: this.cameraManager.activeCamera.currentResolution.height
      })), await this.cameraManager.setZoom(this.settings.zoomFactor);
      let o = this.settings.getProperty("minFrameRate"),
        c = this.settings.getProperty("maxFrameRate");
      (o != null || c != null) && (await this.cameraManager.setFrameRate({
        min: o,
        max: c
      }));
      let m = this.settings.getProperty("manualLensPosition");
      m != null && (await this.cameraManager.setFocus(m));
      let u = this.settings.getProperty("exposureTargetBias"),
        d = this.settings.getProperty("exposureDuration");
      (u != null || d != null) && (await this.cameraManager.setExposure({
        compensation: u,
        time: d
      }));
    }
    return this.notifyContext({
      type: "cameraSettings",
      newValue: this.settings
    });
  }
  toJSONObject() {
    return {
      type: this.type,
      position: this.position,
      settings: this.settings.toJSONObject(),
      desiredState: this.desiredState,
      desiredTorchState: this._desiredTorchState
    };
  }
  getMirrorImageEnabled() {
    var e;
    return (e = this._desiredMirrorImageEnabled) != null ? e : this.cameraManager.isMirrorImageEnabled();
  }
  async setMirrorImageEnabled(e) {
    this._desiredMirrorImageEnabled = e, this.cameraManager.setMirrorImageEnabled(e, !0), this.context && (await this.context.setFrameSource(this));
  }
  getCurrentState() {
    return this._currentState;
  }
  async setCurrentState(e) {
    if (e !== this._currentState) {
      this._currentState = e, this.notifyListeners();
      try {
        await this.notifyContext({
          type: "frameSourceState",
          newValue: e
        }), e === "on" && this.startSendingCapturesToWorker();
      } catch (t) {
        a.log(a.Level.Warn, "Error while notifying context about new state of Camera");
      }
    }
  }
  async setupCamera() {
    if (this.cameraManager.setSelectedCameraSettings(this.settings), this.deviceId === "") this.cameraManager.setSelectedCamera(), this.cameraManager.setInitialCameraPosition(this.position);else {
      let t = (await a$1.getCameras()).find(i => i.deviceId === this.deviceId);
      t && (this.cameraManager.setInitialCameraPosition(t.position), this.cameraManager.setSelectedCamera(t));
    }
    await this.cameraManager.setupCameras(), typeof this._desiredMirrorImageEnabled == "boolean" ? this.cameraManager.setMirrorImageEnabled(this._desiredMirrorImageEnabled, !0) : this.cameraManager.setMirrorImageEnabled(this.cameraManager.isMirrorImageEnabled(), !1), this.context && (await this.context.setFrameSource(this)), this.cameraManager.activeCamera && (this.label = this.cameraManager.activeCamera.label, this.position = this.cameraManager.activeCamera.position, this.deviceId = this.cameraManager.activeCamera.deviceId, this._currentResolution = this.cameraManager.activeCamera.currentResolution);
  }
  async notifyContext(e) {
    if (this.context) return this.context.update([e]);
  }
  notifyListeners() {
    for (let e of this.listeners) e.didChangeState && e.didChangeState(this, this.currentState);
  }
  updateCanvasVideoImage() {
    this.currentState === "on" && this.context && (this.cameraManager.updateCanvasVideoImage(), this._lastCanvasVideoPreviewAnimationFrame = this.cameraManager.requestVideoFrame(this.updateCanvasVideoImage));
  }
  async captureAndSend() {
    if (this.currentState !== "on" || !this.context) return;
    if (!this.context.hasEnabledMode()) {
      this._lastCaptureRequestAnimationFrame = this.cameraManager.requestVideoFrame(this.captureAndSend);
      return;
    }
    let e = null;
    try {
      e = this.cameraManager.captureImage();
    } catch (t) {
      a.log(a.Level.Warn, t.message);
    } finally {
      if (e != null) if (this.cameraManager.synchronousFrameHandling) {
        let t = await this.context.sendFrameToProcessor(e);
        this.cameraManager.recycle(t.data);
      } else this.context.sendFrameToProcessor(e).then(t => (this.cameraManager.recycle(t.data), null)).catch(t => {
        a.log(a.Level.Warn, "error while recycling uint8array", t);
      });
      this._lastCaptureRequestAnimationFrame = this.cameraManager.requestVideoFrame(this.captureAndSend);
    }
  }
  startSendingCapturesToWorker() {
    this.stopSendingCapturesToWorker(), this.captureAndSend();
  }
  stopSendingCapturesToWorker() {
    this._lastCanvasVideoPreviewAnimationFrame != null && this.cameraManager.cancelVideoFrame(this._lastCanvasVideoPreviewAnimationFrame), this._lastCaptureRequestAnimationFrame != null && this.cameraManager.cancelVideoFrame(this._lastCaptureRequestAnimationFrame);
  }
  async transitionFromStateOffToOn() {
    if (!(this.currentState === "on" || this.currentState === "starting" || this.currentState === "wakingUp")) {
      this._desiredState = "on", await this.setCurrentState("starting");
      try {
        await this.setupCamera(), await this.setCurrentState("on");
      } catch (e) {
        throw a.log(a.Level.Error, e), await this.setCurrentState("off"), e;
      }
    }
  }
  async transitionFromStateOffToStandby() {
    if (!(this.currentState === "standby" || this.currentState === "bootingUp" || this.currentState === "goingToSleep")) {
      if (await this.isAndroidWebView()) {
        a.log(a.Level.Warn, 'Warning: "Standby" state does not work inside web views. Falling back to state "On".'), await this.transitionFromStateOffToOn();
        return;
      }
      this._desiredState = "standby", await this.setCurrentState("bootingUp");
      try {
        await this.setupCamera(), this.cameraManager.pauseStream(), await this.setCurrentState("standby");
      } catch (e) {
        throw a.log(a.Level.Error, e), await this.setCurrentState("off"), e;
      }
      await this.setCurrentState("standby"), await this.setDesiredTorchState("off");
    }
  }
  async transitionFromStateOnToOff() {
    this.currentState === "off" || this.currentState === "stopping" || (this._desiredState = "off", await this.setCurrentState("stopping"), await this.cameraManager.stopStream(), await this.setCurrentState("off"), await this.setDesiredTorchState("off"));
  }
  async transitionFromStateOnToStandby() {
    if (!(this.currentState === "standby" || this.currentState === "bootingUp" || this.currentState === "goingToSleep")) {
      if (await this.isAndroidWebView()) {
        a.log(a.Level.Warn, 'Warning: "Standby" state does not work inside web views. Falling back to state "Off".'), await this.transitionFromStateOnToOff();
        return;
      }
      this._desiredState = "standby", await this.setCurrentState("goingToSleep"), this.cameraManager.pauseStream(), await this.setCurrentState("standby"), await this.setDesiredTorchState("off");
    }
  }
  async transitionFromStateStandbyToOff() {
    this.currentState === "off" || this.currentState === "stopping" || (this._desiredState = "off", await this.setCurrentState("shuttingDown"), await this.cameraManager.stopStream(), await this.setCurrentState("off"), await this.setDesiredTorchState("off"));
  }
  async transitionFromStateStandbyToOn() {
    this.currentState === "on" || this.currentState === "starting" || this.currentState === "wakingUp" || (this._desiredState = "on", await this.setCurrentState("wakingUp"), this.cameraManager.resumeStream(), await this.setCurrentState("on"));
  }
  async isAndroidWebView() {
    return this._isAndroidWebView == null && (this._isAndroidWebView = await a$2.isAndroidWebView()), this._isAndroidWebView;
  }
};
export { g as a };