var r = class {
  constructor(e) {
    this.items = [];
    if (e.lowWaterMark >= e.capacity) throw new Error("Invalid Watermark options: lowWaterMark must be less than capacity");
    this.capacity = e.capacity, this.lowWaterMark = e.lowWaterMark, this.createItem = e.createItem;
    for (let t = 0; t < this.capacity; t++) this.items.push(this.createItem());
  }
  get length() {
    return this.items.length;
  }
  pop() {
    for (; this.isBelowOrEqualWaterMark();) this.items.unshift(this.createItem());
    return this.items.pop();
  }
  push(e) {
    if (this.items.length < this.capacity) return this.items.push(e);
  }
  empty() {
    this.items.length = 0;
  }
  isBelowOrEqualWaterMark() {
    return this.items.length <= this.lowWaterMark;
  }
};
export { r as a };