import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MessageBoardItemDTO } from '@hub/notifications';

@Component({
  selector: 'modal-notifications-update-group',
  templateUrl: 'notifications-update-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ModalNotificationsUpdateGroupComponent {
  @Input()
  notifications: MessageBoardItemDTO[];

  constructor() {}

  reload() {
    location.reload();
  }
}
