import { NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  QueryList,
  ViewChildren,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { EnvironmentService } from '@core/environment';

@Component({
  selector: 'shared-splitscreen',
  templateUrl: 'splitscreen.component.html',
  styleUrls: ['splitscreen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'shared-splitscreen' },
  imports: [RouterOutlet, NgIf],
})
export class SharedSplitscreenComponent implements AfterViewInit {
  destroyRef = inject(DestroyRef);

  environment = inject(EnvironmentService);

  @ViewChildren('sideOutlet', { read: RouterOutlet })
  side: QueryList<RouterOutlet>;

  desktopLarge = signal(false);

  sideActivated = signal(false);

  ngAfterViewInit() {
    this.environment.matchDesktopLarge$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((desktopLarge) => {
      this.desktopLarge.set(desktopLarge);
      if (!desktopLarge) {
        this.sideActivated.set(false);
      }
    });
  }

  onActivate() {
    this.sideActivated.set(true);
  }

  onDeactivate() {
    this.sideActivated.set(false);
  }
}
