import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, HostBinding } from '@angular/core';
import { PackageDTO2 } from '@generated/swagger/wws-api';

@Component({
  selector: 'page-package-list-item',
  templateUrl: 'package-list-item.component.html',
  styleUrls: ['package-list-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PackageListItemComponent implements OnChanges {
  @Input()
  package: PackageDTO2;

  annotation: string | undefined;

  @HostBinding('class.has-annotation')
  hasAnnotation = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.package) {
      this.annotation = this.package?.features?.['annotation'] ?? undefined;
      this.hasAnnotation = !!this.annotation;
    }
  }
}
