import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostListener,
  EventEmitter,
  Output,
  ElementRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'ui-select-option',
  templateUrl: 'ui-select-option.component.html',
  styleUrls: ['ui-select-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UiSelectOptionComponent {
  @Input()
  label: string;

  @Input()
  value: any;

  onSelect = (value: any) => {};

  onFocusOut = () => {};

  @ViewChild('selectButton', { read: ElementRef })
  selectButton: ElementRef;

  get focused() {
    const host: HTMLElement = this.elementRef.nativeElement;
    return !!host.querySelector(':focus');
  }

  constructor(private elementRef: ElementRef) {}

  registerOnSelect(fn: any) {
    this.onSelect = fn;
  }

  registerOnFocusOut(fn: any) {
    this.onFocusOut = fn;
  }

  select() {
    if (typeof this.onSelect === 'function') {
      this.onSelect.call(null, this.value);
    }
  }

  focus() {
    setTimeout(() => {
      this.selectButton.nativeElement.focus();
    }, 1);
  }

  @HostListener('focusout')
  focusOut() {
    // if (typeof this.onFocusOut === 'function') {
    //   setTimeout(() => this.onFocusOut.call(null), 1);
    // }
  }
}
