import { Directive } from '@angular/core';

@Directive({
  selector: 'sharedinput-control-prefix',
  host: { class: 'shared-input-control-prefix' },
  standalone: false,
})
export class PrefixDirective {
  constructor() {}
}
