import { Pipe, PipeTransform } from '@angular/core';
import { FilterResetStrategy } from '../components/defs';

@Pipe({
  name: 'filterResetWording',
  standalone: false,
})
export class FilterResetWordingPipe implements PipeTransform {
  RESET_TO_DEFAULT_WORDING = 'Filter zurücksetzen';
  RESET_All_WORDING = 'Alle Filter Entfernen';

  transform(strategy: FilterResetStrategy): string {
    if (strategy === 'default') {
      return this.RESET_TO_DEFAULT_WORDING;
    }

    return this.RESET_All_WORDING;
  }
}
