var q = Object.create;
var o = Object.defineProperty;
var r = Object.getOwnPropertyDescriptor;
var s = Object.getOwnPropertyNames;
var t = Object.getPrototypeOf,
  u = Object.prototype.hasOwnProperty;
var l = (a, b) => (b = Symbol[a]) ? b : Symbol.for("Symbol." + a);
var x = (a, b) => () => (b || a((b = {
  exports: {}
}).exports, b), b.exports);
var v = (a, b, c, e) => {
  if (b && typeof b == "object" || typeof b == "function") for (let d of s(b)) !u.call(a, d) && d !== c && o(a, d, {
    get: () => b[d],
    enumerable: !(e = r(b, d)) || e.enumerable
  });
  return a;
};
var y = (a, b, c) => (c = a != null ? q(t(a)) : {}, v(b || !a || !a.__esModule ? o(c, "default", {
  value: a,
  enumerable: !0
}) : c, a));
var w = function (a, b) {
    this[0] = a, this[1] = b;
  },
  z = (a, b, c) => {
    var e = (h, g, i, k) => {
        try {
          var m = c[h](g),
            n = (g = m.value) instanceof w,
            p = m.done;
          Promise.resolve(n ? g[0] : g).then(j => n ? e(h === "return" ? h : "next", g[1] ? {
            done: j.done,
            value: j.value
          } : j, i, k) : i({
            value: j,
            done: p
          })).catch(j => e("throw", j, i, k));
        } catch (j) {
          k(j);
        }
      },
      d = h => f[h] = g => new Promise((i, k) => e(h, g, i, k)),
      f = {};
    return c = c.apply(a, b), f[l("asyncIterator")] = () => f, d("next"), d("throw"), d("return"), f;
  };
var A = (a, b, c) => (b = a[l("asyncIterator")]) ? b.call(a) : (a = a[l("iterator")](), b = {}, c = (e, d) => (d = a[e]) && (b[e] = f => new Promise((h, g, i) => (f = d.call(a, f), i = f.done, Promise.resolve(f.value).then(k => h({
  value: k,
  done: i
}), g)))), c("next"), c("return"), b);
export { x as a, y as b, w as c, z as d, A as e };