import { a } from './chunk-JYVE7GVH.js';
import { a as a$1 } from './chunk-D3GTNMBR.js';
import { b } from './chunk-TRWFJYCT.js';
import { CameraSettings, Logger, convertToPublicFrameData } from 'scandit-web-datacapture-core';
var p = class u {
  constructor() {
    this.type = "barcodeCapture";
    this._isEnabled = !0;
    this._feedback = a.default;
    this._context = null;
    this.listeners = new Set();
    this.allowedRemainingWorkerMessages = new Set();
    this.workerMessageListener = this.onWorkerMessage.bind(this);
  }
  static get recommendedCameraSettings() {
    return new CameraSettings(b.BarcodeCapture.RecommendedCameraSettings);
  }
  get context() {
    return this._context;
  }
  get feedback() {
    return this._feedback;
  }
  set feedback(e) {
    this._feedback = e, Logger.log(Logger.Level.Info, "Please use BarcodeCapture.setFeedback instead of BarcodeCapture.feedback setter"), this.notifyContext();
  }
  static async forContext(e, a) {
    let t = new u();
    return t.settings = a, e && (await e.addMode(t)), t;
  }
  async setFeedback(e) {
    return this._feedback = e, this.notifyContext();
  }
  isEnabled() {
    return this._isEnabled;
  }
  async setEnabled(e) {
    this._isEnabled = e, await this.notifyContext();
  }
  async applySettings(e) {
    return this.settings = e, this.notifyContext();
  }
  addListener(e) {
    this.listeners.has(e) || this.listeners.add(e);
  }
  removeListener(e) {
    this.listeners.delete(e);
  }
  toJSONObject() {
    return {
      type: this.type,
      enabled: this._isEnabled,
      feedback: this.feedback.toJSONObject(),
      settings: this.settings.toJSONObject()
    };
  }
  attachedToContext(e) {
    this._context = e, this._context.subscribeToWorkerMessages(this.workerMessageListener);
  }
  async detachedFromContext() {
    var e;
    (e = this._context) == null || e.unsubscribeToWorkerMessages(this.workerMessageListener), this._context = null;
  }
  sendClearFrameDataRequest(e) {
    setTimeout(() => {
      var a;
      (a = this.context) == null || a.workerCommand("deleteFrameData", {
        frameId: e
      });
    }, 0);
  }
  async onDidScan(e) {
    if (this.context == null) return;
    let a = a$1.fromJSON(e.payload.session),
      {
        frameData: t
      } = e.payload;
    if (!this._isEnabled) {
      this.sendClearFrameDataRequest(t.frameId), this.allowedRemainingWorkerMessages.clear();
      return;
    }
    this.allowedRemainingWorkerMessages.add("successFeedback");
    let n = [];
    for (let r of this.listeners) if (r.didScan) try {
      let s = r.didScan(this, a, convertToPublicFrameData(t, this.context));
      s instanceof Promise && (await s);
    } catch (s) {
      n.push(s);
    }
    this.sendClearFrameDataRequest(t.frameId), this.allowedRemainingWorkerMessages.add("barcodeCaptureDidUpdateSession");
    for (let r of n) Logger.log(Logger.Level.Error, r);
  }
  onDidUpdateSession(e) {
    let a = a$1.fromJSON(e.payload);
    if (!(!this._isEnabled && !this.allowedRemainingWorkerMessages.has(e.type))) {
      this.allowedRemainingWorkerMessages.delete(e.type);
      for (let t of this.listeners) t.didUpdateSession && t.didUpdateSession(this, a);
    }
  }
  onSuccessFeedback(e) {
    !this._isEnabled && !this.allowedRemainingWorkerMessages.has(e.type) || (this.allowedRemainingWorkerMessages.delete(e.type), this.feedback.success.emit());
  }
  async onWorkerMessage(e) {
    switch (e.type) {
      case "barcodeCaptureDidScan":
        {
          this.onDidScan(e);
          break;
        }
      case "barcodeCaptureDidUpdateSession":
        {
          this.onDidUpdateSession(e);
          break;
        }
      case "successFeedback":
        {
          this.onSuccessFeedback(e);
          break;
        }
    }
  }
  async notifyContext() {
    this.context != null && (await this.context.update());
  }
};
export { p as a };