import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CustomerCreateSideViewModule } from './customer-create-side-view';
import { map } from 'rxjs/operators';
import { SharedSplitscreenComponent } from '@shared/components/splitscreen';

@Component({
  selector: 'page-create-customer',
  templateUrl: 'create-customer.component.html',
  styleUrls: ['create-customer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterModule, SharedSplitscreenComponent, CustomerCreateSideViewModule],
})
export class CreateCustomerComponent {
  processId$ = this._activatedRoute.parent.data.pipe(map((data) => data.processId));

  constructor(private _activatedRoute: ActivatedRoute) {}
}
