import { Pipe, PipeTransform } from '@angular/core';
import { ArrivalStatus } from '@generated/swagger/wws-api';

@Pipe({
  name: 'arrivalStatus',
  pure: true,
  standalone: false,
})
export class ArrivalStatusPipe implements PipeTransform {
  static readonly ARRIVAL_STATUS = {
    0: 'Offen',
    1: 'Eingetroffen',
    2: 'Eingetroffen',
    4: 'Fehlt',
    8: 'Falsche Filiale',
    16: 'Fehlt',
  };

  transform(value: ArrivalStatus): string {
    return ArrivalStatusPipe.ARRIVAL_STATUS[value] ?? String(value);
  }
}
