var t = class t {
  constructor() {
    this.hasApi = !1;
    this.hasTouch = !1;
    this.requiresPrefix = !1;
  }
  detect() {
    var e;
    try {
      "PointerEvent" in window ? this.hasApi = !0 : "msPointerEnabled" in navigator && (this.hasApi = !0, this.requiresPrefix = !0);
      let o = (e = navigator.msMaxTouchPoints) != null ? e : navigator.maxTouchPoints;
      this.hasTouch = o > 0;
    } catch (o) {}
  }
  prefixIfNeeded(e) {
    return this.requiresPrefix ? t.prefixEventMap[e] : e;
  }
};
t.prefixEventMap = {
  pointerdown: "MSPointerDown",
  pointerup: "MSPointerUp",
  pointercancel: "MSPointerCancel",
  pointermove: "MSPointerMove",
  pointerover: "MSPointerOver",
  pointerout: "MSPointerOut",
  pointerenter: "MSPointerEnter",
  pointerleave: "MSPointerLeave",
  gotpointercapture: "MSGotPointerCapture",
  lostpointercapture: "MSLostPointerCapture"
};
var n = t,
  i = new n();
export { i as a };