import { l } from './chunk-N4NOODAT.js';
var s = {
    type: "none"
  },
  o = class {
    constructor(t) {
      this.type = "radius";
      this._radius = t;
    }
    get radius() {
      return this._radius;
    }
    toJSONObject() {
      return {
        type: this.type,
        radius: this.radius.toJSONObject()
      };
    }
  },
  a = class n {
    constructor() {
      this.type = "rectangular";
    }
    get sizeWithUnitAndAspect() {
      return this._sizeWithUnitAndAspect;
    }
    static withSize(t) {
      let i = new n();
      return i._sizeWithUnitAndAspect = l.sizeWithWidthAndHeight(t), i;
    }
    static withWidthAndAspectRatio(t, i) {
      let e = new n();
      return e._sizeWithUnitAndAspect = l.sizeWithWidthAndAspectRatio(t, i), e;
    }
    static withHeightAndAspectRatio(t, i) {
      let e = new n();
      return e._sizeWithUnitAndAspect = l.sizeWithHeightAndAspectRatio(t, i), e;
    }
    toJSONObject() {
      return {
        type: this.type,
        size: this.sizeWithUnitAndAspect.toJSONObject()
      };
    }
  };
export { s as a, o as b, a as c };