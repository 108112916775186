import { Component, ChangeDetectionStrategy, Input, Optional, Inject, HostBinding } from '@angular/core';
import { UI_ICON_HREF, UI_ICON_VIEW_BOX } from './tokens';

@Component({
  selector: 'ui-icon',
  templateUrl: 'icon.component.html',
  styleUrls: ['icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UiIconComponent {
  @Input()
  @HostBinding('attr.icon')
  icon: string;

  @Input()
  size = '1em';

  @Input()
  rotate = '0deg';

  constructor(
    @Optional() @Inject(UI_ICON_HREF) public iconHref: string,
    @Optional() @Inject(UI_ICON_VIEW_BOX) public viewBox: string,
  ) {
    this.iconHref = this.iconHref || '/assets/icons.svg';
    this.viewBox = this.viewBox || '0 0 32 32';
  }
}
