import { UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export function requiredIfControlIsSet(
  requiredControlName: string,
  ifSetControlName: string,
): ValidatorFn {
  return (control: UntypedFormGroup): ValidationErrors | null => {
    const requiredControl = control.get(requiredControlName);
    const ifSetControl = control.get(ifSetControlName);

    if (ifSetControl && ifSetControl.value) {
      return Validators.required(requiredControl);
    }

    return null;
  };
}
