import { Component, ContentChildren, QueryList } from '@angular/core';
import { MenuItemDirective } from './menu-item.directive';

@Component({
  selector: 'menu, shared-menu',
  template: `
    <ng-content [selector]="[menuItem]"></ng-content>
  `,
  host: { class: 'menu', role: 'menu' },
  exportAs: 'menu',
  standalone: false,
})
export class MenuComponent {
  private _menuItems: QueryList<MenuItemDirective>;

  @ContentChildren(MenuItemDirective, { descendants: true })
  set menuItems(items: QueryList<MenuItemDirective>) {
    this._menuItems = items;
  }
  get menuItems() {
    return this._menuItems;
  }

  constructor() {}

  registerOnClick(fn: (item: MenuItemDirective) => void) {
    this.menuItems.forEach((item) => item.registerOnClick(fn));
  }
}
