import { DebounceSettings, debounce as lodashDebounce } from 'lodash';

export function debounce(wait: number, options?: DebounceSettings): MethodDecorator {
  return (
    target: object,
    propertyKey: string,
    descriptor: TypedPropertyDescriptor<any>,
  ): TypedPropertyDescriptor<any> => {
    const currentMethod = descriptor.value;
    descriptor.value = lodashDebounce(currentMethod, wait, options);
    return descriptor;
  };
}
