import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, inject } from '@angular/core';
import { CustomerSearchStore } from '../store';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Subject, combineLatest, of } from 'rxjs';
import { catchError, map, share, switchMap } from 'rxjs/operators';
import { CrmCustomerService } from '@domain/crm';
import { KundenkarteComponent } from '../../components/kundenkarte';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { IconComponent } from '@shared/components/icon';
import { CustomerSearchNavigation } from '@shared/services/navigation';
import { BonusCardInfoDTO } from '@generated/swagger/crm-api';
import { CustomerMenuComponent } from '../../components/customer-menu';

@Component({
  selector: 'page-customer-kundenkarte-main-view',
  templateUrl: 'kundenkarte-main-view.component.html',
  styleUrls: ['kundenkarte-main-view.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'page-customer-kundenkarte-main-view' },
  imports: [CustomerMenuComponent, KundenkarteComponent, NgFor, AsyncPipe, NgIf, IconComponent, RouterLink],
})
export class KundenkarteMainViewComponent implements OnInit, OnDestroy {
  private _store = inject(CustomerSearchStore);
  private _activatedRoute = inject(ActivatedRoute);
  private _customerService = inject(CrmCustomerService);
  private _navigation = inject(CustomerSearchNavigation);

  private _onDestroy$ = new Subject<void>();

  customerId$ = this._activatedRoute.params.pipe(map((params) => params.customerId));

  processId$ = this._store.processId$;

  kundenkarte$ = this.customerId$.pipe(
    switchMap((customerId) =>
      this._customerService.getCustomerCard(customerId).pipe(
        map((response) => response.result?.filter((f) => f.isActive)),
        catchError(() => of<BonusCardInfoDTO[]>([])),
      ),
    ),
    share(),
  );

  noDataFound$ = this.kundenkarte$.pipe(map((kundenkarte) => kundenkarte?.length == 0));

  primaryKundenkarte$ = this.kundenkarte$.pipe(map((kundenkarte) => kundenkarte?.filter((k) => k.isPrimary)));

  partnerKundenkarte$ = this.kundenkarte$.pipe(map((kundenkarte) => kundenkarte?.filter((k) => !k.isPrimary)));

  detailsRoute$ = combineLatest([this._store.processId$, this._store.customerId$]).pipe(
    map(([processId, customerId]) => this._navigation.detailsRoute({ processId, customerId })),
  );

  ngOnInit() {
    this.customerId$.subscribe((customerId) => {
      this._store.selectCustomer(customerId);
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
