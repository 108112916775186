function p(t, e = location) {
  return t += t.endsWith("/") ? "" : "/", /^https?:\/\//.test(t) ? t : (t = t.split("/").filter(n => n.length > 0).join("/"), t = t === "" ? "/" : `/${t}/`, e.protocol === "file:" || e.origin === "null" ? `${e.href.split("/").slice(0, -1).join("/")}${t}` : `${e.origin}${t}`);
}
function c(t) {
  return /^https?:\/\/(?:[^./]*\.)*cdn.jsdelivr.net\//.test(t) ? {
    result: !0,
    cdnBaseURL: "https://cdn.jsdelivr.net/npm/"
  } : /^https?:\/\/(?:[^./]*\.)*unpkg.com\//.test(t) ? {
    result: !0,
    cdnBaseURL: "https://unpkg.com/"
  } : {
    result: !1,
    cdnBaseURL: ""
  };
}
function l(t) {
  let n = /scandit-web-datacapture-[a-z]+/i.exec(t);
  return n ? n[0] : null;
}
function g(t, e, n) {
  let s = e,
    i = c(s);
  if (i.result) {
    let u = l(s);
    u != null && (s = `${i.cdnBaseURL}${u}@${t}/build/engine/`);
  }
  let r = n.replace(".wasm", "");
  return i.result ? {
    jsURI: `${s}${r}.js`,
    wasmURI: `${s}${r}.wasm`
  } : {
    jsURI: `${s}${r}.js?v=${t}`,
    wasmURI: `${s}${r}.wasm?v=${t}`
  };
}
export { p as a, c as b, l as c, g as d };