import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  TemplateRef,
  Type,
  ChangeDetectorRef,
  OnDestroy,
  HostBinding,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { UiModalRef } from './defs/modal-ref';

@Component({
  selector: 'ui-modal',
  templateUrl: 'modal.component.html',
  styleUrls: ['modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UiModalComponent implements OnInit, OnDestroy {
  contentType: 'template' | 'string' | 'component';
  content: string | TemplateRef<any> | Type<any>;
  context;

  get title() {
    return navigator.onLine ? this.ref?.title : 'Sie sind offline, keine Verbindung zum Netzwerk';
  }

  get componentType() {
    return this.content as Type<any>;
  }

  get templateRef() {
    return this.content as TemplateRef<any>;
  }

  private subscription: Subscription;

  constructor(
    public ref: UiModalRef,
    private cdr: ChangeDetectorRef,
  ) {}

  close(): void {
    this.ref.close(null);
  }

  ngOnInit(): void {
    this.content = this.ref.content;

    if (typeof this.content === 'string') {
      this.contentType = 'string';
    } else if (this.content instanceof TemplateRef) {
      this.contentType = 'template';
      this.context = {
        close: this.ref.close.bind(this.ref),
      };
    } else {
      this.contentType = 'component';
    }

    this.subscription = this.ref.afterChanged$.subscribe(() => {
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostBinding('style') get class() {
    return !this.ref?.config?.padding ? 'padding: 0px' : '';
  }
}
