import { b } from './chunk-AXTXPLXP.js';
import { d as d$1 } from './chunk-JUG6FO4O.js';
import { b as b$1, c } from './chunk-GX4YCYCH.js';
import { m, e, d, n, l as l$1 } from './chunk-N4NOODAT.js';
var s = class {
    static fromJSON(t) {
      return (t == null ? void 0 : t.type) === "tapToFocus" ? new b$1() : null;
    }
  },
  u = class {
    static fromJSON(t) {
      return (t == null ? void 0 : t.type) === "swipeToZoom" ? new c() : null;
    }
  };
function l(e) {
  let t = "document" in globalThis ? globalThis.document : void 0,
    i = t == null ? void 0 : t.createRange().createContextualFragment(e);
  if (i) return i.firstElementChild;
}
function p(e$1) {
  var t;
  return {
    Camera: {
      Settings: {
        preferredResolution: e$1.Camera.Settings.preferredResolution,
        zoomFactor: e$1.Camera.Settings.zoomFactor,
        zoomGestureZoomFactor: e$1.Camera.Settings.zoomGestureZoomFactor,
        focusGestureStrategy: e$1.Camera.Settings.focusGestureStrategy
      },
      defaultPosition: (t = e$1.Camera.defaultPosition) != null ? t : null,
      availablePositions: e$1.Camera.availablePositions
    },
    SingleImageUploader: {
      Settings: {
        iconElement: l(e$1.SingleImageUploader.Settings.iconElement),
        informationElement: l(e$1.SingleImageUploader.Settings.informationElement),
        buttonElement: l(e$1.SingleImageUploader.Settings.buttonElement),
        containerStyle: e$1.SingleImageUploader.Settings.containerStyle,
        iconStyle: e$1.SingleImageUploader.Settings.iconStyle,
        informationStyle: e$1.SingleImageUploader.Settings.informationStyle,
        buttonStyle: e$1.SingleImageUploader.Settings.buttonStyle,
        onlyCameraCapture: e$1.SingleImageUploader.Settings.onlyCameraCapture
      }
    },
    DataCaptureView: {
      scanAreaMargins: m.fromJSON(JSON.parse(e$1.DataCaptureView.scanAreaMargins)),
      pointOfInterest: e.fromJSON(JSON.parse(e$1.DataCaptureView.pointOfInterest)),
      logoStyle: e$1.DataCaptureView.logoStyle,
      logoAnchor: e$1.DataCaptureView.logoAnchor,
      logoOffset: e.fromJSON(JSON.parse(e$1.DataCaptureView.logoOffset)),
      focusGesture: s.fromJSON(JSON.parse(e$1.DataCaptureView.focusGesture)),
      zoomGesture: u.fromJSON(JSON.parse(e$1.DataCaptureView.zoomGesture)),
      cameraRecoveryText: e$1.DataCaptureView.cameraRecoveryText
    },
    LaserlineViewfinder: Object.keys(e$1.LaserlineViewfinder.styles).reduce((i, o) => {
      let r = e$1.LaserlineViewfinder.styles[o];
      return i.styles[o] = {
        width: d.fromJSON(JSON.parse(r.width)),
        enabledColor: n.fromJSON(r.enabledColor),
        disabledColor: n.fromJSON(r.disabledColor),
        style: r.style
      }, i;
    }, {
      defaultStyle: e$1.LaserlineViewfinder.defaultStyle,
      styles: {}
    }),
    RectangularViewfinder: Object.keys(e$1.RectangularViewfinder.styles).reduce((i, o) => {
      let r = e$1.RectangularViewfinder.styles[o];
      return i.styles[o] = {
        size: l$1.fromJSON(JSON.parse(r.size)),
        color: n.fromJSON(r.color),
        style: r.style,
        lineStyle: r.lineStyle,
        dimming: Number.parseFloat(r.dimming.toString()),
        animation: d$1.fromJSON(JSON.parse(r.animation))
      }, i;
    }, {
      defaultStyle: e$1.RectangularViewfinder.defaultStyle,
      styles: {}
    }),
    AimerViewfinder: {
      frameColor: n.fromJSON(e$1.AimerViewfinder.frameColor),
      dotColor: n.fromJSON(e$1.AimerViewfinder.dotColor)
    },
    Brush: {
      fillColor: n.fromJSON(e$1.Brush.fillColor),
      strokeColor: n.fromJSON(e$1.Brush.strokeColor),
      strokeWidth: e$1.Brush.strokeWidth
    },
    Feedback: {
      defaultVibrationPattern: e$1.Feedback.defaultVibrationPattern
    }
  };
}
var C = p({
  DataCaptureView: {
    focusGesture: "null",
    zoomGesture: "null",
    logoAnchor: "bottomRight",
    logoStyle: "extended",
    logoOffset: JSON.stringify({
      x: {
        value: 0,
        unit: "fraction"
      },
      y: {
        value: 0,
        unit: "fraction"
      }
    }),
    pointOfInterest: JSON.stringify({
      x: {
        value: .5,
        unit: "fraction"
      },
      y: {
        value: .5,
        unit: "fraction"
      }
    }),
    scanAreaMargins: JSON.stringify({
      left: {
        value: 0,
        unit: "fraction"
      },
      right: {
        value: 0,
        unit: "fraction"
      },
      top: {
        value: 0,
        unit: "fraction"
      },
      bottom: {
        value: 0,
        unit: "fraction"
      }
    }),
    cameraRecoveryText: "(This text is updated from translations)"
  },
  Camera: {
    Settings: {
      preferredResolution: "auto",
      zoomFactor: 1,
      focusGestureStrategy: "manualUntilCapture",
      zoomGestureZoomFactor: 2
    },
    defaultPosition: "worldFacing",
    availablePositions: ["worldFacing", "userFacing"]
  },
  SingleImageUploader: {
    Settings: {
      iconElement: atob(b),
      informationElement: "<p data-scandit-default>(This text is updated from translations)</p>",
      buttonElement: "<div data-scandit-default>(This text is updated from translations)</div>",
      containerStyle: {
        backgroundColor: "#FFFFFF"
      },
      iconStyle: {
        fill: "#121619"
      },
      informationStyle: {
        color: "#121619",
        marginBottom: "2em"
      },
      buttonStyle: {
        color: "#FFFFFF",
        backgroundColor: "#121619",
        fontWeight: "bold",
        padding: "1.25em",
        width: "12em",
        textAlign: "center",
        textTransform: "uppercase"
      },
      onlyCameraCapture: !1
    }
  },
  LaserlineViewfinder: {
    defaultStyle: "animated",
    styles: {
      animated: {
        width: JSON.stringify({
          unit: "fraction",
          value: .8
        }),
        enabledColor: "#FFFFFFFF",
        disabledColor: "#00000000",
        style: "animated"
      }
    }
  },
  AimerViewfinder: {
    frameColor: "#FFFFFFFF",
    dotColor: "#FFFFFFCC"
  },
  RectangularViewfinder: {
    defaultStyle: "rounded",
    styles: {
      rounded: {
        size: JSON.stringify({
          aspect: 1,
          shorterDimension: {
            unit: "fraction",
            value: .75
          }
        }),
        color: "#FFFFFFFF",
        style: "rounded",
        lineStyle: "light",
        dimming: 0,
        animation: JSON.stringify({
          looping: !0
        })
      },
      square: {
        size: JSON.stringify({
          aspect: 1,
          shorterDimension: {
            unit: "fraction",
            value: .75
          }
        }),
        color: "#FFFFFFFF",
        style: "square",
        lineStyle: "light",
        dimming: 0,
        animation: JSON.stringify({
          looping: !0
        })
      }
    }
  },
  Brush: {
    fillColor: "#00000000",
    strokeColor: "#00000000",
    strokeWidth: 0
  },
  Feedback: {
    defaultVibrationPattern: [300]
  }
});
function O(e) {
  C = e;
}
export { s as a, u as b, p as c, C as d, O as e };