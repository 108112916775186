import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[uiSearchboxAutocompleteOption]',
  standalone: false,
})
export class UiSearchboxAutocompleteOptionDirective {
  @Input()
  value: string;

  get nativeElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  @HostBinding('class.selected')
  selected = false;

  onSelect = (value: string) => {};

  constructor(private elementRef: ElementRef) {}

  registerOnSelect(fn: any) {
    this.onSelect = fn;
  }

  @HostListener('click')
  select() {
    this.onSelect(this.value);
  }
}
