import { Pipe, PipeTransform } from '@angular/core';
import { OrderListItemDTO } from '@generated/swagger/oms-api';

@Pipe({
  name: 'orderTarget',
  standalone: false,
})
export class OrderTargetPipe implements PipeTransform {
  transform(orderListItem: OrderListItemDTO): any {
    if (orderListItem.orderType === 2) {
      return 'Versand';
    }

    if (orderListItem.targetBranchName) {
      return orderListItem.targetBranchName;
    }

    return '';
  }
}
