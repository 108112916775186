import { a as a$1 } from './chunk-JX5VRGFB.js';
import { a } from './chunk-LKZBVQXD.js';
import { Localization } from 'scandit-web-datacapture-core';
function l(r) {
  return Localization.getInstance().updateIfMissing({
    ...a
  }), {
    moduleName: "BarcodeCapture",
    load: async t => {
      var e;
      let n = t;
      return await a$1.create({
        ...n,
        highEndBlurryRecognition: (e = r == null ? void 0 : r.highEndBlurryRecognition) != null ? e : !1
      });
    }
  };
}
export { l as a };