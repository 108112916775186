import { Component, OnInit } from '@angular/core';
import { UiModalRef } from '../defs';

@Component({
  selector: 'ui-debug-modal',
  styleUrls: ['./debug-modal.component.scss'],
  template: `
    <pre> {{ modalRef.data | json }} </pre>
  `,
  standalone: false,
})
export class UiDebugModalComponent implements OnInit {
  constructor(public modalRef: UiModalRef) {}

  ngOnInit() {}
}
