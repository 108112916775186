import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ElementRef, Injectable } from '@angular/core';

@Injectable()
export class ScrollPositionService {
  private scrollContainerRef: CdkVirtualScrollViewport;
  private scrollPos = undefined;
  private prefetchedData: any;
  private prefetchedSkip: number;

  get scrollPosition(): number {
    return this.scrollPos;
  }

  set scrollPosition(scrollPosition: number) {
    this.scrollPos = scrollPosition;
  }

  get data(): any {
    return this.prefetchedData;
  }

  set data(data: any) {
    this.prefetchedData = data;
  }

  get skip(): number {
    return this.prefetchedSkip;
  }

  set skip(value: number) {
    this.prefetchedSkip = value;
  }

  constructor() {}

  setScrollContainerRef(scrollContainer: CdkVirtualScrollViewport) {
    this.scrollContainerRef = scrollContainer;
  }

  getScrollPosition(): number {
    if (this.scrollContainerRef instanceof CdkVirtualScrollViewport) {
      this.scrollPosition = this.scrollContainerRef.measureScrollOffset('top');
      return this.scrollPosition;
    }
  }

  resetService() {
    this.scrollPosition = 0;
    if (this.scrollContainerRef instanceof CdkVirtualScrollViewport) {
      setTimeout(() => this.scrollContainerRef.scrollTo({ top: 0 }), 500);
    }
  }

  scrollToLastRememberedPositionVirtualScroll() {
    // Skip initial call
    if (this.scrollPosition !== 0) {
      const top = this.scrollPosition;
      setTimeout(() => this.scrollContainerRef.scrollTo({ top }), 500);
    }
  }

  scrollToLastRememberedPosition(elementRef: ElementRef<any>) {
    // Skip initial call
    if (this.scrollPosition) {
      elementRef.nativeElement.scrollTo(0, this.scrollPosition);
    }
  }
}
