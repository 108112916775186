import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: 'input[uiCapture][type="file"]',
  standalone: false,
})
export class UiCaptureDirective {
  @Output()
  fileChanged = new EventEmitter<string | ArrayBuffer>();

  constructor() {}

  @HostListener('change', ['$event.target'])
  onChange(input: HTMLInputElement) {
    const files = input?.files;

    if (files.length > 1) {
      throw Error('No Image Uploaded');
    }

    const file = files[0];
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      this.fileChanged.emit(event.target.result);
      input.value = '';
    };
    reader.readAsDataURL(file);
  }
}
