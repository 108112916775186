import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CustomerDataEditComponent } from './customer-data-edit.component';
import { CommonModule } from '@angular/common';
import { UiCommonModule } from '@ui/common';
import { UiFormControlModule } from '@ui/form-control';
import { UiSelectModule } from '@ui/select';
import { ReactiveFormsModule } from '@angular/forms';
import { UiIconModule } from '@ui/icon';
import { RouterLink } from '@angular/router';
import { UiDateInputDirective } from '@ui/input';

@Component({
  selector: 'page-customer-data-edit-b2c',
  templateUrl: 'customer-data-edit-b2c.component.html',
  styleUrls: ['customer-data-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    UiCommonModule,
    UiFormControlModule,
    UiSelectModule,
    UiIconModule,
    ReactiveFormsModule,
    RouterLink,
    UiDateInputDirective,
  ],
})
export class CustomerDataEditB2CComponent extends CustomerDataEditComponent {}
