import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InputOptionTriStateComponent } from './filter-input-option-tri-state.component';
import { FormsModule } from '@angular/forms';
import { UiIconModule } from '@ui/icon';
import { UiSwitchModule } from '@ui/switch';
import { MatomoModule } from 'ngx-matomo-client';

@NgModule({
  imports: [CommonModule, UiSwitchModule, FormsModule, UiIconModule, MatomoModule],
  exports: [InputOptionTriStateComponent],
  declarations: [InputOptionTriStateComponent],
})
export class InputOptionTriStateModule {}
