<div class="searchbox-input-wrapper">
  <div class="searchbox-hint-wrapper">
    <input
      id="searchbox"
      class="searchbox-input"
      autocomplete="off"
      #input
      type="text"
      [placeholder]="placeholder"
      [(ngModel)]="query"
      (ngModelChange)="setQuery($event, true, true)"
      (focus)="clearHint(); focused.emit(true)"
      (blur)="focused.emit(false)"
      (keyup)="onKeyup($event)"
      (keyup.enter)="tracker.trackEvent({ action: 'keyup enter', name: 'search' })"
      matomoTracker
      #tracker="matomo"
      matomoCategory="searchbox"
    />
    <div *ngIf="showHint" class="searchbox-hint" (click)="focus()">{{ hint }}</div>
  </div>
  <button (click)="clear(); focus()" tabindex="-1" *ngIf="input.value" class="searchbox-clear-btn" type="button">
    <shared-icon icon="close" [size]="32"></shared-icon>
  </button>
  <ng-container *ngIf="!loading">
    <button
      tabindex="0"
      class="searchbox-search-btn"
      type="button"
      *ngIf="!canScan"
      (click)="emitSearch()"
      [disabled]="completeValue !== query"
      matomoClickAction="click"
      matomoClickCategory="searchbox"
      matomoClickName="search"
    >
      <ui-icon icon="search" size="1.5rem"></ui-icon>
    </button>
    <button
      tabindex="0"
      class="searchbox-scan-btn"
      type="button"
      *ngIf="canScan"
      (click)="startScan()"
      matomoClickAction="open"
      matomoClickCategory="searchbox"
      matomoClickName="scanner"
    >
      <shared-icon icon="barcode-scan" [size]="32"></shared-icon>
    </button>
  </ng-container>
  <div *ngIf="loading" class="searchbox-load-indicator">
    <ui-icon icon="spinner" size="32px"></ui-icon>
  </div>
</div>
<ng-content select="ui-autocomplete"></ng-content>
