import { a } from './chunk-SP3HSP4K.js';
function d(r, o, t) {
  let e = r.createProgram();
  if (!e) throw new Error("Cannot create webgl program");
  return r.attachShader(e, o), r.attachShader(e, t), r.linkProgram(e), r.getProgramParameter(e, r.LINK_STATUS) ? e : (a.log(a.Level.Error, "Program linking error:", r.getProgramInfoLog(e)), r.deleteProgram(e), null);
}
function h(r, o, t) {
  let e = r.createShader(o);
  if (!e) throw new Error("Cannot create a shader");
  return r.shaderSource(e, t), r.compileShader(e), r.getShaderParameter(e, r.COMPILE_STATUS) ? e : (a.log(a.Level.Error, "Shader compilation error:", r.getShaderInfoLog(e)), r.deleteShader(e), null);
}
export { d as a, h as b };