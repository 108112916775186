import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'page-package-list-item-loader',
  templateUrl: 'package-list-item-loader.component.html',
  styleUrls: ['package-list-item-loader.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PackageListItemLoaderComponent {
  constructor() {}
}
