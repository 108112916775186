<ui-searchbox
  class="max-w-lg mx-auto my-6"
  placeholder="EAN / ISBN"
  [formControl]="queryControl"
  [scanner]="true"
  [focusAfterViewInit]="true"
  [loading]="fetching$ | async"
  (search)="search($event)"
  (scan)="search($event)"
  [hint]="hint$ | async"
></ui-searchbox>
