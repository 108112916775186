import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AddressDTO } from '@generated/swagger/crm-api';
import { UiModalRef } from '@ui/modal';

@Component({
  selector: 'page-address-selection-modal',
  templateUrl: 'address-selection-modal.component.html',
  styleUrls: ['address-selection-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AddressSelectionModalComponent {
  constructor(public ref: UiModalRef<AddressDTO[] | string>) {}
}
