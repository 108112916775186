import { Highlightable } from '@angular/cdk/a11y';
import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[menuItem], [sharedMenuItem]',
  host: { class: 'menu-item', role: 'menuitem', tabindex: '-1' },
  standalone: false,
})
export class MenuItemDirective implements Highlightable {
  private _onClick = (_: MenuItemDirective) => {};

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
  ) {}

  setActiveStyles(): void {
    this._renderer.addClass(this._elementRef.nativeElement, 'active');
  }

  setInactiveStyles(): void {
    this._renderer.removeClass(this._elementRef.nativeElement, 'active');
  }

  getLabel?(): string {
    return this._elementRef.nativeElement.innerText;
  }

  registerOnClick(fn: (item: MenuItemDirective) => void) {
    this._onClick = fn;
  }

  @HostListener('click')
  onClick() {
    this._onClick.call(this, this);
  }
}
