<header class="bg-surface text-surface-content h-[4.625rem] flex flex-row items-center justify-start px-[0.938rem]">
  <button (click)="toggleSideMenu()" class="btn btn-icon mr-4 desktop-small:hidden shell-top-bar__side-menu">
    <shared-icon [icon]="menuIcon$ | async"></shared-icon>
  </button>
  <span class="text-[1.625rem] leading-[2.25rem] font-bold shell-top-bar__title" *ngIf="title$ | async; let title">
    {{ title }}
  </span>
  <div class="grow"></div>

  <div class="mr-8 flex flex-row items-end">
    <button
      type="button"
      class="btn btn-icon btn-white shell-top-bar__decrease-font-size"
      [disabled]="canNotDecreaseFontSize$ | async"
      (click)="decreaseFontSize()"
    >
      <shared-icon icon="text-decrease" [size]="18"></shared-icon>
    </button>
    <button
      type="button"
      class="btn btn-icon btn-white shell-top-bar__increase-font-size"
      [disabled]="canNotIncreaseFontSize$ | async"
      (click)="increaseFontSize()"
    >
      <shared-icon icon="text-increase" [size]="26"></shared-icon>
    </button>
  </div>

  <a class="btn btn-white shell-top-bar__dashboard" [routerLink]="['/kunde/dashboard']">
    <shared-icon icon="dashboard"></shared-icon>
    Dashboard
  </a>

  <button type="button" class="btn btn-white shell-top-bar__logout" (click)="logout()">
    <shared-icon icon="logout"></shared-icon>
    <strong>{{ branchKey$ | async }}</strong>
  </button>

  <div class="w-4"></div>

  <button
    class="btn btn-icon btn-light relative shell-top-bar__notifications"
    [disabled]="(notificationCount$ | async) === 0"
    (click)="openNotifications()"
  >
    <shared-icon icon="notifications"></shared-icon>
    <div
      class="grid absolute -top-2 -right-2 bg-brand text-white rounded-full w-6 h-6 shell-top-bar__notifications-count"
      *ngIf="notificationCount$ | async; let count"
    >
      <span class="place-self-center text-xs font-bold">{{ count }}</span>
    </div>
  </button>
</header>
