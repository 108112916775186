var e = {
    CUSTOM_VIEW_CONTAINER: "scandit-custom-views"
  },
  t = `
  .${e.CUSTOM_VIEW_CONTAINER} {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .${e.CUSTOM_VIEW_CONTAINER} img {
    position: absolute;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`,
  s = t;
export { e as a, s as b };