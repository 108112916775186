import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CustomerSearchStore } from '../store';
import { map } from 'rxjs/operators';
import { CustomerNamePipe } from '@shared/pipes/customer';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ProductImagePipe } from '@cdn/product-image';
import { CustomerSearchNavigation } from '@shared/services/navigation';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { OrderItemProcessingStatusPipe } from '@shared/pipes/order';

@Component({
  selector: 'page-customer-order-details-side-view',
  templateUrl: 'order-details-side-view.component.html',
  styleUrls: ['order-details-side-view.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'page-customer-order-details-side-view' },
  imports: [
    CustomerNamePipe,
    AsyncPipe,
    NgFor,
    ProductImagePipe,
    RouterLink,
    NgIf,
    RouterLinkActive,
    OrderItemProcessingStatusPipe,
  ],
})
export class OrderDetailsSideViewComponent {
  customer$ = this._store.customer$;

  orderItems$ = this._store.order$.pipe(map((order) => order?.items?.map((item) => item.data)));

  selectedOrderItemId$ = this._store.selectedOrderItemId$;

  constructor(private _store: CustomerSearchStore) {}

  selectOrderItem(orderItemId: number) {
    this._store.selectOrderItemId(orderItemId);
  }
}
