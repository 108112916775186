import { a as a$2 } from './chunk-3A4KLILV.js';
import { a as a$3 } from './chunk-U6BTTEV6.js';
import { a } from './chunk-EV4OEANA.js';
import { a as a$1 } from './chunk-GE4CBD4E.js';
var l = "scandit-arrows-up",
  t = class t extends a {
    constructor() {
      super();
      let a = this.attachShadow({
        mode: "open"
      });
      a.innerHTML = this.render(), a.prepend(t.createStyleElement().cloneNode(!0));
    }
    static createStyleElement() {
      return a$1`
      ${t.tag} {
        display: flex;
      }
      ${t.tag}[hidden] {
        display: none;
      }
      .arrow {
        animation: blink 1.5s infinite;
        animation-duration: 2s;
      }

      .arrow-1 {
        animation-delay: 0s;
      }

      .arrow-2 {
        animation-delay: 0.5s;
      }

      .arrow-3 {
        animation-delay: 1s;
      }

      @keyframes blink {
        0%,
        100% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
      }
    `;
    }
    static create() {
      return document.createElement(t.tag);
    }
    static register() {
      a$2({
        [t.tag]: t
      });
    }
    render() {
      return a$3`
      <svg width="120" height="45" viewBox="0 0 120 45" fill="none">
        <path
          class="arrow arrow-1"
          opacity="0.8"
          d="M105.561 45C108.718 45 109.586 40.6607 106.672 39.4467L61.1539 20.4808C60.4154 20.1731 59.5846 20.1731 58.8462 20.4808L13.328 39.4467C10.4143 40.6607 11.2822 45 14.4387 45C14.8094 45 15.1767 44.9286 15.5205 44.7897L58.8762 27.2723C59.5971 26.981 60.4029 26.981 61.1239 27.2723L104.48 44.7897C104.823 44.9286 105.191 45 105.561 45Z"
          fill="white"
        ></path>
        <path
          class="arrow arrow-2"
          opacity="0.6"
          d="M94.6148 26C96.8169 26 97.4022 22.9609 95.3576 22.143L60.7428 8.29711C60.266 8.10638 59.734 8.10638 59.2572 8.29711L24.6424 22.143C22.5978 22.9609 23.1831 26 25.3852 26H25.6254C25.873 26 26.1184 25.954 26.3492 25.8644L59.2762 13.081C59.7418 12.9002 60.2582 12.9002 60.7238 13.081L93.6508 25.8644C93.8816 25.954 94.127 26 94.3746 26H94.6148Z"
          fill="white"
        ></path>
        <path
          class="arrow arrow-3"
          opacity="0.4"
          d="M81.2078 10C82.8264 10 83.1945 7.7315 81.659 7.21967L60.6325 0.210819C60.2219 0.073974 59.7781 0.0739735 59.3675 0.210818L38.341 7.21967C36.8055 7.73149 37.1736 10 38.7922 10C38.93 10 39.0671 9.98003 39.1992 9.94072L59.4295 3.91979C59.8018 3.809 60.1982 3.809 60.5705 3.91979L80.8008 9.94072C80.9329 9.98003 81.07 10 81.2078 10Z"
          fill="white"
        ></path>
      </svg>
    `;
    }
  };
t.tag = l;
var e = t;
e.register();
export { e as a };