import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InputControlComponent } from './input-control.component';
import { InputDirective } from './input.directive';
import { PrefixDirective } from './prefix.directive';
import { SuffixDirective } from './suffix.directive';
import { ErrorComponent } from './error.component';
import { OutletDirective } from './outlet.directive';
import { IndicatorComponent } from './indicator.component';

@NgModule({
  imports: [CommonModule],
  exports: [
    InputControlComponent,
    InputDirective,
    PrefixDirective,
    SuffixDirective,
    ErrorComponent,
    IndicatorComponent,
  ],
  declarations: [
    InputControlComponent,
    InputDirective,
    PrefixDirective,
    SuffixDirective,
    ErrorComponent,
    OutletDirective,
    IndicatorComponent,
  ],
})
export class InputControlModule {}
