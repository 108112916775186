import { Injectable } from '@angular/core';
import { DomainOmsService } from '../oms.service';
import { OrderItemListItemDTO, StatusValues } from '@generated/swagger/oms-api';
import { ChangeOrderItemStatusBaseActionHandler } from './change-order-item-status-base.action-handler';
import { OrderItemsContext } from './order-items.context';

@Injectable()
export class BackToStockActionHandler extends ChangeOrderItemStatusBaseActionHandler {
  constructor(domainOmsService: DomainOmsService) {
    super(domainOmsService, 'BACKTOSTOCK');
  }

  getStatusValues(orderItem: OrderItemListItemDTO, context: OrderItemsContext): StatusValues {
    return {
      processingStatus: 262144,
      shippingDelayComment: context?.shippingDelayComment,
      quantity: context.itemQuantity?.get(orderItem.orderItemSubsetId),
    };
  }
}
