import { h, d } from './chunk-N4NOODAT.js';
var o = class c {
  constructor(e) {
    this.element = e, this.setupListeners(), this.cacheDOMRect();
  }
  get width() {
    return this.cachedDOMRect.width;
  }
  get height() {
    return this.cachedDOMRect.height;
  }
  static areEquivalentJSONStates(e, t) {
    return !e || !t ? !1 : e.size.isSameAs(t.size) && e.visible === t.visible;
  }
  onStateChanged(e) {
    this.onStateChangedListener = e;
  }
  onDisconnected(e) {
    this.onDisconnectedListener = e;
  }
  toJSONObject() {
    return {
      size: new h(new d(this.width, "pixel"), new d(this.height, "pixel")),
      visible: this.isVisible()
    };
  }
  isSameAs(e) {
    return c.areEquivalentJSONStates(this.toJSONObject(), e.toJSONObject());
  }
  removeListeners() {
    this.resizeObserver.disconnect(), this.mutationObserver.disconnect();
  }
  isVisible() {
    return this.width > 0 && this.height > 0 || this.element.getClientRects().length > 0;
  }
  setupListeners() {
    this.resizeObserver = new ResizeObserver(this.onSizeChange.bind(this)), this.resizeObserver.observe(this.element), this.mutationObserver = new MutationObserver(this.onMutation.bind(this)), this.element.parentElement && this.mutationObserver.observe(this.element.parentElement, {
      childList: !0
    });
  }
  onSizeChange(e) {
    var t;
    this.cacheDOMRect(e), document.contains(this.element) && ((t = this.onStateChangedListener) == null || t.call(this));
  }
  onMutation(e) {
    var s;
    let t = e.find(({
      type: i
    }) => i === "childList");
    if (t) {
      for (let i of t.removedNodes) if (i === this.element && !document.contains(this.element)) {
        (s = this.onDisconnectedListener) == null || s.call(this);
        return;
      }
    }
  }
  cacheDOMRect(e) {
    let t;
    Array.isArray(e) && e.length > 0 && (t = e[0].contentRect), t == null && (t = this.element.getBoundingClientRect()), this.cachedDOMRect = t;
  }
};
export { o as a };