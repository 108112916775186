<div class="flex flex-row justify-end -mt-2">
  <page-customer-menu [customerId]="customerId$ | async" [processId]="processId$ | async" [showCustomerCard]="false"></page-customer-menu>
</div>
<h1 class="text-center text-2xl font-bold">Kundenkarte</h1>
<p class="text-center text-xl" *ngIf="!(noDataFound$ | async)">
  Alle Infos zu Ihrer Kundenkarte
  <br />
  und allen Partnerkarten.
</p>
<p class="text-center text-xl" *ngIf="noDataFound$ | async">Keine Kundenkarte gefunden.</p>
<page-customer-kundenkarte
  class="justify-self-center"
  *ngFor="let karte of primaryKundenkarte$ | async"
  [cardDetails]="karte"
  [isCustomerCard]="true"
></page-customer-kundenkarte>

<p class="text-center text-xl font-bold" *ngIf="(partnerKundenkarte$ | async)?.length">Partnerkarten</p>

<page-customer-kundenkarte
  class="justify-self-center"
  *ngFor="let karte of partnerKundenkarte$ | async"
  [cardDetails]="karte"
  [isCustomerCard]="false"
></page-customer-kundenkarte>
