<div>
  <div class="h-[calc(100vh-40rem)] desktop-large:h-[calc(100vh-35rem)] inputs" [class.overflow-y-scroll]="uiInputGroup?.input?.length > 5">
    <button
      class="ui-input mb-2 border border-solid border-[#EDEFF0]"
      type="button"
      *ngFor="let input of uiInputGroup?.input"
      [class.active]="activeInput === input"
      [class.has-options]="input?.hasSelectedOptions() || input?.hasUnselectedOptions() || input?.selected"
      (click)="setActiveInput(input)"
    >
      <div class="grow">
        {{ input?.label }}
      </div>
      <span class="w-[14px] h-[14px] flex items-center justify-center text-h3 text-[#596470]" *ngIf="activeInput !== input">
        <shared-icon icon="add"></shared-icon>
      </span>
      <span class="w-[14px] h-[14px] flex items-center justify-center text-h3 text-black" *ngIf="activeInput === input">
        <shared-icon class="mr-[1px]" icon="remove"></shared-icon>
      </span>
    </button>
    <div class="w-full h-[6rem]"></div>
  </div>
</div>

<shared-filter-input-options
  *ngIf="activeInput?.options?.values?.length"
  [class.remove-rounded-top-left]="isFirstInputSelected"
  class="options"
  [inputOptions]="activeInput?.options"
></shared-filter-input-options>

<div
  *ngIf="!activeInput?.options?.values?.length"
  class="shared-filter-input bg-white p-4 rounded h-[calc(100vh-34.5rem)] desktop-large:h-[calc(100vh-28rem)]"
  [ngSwitch]="activeInput?.type"
>
  <p *ngIf="activeInput?.description" class="font-bold">{{ activeInput?.description }}</p>

  <ng-container *ngIf="inputTemplate">
    <ng-container *ngTemplateOutlet="inputTemplate; context: { $implicit: activeInput }"></ng-container>
  </ng-container>
  <ng-container *ngIf="!inputTemplate">
    <shared-filter-input-text *ngSwitchCase="1" [input]="activeInput"></shared-filter-input-text>
  </ng-container>
</div>
