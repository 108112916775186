import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { UiModalRef } from '@ui/modal';
import { MessageBoardItemDTO } from '@hub/notifications';

interface ModalNotificationComponentState {
  selectedArea: string;
  notifications: Record<string, MessageBoardItemDTO[]>;
}

@Component({
  selector: 'modal-notifications',
  templateUrl: 'notifications.component.html',
  styleUrls: ['notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class ModalNotificationsComponent extends ComponentStore<ModalNotificationComponentState> {
  private _selectedAreaSelector = (state: ModalNotificationComponentState) => {
    if (state.selectedArea) {
      return state.selectedArea;
    }

    const keys = Object.keys(state.notifications);

    for (const key of keys) {
      if (state.notifications[key]?.length > 0) {
        return key;
      }
    }

    return undefined;
  };

  get selectedArea() {
    return this.get(this._selectedAreaSelector);
  }

  selectedArea$ = this.select(this._selectedAreaSelector);

  private _categorySelector = (state: ModalNotificationComponentState) => {
    const selectedArea = this._selectedAreaSelector(state);

    return state.notifications[selectedArea]?.[0]?.category;
  };

  get category() {
    return this.get(this._categorySelector);
  }

  category$ = this.select(this._categorySelector);

  get notifications() {
    return this.get((s) => s.notifications);
  }

  notifications$ = this.select((s) => s.notifications);

  constructor(private _modalRef: UiModalRef<any, Record<string, MessageBoardItemDTO[]>>) {
    super({
      selectedArea: undefined,
      notifications: _modalRef.data,
    });
  }

  close() {
    this._modalRef.close();
  }

  selectArea(area: string) {
    this.patchState({
      selectedArea: area,
    });
  }
}
