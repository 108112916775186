import { NgModule } from '@angular/core';
import { UiFilterGroupComponent } from './components/filter-group';
import { CommonModule } from '@angular/common';
import { UiSelectFilterComponent, UiSelectFilterOptionComponent } from './components/select-filter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiIconModule } from '@ui/icon';
import { UiRangeFilterComponent } from './components/range-filter';
import { UiInputModule } from '@ui/input';
import { UiCheckboxModule } from '@ui/checkbox';

@NgModule({
  imports: [CommonModule, UiIconModule, FormsModule, UiInputModule, ReactiveFormsModule, UiCheckboxModule],
  exports: [UiFilterGroupComponent, FormsModule, UiCheckboxModule],
  declarations: [
    UiFilterGroupComponent,
    UiSelectFilterComponent,
    UiSelectFilterOptionComponent,
    UiRangeFilterComponent,
  ],
  providers: [],
})
export class UiFilterGroupModule {}
