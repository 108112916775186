<h1>Sie haben neue Nachrichten</h1>

<ng-container *ngFor="let notification of notifications$ | async | keyvalue">
  <button type="button" class="notification-card" (click)="selectArea(notification.key)">
    <div class="notification-icon">
      <div class="notification-counter">{{ notification.value?.length }}</div>
      <ui-icon icon="notification" size="26px"></ui-icon>
    </div>
    <span>{{ notification.value?.[0]?.category }}</span>
  </button>
  <hr class="-mx-4" />
  <ng-container *ngIf="notification.key === selectedArea" [ngSwitch]="notification.value?.[0]?.category">
    <modal-notifications-update-group
      *ngSwitchCase="'ISA-Update'"
      [notifications]="notifications[selectedArea]"
    ></modal-notifications-update-group>
    <modal-notifications-reservation-group
      *ngSwitchCase="'Reservierungsanfragen'"
      [notifications]="notifications[selectedArea]"
      (navigated)="close()"
    ></modal-notifications-reservation-group>
    <modal-notifications-remission-group
      *ngSwitchCase="'Remission'"
      [notifications]="notifications[selectedArea]"
      (navigated)="close()"
    ></modal-notifications-remission-group>
    <modal-notifications-task-calendar-group
      *ngSwitchCase="'Tätigkeitskalender'"
      [notifications]="notifications[selectedArea]"
      (navigated)="close()"
    ></modal-notifications-task-calendar-group>
    <modal-notifications-package-inspection-group
      *ngSwitchCase="'Wareneingang Lagerware'"
      [notifications]="notifications[selectedArea]"
      (navigated)="close()"
    ></modal-notifications-package-inspection-group>
  </ng-container>
</ng-container>
