import { b } from './chunk-QBDQQMQ5.js';
import { b as b$1 } from './chunk-LA6TM76R.js';
var d = (e => (e.Nonzero = "nonzero", e.Evenodd = "evenodd", e))(d || {}),
  n = class n extends b {
    constructor(e) {
      super();
      this._context = null;
      this.isNextDrawPending = !1;
      this.latestCommands = new Uint8Array([]);
      this.canvas = e, this.doDrawLoop = this.doDraw.bind(this);
    }
    get context() {
      if (!this._context && (this._context = this.canvas.getContext("2d"), !this._context)) throw new TypeError("CanvasRenderingContext cannot be retrieved.");
      return this._context;
    }
    draw(e) {
      if (this.latestCommands = e, !this.isCanvasReady()) {
        this.postponeDraw();
        return;
      }
      this.isNextDrawPending || (this.isNextDrawPending = !0, requestAnimationFrame(this.doDrawLoop));
    }
    updateCanvasSizeAttributes(e, s, t = window.devicePixelRatio) {
      let i = Math.round(e * t),
        r = Math.round(s * t);
      (i !== this.canvas.width || r !== this.canvas.height) && (this.canvas.width = i, this.canvas.height = r, this.context.scale(t, t));
    }
    startObservingCanvasResize() {
      if (!this.resizeObserver) {
        if (!n.ResizeObserver) throw new Error("PrivateCanvasDrawer.ResizeObserver is not set");
        this.resizeObserver = new n.ResizeObserver(e => {
          var r;
          let [s] = e,
            {
              width: t,
              height: i
            } = (r = s.contentRect) != null ? r : this.canvas;
          t === 0 || i === 0 || (this.updateCanvasSizeAttributes(t, i), this.latestCommands.length !== 0 && (this.draw(this.latestCommands), this.latestCommands = new Uint8Array([])));
        }), this.resizeObserver.observe(this.canvas);
      }
    }
    endObservingCanvasResize() {
      this.resizeObserver && this.resizeObserver.unobserve(this.canvas);
    }
    doDraw() {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      let e = new b$1(this.latestCommands);
      for (; !e.isConsumed();) {
        let s = e.extractCommandType();
        this.drawSingleCommand(s, e);
      }
      this.isNextDrawPending = !1;
    }
    postponeDraw() {
      this.startObservingCanvasResize();
    }
    isCanvasReady() {
      return this.canvas.width > 0 && this.canvas.height > 0;
    }
  };
n.ResizeObserver = "ResizeObserver" in globalThis ? globalThis.ResizeObserver : void 0;
var h = n;
export { d as a, h as b };