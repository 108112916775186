import { Directive, HostBinding, Input, OnChanges, Sanitizer, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Directive({
  selector: 'img[isaBlobImage]',
  standalone: false,
})
export class BlobImageDirective implements OnChanges {
  @Input()
  isaBlobImage: Blob;

  @HostBinding('src')
  src: SafeUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges({ isaBlobImage }: SimpleChanges): void {
    if (isaBlobImage) {
      let objectURL = URL.createObjectURL(isaBlobImage.currentValue);
      this.src = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    }
  }
}
