import { Injectable } from '@angular/core';
import { ActionHandler } from '@core/command';
import { Dictionary } from '@ngrx/entity';
import { OrderService } from '@generated/swagger/oms-api';
import { OrderItemsContext } from './order-items.context';

@Injectable()
export class CollectOnDeliveryNoteActionHandler extends ActionHandler<OrderItemsContext> {
  constructor(private orderService: OrderService) {
    super('COLLECT_ON_DELIVERYNOTE');
  }

  async handler(context: OrderItemsContext): Promise<OrderItemsContext> {
    const data: Record<number, number> = {};

    context.items.forEach((orderItemSubsetId) => {
      data[orderItemSubsetId.orderItemSubsetId] =
        context.itemQuantity?.get(orderItemSubsetId.orderItemSubsetId) ?? orderItemSubsetId.quantity;
    });

    const response = await this.orderService
      .OrderCollectOnDeliveryNote({
        data,
        eagerLoading: 1,
      })
      .toPromise();

    // Für korrekte Navigation nach Aufruf, da ProcessingStatus Serverseitig auf abgeholt gesetzt wird
    context.items?.forEach((i) => (i.processingStatus = 256));

    return {
      ...context,
      receipts: response.result.map((r) => r.data),
    };
  }
}
