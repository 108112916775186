import { a as a$1 } from './chunk-3A4KLILV.js';
import { a as a$2 } from './chunk-U6BTTEV6.js';
import { a } from './chunk-EV4OEANA.js';
var r = "inline-style",
  e = class e extends a {
    get fill() {
      var t;
      return (t = this.getAttribute("fill")) != null ? t : "black";
    }
    set fill(t) {
      this.setAttribute("fill", t);
    }
    get size() {
      var t;
      return Number((t = this.getAttribute("size")) != null ? t : "32");
    }
    set size(t) {
      this.setAttribute("size", t.toString());
    }
    get [r]() {
      return this.getAttribute(r);
    }
    set [r](t) {
      t == null ? this.removeAttribute(r) : this.setAttribute(r, t);
    }
    static create() {
      return a$1({
        [e.tag]: e
      }), document.createElement(e.tag);
    }
    render() {
      return a$2`<div></div>`;
    }
    heightForViewbox(t, i) {
      return this.size * this.aspectRatioForViewbox(t, i);
    }
    aspectRatioForViewbox(t, i) {
      return i / t;
    }
    connectedCallback() {
      this.innerHTML = this.render();
    }
    attributeChangedCallback(t, i, b) {
      b !== i && (this.innerHTML = this.render());
    }
  };
e.tag = "scandit-svg-icon", e.observedAttributes = ["fill", "size"];
var u = e;
export { u as a };