import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { merge, Subscription } from 'rxjs';
import { IUiInput, UiInput } from '../../tree';

@Component({
  selector: 'ui-filter-input-chip',
  templateUrl: 'filter-input-chip.component.html',
  styleUrls: ['filter-input-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UiFilterInputChipComponent implements OnDestroy {
  private _input: UiInput;

  @Input()
  set input(value: IUiInput) {
    if (value instanceof UiInput) {
      this._input = value;
    } else {
      this._input = UiInput.create(value);
    }
    this.registerChanges();
  }

  get uiInput() {
    return this._input;
  }

  private changeSubscription: Subscription;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnDestroy() {
    this.unregisterChanges();
  }

  registerChanges() {
    this.unregisterChanges();

    if (this.uiInput) {
      merge(this.uiInput.changes, ...(this.uiInput?.options?.values?.map((o) => o.changes) || [])).subscribe(() => {
        this.cdr.markForCheck();
      });
    }
  }

  unregisterChanges() {
    this.changeSubscription?.unsubscribe();
    this.changeSubscription = new Subscription();
  }
}
