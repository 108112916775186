import { Component, OnInit } from '@angular/core';
import { UiModalRef } from '../defs';
import { PromptModalData } from './prompt-modal.data';

@Component({
  selector: 'ui-prompt-modal',
  template: `
    <p class="message">
      {{ modalRef.data.message }}
    </p>
    <input
      type="text"
      #promptInput
      [placeholder]="modalRef.data.placeholder ?? ''"
      [value]="modalRef.data.defaultValue ?? ''"
      (keyup.enter)="modalRef.close(promptInput.value)"
      (keyup.esc)="modalRef.close()"
    />
    <div class="actions">
      <button class="cancel" (click)="modalRef.close()">{{ modalRef.data.cancelText || 'abbrechen' }}</button>
      <button class="confirm" (click)="modalRef.close(promptInput.value)">
        {{ modalRef.data.confirmText || 'weiter' }}
      </button>
    </div>
  `,
  styleUrls: ['./prompt-modal.component.scss'],
  standalone: false,
})
export class UiPromptModalComponent implements OnInit {
  constructor(public modalRef: UiModalRef<string, PromptModalData>) {}

  ngOnInit() {}
}
