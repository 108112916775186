import { createAction, props } from '@ngrx/store';
import {
  ShoppingCartDTO,
  NotificationChannel,
  CheckoutDTO,
  DestinationDTO,
  ShippingAddressDTO,
  BuyerDTO,
  PayerDTO,
  AvailabilityDTO,
} from '@generated/swagger/checkout-api';
import { CustomerDTO } from '@generated/swagger/crm-api';
import { DisplayOrderDTO, DisplayOrderItemDTO } from '@generated/swagger/oms-api';

const prefix = '[DOMAIN-CHECKOUT]';

export const setShoppingCart = createAction(
  `${prefix} Set Shopping Cart`,
  props<{ processId: number; shoppingCart: ShoppingCartDTO }>(),
);

export const setCheckout = createAction(
  `${prefix} Set Checkout`,
  props<{ processId: number; checkout: CheckoutDTO }>(),
);

export const setNotificationChannels = createAction(
  `${prefix} Set Notification Channel`,
  props<{
    processId: number;
    notificationChannels: NotificationChannel;
  }>(),
);

export const setBuyerCommunicationDetails = createAction(
  `${prefix} Set Communication Details`,
  props<{
    processId: number;
    mobile?: string;
    email?: string;
  }>(),
);

export const setCheckoutDestination = createAction(
  `${prefix} Set Checkout Destination`,
  props<{ processId: number; destination: DestinationDTO }>(),
);

export const setShippingAddress = createAction(
  `${prefix} Set Shipping Address`,
  props<{ processId: number; shippingAddress: ShippingAddressDTO }>(),
);

export const removeCheckoutWithProcessId = createAction(
  `${prefix} Remove Checkout With Process Id`,
  props<{ processId: number }>(),
);

export const setOrders = createAction(`${prefix} Add Orders`, props<{ orders: DisplayOrderDTO[] }>());

export const updateOrderItem = createAction(`${prefix} Update Orders`, props<{ item: DisplayOrderItemDTO }>());

export const removeAllOrders = createAction(`${prefix} Remove All Orders`);

export const setBuyer = createAction(`${prefix} Set Buyer`, props<{ processId: number; buyer: BuyerDTO }>());

export const setPayer = createAction(`${prefix} Set Payer`, props<{ processId: number; payer: PayerDTO }>());

export const setSpecialComment = createAction(
  `${prefix} Set Agent Comment`,
  props<{ processId: number; agentComment: string }>(),
);

export const setOlaError = createAction(
  `${prefix} Set Ola Error`,
  props<{ processId: number; olaErrorIds: number[] }>(),
);

export const setCustomer = createAction(
  `${prefix} Set Customer`,
  props<{ processId: number; customer: CustomerDTO }>(),
);

export const addShoppingCartItemAvailabilityToHistory = createAction(
  `${prefix} Add Shopping Cart Item Availability To History`,
  props<{ processId: number; shoppingCartItemId: number; availability: AvailabilityDTO }>(),
);

export const addShoppingCartItemAvailabilityToHistoryByShoppingCartId = createAction(
  `${prefix} Add Shopping Cart Item Availability To History By Shopping Cart Id`,
  props<{ shoppingCartId: number; shoppingCartItemId: number; availability: AvailabilityDTO }>(),
);
