<shared-form-control label="Kundenkartencode" class="flex-grow">
  <input
    placeholder="Kundenkartencode"
    class="input-control"
    type="text"
    [formControl]="control"
    [tabindex]="tabIndexStart"
    [readonly]="readonly"
   
  />
</shared-form-control>
<button type="button" *ngIf="!readonly && canScan()" (click)="scan()">
  <shared-icon icon="barcode-scan" [size]="32"></shared-icon>
</button>
