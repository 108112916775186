import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'shared-skeleton-loader',
  templateUrl: 'skeleton-loader.component.html',
  styleUrls: ['skeleton-loader.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'shared-skeleton-loader' },
  imports: [],
})
export class SkeletonLoaderComponent {
  constructor() {}
}
