import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UiModalRef } from '@ui/modal';
import { MessageModalData } from './message-modal.data';
import { MessageModalAction } from './message-modal.action';

@Component({
  selector: 'shared-message-modal',
  templateUrl: 'message-modal.component.html',
  styleUrls: ['message-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'shared-message-modal' },
  imports: [CommonModule],
})
export class MessageModalComponent {
  get message() {
    return this._ref.data?.message ?? '';
  }

  get actions() {
    return this._ref.data?.actions ?? [];
  }

  constructor(private _ref: UiModalRef<void, MessageModalData>) {}

  clickAction(action: MessageModalAction<any>): void {
    this._ref.close(action.value);
    if (typeof action.action === 'function') {
      action.action.call(action, action.value);
    }
  }
}
