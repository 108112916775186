<div class="wrapper">
  <div class="flex flex-row justify-end -mt-4 -mr-2">
    <page-customer-menu
      [customerId]="customerId$ | async"
      [processId]="processId$ | async"
      [hasCustomerCard]="hasKundenkarte$ | async"
      [showCustomerOrders]="false"
    ></page-customer-menu>
  </div>
  <h1 class="text-2xl text-center font-bold mb-4">Bestellungen</h1>
  <p class="text-xl text-center">
    Übersicht aller offenen und
    <br />
    früheren Bestellungen
  </p>
  <shared-loader [loading]="fetching$ | async" background="true" spinnerSize="48">
    <div
      class="grid grid-flow-row gap-px-3 -mx-6 -mb-8 mt-8 overflow-scroll max-h-[calc(100vh-27.125rem)] scroll-bar"
      [class.fetching-min-h]="fetching$ | async"
    >
      <page-customer-order-list-item *ngFor="let order of orders$ | async" [orderListItem]="order"></page-customer-order-list-item>
    </div>
  </shared-loader>
  <div *ngIf="noResults$ | async" class="grid items-center justify-center min-h-[10rem]">
    <p class="text-xl font-bold">Keine Bestellungen gefunden</p>
  </div>
</div>
