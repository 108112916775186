import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiInputOptionNumberRangeComponent } from './filter-input-option-number-range.component';
import { UiFormControlModule } from '@ui/form-control';
import { FormsModule } from '@angular/forms';
import { MatomoModule } from 'ngx-matomo-client';

@NgModule({
  imports: [CommonModule, UiFormControlModule, FormsModule, MatomoModule],
  exports: [UiInputOptionNumberRangeComponent],
  declarations: [UiInputOptionNumberRangeComponent],
})
export class UiInputOptionNumberRangeModule {}
