import { a as a$2 } from './chunk-5IVFVUF4.js';
import { a as a$3 } from './chunk-M46MIHI5.js';
import { e as e$1 } from './chunk-MSPQDVWJ.js';
import { a as a$6 } from './chunk-3A4KLILV.js';
import { a as a$4 } from './chunk-U6BTTEV6.js';
import { a as a$1 } from './chunk-EV4OEANA.js';
import { a as a$5 } from './chunk-GE4CBD4E.js';
var b = (i => (i.DidShow = "didshow", i.DidHide = "didhide", i.WillShow = "willshow", i))(b || {}),
  o = "--text-size",
  s = "--text-color",
  n = "--text-alignment",
  a = "--text-weight",
  r = "--line-height",
  l = "--background-color",
  c = "--corner-radius",
  h = "--max-lines",
  x = "ease-in-out",
  e = class e extends a$1 {
    constructor() {
      super();
      this.toast = null;
      this._hintStyle = null;
      this.currentAnimation = null;
      this.animationDuration = 250;
      let t = this.attachShadow({
        mode: "open"
      });
      this._exclamationIcon = a$2.create(), this._checkIcon = a$3.create(), this._exclamationIcon.size = 24, this._checkIcon.size = 24, t.innerHTML = a$4`
      <div class="toast">
        <slot name="first-child"></slot>
        <div class="left">
          <slot name="icon"></slot>
        </div>
        <p class="middle">
          <slot></slot>
        </p>
      </div>
    `, t.append(e.createStyleElement().cloneNode(!0)), this.toast = t.querySelector(".toast");
    }
    static createStyleElement() {
      return a$5`
      :host {
        font-family: "SF Pro Display", sans-serif;
        display: inline-block;
      }
      :host([hidden]) {
        display: none;
      }
      .toast {
        ${o}: 1rem;
        ${s} black;
        ${a}: 400;
        ${n}: ${"center"};
        ${r}: 1.5rem;
        ${l}: white;
        ${c}: 0.25rem;
        ${h}: 2;

        color: var(${s});
        font-size$: var(${o});
        font-weight: var(${a});
        line-height: var(${r});

        padding: 1rem;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: var(${n});
        gap: 0.625rem;
        border-radius: var(${c});
        background-color: var(${l});
      }

      :host([direction="column"]) .toast {
        flex-direction: column;
        align-items: center;
      }

      :host([diretion="row"]) .toast {
        flex-direction: row;
        align-items: center;
      }

      .toast .left {
        display: flex;
        align-self: flex-start;
      }

      .toast.icon-${"none"} .left {
        display: none;
      }

      .toast p.middle {
        display: inline-block;
        margin: 0;
        padding: 0;
        text-align: var(${n});
        display: -webkit-box;
        -webkit-line-clamp: var(${h});
        -webkit-box-orient: vertical;
        overflow: hidden;
        align-self: center;
        text-shadow: 0px 1px 2px #${e$1.withAlpha(.3).toJSON()};
      }

      p.middle {
        text-align: var(${n});
      }
    `;
    }
    get hintStyle() {
      return this._hintStyle;
    }
    set hintStyle(t) {
      this._hintStyle = t, this.render();
    }
    get showAnimation() {
      return new Animation(new KeyframeEffect(this, [{
        opacity: 0
      }, {
        opacity: 1
      }], {
        duration: this.animationDuration,
        fill: "both",
        easing: x
      }));
    }
    get hideAnimation() {
      return new Animation(new KeyframeEffect(this, [{
        opacity: 1
      }, {
        opacity: 0
      }], {
        duration: this.animationDuration,
        fill: "both",
        easing: x
      }));
    }
    static create() {
      return document.createElement(e.tag);
    }
    static register() {
      a$6({
        [e.tag]: e
      });
    }
    async show() {
      return this.toggle(!0);
    }
    async hide() {
      return this.toggle(!1);
    }
    connectedCallback() {
      this.render();
    }
    async toggle(t) {
      var i, m;
      if (this.currentAnimation == null) {
        this.animationDuration = (m = (i = this.hintStyle) == null ? void 0 : i.isAnimatedToView) == null || m ? 250 : 0, this.currentAnimation = t ? this.showAnimation : this.hideAnimation, t && this.dispatchEvent(new CustomEvent("willshow", {
          bubbles: !0
        })), this.currentAnimation.play();
        try {
          await this.currentAnimation.finished, this.dispatchEvent(new CustomEvent(t ? "didshow" : "didhide", {
            bubbles: !0,
            detail: {
              target: this
            }
          }));
        } catch (E) {} finally {
          this.currentAnimation = null;
        }
      }
    }
    render() {
      var t;
      !this.toast || !this.hintStyle || (this.toast.className = "toast", this.toast.style.setProperty(o, `${this.hintStyle.textSize}px`), this.toast.style.setProperty(s, `#${this.hintStyle.textColor}`), this.toast.style.setProperty(a, this.hintStyle.textWeight.toString()), this.toast.style.setProperty(n, this.hintStyle.textAlignment), this.toast.style.setProperty(r, `${this.hintStyle.lineHeight / 14}`), this.toast.style.setProperty(l, `#${this.hintStyle.backgroundColor}`), this.toast.style.setProperty(c, `${this.hintStyle.cornerRadius / 10}rem`), this.toast.style.setProperty(h, this.hintStyle.maxLines.toString()), this.toast.classList.toggle("icon-none", this.hintStyle.hintIcon === "none"), this.hintStyle.hintIcon === "check" ? (this._checkIcon.setAttribute("slot", "icon"), this.append(this._checkIcon)) : this.hintStyle.hintIcon === "exclamationMark" && (this._exclamationIcon.setAttribute("slot", "icon"), this.append(this._exclamationIcon)), (t = this.querySelector("[slot=icon]")) == null || t.setAttribute("fill", `#${this.hintStyle.iconColor}`));
    }
  };
e.tag = "scandit-toast-hint";
var d = e;
d.register();
export { b as a, d as b };