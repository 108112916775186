import { a as a$1 } from './chunk-IEE5MKEA.js';
import { a } from './chunk-J4VFWBJ7.js';
import { Quadrilateral } from 'scandit-web-datacapture-core';
var l = (t => (t.EAN13UPCA = "ean13Upca", t.UPCE = "upce", t.EAN8 = "ean8", t.Code39 = "code39", t.Code93 = "code93", t.Code128 = "code128", t.Code11 = "code11", t.Code25 = "code25", t.Codabar = "codabar", t.InterleavedTwoOfFive = "interleavedTwoOfFive", t.MSIPlessey = "msiPlessey", t.QR = "qr", t.DataMatrix = "dataMatrix", t.Aztec = "aztec", t.MaxiCode = "maxicode", t.DotCode = "dotcode", t.KIX = "kix", t.RM4SCC = "rm4scc", t.GS1Databar = "databar", t.GS1DatabarExpanded = "databarExpanded", t.GS1DatabarLimited = "databarLimited", t.PDF417 = "pdf417", t.MicroPDF417 = "microPdf417", t.MicroQR = "microQr", t.Code32 = "code32", t.Lapa4SC = "lapa4sc", t.IATATwoOfFive = "iata2of5", t.MatrixTwoOfFive = "matrix2of5", t.USPSIntelligentMail = "uspsIntelligentMail", t))(l || {}),
  D = (r => (r.A = "A", r.B = "B", r.C = "C", r))(D || {}),
  m = (i => (i.Mod10 = "mod10", i.Mod11 = "mod11", i.Mod16 = "mod16", i.Mod43 = "mod43", i.Mod47 = "mod47", i.Mod103 = "mod103", i.Mod10AndMod11 = "mod1110", i.Mod10AndMod10 = "mod1010", i))(m || {}),
  g = (n => (n.None = "none", n.Unknown = "unknown", n.Linked = "linked", n.GS1TypeA = "gs1TypeA", n.GS1TypeB = "gs1TypeB", n.GS1TypeC = "gs1TypeC", n))(g || {}),
  s = class c {
    get isStructuredAppend() {
      return this._structuredAppendData != null;
    }
    get structuredAppendData() {
      return this._structuredAppendData;
    }
    get addOnData() {
      return this._addOnData;
    }
    get compositeData() {
      return this._compositeData;
    }
    get compositeFlag() {
      return this._compositeFlag;
    }
    get compositeRawData() {
      return this._compositeRawData;
    }
    get data() {
      return this._data;
    }
    get encodingRanges() {
      return this._encodingRanges;
    }
    get frameID() {
      return this._frameID;
    }
    get isColorInverted() {
      return this._isColorInverted;
    }
    get isGS1DataCarrier() {
      return this._isGS1DataCarrier;
    }
    get location() {
      return this._location;
    }
    get rawData() {
      return this._rawData;
    }
    get symbolCount() {
      return this._symbolCount;
    }
    get symbology() {
      return this._symbology;
    }
    static fromJSON(e) {
      var r;
      let a$2 = new c();
      return a$2._symbology = e.symbology, a$2._data = e.data, a$2._rawData = e.rawData, a$2._compositeData = e.compositeData, a$2._compositeRawData = e.compositeRawData, a$2._addOnData = (r = e.addOnData) != null ? r : null, a$2._isGS1DataCarrier = e.isGS1DataCarrier, a$2._compositeFlag = e.compositeFlag, a$2._isColorInverted = e.isColorInverted, a$2._symbolCount = e.symbolCount, a$2._frameID = e.frameId, a$2._encodingRanges = e.encodingRanges.map(o => a.fromJSON(o)), a$2._location = Quadrilateral.fromJSON(e.location), a$2._structuredAppendData = a$1.fromJSON(e.structuredAppendData), a$2;
    }
    toJSONObject() {
      var e, a, r;
      return {
        symbology: this.symbology.toString(),
        data: this.data,
        rawData: this.rawData,
        compositeData: this.compositeData,
        compositeRawData: this.compositeRawData,
        addOnData: (e = this.addOnData) != null ? e : null,
        isGS1DataCarrier: this.isGS1DataCarrier,
        compositeFlag: this.compositeFlag.toString(),
        isColorInverted: this.isColorInverted,
        symbolCount: this.symbolCount,
        frameId: this.frameID,
        encodingRanges: this.encodingRanges.map(o => o.toJSONObject()),
        location: this.location.toJSONObject(),
        structuredAppendData: (r = (a = this.structuredAppendData) == null ? void 0 : a.toJSONObject()) != null ? r : null
      };
    }
  };
export { l as a, D as b, m as c, g as d, s as e };