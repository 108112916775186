import { BooleanInput, NumberInput, coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { CdkMenuModule } from '@angular/cdk/menu';
import { Component, ChangeDetectionStrategy, Input, inject } from '@angular/core';
import { IconComponent } from '@shared/components/icon';
import { SharedMenuModule } from '@shared/components/menu';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerSearchNavigation } from '@shared/services/navigation';
import { ComponentStore } from '@ngrx/component-store';
import { RouterLink } from '@angular/router';
import { AsyncPipe, NgIf } from '@angular/common';

export interface CustomerMenuComponentState {
  customerId?: number;
  processId?: number;
  hasCustomerCard?: boolean;
  showCustomerDetails: boolean;
  showCustomerOrders: boolean;
  showCustomerHistory: boolean;
  showCustomerCard: boolean;
}

@Component({
  selector: 'page-customer-menu',
  templateUrl: 'customer-menu.component.html',
  styleUrls: ['customer-menu.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'page-customer-menu' },
  imports: [CdkMenuModule, SharedMenuModule, IconComponent, RouterLink, NgIf, AsyncPipe],
})
export class CustomerMenuComponent extends ComponentStore<CustomerMenuComponentState> {
  private _navigation = inject(CustomerSearchNavigation);

  @Input() set customerId(value: NumberInput) {
    this.patchState({ customerId: coerceNumberProperty(value) });
  }

  @Input() set hasCustomerCard(value: BooleanInput) {
    this.patchState({ hasCustomerCard: coerceBooleanProperty(value) });
  }

  @Input() set processId(value: NumberInput) {
    this.patchState({ processId: coerceNumberProperty(value) });
  }

  @Input() set showCustomerDetails(value: BooleanInput) {
    this.patchState({ showCustomerDetails: coerceBooleanProperty(value) });
  }

  @Input() set showCustomerOrders(value: BooleanInput) {
    this.patchState({ showCustomerOrders: coerceBooleanProperty(value) });
  }

  @Input() set showCustomerHistory(value: BooleanInput) {
    this.patchState({ showCustomerHistory: coerceBooleanProperty(value) });
  }

  @Input() set showCustomerCard(value: BooleanInput) {
    this.patchState({ showCustomerCard: coerceBooleanProperty(value) });
  }

  readonly customerId$ = this.select((state) => state.customerId);

  readonly processId$ = this.select((state) => state.processId);

  readonly hasCustomerCard$ = this.select((state) => state.hasCustomerCard);

  readonly showCustomerDetails$ = this.select((state) => state.showCustomerDetails);

  readonly showCustomerOrders$ = this.select((state) => state.showCustomerOrders);

  readonly showCustomerHistory$ = this.select((state) => state.showCustomerHistory);

  readonly showCustomerCard$ = this.select((state) => state.showCustomerCard);

  historyRoute$ = combineLatest([this.showCustomerHistory$, this.processId$, this.customerId$]).pipe(
    map(
      ([showCustomerHistory, processId, customerId]) =>
        showCustomerHistory && processId && customerId && this._navigation.historyRoute({ processId, customerId }),
    ),
  );

  ordersRoute$ = combineLatest([this.showCustomerOrders$, this.processId$, this.customerId$]).pipe(
    map(
      ([showCustomerOrders, processId, customerId]) =>
        showCustomerOrders && processId && customerId && this._navigation.ordersRoute({ processId, customerId }),
    ),
  );

  kundenkarteRoute$ = combineLatest([
    this.showCustomerCard$,
    this.hasCustomerCard$,
    this.processId$,
    this.customerId$,
  ]).pipe(
    map(
      ([showCustomerCard, hasCustomerCard, processId, customerId]) =>
        showCustomerCard &&
        hasCustomerCard &&
        processId &&
        customerId &&
        this._navigation.kundenkarteRoute({ processId, customerId }),
    ),
  );

  customerDetailsRoute$ = combineLatest([this.showCustomerDetails$, this.processId$, this.customerId$]).pipe(
    map(
      ([showCustomerDetails, processId, customerId]) =>
        showCustomerDetails && processId && customerId && this._navigation.detailsRoute({ processId, customerId }),
    ),
  );

  constructor() {
    super({
      showCustomerCard: true,
      showCustomerDetails: true,
      showCustomerHistory: true,
      showCustomerOrders: true,
    });
  }
}
