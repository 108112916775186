import { a as a$5 } from './chunk-GXUTZY74.js';
import { a as a$6 } from './chunk-DTSV5FYV.js';
import { a as a$3 } from './chunk-3A4KLILV.js';
import { a as a$2 } from './chunk-U6BTTEV6.js';
import { a as a$1 } from './chunk-EV4OEANA.js';
import { a as a$4 } from './chunk-GE4CBD4E.js';
var d = "scandit-loading-overlay",
  t = class t extends a$1 {
    constructor() {
      super();
      this._progressBar = null;
      this._label = null;
      let e = this.attachShadow({
        mode: "open"
      });
      e.innerHTML = a$2`
      <div class="loading-overlay">
        <label for="scandit-progress" class="message">Loading...</label>
        <scandit-progress-bar id="scandit-progress" min="0" max="100"></scandit-progress-bar>
      </div>
    `, e.append(t.createStyleElement().cloneNode(!0));
    }
    set progress(e) {
      this._progressBar && (this._progressBar.value = e);
    }
    set message(e) {
      this._label && (this._label.textContent = e);
    }
    get message() {
      var e, r;
      return (r = (e = this._label) == null ? void 0 : e.textContent) != null ? r : "";
    }
    static create() {
      return document.createElement(t.tag);
    }
    static register() {
      a$3({
        [d]: t
      });
    }
    static createStyleElement() {
      return a$4`
      @media (prefers-color-scheme: dark) {
        .loading-overlay {
          --foreground-color: white;
          --background-color: black;
        }
      }

      @media (prefers-color-scheme: light) {
        .loading-overlay {
          --foreground-color: black;
          --background-color: white;
        }
      }

      ${a$5.tag} {
        max-width: 15rem;
      }

      .loading-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 1;
        background-color: var(--background-color);
      }

      :host([hidden]) {
        display: none;
      }

      .loading-overlay .message {
        text-align: center;
        font-family: "Open Sans", sans-serif;
        margin-bottom: 1rem;
        color: var(--foreground-color);
      }
    `;
    }
    connectedCallback() {
      var e, r;
      this._progressBar = (e = this.shadowRoot) == null ? void 0 : e.querySelector(a$5.tag), this._label = (r = this.shadowRoot) == null ? void 0 : r.querySelector("label"), this.message = a$6.getInstance().get("core.view.loading");
    }
    show() {
      this.removeAttribute("hidden");
    }
    hide() {
      this.setAttribute("hidden", "");
    }
  };
t.tag = d;
var a = t;
a.register();
export { a };