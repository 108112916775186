import { b as b$1 } from './chunk-U2VVDSM3.js';
import { a as a$7 } from './chunk-CNCPCHBZ.js';
import { b, a as a$6 } from './chunk-5S537AFM.js';
import { a as a$5 } from './chunk-3A4KLILV.js';
import { a as a$3 } from './chunk-U6BTTEV6.js';
import { a as a$1 } from './chunk-EV4OEANA.js';
import { a as a$4 } from './chunk-GE4CBD4E.js';
import { a as a$2 } from './chunk-R6E4CT22.js';
var o = "--view-finder-top",
  a = "--view-finder-bottom",
  s = "--hint-anchor-offset",
  H = "--max-width-fraction",
  T = "--horizontal-margin",
  d = "--max-width-ratio-adjust",
  w = (c => (c.Update = "hintpresenterupdate", c))(w || {}),
  n = class n extends a$1 {
    constructor() {
      super();
      this.onDidToastHideHandler = this.onDidToastHide.bind(this);
      this.orientationObserver = new b();
      this.onOrientationChangeHandler = a$2(this.onOrientationChange.bind(this), 10);
      let t = this.attachShadow({
        mode: "open"
      });
      t.innerHTML = a$3`
      <div id="root">
        <slot></slot>
      </div>
    `, t.append(n.createStyleElement().cloneNode(!0)), this.root = t.querySelector("#root");
    }
    static createStyleElement() {
      return a$4`
      :host {
        pointer-events: none;
      }
      :host #root {
        position: absolute;
        width: 100%;
        top: env(safe-area-inset-top, 5vh);
        right: env(safe-area-inset-right, 0px);
        bottom: env(safe-area-inset-bottom, 5vh);
        left: env(safe-area-inset-left, 0px);
        ${o}: 0px;
        ${a}: 0px;
        ${d}: 1;
        z-index: 1;
      }

      ::slotted(${b$1.tag}) {
        position: absolute;
        left: 50%;
        transform: translate3d(-50%, 0%, 0);
        width: 100%;
        max-width: calc(
          calc(var(${H}) * var(${d}) * 100%) -
            var(${T})
        );
      }

      ::slotted(${b$1.tag}.${"aboveViewFinder"}) {
        transform: translate3d(
          -50%,
          calc(-100% + var(${o}) - var(${s})),
          0
        );
      }

      ::slotted(${b$1.tag}.${"belowViewFinder"}) {
        transform: translate3d(
          -50%,
          calc(var(${a}) + var(${s})),
          0
        );
      }

      ::slotted(${b$1.tag}.${"top"}) {
        top: calc(var(${s}));
      }

      ::slotted(${b$1.tag}.fitToText) {
        width: fit-content;
      }
    `;
    }
    static register() {
      a$5({
        [n.tag]: n
      });
    }
    static create() {
      return document.createElement(n.tag);
    }
    connectedCallback() {
      this.orientationObserver.addEventListener(a$6, this.onOrientationChangeHandler), this.orientationObserver.register(), this.addEventListener("didhide", this.onDidToastHideHandler), this._viewFinderRect == null && (this.root.style.setProperty(o, `${this.root.clientHeight / 2}px`), this.root.style.setProperty(a, `${this.root.clientHeight / 2 + 1}px`));
    }
    disconnectedCallback() {
      this.removeEventListener("didhide", this.onDidToastHideHandler);
    }
    setViewFinderRect(t) {
      if (t === null) {
        this.root.style.removeProperty(o), this.root.style.removeProperty(a);
        return;
      }
      this._viewFinderRect = t;
      let {
        origin: {
          y: i
        },
        size: {
          height: e
        }
      } = this._viewFinderRect;
      this.root.style.setProperty(o, `${i}px`), this.root.style.setProperty(a, `${i + e}px`);
    }
    handleMessage(t) {
      let {
        type: i
      } = t;
      switch (i) {
        case "showToast":
          {
            this.showToast(t.payload);
            break;
          }
        case "hideToast":
          {
            this.hideToast(t.payload);
            break;
          }
        case "showGuidance":
          {
            this.showGuidance(t.payload);
            break;
          }
        case "hideGuidance":
          {
            this.hideGuidance(t.payload);
            break;
          }
        case "startUpdateTimer":
          {
            this.startUpdateTimer(t.payload.duration.value);
            break;
          }
        case "stopUpdateTimer":
          {
            this.stopUpdateTimer();
            break;
          }
      }
    }
    onDidToastHide(t) {
      t.stopPropagation(), t.detail.target.remove();
    }
    async showToast(t) {
      let i = this.idFromHint(t),
        e = this.querySelector(`#${i}`);
      if (e || (e = b$1.create(), e.id = i, this.append(e)), e.textContent = t.text, e.hintStyle = t.hintStyle, e.className = "", e.style.setProperty(s, `${e.hintStyle.hintAnchorOffset / 16}rem`), e.style.setProperty(H, t.hintStyle.maxWidthFraction.toString()), e.style.setProperty(T, `${t.hintStyle.horizontalMargin / 16}rem`), e.classList.add(t.hintStyle.hintAnchor), e.classList.toggle("fitToText", t.hintStyle.fitToText), t.tag === "MoveCloserGuidance") {
        let h = a$7.create();
        h.setAttribute("slot", "first-child"), e.append(h), e.setAttribute("direction", "column");
      }
      return e.show();
    }
    async hideToast(t) {
      let i = this.querySelector(`#${this.idFromHint(t)}`);
      if (i != null) return i.hide();
    }
    async showGuidance(t) {
      return this.showToast(t);
    }
    async hideGuidance(t) {
      return this.hideToast(t);
    }
    startUpdateTimer(t) {
      this.intervalId = setInterval(() => {
        this.dispatchEvent(new CustomEvent("hintpresenterupdate"));
      }, t);
    }
    stopUpdateTimer() {
      clearInterval(this.intervalId);
    }
    idFromHint(t) {
      var i;
      return btoa([t.hintStyle.textColor, t.hintStyle.backgroundColor, t.hintStyle.hintAnchor, (i = t.tag) != null ? i : "", t.hintStyle.textAlignment].join("-")).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
    }
    onOrientationChange(t) {
      let i = 1;
      i = t.detail.value === "landscapeLeft" || t.detail.value === "landscapeRight" ? window.innerHeight / window.innerWidth : 1, this.root.style.setProperty(d, i.toString()), this._viewFinderRect == null && (this.root.style.setProperty(o, `${this.root.clientHeight / 2}px`), this.root.style.setProperty(a, `${this.root.clientHeight / 2 + 1}px`));
    }
  };
n.tag = "scandit-hints";
var l = n;
l.register();
export { w as a, l as b };