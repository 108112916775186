<form *ngIf="formData$ | async; let data" (keydown.enter)="$event.preventDefault()">
  <h1 class="title">Kundendaten erfassen</h1>
  <p class="description">
    Um Ihnen den ausgewählten Service zu
    <br />
    ermöglichen, legen wir Ihnen gerne
    <br />
    ein Kundenkonto an.
  </p>

  <app-customer-type-selector
    [processId]="processId$ | async"
    [p4mUser]="false"
    customerType="b2b"
    (valueChanges)="customerTypeChanged($event)"
  ></app-customer-type-selector>

  <app-organisation-form-block
    #orga
    [tabIndexStart]="1"
    [data]="data.organisation"
    (dataChanges)="patchFormData('organisation', $event)"
    (onInit)="addFormBlock('organisation', $event)"
    [requiredMarks]="organisationFormBlockRequiredMarks"
    [validatorFns]="organisationFormBlockValidators"
  ></app-organisation-form-block>

  <app-name-form-block
    #name
    [tabIndexStart]="orga.tabIndexEnd + 1"
    [data]="data.name"
    (dataChanges)="patchFormData('name', $event)"
    (onInit)="addFormBlock('name', $event)"
  ></app-name-form-block>

  <app-address-form-block
    #address
    [tabIndexStart]="name.tabIndexEnd + 1"
    [data]="data.address"
    (dataChanges)="patchFormData('address', $event)"
    (onInit)="addFormBlock('address', $event)"
    [requiredMarks]="addressRequiredMarks"
    [validatorFns]="addressValidators"
    [defaults]="{ country: 'DEU' }"
  ></app-address-form-block>

  <app-email-form-block
    #email
    [tabIndexStart]="address.tabIndexEnd + 1"
    [data]="data.email"
    (dataChanges)="patchFormData('email', $event)"
    [validatorFns]="emailFormBlockValidators"
    (onInit)="addFormBlock('email', $event)"
  ></app-email-form-block>

  <app-phone-numbers-form-block
    #phoneNumbers
    [tabIndexStart]="email.tabIndexEnd + 1"
    [data]="data.phoneNumbers"
    (dataChanges)="patchFormData('phoneNumbers', $event)"
    (onInit)="addFormBlock('phoneNumbers', $event)"
  ></app-phone-numbers-form-block>

  <app-deviating-address-form-block
    [tabIndexStart]="phoneNumbers.tabIndexEnd + 1"
    [data]="data.deviatingDeliveryAddress"
    (dataChanges)="patchFormData('deviatingDeliveryAddress', $event)"
    (onInit)="addFormBlock('deviatingDeliveryAddress', $event)"
    [nameRequiredMarks]="deviatingNameRequiredMarks"
    [nameValidatorFns]="deviatingNameValidationFns"
    [addressRequiredMarks]="addressRequiredMarks"
    [addressValidatorFns]="addressValidators"
    [organisationRequiredMarks]="organisationFormBlockRequiredMarks"
    [organisationValidatorFns]="organisationFormBlockValidators"
    [defaults]="{ address: { country: 'DEU' } }"
    [organisation]="true"
    [email]="true"
    [phoneNumbers]="true"
  >
    Die Lieferadresse weicht von der Rechnungsadresse ab
  </app-deviating-address-form-block>

  <div class="spacer"></div>
  <div class="sticky w-full flex items-center justify-center">
    <button class="cta-submit" type="button" (click)="save()" [disabled]="form.invalid || form.pending">
      <ui-spinner [show]="busy$ | async">Speichern</ui-spinner>
    </button>
  </div>
</form>
