import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IconRegistry } from './icon-registry';

@Component({
  selector: 'ui-svg-icon',
  template: `
    <svg [style.width.rem]="size / 16" [style.height.rem]="size / 16" [attr.viewBox]="viewBox">
      <path fill="currentColor" [attr.d]="data" />
    </svg>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UISvgIconComponent implements OnChanges {
  @Input()
  icon: string;

  data: string;

  viewBox: string;

  @Input()
  size: number = 24;

  constructor(
    private readonly _iconRegistry: IconRegistry,
    private readonly _cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.icon) {
      const icon = this._iconRegistry.get(this.icon);
      this.data = icon?.data;
      this.viewBox = icon?.viewBox;
      this._cdr.markForCheck();
    }
  }
}
