<div class="page-customer-order-details-main-view__card">
  <div class="page-customer-order-details-main-view__card-header">
    <div class="flex flex-row">
      <shared-icon class="mr-3" [icon]="accountTypeKey$ | async"></shared-icon>
      <span>{{ accountTypeDescription$ | async }}</span>
    </div>
    <div class="text-right">
      <a
        *ngIf="ordersRoute$ | async; let ordersRoute"
        [routerLink]="ordersRoute.path"
        [queryParams]="ordersRoute.urlTree.queryParams"
        [queryParamsHandling]="'merge'"
      >
        <shared-icon icon="close" class="text-2xl"></shared-icon>
      </a>
    </div>
  </div>
  <div class="page-customer-order-details-main-view__card-content" *ngIf="order$ | async; let order">
    <div class="grid grid-cols-2 items-end gap-2">
      <div class="col-data order-date">
        <div class="col-label">Bestelldatum</div>
        <div class="col-value">{{ order?.orderDate | date: 'dd.MM.yyyy | HH:mm' }} Uhr</div>
      </div>
      <div class="font-bold text-right text-2xl">
        {{ order?.orderNumber }}
      </div>
      <div class="col-data">
        <div class="col-label">Bestellkanal</div>
        <div class="col-value">
          {{ order?.features?.orderSource }}
        </div>
      </div>
      <div class="font-bold text-right">
        {{ order?.processingStatus | orderProcessingStatus }}
      </div>
      <div class="col-data" *ngIf="orderTargetBranch$ | async; let orderTargetBranch">
        <div class="col-label">Zielfiliale</div>
        <div class="col-value">
          {{ orderTargetBranch | resolveBranch | branchName: 'name' }}
        </div>
      </div>

      <div class="col-data" *ngIf="orderShippingTarget$ | async; let orderShippingTarget">
        <div class="col-label">Versand</div>
        <div class="col-value whitespace-nowrap truncate">
          {{ orderShippingTarget | address }}
        </div>
      </div>

      <div class="text-right">
        <span class="font-bold">{{ order?.orderValue | currency: order?.orderValueCurrency : 'code' }}</span>
        |
        <span>{{ order?.items?.length }} Artikel</span>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="showItemList$ | async">
  <div *ngFor="let orderItemOrderTypeGroup of groupedOrderItemsByOrderType$ | async">
    <div class="page-customer-order-details-main-view__card-header">
      <div class="grid grid-flow-col gap-3 justify-start" [ngSwitch]="orderItemOrderTypeGroup.group">
        <shared-icon [icon]="orderItemOrderTypeGroup.group"></shared-icon>
        <span>{{ orderItemOrderTypeGroup.group }}</span>
        <span class="font-normal" *ngSwitchCase="'Abholung'">{{ orderTargetBranch$ | async | resolveBranch | branchName: 'name' }}</span>
        <span class="font-normal" *ngSwitchCase="'Rücklage'">{{ orderTargetBranch$ | async | resolveBranch | branchName: 'name' }}</span>
        <span class="font-normal" *ngSwitchDefault>{{ orderShippingTarget$ | async | address }}</span>
      </div>
    </div>

    <page-customer-order-item-list-item
      *ngFor="let orderItem of orderItemOrderTypeGroup.items"
      [orderItem]="orderItem"
    ></page-customer-order-item-list-item>
  </div>
</ng-container>

<ng-container *ngIf="showSelectedItem$ | async">
  <div *ngIf="selectedOrderItem$ | async; let selectedOrderItem">
    <div class="page-customer-order-details-main-view__card-header">
      <div class="grid grid-flow-col gap-3 justify-start" [ngSwitch]="selectedOrderItemOrderType$ | async">
        <shared-icon [icon]="selectedOrderItemOrderType$ | async"></shared-icon>
        <span>{{ selectedOrderItemOrderType$ | async }}</span>
        <span class="font-normal" *ngSwitchCase="'Abholung'">{{ orderTargetBranch$ | async | resolveBranch | branchName: 'name' }}</span>
        <span class="font-normal" *ngSwitchCase="'Rücklage'">{{ orderTargetBranch$ | async | resolveBranch | branchName: 'name' }}</span>
        <span class="font-normal" *ngSwitchDefault>{{ orderShippingTarget$ | async | address }}</span>
      </div>
    </div>

    <page-customer-order-item-list-item [orderItem]="selectedOrderItem"></page-customer-order-item-list-item>
  </div>
</ng-container>
