import { a as a$1 } from './chunk-IGJQFAQU.js';
import { a } from './chunk-EP3GI7KA.js';
import { a as a$2 } from './chunk-N4NOODAT.js';
var v = class extends a {
  constructor(e) {
    super();
    this._onTouchStart = this.onTouchStart.bind(this);
    this._onPointerDown = this.onPointerDown.bind(this);
    a$1.detect(), this.element = e, this.addPointerDownListener();
  }
  removeAllListeners() {
    this.element.removeEventListener("touchend", this._onTouchStart), this.element.removeEventListener("mousedown", this._onPointerDown), this.element.removeEventListener("pointerdown", this._onPointerDown);
  }
  addPointerDownListener() {
    a$1.hasApi ? this.element.addEventListener(a$1.prefixIfNeeded("pointerdown"), this._onPointerDown) : a$1.hasTouch ? this.element.addEventListener("touchstart", this._onTouchStart) : this.element.addEventListener("mousedown", this._onPointerDown);
  }
  onPointerDown(e) {
    let {
      clientX: n,
      clientY: t,
      button: o,
      currentTarget: a = this.element
    } = e;
    o === 0 && this.onTap(this.pointRelativeToElement({
      clientX: n,
      clientY: t,
      rect: a.getBoundingClientRect()
    }), e);
  }
  onTouchStart(e) {
    let [n] = e.touches,
      {
        clientX: t,
        clientY: o
      } = n;
    this.onTap(this.pointRelativeToElement({
      clientX: t,
      clientY: o,
      rect: this.element.getBoundingClientRect()
    }), e);
  }
  pointRelativeToElement({
    clientX: e,
    clientY: n,
    rect: t
  }) {
    return new a$2(e - t.left, n - t.top);
  }
  onTap(e, n) {
    var t;
    for (let o of this.listeners) (t = o.onTap) == null || t.call(o, e, n);
  }
};
export { v as a };