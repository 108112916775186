var a = class e {
    constructor(t, i) {
      this._x = t, this._y = i;
    }
    get x() {
      return this._x;
    }
    get y() {
      return this._y;
    }
    static mirrorX(t, i) {
      let r = t.x - i.width;
      return r *= -1, new e(r, t.y);
    }
    static fromJSON(t) {
      return new e(t.x, t.y);
    }
    toJSONObject() {
      return {
        x: this.x,
        y: this.y
      };
    }
  },
  b = class e {
    constructor(t, i, r, o) {
      this._topLeft = t, this._topRight = i, this._bottomRight = r, this._bottomLeft = o;
    }
    get topLeft() {
      return this._topLeft;
    }
    get topRight() {
      return this._topRight;
    }
    get bottomRight() {
      return this._bottomRight;
    }
    get bottomLeft() {
      return this._bottomLeft;
    }
    static fromJSON(t) {
      return new e(a.fromJSON(t.topLeft), a.fromJSON(t.topRight), a.fromJSON(t.bottomRight), a.fromJSON(t.bottomLeft));
    }
    toJSONObject() {
      return {
        topLeft: this.topLeft.toJSONObject(),
        topRight: this.topRight.toJSONObject(),
        bottomLeft: this.bottomLeft.toJSONObject(),
        bottomRight: this.bottomRight.toJSONObject()
      };
    }
  },
  W = (i => (i.Pixel = "pixel", i.Fraction = "fraction", i))(W || {}),
  n = class e {
    constructor(t, i) {
      this._value = t, this._unit = i;
    }
    get value() {
      return this._value;
    }
    get unit() {
      return this._unit;
    }
    static fromJSON(t) {
      return new e(t.value, t.unit);
    }
    toJSONObject() {
      return {
        unit: this.unit,
        value: this.value
      };
    }
  },
  S = class e {
    constructor(t, i) {
      this._x = t, this._y = i;
    }
    static get zero() {
      return new e(new n(0, "pixel"), new n(0, "pixel"));
    }
    get x() {
      return this._x;
    }
    get y() {
      return this._y;
    }
    static fromJSON(t) {
      return new e(n.fromJSON(t.x), n.fromJSON(t.y));
    }
    toJSONObject() {
      return {
        x: this.x.toJSONObject(),
        y: this.y.toJSONObject()
      };
    }
  },
  g = class {
    constructor(t, i) {
      this._origin = t, this._size = i;
    }
    get origin() {
      return this._origin;
    }
    get size() {
      return this._size;
    }
    toJSONObject() {
      return {
        origin: this.origin.toJSONObject(),
        size: this.size.toJSONObject()
      };
    }
  },
  N = class {
    constructor(t, i) {
      this._origin = t, this._size = i;
    }
    get origin() {
      return this._origin;
    }
    get size() {
      return this._size;
    }
    toJSONObject() {
      return {
        origin: this._origin.toJSONObject(),
        size: this._size.toJSONObject()
      };
    }
  },
  l = class e {
    constructor(t, i) {
      this._width = t, this._height = i;
    }
    get width() {
      return this._width;
    }
    get height() {
      return this._height;
    }
    static fromJSON(t) {
      return new e(n.fromJSON(t.width), n.fromJSON(t.height));
    }
    isSameAs(t) {
      return JSON.stringify(this.toJSONObject()) === JSON.stringify(t.toJSONObject());
    }
    toJSONObject() {
      return {
        width: this.width.toJSONObject(),
        height: this.height.toJSONObject()
      };
    }
  },
  d = class e {
    constructor(t, i) {
      this._width = t, this._height = i;
    }
    get width() {
      return this._width;
    }
    get height() {
      return this._height;
    }
    static fromJSON(t) {
      return new e(t.width, t.height);
    }
    toJSONObject() {
      return {
        height: this.height,
        width: this.width
      };
    }
  },
  p = class {
    constructor(t, i) {
      this._size = t, this._aspect = i;
    }
    get size() {
      return this._size;
    }
    get aspect() {
      return this._aspect;
    }
  },
  f = (o => (o.WidthAndHeight = "widthAndHeight", o.WidthAndAspectRatio = "widthAndAspectRatio", o.HeightAndAspectRatio = "heightAndAspectRatio", o.ShorterDimensionAndAspectRatio = "shorterDimensionAndAspectRatio", o))(f || {}),
  O = class e {
    constructor() {
      this._shorterDimensionAndAspectRatio = null;
    }
    get widthAndHeight() {
      return this._widthAndHeight;
    }
    get widthAndAspectRatio() {
      return this._widthAndAspectRatio;
    }
    get heightAndAspectRatio() {
      return this._heightAndAspectRatio;
    }
    get shorterDimensionAndAspectRatio() {
      return this._shorterDimensionAndAspectRatio;
    }
    get sizingMode() {
      return this.widthAndAspectRatio ? "widthAndAspectRatio" : this.heightAndAspectRatio ? "heightAndAspectRatio" : this.shorterDimensionAndAspectRatio ? "shorterDimensionAndAspectRatio" : "widthAndHeight";
    }
    static sizeWithWidthAndHeight(t) {
      let i = new e();
      return i._widthAndHeight = t, i;
    }
    static sizeWithWidthAndAspectRatio(t, i) {
      let r = new e();
      return r._widthAndAspectRatio = new p(t, i), r;
    }
    static sizeWithHeightAndAspectRatio(t, i) {
      let r = new e();
      return r._heightAndAspectRatio = new p(t, i), r;
    }
    static sizeWithShorterDimensionAndAspectRatio(t, i) {
      let r = new e();
      return r._shorterDimensionAndAspectRatio = new p(t, i), r;
    }
    static fromJSON(t) {
      if (t.width && t.height) return this.sizeWithWidthAndHeight(new l(n.fromJSON(t.width), n.fromJSON(t.height)));
      if (t.width && typeof t.aspect == "number") return this.sizeWithWidthAndAspectRatio(n.fromJSON(t.width), t.aspect);
      if (t.height && typeof t.aspect == "number") return this.sizeWithHeightAndAspectRatio(n.fromJSON(t.height), t.aspect);
      if (t.shorterDimension && typeof t.aspect == "number") return this.sizeWithShorterDimensionAndAspectRatio(n.fromJSON(t.shorterDimension), t.aspect);
      throw new Error(`SizeWithUnitAndAspectJSON is malformed: ${JSON.stringify(t)}`);
    }
    toJSONObject() {
      var t, i, r, o, s, c, h, m;
      switch (this.sizingMode) {
        case "widthAndAspectRatio":
          return {
            width: (t = this.widthAndAspectRatio) == null ? void 0 : t.size.toJSONObject(),
            aspect: (i = this.widthAndAspectRatio) == null ? void 0 : i.aspect
          };
        case "heightAndAspectRatio":
          return {
            height: (r = this.heightAndAspectRatio) == null ? void 0 : r.size.toJSONObject(),
            aspect: (o = this.heightAndAspectRatio) == null ? void 0 : o.aspect
          };
        case "shorterDimensionAndAspectRatio":
          return {
            shorterDimension: (s = this.shorterDimensionAndAspectRatio) == null ? void 0 : s.size.toJSONObject(),
            aspect: (c = this.shorterDimensionAndAspectRatio) == null ? void 0 : c.aspect
          };
        default:
          return {
            width: (h = this.widthAndHeight) == null ? void 0 : h.width.toJSONObject(),
            height: (m = this.widthAndHeight) == null ? void 0 : m.height.toJSONObject()
          };
      }
    }
  },
  J = class e {
    constructor(t, i, r, o) {
      this._left = t, this._top = i, this._right = r, this._bottom = o;
    }
    static get zero() {
      return new e(new n(0, "pixel"), new n(0, "pixel"), new n(0, "pixel"), new n(0, "pixel"));
    }
    get left() {
      return this._left;
    }
    get right() {
      return this._right;
    }
    get top() {
      return this._top;
    }
    get bottom() {
      return this._bottom;
    }
    static fromJSON(t) {
      return new e(n.fromJSON(t.left), n.fromJSON(t.top), n.fromJSON(t.right), n.fromJSON(t.bottom));
    }
    toJSONObject() {
      return {
        left: this.left.toJSONObject(),
        right: this.right.toJSONObject(),
        top: this.top.toJSONObject(),
        bottom: this.bottom.toJSONObject()
      };
    }
  },
  A = class e {
    constructor(t) {
      this.hexadecimalString = t;
    }
    get redComponent() {
      return this.hexadecimalString.slice(0, 2);
    }
    get greenComponent() {
      return this.hexadecimalString.slice(2, 4);
    }
    get blueComponent() {
      return this.hexadecimalString.slice(4, 6);
    }
    get alphaComponent() {
      return this.hexadecimalString.slice(6, 8);
    }
    get red() {
      return e.hexToNumber(this.redComponent);
    }
    get green() {
      return e.hexToNumber(this.greenComponent);
    }
    get blue() {
      return e.hexToNumber(this.blueComponent);
    }
    get alpha() {
      return e.hexToNumber(this.alphaComponent);
    }
    static fromHex(t) {
      return new e(e.normalizeHex(t));
    }
    static fromRGBA(t, i, r, o = 1) {
      let s = [t, i, r, this.normalizeAlpha(o)].reduce((c, h) => c + this.numberToHex(h), "");
      return new e(s);
    }
    static areEquals(t, i) {
      return t == null || i == null ? !1 : t.hexadecimalString === i.hexadecimalString;
    }
    static hexToNumber(t) {
      return Number.parseInt(t, 16);
    }
    static fromJSON(t) {
      return e.fromHex(t);
    }
    static numberToHex(t) {
      let r = Math.round(t).toString(16);
      return r.length === 1 && (r = `0${r}`), r.toUpperCase();
    }
    static normalizeHex(t) {
      let i = t;
      return i.startsWith("#") && (i = i.slice(1)), i.length < 6 && (i = [...i].map(r => r + r).join("")), i.length === 6 && (i += "FF"), i.toUpperCase();
    }
    static normalizeAlpha(t) {
      return t > 0 && t <= 1 ? 255 * t : t;
    }
    withAlpha(t) {
      let i = this.hexadecimalString.slice(0, 6) + e.numberToHex(e.normalizeAlpha(t));
      return e.fromHex(i);
    }
    toJSON() {
      return this.hexadecimalString;
    }
  },
  U = (s => (s.Unknown = "unknown", s.Portrait = "portrait", s.PortraitUpsideDown = "portraitUpsideDown", s.LandscapeRight = "landscapeRight", s.LandscapeLeft = "landscapeLeft", s))(U || {}),
  z = (h => (h.None = "none", h.Horizontal = "horizontal", h.LeftToRight = "leftToRight", h.RightToLeft = "rightToLeft", h.Vertical = "vertical", h.TopToBottom = "topToBottom", h.BottomToTop = "bottomToTop", h))(z || {}),
  x = (u => (u.TopLeft = "topLeft", u.TopCenter = "topCenter", u.TopRight = "topRight", u.CenterLeft = "centerLeft", u.Center = "center", u.CenterRight = "centerRight", u.BottomLeft = "bottomLeft", u.BottomCenter = "bottomCenter", u.BottomRight = "bottomRight", u))(x || {});
export { a, b, W as c, n as d, S as e, g as f, N as g, l as h, d as i, p as j, f as k, O as l, J as m, A as n, U as o, z as p, x as q };