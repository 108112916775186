import { Component, ChangeDetectionStrategy, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { IUiInputOptions, UiInputOptions, UiInputType, UiOption } from '../../tree';

@Component({
  selector: 'ui-filter-input-options',
  templateUrl: 'filter-input-options.component.html',
  styleUrls: ['filter-input-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UiFilterInputOptionsComponent {
  @ViewChild('inputOptionsConainter', { read: ElementRef, static: false })
  inputOptionsConainter: ElementRef;

  get inputOptionsConainterNative(): HTMLElement {
    return this.inputOptionsConainter?.nativeElement;
  }

  private _inputOptions: UiInputOptions;

  @Input()
  set inputOptions(value: IUiInputOptions) {
    if (value instanceof UiInputOptions) {
      this._inputOptions = value;
    } else {
      this._inputOptions = UiInputOptions.create(value);
    }

    this.markForCheck();
  }

  get uiInputOptions() {
    return this._inputOptions;
  }

  get scrollable() {
    return this.inputOptionsConainterNative?.scrollHeight > this.inputOptionsConainterNative?.clientHeight;
  }

  get scrollPersantage() {
    const scrollHeight =
      this.inputOptionsConainterNative?.scrollHeight - this.inputOptionsConainterNative?.clientHeight;
    if (scrollHeight === 0) {
      return 0;
    }

    const scrollTop = this.inputOptionsConainterNative?.scrollTop;

    return (100 / scrollHeight) * scrollTop;
  }

  constructor(private cdr: ChangeDetectorRef) {}

  optionChange(option: UiOption) {
    if (!this.uiInputOptions?.max || !option.selected) {
      return;
    }

    const max = this.uiInputOptions.max;
    const selectedOptions = this.uiInputOptions.values.filter((o) => o.selected);

    if (selectedOptions.length > max) {
      const optionsToUnselect = selectedOptions.filter((o) => o.label !== option.label);
      optionsToUnselect.forEach((option) => option.setSelected(false));
    }
  }

  markForCheck() {
    setTimeout(() => this.cdr.markForCheck(), 0);
  }

  setSelected(value?: boolean) {
    this.uiInputOptions.values?.forEach((option) => option.setSelected(value));
  }

  scroll(percent: number) {
    if (this.scrollPersantage > percent) {
      this.scrollToTop();
    } else {
      this.scrollBottom();
    }
  }

  scrollToTop() {
    this.inputOptionsConainterNative?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  scrollBottom() {
    this.inputOptionsConainterNative?.scrollTo({
      top: this.inputOptionsConainterNative?.scrollHeight - this.inputOptionsConainterNative?.clientHeight,
      behavior: 'smooth',
    });
  }
}
