<div class="options-wrapper">
  <div
    class="option"
    *ngIf="uiStartOption"
    [attr.data-label]="uiStartOption?.label"
    [attr.data-value]="uiStartOption?.value"
    [attr.data-key]="uiStartOption?.key"
    [attr.data-selected]="uiStartOption?.selected"
  >
    <div class="option-wrapper">
      <ui-form-control [label]="uiStartOption?.label">
        <input
          type="text"
          [ngModel]="uiStartOption?.value"
          (ngModelChange)="uiStartOption?.setValue($event)"
          #startModel="ngModel"
          [matomoTracker]="['blur']"
          matomoCategory="filter-option-date-rage"
          matomoName="start"
          [matomoValue]="startModel.value"
        />
      </ui-form-control>
    </div>
  </div>
  <div
    class="option"
    *ngIf="uiStopOption"
    [attr.data-label]="uiStopOption?.label"
    [attr.data-value]="uiStopOption?.value"
    [attr.data-key]="uiStopOption?.key"
    [attr.data-selected]="uiStopOption?.selected"
  >
    <div class="option-wrapper">
      <ui-form-control [label]="uiStopOption?.label">
        <input
          type="text"
          [ngModel]="uiStopOption?.value"
          (ngModelChange)="uiStopOption?.setValue($event)"
          #stopModel="ngModel"
          [matomoTracker]="['blur']"
          matomoCategory="filter-option-date-rage"
          matomoName="stop"
          [matomoValue]="stopModel.value"
        />
      </ui-form-control>
    </div>
  </div>
</div>
<p class="ui-filter-date-range-validation">
  {{ uiStartOption?.validate() || uiStopOption?.validate() }}
</p>
