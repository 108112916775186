<div class="bg-surface rounded min-h-[10rem]">
  <shared-loader [loading]="fetching$ | async" [background]="true" [spinnerSize]="48">
    <div>
      <div class="customer-history-header">
        <div class="customer-history-header-actions flex flex-row justify-end pt-4 px-4">
          <page-customer-menu
            [customerId]="customerId$ | async"
            [processId]="processId$ | async"
            [hasCustomerCard]="hasKundenkarte$ | async"
            [showCustomerHistory]="false"
          ></page-customer-menu>
        </div>
        <div class="customer-history-header-body text-center -mt-3">
          <h1 class="text-[1.625rem] font-bold">Historie</h1>
        </div>
        <div class="customer-history-header-info flex flex-row justify-evenly items-center my-5">
          <div class="flex flex-row">
            <div class="w-36">Kundenname</div>
            <div class="grow font-bold">{{ customerName$ | async }}</div>
          </div>
          <div class="flex flex-row">
            <div class="w-36">Kundennummer</div>
            <div class="grow font-bold">{{ customerNumber$ | async }}</div>
          </div>
        </div>
      </div>
      <div class="px-3 bg-surface-2 text-surface-2-content">
        <shared-history-list [history]="history$ | async"></shared-history-list>
      </div>
    </div>
  </shared-loader>
</div>
