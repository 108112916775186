var a = "orientationchange",
  n = {
    "portrait-primary": {
      type: "portrait-primary",
      angle: 0,
      value: "portrait"
    },
    "portrait-secondary": {
      type: "portrait-secondary",
      angle: 180,
      value: "portraitUpsideDown"
    },
    "landscape-primary": {
      type: "landscape-primary",
      angle: 90,
      value: "landscapeLeft"
    },
    "landscape-secondary": {
      type: "landscape-secondary",
      angle: 270,
      value: "landscapeRight"
    }
  },
  t = class extends EventTarget {
    constructor() {
      super(...arguments);
      this.orientation = {
        value: "portrait",
        type: "portrait-primary",
        angle: 0
      };
      this.landscapeOrientationMediaQuery = matchMedia("(orientation: landscape)");
      this.screenOrientationChangeListener = this.onScreenOrientationChange.bind(this);
      this.landscapeOrientationMediaQueryChangeListener = this.onLandscapeOrientationMediaQueryChange.bind(this);
    }
    register() {
      this.isScreenOrientationApiSupported() ? (screen.orientation.addEventListener("change", this.screenOrientationChangeListener), this.onScreenOrientationChange()) : (this.landscapeOrientationMediaQuery.addEventListener("change", this.landscapeOrientationMediaQueryChangeListener), this.onLandscapeOrientationMediaQueryChange(new MediaQueryListEvent("change", {
        matches: this.landscapeOrientationMediaQuery.matches
      })));
    }
    unregister() {
      this.isScreenOrientationApiSupported() ? screen.orientation.removeEventListener("change", this.screenOrientationChangeListener) : this.landscapeOrientationMediaQuery.removeEventListener("change", this.landscapeOrientationMediaQueryChangeListener);
    }
    isScreenOrientationApiSupported() {
      return "screen" in window && "orientation" in screen && typeof screen.orientation.addEventListener == "function";
    }
    dispatchOrientationChangeEvent(e) {
      this.dispatchEvent(new CustomEvent(a, {
        detail: e
      }));
    }
    onScreenOrientationChange() {
      this.orientation = n[screen.orientation.type], this.dispatchOrientationChangeEvent(this.orientation);
    }
    onLandscapeOrientationMediaQueryChange(e) {
      let i = e;
      this.orientation = i.matches ? n["landscape-primary"] : n["portrait-primary"], this.dispatchOrientationChangeEvent(this.orientation);
    }
  };
export { a, t as b };