import { b } from './chunk-FTD535WI.js';
var l = (e => (e.Nonzero = "nonzero", e.Evenodd = "evenodd", e))(l || {}),
  c = class {
    constructor() {
      this.pathWinding = "nonzero";
    }
    drawSingleCommand(t, e) {
      switch (t) {
        case 0:
          {
            this.addLine(e);
            break;
          }
        case 1:
          {
            this.addArc(e);
            break;
          }
        case 2:
          {
            this.addCircle(e);
            break;
          }
        case 3:
          {
            this.addRectangle(e);
            break;
          }
        case 4:
          {
            this.addRoundedRectangle(e);
            break;
          }
        case 5:
          {
            this.beginPath(e);
            break;
          }
        case 6:
          {
            this.closePath(e);
            break;
          }
        case 7:
          {
            this.fill(e);
            break;
          }
        case 8:
          {
            this.stroke(e);
            break;
          }
        case 10:
          {
            this.setStrokeWidth(e);
            break;
          }
        case 11:
          {
            this.setFillColor(e);
            break;
          }
        case 12:
          {
            this.setStrokeColor(e);
            break;
          }
        case 13:
          {
            this.moveTo(e);
            break;
          }
        case 14:
          {
            this.lineTo(e);
            break;
          }
        case 15:
          {
            this.saveState(e);
            break;
          }
        case 16:
          {
            this.restoreState(e);
            break;
          }
        case 17:
          {
            this.translate(e);
            break;
          }
        case 19:
          {
            this.bezierTo(e);
            break;
          }
        case 20:
          {
            this.transform(e);
            break;
          }
        case 21:
          {
            this.scale(e);
            break;
          }
        case 22:
          {
            this.addPathWinding(e);
            break;
          }
        case 9:
        case 18:
          break;
        default:
          {
            b(t);
            break;
          }
      }
    }
    beginPath(t) {
      this.context.beginPath();
    }
    closePath(t) {
      this.context.closePath();
    }
    setStrokeColor(t) {
      let e = t.extractColor();
      this.context.strokeStyle = this.colorToRgbaString(e);
    }
    setFillColor(t) {
      let e = t.extractColor();
      this.context.fillStyle = this.colorToRgbaString(e);
    }
    fill(t) {
      this.context.fill(this.pathWinding);
    }
    stroke(t) {
      this.context.stroke();
    }
    addLine(t) {
      let e = t.extractFloat(),
        a = t.extractFloat();
      this.context.moveTo(e, a);
      let o = t.extractFloat(),
        r = t.extractFloat();
      this.context.lineTo(o, r);
    }
    lineTo(t) {
      let e = t.extractFloat(),
        a = t.extractFloat();
      this.context.lineTo(e, a);
    }
    moveTo(t) {
      let e = t.extractFloat(),
        a = t.extractFloat();
      this.context.moveTo(e, a);
    }
    addRectangle(t) {
      let e = t.extractFloat(),
        a = t.extractFloat(),
        o = t.extractFloat(),
        r = t.extractFloat();
      this.context.rect(e, a, o, r);
    }
    addRoundedRectangle(t) {
      let e = t.extractFloat(),
        a = t.extractFloat(),
        o = t.extractFloat(),
        r = t.extractFloat(),
        i = t.extractFloat();
      this.context.moveTo(e + i, a), this.context.lineTo(e + o - i, a), this.context.quadraticCurveTo(e + o, a, e + o, a + i), this.context.lineTo(e + o, a + r - i), this.context.quadraticCurveTo(e + o, a + r, e + o - i, a + r), this.context.lineTo(e + i, a + r), this.context.quadraticCurveTo(e, a + r, e, a + r - i), this.context.lineTo(e, a + i), this.context.quadraticCurveTo(e, a, e + i, a), this.context.closePath();
    }
    setStrokeWidth(t) {
      this.context.lineWidth = t.extractFloat();
    }
    addArc(t) {
      let e = t.extractFloat(),
        a = t.extractFloat(),
        o = t.extractFloat(),
        r = t.extractFloat(),
        i = t.extractFloat(),
        s = t.extractBoolean();
      this.context.arc(e, a, o, r, i, s);
    }
    addCircle(t) {
      let e = t.extractFloat(),
        a = t.extractFloat(),
        o = t.extractFloat();
      this.context.arc(e, a, o, 0, Math.PI * 2);
    }
    bezierTo(t) {
      let e = t.extractFloat(),
        a = t.extractFloat(),
        o = t.extractFloat(),
        r = t.extractFloat(),
        i = t.extractFloat(),
        s = t.extractFloat();
      this.context.bezierCurveTo(e, a, o, r, i, s);
    }
    saveState(t) {
      this.context.save();
    }
    restoreState(t) {
      this.context.restore();
    }
    translate(t) {
      let e = t.extractFloat(),
        a = t.extractFloat();
      this.context.translate(e, a);
    }
    transform(t) {
      let e = t.extractFloat(),
        a = t.extractFloat(),
        o = t.extractFloat(),
        r = t.extractFloat(),
        i = t.extractFloat(),
        s = t.extractFloat();
      this.context.transform(e, a, o, r, i, s);
    }
    scale(t) {
      let e = t.extractFloat();
      this.context.scale(e, e);
    }
    addPathWinding(t) {
      let e = t.extractBoolean();
      this.pathWinding = e ? "nonzero" : "evenodd";
    }
    colorToRgbaString(t) {
      return `rgba(${t.r},${t.g},${t.b},${t.a})`;
    }
  };
export { l as a, c as b };