import { a } from './chunk-MW5PTAU7.js';
import { a as a$2 } from './chunk-QHBIT2WY.js';
import { a as a$1 } from './chunk-SP3HSP4K.js';
var s = (e => (e.RGBA = "RGBA", e.GRAYSCALE = "GRAYSCALE", e))(s || {}),
  n = class extends a {
    constructor() {
      super(...arguments);
      this.colorType = "RGBA";
    }
    get _initialized() {
      return this._texture != null && this._framebuffer != null;
    }
    readFromSource(e) {
      this._initialized || this.setup(), this.updateFrameSizeIfNeeded();
      let i = this._framePool.pop();
      return this._contextWebGL.texImage2D(this._contextWebGL.TEXTURE_2D, 0, this._contextWebGL.RGBA, this._contextWebGL.RGBA, this._contextWebGL.UNSIGNED_BYTE, e), this._contextWebGL.readPixels(0, 0, this._contextWebGL.drawingBufferWidth, this._contextWebGL.drawingBufferHeight, this._contextWebGL.RGBA, this._contextWebGL.UNSIGNED_BYTE, i), {
        width: this._contextWebGL.drawingBufferWidth,
        height: this._contextWebGL.drawingBufferHeight,
        data: i,
        colorType: this.colorType
      };
    }
    setup() {
      this._texture = this._contextWebGL.createTexture(), this._texture == null && a$1.log(a$1.Level.Warn, "Could not create texture from WebGLRenderingContext"), this._contextWebGL.bindTexture(this._contextWebGL.TEXTURE_2D, this._texture), this._framebuffer = this._contextWebGL.createFramebuffer(), this._framebuffer == null && a$1.log(a$1.Level.Warn, "Could not create frameBuffer from WebGLRenderingContext"), this._contextWebGL.bindFramebuffer(this._contextWebGL.FRAMEBUFFER, this._framebuffer), this._contextWebGL.framebufferTexture2D(this._contextWebGL.FRAMEBUFFER, this._contextWebGL.COLOR_ATTACHMENT0, this._contextWebGL.TEXTURE_2D, this._texture, 0), this._contextWebGL.texParameteri(this._contextWebGL.TEXTURE_2D, this._contextWebGL.TEXTURE_WRAP_S, this._contextWebGL.CLAMP_TO_EDGE), this._contextWebGL.texParameteri(this._contextWebGL.TEXTURE_2D, this._contextWebGL.TEXTURE_WRAP_T, this._contextWebGL.CLAMP_TO_EDGE), this._contextWebGL.texParameteri(this._contextWebGL.TEXTURE_2D, this._contextWebGL.TEXTURE_MIN_FILTER, this._contextWebGL.NEAREST), this._contextWebGL.texParameteri(this._contextWebGL.TEXTURE_2D, this._contextWebGL.TEXTURE_MAG_FILTER, this._contextWebGL.NEAREST);
    }
    dispose() {
      var e;
      this._texture != null && (this._contextWebGL.deleteTexture(this._texture), this._texture = null), this._framebuffer != null && (this._contextWebGL.deleteFramebuffer(this._framebuffer), this._framebuffer = null), (e = this._framePool) == null || e.empty();
    }
    updateFrameSizeIfNeeded() {
      var o;
      let {
          drawingBufferWidth: e,
          drawingBufferHeight: i
        } = this._contextWebGL,
        r = e * i * 4;
      this._frameSize !== r && (this._frameSize = r, (o = this._framePool) == null || o.empty(), this._framePool = new a$2({
        capacity: this._maxPoolCapacity,
        lowWaterMark: this._minPoolCapacity,
        createItem: () => new Uint8ClampedArray(this._frameSize)
      }));
    }
  };
export { s as a, n as b };