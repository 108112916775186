import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { CustomerSearchStore } from '../store';
import { Filter, FilterModule } from '@shared/components/filter';
import { map } from 'rxjs/operators';
import { AsyncPipe, NgIf } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { IconComponent } from '@shared/components/icon';
import { combineLatest } from 'rxjs';
import { CustomerSearchNavigation, CustomerCreateNavigation } from '@shared/services/navigation';
import { CustomerFilterMainViewModule } from '../filter-main-view/filter-main-view.module';
import { isEmpty } from 'lodash';
import { CustomerInfoDTO } from '@generated/swagger/crm-api';

@Component({
  selector: 'page-customer-main-view',
  templateUrl: 'main-view.component.html',
  styleUrls: ['main-view.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'page-customer-main-view' },
  imports: [AsyncPipe, RouterLink, FilterModule, NgIf, IconComponent, CustomerFilterMainViewModule],
})
export class CustomerMainViewComponent {
  private _searchNavigation = inject(CustomerSearchNavigation);
  private _customerCreateNavigation = inject(CustomerCreateNavigation);
  private _store = inject(CustomerSearchStore);
  private _router = inject(Router);

  filterRoute$ = combineLatest([this._store.processId$, this._store.filter$]).pipe(
    map(([processId, filter]) => {
      const route = this._searchNavigation.filterRoute({ processId, comingFrom: this._router.url?.split('?')[0] });
      route.queryParams = { ...route.queryParams, ...filter?.getQueryParams() };
      route.urlTree.queryParams = { ...route.urlTree.queryParams, ...filter?.getQueryParams() };
      return route;
    }),
  );

  createRoute$ = combineLatest(this._store.filter$, this._store.processId$).pipe(
    map(([filter, processId]) => {
      const queryParams = filter?.getQueryParams();

      let customerInfo: CustomerInfoDTO;

      if (queryParams?.main_qs) {
        const isMail = queryParams.main_qs.includes('@');
        customerInfo = {
          lastName: !isMail ? queryParams.main_qs : undefined,
          communicationDetails: isMail
            ? {
                email: queryParams.main_qs,
              }
            : undefined,
        };
      }

      return this._customerCreateNavigation.createCustomerRoute({ processId, customerInfo });
    }),
  );

  filter$ = this._store.filter$;

  hasFilter$ = this.filter$.pipe(
    map((filter) => {
      if (!filter) return false;
      const qt = filter.getQueryToken();
      return !isEmpty(qt.filter);
    }),
  );

  fetching$ = this._store.fetchingCustomerList$;

  message$ = this._store.message$;

  search(filter: Filter) {
    this._store.setFilter(filter);
    this._store.search({ resetScrollIndex: true, ignoreRestore: true });
  }
}
