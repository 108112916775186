import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, inject } from '@angular/core';
import { CustomerSearchStore } from '../store';
import { Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { CustomerSearchNavigation } from '@shared/services/navigation';
import { RouterLink } from '@angular/router';
import { IconComponent } from '@shared/components/icon';
import { LoaderComponent } from '@shared/components/loader';
import { CustomerOrderListItemComponent } from './order-list-item/order-list-item.component';
import { CustomerMenuComponent } from '../../components/customer-menu';

@Component({
  selector: 'page-customer-orders-main-view',
  templateUrl: 'orders-main-view.component.html',
  styleUrls: ['orders-main-view.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'page-customer-orders-main-view' },
  imports: [
    CustomerMenuComponent,
    AsyncPipe,
    NgFor,
    NgIf,
    RouterLink,
    IconComponent,
    LoaderComponent,
    CustomerOrderListItemComponent,
  ],
})
export class CustomerOrdersMainViewComponent implements OnInit, OnDestroy {
  private _store = inject(CustomerSearchStore);
  private _navigation = inject(CustomerSearchNavigation);

  private _onDestroy = new Subject<void>();

  processId$ = this._store.processId$;

  customerId$ = this._store.customerId$;

  hasKundenkarte$ = combineLatest([this._store.isKundenkarte$, this._store.isOnlineKontoMitKundenkarte$]).pipe(
    map(([isKundenkarte, isOnlineKontoMitKundenkarte]) => isKundenkarte || isOnlineKontoMitKundenkarte),
  );

  orders$ = this._store.customerOrders$;

  fetching$ = this._store.fetchingCustomerOrders$;

  noResults$ = combineLatest([this.orders$, this.fetching$]).pipe(
    map(([orders, fetching]) => !fetching && (!orders || orders.length === 0)),
  );

  detailsRoute$ = combineLatest([this._store.processId$, this._store.customerId$]).pipe(
    map(([processId, customerId]) => this._navigation.detailsRoute({ processId, customerId })),
  );

  ngOnInit(): void {
    this._store.customer$
      .pipe(
        takeUntil(this._onDestroy),
        map((c) => c?.customerNumber),
        distinctUntilChanged(),
      )
      .subscribe((customerNumber) => {
        if (customerNumber) {
          this._store.fetchOrders();
        }
      });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
