<div class="shared-breadcrumb__prefix">
  <button
    type="button"
    class="shared-breadcrumb__button-back"
    *ngIf="previousBreadcrumb$ | async; else placeholder; let crumb"
    [routerLink]="crumb.path"
    [queryParams]="crumb.params"
  >
    <shared-icon icon="arrow-left" [size]="18"></shared-icon>
    Zurück
  </button>
</div>
<div class="shared-breadcrumb__crumbs">
  <ng-container *ngFor="let crumb of breadcrumbs$ | async; let idx = index; let last = last">
    <a class="shared-breadcrumb__crumb" [attr.data-index]="idx" [routerLink]="crumb.path" [queryParams]="crumb.params">
      <span [class.font-bold]="last">
        {{ crumb.name }}
      </span>
    </a>
    <div class="shared-breadcrumb__chevron" *ngIf="!last">
      <shared-icon icon="chevron-right"></shared-icon>
    </div>
  </ng-container>
</div>
<div class="shared-breadcrumb__suffix">
  <ng-content></ng-content>
</div>

<ng-template #placeholder>
  <div class="shared-breadcrumb__placeholder"></div>
</ng-template>
