import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UiSearchboxNextModule } from '@ui/searchbox';
import { KulturpassOrderSearchboxStore } from './kulturpass-order-searchbox.store';
import { provideComponentStore } from '@ngrx/component-store';
import { Subject } from 'rxjs';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'shared-kulturpass-order-searchbox',
  templateUrl: 'kulturpass-order-searchbox.component.html',
  styleUrls: ['kulturpass-order-searchbox.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'shared-kulturpass-order-searchbox' },
  imports: [UiSearchboxNextModule, ReactiveFormsModule, CommonModule],
  providers: [provideComponentStore(KulturpassOrderSearchboxStore)],
})
export class KulturpassOrderSearchboxComponent implements OnInit, OnDestroy {
  private _onDestroy$ = new Subject<void>();

  queryControl = new FormControl(this._store.query);

  fetching$ = this._store.fetching$;

  hint$ = this._store.hint$;

  constructor(
    private _store: KulturpassOrderSearchboxStore,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this._store.query$.pipe(takeUntil(this._onDestroy$)).subscribe((query) => {
      if (this.queryControl.value === query) {
        return;
      }

      this.queryControl.setValue(query);
      this._cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  search(query: string) {
    this._store.updateQuery(query);
    this._store.search();
  }
}
