import {
  Component,
  ChangeDetectionStrategy,
  ContentChild,
  Input,
  AfterContentInit,
  ChangeDetectorRef,
  OnDestroy,
  HostBinding,
  EventEmitter,
  Output,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { combineLatest, Subscription } from 'rxjs';
import { UiFormControlDirective } from './ui-form-control.directive';

@Component({
  selector: 'ui-form-control',
  templateUrl: 'ui-form-control.component.html',
  styleUrls: ['ui-form-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UiFormControlComponent implements AfterContentInit, OnDestroy {
  private subscriptions = new Subscription();

  @Input()
  @HostBinding('attr.variant')
  variant: 'inline' | 'default' = 'default';

  @HostBinding('attr.type')
  get controlType() {
    return this.uiControl?.type;
  }

  @HostBinding('class.ui-form-control--readonly')
  get isReadonly() {
    return this.uiControl?.readonly;
  }

  @Input()
  label: string;

  @Input()
  requiredMark: string;

  @Input()
  clearable = false;

  @Input()
  statusLabel: string;

  @Input()
  prefix: string;

  @Input()
  suffix: string;

  @Input()
  showHint = true;

  @Output()
  cleared = new EventEmitter();

  @ContentChild(NgControl, { read: NgControl })
  ngControl: NgControl;

  @ContentChild(UiFormControlDirective, { read: UiFormControlDirective })
  uiControl: UiFormControlDirective<any>;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterContentInit() {
    if (this.ngControl) {
      this.subscriptions.add(
        combineLatest([this.ngControl.control.statusChanges, this.ngControl.control.valueChanges]).subscribe(
          (value) => {
            this.cdr.markForCheck();
          },
        ),
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  clear() {
    this.uiControl?.clear();
    this.cleared?.emit();
  }
}
