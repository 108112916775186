import { c, a, b } from './chunk-JFRSUCMO.js';
import { a as a$1 } from './chunk-DTSV5FYV.js';
import { a as a$2 } from './chunk-SP3HSP4K.js';
import { c as c$1 } from './chunk-AXTXPLXP.js';
var s = class s {
  constructor(e) {
    this.canUploadFile = !1;
    this._mounted = !1;
    this._loading = !1;
    this._htmlRoot = void 0;
    this._canvas = void 0;
    this._onInputCheck = void 0;
    this._onBeforeFileRead = void 0;
    this._onImageLoadError = void 0;
    this._onFileReaderError = void 0;
    this._onCaptureReady = void 0;
    this._resizedImageSizeLimit = 1440;
    this._inputElement = void 0;
    this._buttonElement = void 0;
    this._chooseImageText = "";
    this._settings = e;
  }
  get canvas() {
    return this._canvas || (this._canvas = document.createElement("canvas")), this._canvas;
  }
  get canvasContext() {
    return this._canvasContext || (this._canvasContext = this.canvas.getContext("2d", {
      willReadFrequently: !0
    })), this._canvasContext;
  }
  mount(e) {
    var E;
    if (this._mounted) return;
    if (!document.head.querySelector(`[data-component=${s.componentName}]`)) {
      let o = document.createElement("style");
      o.dataset.component = s.componentName, o.textContent = c, document.head.append(o);
    }
    let t = document.createElement("div");
    t.className = a.SINGLE_IMAGE_UPLOADER_CONTAINER, Object.assign(t.style, b.SINGLE_IMAGE_UPLOADER_CONTAINER_STYLE, this._settings.containerStyle), e.append(t);
    let {
      iconElement: n,
      iconStyle: d,
      informationElement: i,
      informationStyle: u,
      buttonElement: r,
      buttonStyle: c$2,
      onlyCameraCapture: b$1
    } = this._settings;
    Object.assign(n.style, b.SINGLE_IMAGE_UPLOADER_ICON_STYLE, d, {
      maxWidth: "100px",
      maxHeight: "100px"
    }), t.append(n), i.hasAttribute(s.DEFAULT_DOM_ATTRIBUTE) && (Object.assign(i.style, b.SINGLE_IMAGE_UPLOADER_INFORMATION_STYLE, u), i.textContent = a$1.getInstance().get("core.singleImageUploader.title")), t.append(i);
    let a$2 = document.createElement("input");
    a$2.type = "file", a$2.accept = "image/*", b$1 && (a$2.capture = "environment", a$2.dataset.onlyCaptureOnMobile = "environment"), a$2.addEventListener("change", o => {
      this.onFileUpload(o);
    }), a$2.addEventListener("click", o => {
      var l;
      (l = this._onInputCheck) == null || l.call(this, o);
    }), a$2.addEventListener("keydown", o => {
      var l;
      (l = this._onInputCheck) == null || l.call(this, o);
    });
    let p = document.createElement("label");
    p.append(a$2), r.hasAttribute(s.DEFAULT_DOM_ATTRIBUTE) && (Object.assign(r.style, b.SINGLE_IMAGE_UPLOADER_BUTTON_STYLE, c$2), r.textContent = a$1.getInstance().get("core.singleImageUploader.button")), p.append(r), t.append(p);
    let v = document.createRange().createContextualFragment(atob(c$1)).firstElementChild;
    Object.assign(v.style, {
      position: "absolute",
      bottom: "1em",
      right: "1em",
      width: "10em"
    }), t.append(v), this._htmlRoot = t, this._mounted = !0, this._inputElement = a$2, this._buttonElement = r, this._chooseImageText = (E = r.textContent) != null ? E : "";
  }
  unmount() {
    var e, t;
    this._mounted && ((e = this._htmlRoot) == null || e.remove(), (t = document.head.querySelector(`[data-component=${s.componentName}]`)) == null || t.remove(), this._mounted = !1);
  }
  setButtonState(e) {
    this._inputElement && (this._inputElement.disabled = e === "disabled");
  }
  getButtonState() {
    return this._inputElement ? this._inputElement.disabled ? "disabled" : "enabled" : "disabled";
  }
  getLastUploadedImage() {
    return this.canvasContext.getImageData(0, 0, this.canvasContext.canvas.width, this.canvasContext.canvas.height);
  }
  onInputCheck(e) {
    this._onInputCheck = e;
  }
  onBeforeFileRead(e) {
    this._onBeforeFileRead = e;
  }
  onImageLoadError(e) {
    this._onImageLoadError = e;
  }
  onFileReaderError(e) {
    this._onFileReaderError = e;
  }
  onCaptureReady(e) {
    this._onCaptureReady = e;
  }
  onAfterImageProcessed() {
    this.setLoading(!1);
  }
  setLoading(e) {
    this._buttonElement && (this.setButtonState(e ? "disabled" : "enabled"), this._buttonElement.textContent = e ? "..." : this._chooseImageText, this._loading = e);
  }
  getResizedImageDimensions(e, t) {
    return e <= this._resizedImageSizeLimit && t <= this._resizedImageSizeLimit ? {
      width: e,
      height: t
    } : e > t ? {
      width: this._resizedImageSizeLimit,
      height: Math.round(t / e * this._resizedImageSizeLimit)
    } : {
      width: Math.round(e / t * this._resizedImageSizeLimit),
      height: this._resizedImageSizeLimit
    };
  }
  onImageLoad(e) {
    var i;
    let {
      height: t,
      width: n
    } = this.getResizedImageDimensions(e.naturalWidth, e.naturalHeight);
    this.canvasContext.canvas.width = n, this.canvasContext.canvas.height = t, this.canvasContext.drawImage(e, 0, 0, n, t);
    let d = {
      data: this.canvasContext.getImageData(0, 0, n, t).data,
      width: n,
      height: t
    };
    (i = this._onCaptureReady) == null || i.call(this, d);
  }
  onFileReaderLoad(e, t, n) {
    e.value = "", t.result != null && (n.addEventListener("load", () => {
      this.onImageLoad(n);
    }, {
      once: !0
    }), n.addEventListener("error", d => {
      var i;
      (i = this._onImageLoadError) == null || i.call(this, d), this.setLoading(!1);
    }, {
      once: !0
    }), n.src = t.result);
  }
  onFileUpload(e) {
    var u;
    this.setLoading(!0);
    let t = e.currentTarget,
      {
        files: n
      } = t;
    if (n == null || n.length === 0) {
      this.setLoading(!1);
      return;
    }
    if ((u = this._onBeforeFileRead) == null || u.call(this, e), !this.canUploadFile) {
      t.value = "", a$2.log(a$2.Level.Warn, "Can't upload file, no capture mode enabled."), this.setLoading(!1);
      return;
    }
    let d = new Image(),
      i = new FileReader();
    i.addEventListener("load", () => {
      this.onFileReaderLoad(t, i, d);
    }), i.addEventListener("error", r => {
      var c;
      (c = this._onFileReaderError) == null || c.call(this, r), this.setLoading(!1);
    }), i.readAsDataURL(n[0]);
  }
};
s.componentName = "SingleImageUploaderView", s.DEFAULT_DOM_ATTRIBUTE = "data-scandit-default";
var f = s;
export { f as a };