import { b as b$3 } from './chunk-RZAO2YPF.js';
import { a as a$6 } from './chunk-436XQ2I6.js';
import { a as a$2 } from './chunk-N5YZEC56.js';
import { b, a as a$3 } from './chunk-5S537AFM.js';
import { a as a$c } from './chunk-COBZKSUV.js';
import { d as d$2, c, b as b$4 } from './chunk-5YHTIVUK.js';
import { a as a$d } from './chunk-4ZLDSFCW.js';
import { a as a$7 } from './chunk-PXXUQXGB.js';
import { a as a$b } from './chunk-XVLYCDJL.js';
import { a as a$8 } from './chunk-KYNYQBNF.js';
import { a as a$a } from './chunk-44B7Z6ZZ.js';
import { a as a$5 } from './chunk-N5F43TUJ.js';
import { a as a$e } from './chunk-QR4TEDGM.js';
import { b as b$1 } from './chunk-457L5N2F.js';
import { a as a$9 } from './chunk-NMX4VHW2.js';
import { a } from './chunk-DTSV5FYV.js';
import { d as d$1 } from './chunk-XLX4QIMT.js';
import { a as a$1 } from './chunk-SP3HSP4K.js';
import { d } from './chunk-5Y4OYXNX.js';
import { d as d$3 } from './chunk-AXTXPLXP.js';
import { a as a$4, b as b$2, i, f } from './chunk-N4NOODAT.js';
import { a as a$f } from './chunk-WUHKODFA.js';
var $ = class G {
  constructor() {
    this.focusGesture = d.DataCaptureView.focusGesture;
    this.zoomGesture = d.DataCaptureView.zoomGesture;
    this._scanAreaMargins = d.DataCaptureView.scanAreaMargins;
    this._pointOfInterest = d.DataCaptureView.pointOfInterest;
    this._logoStyle = d.DataCaptureView.logoStyle;
    this._logoAnchor = d.DataCaptureView.logoAnchor;
    this._logoOffset = d.DataCaptureView.logoOffset;
    this._cameraRecoveryText = a.getInstance().get("core.camera.recovery");
    this._context = null;
    this.overlays = [];
    this.controls = [];
    this.controlWidgets = new WeakMap();
    this.frozenFrameCanvas = document.createElement("canvas");
    this.listeners = [];
    this.isVideoElementDetached = !1;
    this.orientation = "portrait";
    this.hiddenProperties = {
      stopVideoTracksOnVisibilityChange: !0
    };
    this.orientationObserver = new b();
    this.onOrientationChangeListener = this.onOrientationChange.bind(this);
    this.onHintPresenterUpdateHandler = this.onHintPresenterUpdate.bind(this);
    this.onBeforeShowToastsHandler = this.onBeforeShowToasts.bind(this);
    this.onWorkerMessageHandler = this.onWorkerMessage.bind(this);
    this.visibilityListener = this.onVisibilityChange.bind(this), this.cameraRecoveryListener = this.cameraRecovery.bind(this);
  }
  get scanAreaMargins() {
    return this._scanAreaMargins;
  }
  set scanAreaMargins(e) {
    var t;
    this._scanAreaMargins = e, (t = this.privateContext) == null || t.update();
  }
  get pointOfInterest() {
    return this._pointOfInterest;
  }
  set pointOfInterest(e) {
    var t;
    this._pointOfInterest = e, (t = this.privateContext) == null || t.update();
  }
  get logoStyle() {
    return this._logoStyle;
  }
  set logoStyle(e) {
    var t;
    this._logoStyle = e, (t = this.privateContext) == null || t.update();
  }
  get logoAnchor() {
    return this._logoAnchor;
  }
  set logoAnchor(e) {
    var t;
    this._logoAnchor = e, (t = this.privateContext) == null || t.update();
  }
  get logoOffset() {
    return this._logoOffset;
  }
  set logoOffset(e) {
    var t;
    this._logoOffset = e, (t = this.privateContext) == null || t.update();
  }
  get cameraRecoveryText() {
    return this._cameraRecoveryText;
  }
  set cameraRecoveryText(e) {
    a$1.log(a$1.Level.Debug, "DEPRECATED: DataCaptureView.cameraRecoveryText is deprecated, use the Localization API instead."), this._cameraRecoveryText = e, this.cameraRecoveryElement.textContent = e;
  }
  get width() {
    var e, t;
    return Math.round((t = (e = this.htmlElementState) == null ? void 0 : e.width) != null ? t : 0);
  }
  get height() {
    var e, t;
    return Math.round((t = (e = this.htmlElementState) == null ? void 0 : e.height) != null ? t : 0);
  }
  get canvasDrawer() {
    return !this._canvasDrawer && this.canvasElement != null && (this._canvasDrawer = new b$1(this.canvasElement), this._canvasDrawer.startObservingCanvasResize()), this._canvasDrawer;
  }
  get privateContext() {
    return this._context;
  }
  static async forContext(e) {
    let t = new G();
    return await t.setContext(e), t;
  }
  showProgressBar() {
    var e;
    (e = this.loadingOverlay) == null || e.show();
  }
  hideProgressBar() {
    var e;
    (e = this.loadingOverlay) == null || e.hide();
  }
  setProgressBarPercentage(e) {
    this.loadingOverlay && (this.loadingOverlay.progress = e);
  }
  setProgressBarMessage(e) {
    this.loadingOverlay && (this.loadingOverlay.message = e);
  }
  getContext() {
    return this._context;
  }
  async setContext(e) {
    var t, i, n;
    if (this._context = e, e) {
      if (this.overlays.length > 0 && ((t = this.privateContext) == null ? void 0 : t.modes.size) === 0) throw new Error(["Cannot assign this view to this context. This view has overlays but the context does not have any mode attached matching these overlays.", "Remove the overlays first before attaching the view to the context, or add the corresponding mode to the context before attaching this view."].join(" "));
      await e.setView(this), (i = this.privateContext) == null || i.subscribeToWorkerMessages(this.onWorkerMessageHandler), (n = this._context) != null && n.frameSource && (await this.onFrameSourceChange(this._context.frameSource));
    }
  }
  connectToElement(e) {
    var t, i, n;
    this.orientationObserver.register(), this.setupHtmlElement(e), (t = this.canvasDrawer) == null || t.startObservingCanvasResize(), this.htmlElementDidChange(), a$2.add(this), this.orientation = this.orientationObserver.orientation.value, this.orientationObserver.addEventListener(a$3, this.onOrientationChangeListener), (i = this.hintPresenter) == null || i.addEventListener("hintpresenterupdate", this.onHintPresenterUpdateHandler), (n = this.hintPresenter) == null || n.addEventListener("willshow", this.onBeforeShowToastsHandler);
  }
  detachFromElement() {
    var e, t, i, n, a, o, l;
    (e = this.htmlElementState) == null || e.removeListeners(), this.removeStyles(), (t = this.canvasDrawer) == null || t.endObservingCanvasResize(), document.removeEventListener("visibilitychange", this.visibilityListener);
    for (let m of ["touchend", "mousedown"]) this.cameraRecoveryElement.removeEventListener(m, this.cameraRecoveryListener);
    (i = this.loadingOverlay) == null || i.remove(), (n = this.customLocationsView) == null || n.unmount(), this.htmlElementDidChange(), (a = this.gestureRecognizer) == null || a.removeAllListeners(), this.clearHtmlElementContent(), a$2.delete(this), this.orientationObserver.unregister(), this.orientationObserver.removeEventListener(a$3, this.onOrientationChangeListener), (o = this.hintPresenter) == null || o.removeEventListener("hintpresenterupdate", this.onHintPresenterUpdateHandler), (l = this.hintPresenter) == null || l.removeEventListener("willshow", this.onBeforeShowToastsHandler);
  }
  async addOverlay(e) {
    var t;
    this.overlays.includes(e) || (this.overlays.push(e), await ((t = this.privateContext) == null ? void 0 : t.update([{
      type: "addOverlay",
      newValue: e
    }])));
  }
  async removeOverlay(e) {
    var t;
    this.overlays.includes(e) && (this.overlays.splice(this.overlays.indexOf(e), 1), await ((t = this.privateContext) == null ? void 0 : t.update([{
      type: "removeOverlay",
      newValue: e
    }])));
  }
  addListener(e) {
    this.listeners.includes(e) || this.listeners.push(e);
  }
  removeListener(e) {
    this.listeners.includes(e) && this.listeners.splice(this.listeners.indexOf(e), 1);
  }
  viewPointForFramePoint(e) {
    var v, y, S, b, O, P, L, V;
    let t = {
        width: (y = (v = this.htmlElementState) == null ? void 0 : v.width) != null ? y : 0,
        height: (b = (S = this.htmlElementState) == null ? void 0 : S.height) != null ? b : 0
      },
      i = {
        width: (P = (O = this.videoElement) == null ? void 0 : O.videoWidth) != null ? P : 0,
        height: (V = (L = this.videoElement) == null ? void 0 : L.videoHeight) != null ? V : 0
      },
      n = t.width / t.height,
      a = i.width / i.height,
      o = n > a ? t.width / i.width : t.height / i.height,
      l = {
        x: e.x * o,
        y: e.y * o
      },
      m = (i.width * o - t.width) / 2,
      d = (i.height * o - t.height) / 2;
    return new a$4(l.x - m, l.y - d);
  }
  viewQuadrilateralForFrameQuadrilateral(e) {
    let t = this.viewPointForFramePoint(e.topLeft),
      i = this.viewPointForFramePoint(e.topRight),
      n = this.viewPointForFramePoint(e.bottomLeft),
      a = this.viewPointForFramePoint(e.bottomRight);
    return new b$2(t, i, a, n);
  }
  addControl(e) {
    var t;
    this.controls.includes(e) || (e.view = this, this.controls.push(e), this.controlsUpdated(), (t = this.privateContext) == null || t.update([{
      type: "addControl",
      newValue: e
    }]));
  }
  removeControl(e) {
    var t;
    if (this.controls.includes(e)) {
      e.view = null;
      let i = this.controls.splice(this.controls.indexOf(e), 1);
      this.getControlWidget(i[0]).remove(!0), this.controlsUpdated(), (t = this.privateContext) == null || t.update([{
        type: "removeControl",
        newValue: e
      }]);
    }
  }
  toJSONObject() {
    var e;
    return {
      scanAreaMargins: this.scanAreaMargins.toJSONObject(),
      pointOfInterest: this.pointOfInterest.toJSONObject(),
      logoAnchor: this.logoAnchor,
      logoOffset: this.logoOffset.toJSONObject(),
      logoHidden: (e = this.logoHidden) != null ? e : !1,
      logoStyle: this.logoStyle,
      overlays: this.overlays.map(t => t.toJSONObject()),
      controls: this.controls.map(t => t.toJSONObject()),
      focusGesture: this.focusGesture ? this.focusGesture.toJSONObject() : null,
      zoomGesture: this.zoomGesture ? this.zoomGesture.toJSONObject() : null
    };
  }
  isCameraRecoveryVisible() {
    return !this.cameraRecoveryElement.hidden;
  }
  setCameraRecoveryVisible(e) {
    this.cameraRecoveryElement.hidden = !e;
  }
  async allowPictureInPicture(e) {
    await d$1.instance().allowPictureInPicture(e);
  }
  viewAnchorPositionsForFrameAnchorPositions(e) {
    let t = this.viewPointForFramePoint(e.topLeft),
      i = this.viewPointForFramePoint(e.topRight),
      n = this.viewPointForFramePoint(e.bottomLeft),
      a = this.viewPointForFramePoint(e.bottomRight),
      o = this.viewPointForFramePoint(e.centerLeft),
      l = this.viewPointForFramePoint(e.centerRight),
      m = this.viewPointForFramePoint(e.topCenter),
      d = this.viewPointForFramePoint(e.bottomCenter),
      v = this.viewPointForFramePoint(e.center);
    return new a$5(t, i, a, n, o, m, l, d, v);
  }
  setDidTapCustomLocationsViewListener(e) {
    this.customLocationsView && this.customLocationsView.listener === null && (this.customLocationsView.listener = e);
  }
  renderCustomLocationsView(e) {
    this.customLocationsView != null && (this.customLocationsView.setMirrored(d$1.instance().isMirrorImageEnabled()), this.customLocationsView.render(e));
  }
  removeStyles() {
    var e;
    (e = document.querySelector("style[scandit]")) == null || e.remove();
  }
  onHintPresenterUpdate(e) {
    var t;
    (t = this.privateContext) == null || t.workerCommand("hintPresenterV2update", {});
  }
  clearHtmlElementContent() {
    var e;
    this.htmlElement && ((e = this.canvasDrawer) == null || e.endObservingCanvasResize(), this.htmlElement.innerHTML = "", this.cameraPaintboardElement = void 0, this.singleImageUploaderPaintboardElement = void 0, this.htmlElement = void 0);
  }
  setupHtmlElement(e) {
    var o, l, m;
    this.createStyles(), this.htmlElement = e, b$3.register();
    let t = `
      <div class="${a$9.CONTAINER_CLASS_NAME}">
        <div class="${a$9.PAINTBOARDS_CONTAINER_CLASS_NAME}">
          <div data-js-id="loading-overlay"></div>
          <div class="${a$9.PAINTBOARD_CLASS_NAME}" data-js-id="camera-paintboard">
            <video autoplay="autoplay" playsinline="true" muted="muted" poster="${d$3}" style="opacity: 0;"></video>
            <img class="${a$9.FROZEN_FRAME}" data-js-id="frozen-frame" alt="frozen-frame" hidden />
            <canvas></canvas>
            <div data-js-id="${a$8.CUSTOM_VIEW_CONTAINER}"></div>
            <div class="${a$9.HINT_CONTAINER_CLASS_NAME}">
              <div class="${a$9.HINT_CLASS_NAME}" hidden></div>
            </div>
            <${b$3.tag}></${b$3.tag}>
            <div class="${a$9.CONTROLS_CLASS_NAME}"></div>
            <div class="${a$9.CAMERA_RECOVERY_CLASS_NAME}" hidden>${this.cameraRecoveryText}</div>
            <div class="${a$9.ERROR_CLASS_NAME}" hidden></div>
          </div>
          <div class="${a$9.PAINTBOARD_CLASS_NAME}" data-js-id="singleimage-paintboard"></div>
        </div>
      </div>
    `;
    this.htmlElement.innerHTML = t, this.hintPresenter = this.htmlElement.querySelector(b$3.tag), this.loadingOverlay && this.loadingOverlay.remove(), this.loadingOverlay = a$6.create(), this.loadingOverlay.hide(), (o = this.htmlElement.querySelector('[data-js-id="loading-overlay"]')) == null || o.replaceWith(this.loadingOverlay), this.customLocationsView && this.customLocationsView.unmount(), this.customLocationsView = new a$7(), this.customLocationsView.mount(this.htmlElement.querySelector(`[data-js-id="${a$8.CUSTOM_VIEW_CONTAINER}"]`)), this.customLocationsView.mapViewAnchorPositionsForFramePoint = d => this.viewAnchorPositionsForFrameAnchorPositions(d), this.containerElement = this.htmlElement.querySelector(`.${a$9.CONTAINER_CLASS_NAME}`), this.cameraPaintboardElement = this.containerElement.querySelector('[data-js-id="camera-paintboard"]'), this.singleImageUploaderPaintboardElement = this.containerElement.querySelector('[data-js-id="singleimage-paintboard"]');
    let i = this.cameraPaintboardElement.querySelector("canvas");
    this.canvasElement == null ? this.canvasElement = i : i.replaceWith(this.canvasElement);
    let n = this.cameraPaintboardElement.querySelector('[data-js-id="frozen-frame"]');
    this.frozenFrame == null ? this.frozenFrame = n : n.replaceWith(this.frozenFrame), this.controlsElement = this.cameraPaintboardElement.querySelector(`.${a$9.CONTROLS_CLASS_NAME}`), this.cameraRecoveryElement = this.cameraPaintboardElement.querySelector(`.${a$9.CAMERA_RECOVERY_CLASS_NAME}`), this.errorElement = this.cameraPaintboardElement.querySelector(`.${a$9.ERROR_CLASS_NAME}`), this.hintContainerElement = this.cameraPaintboardElement.querySelector(`.${a$9.HINT_CONTAINER_CLASS_NAME}`), this.hintElement = this.cameraPaintboardElement.querySelector(`.${a$9.HINT_CLASS_NAME}`);
    let a = this.cameraPaintboardElement.querySelector("video");
    this.videoElement ? a.replaceWith(this.videoElement) : (this.videoElement = a, this.videoPauseListener = this.handleVideoPause.bind(this), this.videoElement.addEventListener("pause", this.videoPauseListener)), this.lastFrameSourceState != null && ((m = (l = this._context) == null ? void 0 : l.frameSource) == null || m.switchToDesiredState(this.lastFrameSourceState)), document.addEventListener("visibilitychange", this.visibilityListener);
    for (let d of ["touchend", "mousedown"]) this.cameraRecoveryElement.addEventListener(d, this.cameraRecoveryListener);
    this.htmlElementState && this.htmlElementState.removeListeners(), this.htmlElementState = new a$a(this.htmlElement), this.htmlElementState.onStateChanged(this.htmlElementDidChange.bind(this)), this.htmlElementState.onDisconnected(this.htmlElementDisconnected.bind(this)), this.gestureRecognizer = new a$b(this.htmlElement), this.gestureRecognizer.addListener({
      onTap: async d => {
        var v;
        (v = this.privateContext) == null || v.workerCommand("onTap", {
          point: d.toJSONObject()
        });
      }
    });
  }
  setupHtmlElementSingleImageUploader(e) {
    var i;
    if (this.singleImageUploaderPaintboardElement == null) return;
    this.singleImageUploaderView && this.singleImageUploaderView.unmount(), this.singleImageUploaderView = new a$c(e), this.singleImageUploaderView.mount(this.containerElement.querySelector('[data-js-id="singleimage-paintboard"]'));
    let t = (i = this._context) == null ? void 0 : i.frameSource;
    this.singleImageUploaderView.onInputCheck(n => {
      t.getCurrentState() !== "starting" && n.preventDefault();
    }), this.singleImageUploaderView.onBeforeFileRead(() => {
      t._context && this.singleImageUploaderView && (this.singleImageUploaderView.canUploadFile = t._context.hasEnabledMode());
    }), this.singleImageUploaderView.onImageLoadError(() => {
      t.currentState = "starting", a$1.log(a$1.Level.Error, "Could not load image from selected file");
    }), this.singleImageUploaderView.onFileReaderError(n => {
      var o, l;
      let a = n.target;
      t.currentState = "starting", a$1.log(a$1.Level.Error, `Error while reading the file: ${(l = (o = a.error) == null ? void 0 : o.toString()) != null ? l : ""}`);
    }), this.singleImageUploaderView.onCaptureReady(async n => {
      await t.processUploadedFileCapture(n);
    }), this.singleImageUploaderView.setButtonState(t.getCurrentState() === "starting" ? "enabled" : "disabled"), t.addView(this.singleImageUploaderView);
  }
  setupHtmlElementVisibility(e) {
    this.cameraPaintboardElement != null && (this.cameraPaintboardElement.hidden = e !== "camera"), this.singleImageUploaderPaintboardElement != null && (this.singleImageUploaderPaintboardElement.hidden = e !== "singleImageUploader");
  }
  createStyles() {
    if (document.querySelector("style[scandit]") === null) {
      let e = document.createElement("style");
      e.setAttribute("scandit", "data-capture-sdk"), e.innerHTML = a$d(), document.head.append(e);
    }
  }
  async onOrientationChange(e) {
    var t, i;
    this.orientation = e.detail.value, await ((i = this.privateContext) == null ? void 0 : i.update([{
      type: "viewChange",
      newValue: {
        ...((t = this.htmlElementState) == null ? void 0 : t.toJSONObject()),
        orientation: this.orientation
      }
    }]));
  }
  htmlElementDidChange() {
    var i$1, n;
    if (!this.htmlElementState) return;
    let e = this.htmlElementState,
      t = e.toJSONObject();
    if (!a$a.areEquivalentJSONStates(this.lastHtmlElementState, t)) {
      this.orientation = this.orientationObserver.orientation.value, (i$1 = this.privateContext) == null || i$1.update([{
        type: "viewChange",
        newValue: {
          ...e.toJSONObject(),
          orientation: this.orientation
        }
      }]), this.lastHtmlElementState = t, (n = this.canvasDrawer) == null || n.updateCanvasSizeAttributes(this.width, this.height), this.handleVideoDisplay(t.visible);
      for (let a of this.listeners) typeof a.didChangeSize == "function" && a.didChangeSize(this, new i(t.size.width.value, t.size.height.value), this.orientation);
    }
  }
  async htmlElementDisconnected() {
    var e, t, i, n, a, o, l;
    this.isVideoElementDetached = !1, (e = this.videoElement) == null || e.remove(), this.lastFrameSourceState = (i = (t = this._context) == null ? void 0 : t.frameSource) == null ? void 0 : i.getCurrentState(), await ((a = (n = this._context) == null ? void 0 : n.frameSource) == null ? void 0 : a.switchToDesiredState("off")), await ((l = this.privateContext) == null ? void 0 : l.update([{
      type: "viewChange",
      newValue: {
        ...((o = this.htmlElementState) == null ? void 0 : o.toJSONObject()),
        orientation: this.orientation
      }
    }]));
  }
  handleVideoDisplay(e) {
    this.videoElement == null || this.cameraPaintboardElement == null || (!e && !this.isVideoElementDetached ? (this.videoElement.width = 0, this.videoElement.height = 0, this.videoElement.style.opacity = "0", this.videoElement.style.visibility = "hidden", document.body.append(this.videoElement), this.isVideoElementDetached = !0) : e && this.isVideoElementDetached && (this.cameraPaintboardElement.insertAdjacentElement("afterbegin", this.videoElement), this.isVideoElementDetached = !1, this.videoElement.style.removeProperty("visibility"), this.videoElement.removeAttribute("width"), this.videoElement.removeAttribute("height"), this.videoElement.style.removeProperty("opacity")));
  }
  isCanvasDrawerWithMetrics(e) {
    return e instanceof a$e;
  }
  onWorkerMessage(e) {
    var t, i;
    switch (e.type) {
      case "draw":
        {
          this.drawEngineCommands(e.payload);
          break;
        }
      case "performanceMetricsReport":
        {
          let n = this._canvasDrawer;
          n != null && this.isCanvasDrawerWithMetrics(n) && n.setPerformanceMetrics(e.payload);
          break;
        }
      case "contextDidChangeStatus":
        {
          let n = a$f.fromJSON(e.payload);
          n.isValid || ((t = this.loadingOverlay) == null || t.hide(), this.displayError(n));
          break;
        }
      case "showHint":
        {
          this.displayHint(e.payload.text, e.payload.style);
          break;
        }
      case "updateHint":
        {
          this.updateHint(e.payload.style);
          break;
        }
      case "hideHint":
        {
          this.hideHint();
          break;
        }
      default:
        {
          (i = this.hintPresenter) == null || i.handleMessage(e);
          break;
        }
    }
  }
  async onBeforeShowToasts() {
    var t, i$1;
    let e = await ((t = this._context) == null ? void 0 : t.workerCommand("getViewfinderInfo", {}));
    if ((e == null ? void 0 : e.rect) != null) {
      if (e.rect.origin.x === 0 && e.rect.origin.y === 0 && e.rect.size.width === 0 && e.rect.size.height === 0) return;
      (i$1 = this.hintPresenter) == null || i$1.setViewFinderRect(new f(new a$4(e.rect.origin.x, e.rect.origin.y), new i(e.rect.size.width, e.rect.size.height)));
    }
  }
  drawEngineCommands(e) {
    var t;
    (t = this.canvasDrawer) == null || t.draw(e);
  }
  displayError(e) {
    this.errorElement.textContent = `Error ${e.code}: ${e.message}`, this.errorElement.hidden = !1;
  }
  clearError() {
    this.errorElement.textContent = "", this.errorElement.hidden = !0;
  }
  displayHint(e, t) {
    this.hintElement.textContent = e, this.hintElement.hidden = !1, this.updateHint(t);
  }
  hideHint() {
    this.hintElement.textContent = "", this.hintElement.hidden = !0;
  }
  updateHint(e) {
    this.hintElement.style.background = e.backgroundColor, this.hintElement.style.color = e.textColor, this.hintElement.style.fontSize = e.font === "body" ? "1.25rem" : "1rem", this.hintElement.style.borderRadius = e.hintCornerStyle === "rounded" ? "5px" : "0px", e.hintHeight === "taller" && (this.hintElement.style.paddingTop = "1.25em", this.hintElement.style.paddingBottom = "1.25em"), e.hintWidth === "wider" && (this.hintElement.style.paddingLeft = "1.25em", this.hintElement.style.paddingRight = "1.25em"), this.updateAnchor(e);
  }
  updateAnchor(e) {
    let t = `${e.verticalOffsetRatio * 100}vh`;
    switch (e.anchor) {
      case "topLeft":
        {
          this.hintContainerElement.style.placeContent = "start left", this.hintElement.style.transform = `translateY(${t})`;
          break;
        }
      case "topCenter":
        {
          this.hintContainerElement.style.placeContent = "start center", this.hintElement.style.transform = `translateY(${t})`;
          break;
        }
      case "topRight":
        {
          this.hintContainerElement.style.placeContent = "start right", this.hintElement.style.transform = `translateY(${t})`;
          break;
        }
      case "centerLeft":
        {
          this.hintContainerElement.style.placeContent = "center start";
          break;
        }
      case "center":
        {
          this.hintContainerElement.style.placeContent = "center";
          break;
        }
      case "centerRight":
        {
          this.hintContainerElement.style.placeContent = "center end";
          break;
        }
      case "bottomLeft":
        {
          this.hintContainerElement.style.placeContent = "end left";
          break;
        }
      case "bottomCenter":
        {
          this.hintContainerElement.style.placeContent = "end center";
          break;
        }
      case "bottomRight":
        {
          this.hintContainerElement.style.placeContent = "end right";
          break;
        }
    }
  }
  controlsUpdated() {
    var e;
    this.redrawControls(), (e = this.privateContext) == null || e.update();
  }
  redrawControls() {
    var t;
    let e = (t = this._context) == null ? void 0 : t.frameSource;
    for (let i of this.controls) {
      let n = this.getControlWidget(i);
      e == null || e.desiredState === "off" || e.desiredState === "standby" || e.desiredState === "stopping" ? n.remove() : n.install();
    }
  }
  registerControlWidget(e, t) {
    let i = new t(e, this.controlsElement);
    return this.controlWidgets.set(e, i), i;
  }
  getControlWidget(e) {
    let t = this.controlWidgets.get(e);
    if (t) return t;
    let {
        type: i
      } = e,
      n;
    switch (i) {
      case "torch":
        {
          n = new b$4(e, this.controlsElement), this.controlWidgets.set(e, n);
          break;
        }
      case "camera":
        {
          n = new c(e, this.controlsElement), this.controlWidgets.set(e, n);
          break;
        }
      case "camera-fov":
        {
          n = new d$2(e, this.controlsElement), this.controlWidgets.set(e, n);
          break;
        }
    }
    return n;
  }
  async onFrameSourceChange(e) {
    let t = e == null ? void 0 : e.type;
    if (this.setupHtmlElementVisibility(t), t === "camera") {
      let i = e == null ? void 0 : e.getCurrentState();
      i != null && (await this.onCameraStateChanged(i));
    } else t === "singleImageUploader" && this.setupHtmlElementSingleImageUploader(e.settings);
  }
  async onCameraStateChanged(e) {
    var t;
    switch ((t = this.cameraPaintboardElement) == null || t.classList.toggle(a$9.PAINTBOARD_CLASS_NAME_STATE_ON, e === "on"), e) {
      case "stopping":
        {
          this.freezeFrame(), this.setVideoElementOpacity("0");
          return;
        }
      case "starting":
      case "bootingUp":
      case "wakingUp":
        {
          if (!this.htmlElement) {
            a$1.log(a$1.Level.Error, "cannot set frame source stream before connecting view to an HTML element");
            return;
          }
          this.videoElement && (d$1.instance().videoElement = this.videoElement, d$1.instance().gui.isCameraRecoveryVisible = this.isCameraRecoveryVisible.bind(this), d$1.instance().gui.setCameraRecoveryVisible = this.setCameraRecoveryVisible.bind(this), this.setVideoElementOpacity("0"));
          return;
        }
      case "off":
        {
          this.setVideoElementOpacity("0"), this.redrawControls();
          return;
        }
      case "on":
        {
          await d$1.instance().waitForCapabilities(), this.redrawControls(), this.unfreezeFrame(), this.setVideoElementOpacity("1");
          break;
        }
      case "goingToSleep":
        {
          this.freezeFrame();
          break;
        }
    }
  }
  setVideoElementOpacity(e) {
    this.videoElement && (this.videoElement.style.opacity = e), this.canvasElement && (this.canvasElement.style.opacity = e);
  }
  onSingleImageUploaderSettingsChange(e) {
    this.setupHtmlElementSingleImageUploader(e);
  }
  handleVideoPause() {
    d$1.instance().playVideo();
  }
  setHiddenProperty(e, t) {
    this.hiddenProperties[e] = t;
  }
  async onVisibilityChange() {
    var t, i;
    let e = (t = this.videoElement) == null ? void 0 : t.srcObject;
    if (this.hiddenProperties.stopVideoTracksOnVisibilityChange && (document.visibilityState === "hidden" && d$1.instance().stopVideoTracks(), document.visibilityState === "visible" && d$1.instance().activeCamera != null && e != null)) if (!e.active || (i = e.getVideoTracks()[0]) != null && i.muted) try {
      a$1.log(a$1.Level.Debug, 'Detected visibility change ("visible") event with inactive video source, try to reinitialize camera'), await d$1.instance().reinitializeCamera();
    } catch (n) {} else a$1.log(a$1.Level.Debug, 'Detected visibility change ("visible") event with active video source, replay video'), d$1.instance().playVideo();
  }
  async cameraRecovery(e) {
    e.preventDefault(), d$1.instance().activeCamera = d$1.instance().selectedCamera, await d$1.instance().reinitializeCamera();
  }
  freezeFrame() {
    var i;
    if (!this.frozenFrame) return;
    let e = d$1.instance().getCurrentFrame();
    if (!e) return;
    let t = new ImageData(e.data, e.width, e.height);
    this.frozenFrameCanvas.width = t.width, this.frozenFrameCanvas.height = t.height, this.frozenFrame.width = t.width, this.frozenFrame.height = t.height, (i = this.frozenFrameCanvas.getContext("2d")) == null || i.putImageData(t, 0, 0), this.frozenFrame.src = this.frozenFrameCanvas.toDataURL("image/jpeg", .4), this.frozenFrame.classList.toggle(a$9.MIRRORED_CLASS_NAME, d$1.instance().isMirrorImageEnabled()), this.frozenFrame.hidden = !1;
  }
  unfreezeFrame() {
    this.frozenFrame && (this.frozenFrame.src = "", this.frozenFrame.hidden = !0);
  }
};
export { $ as a };