import { Pipe, PipeTransform } from '@angular/core';
import { IUiInputGroup } from '../tree';

@Pipe({
  name: 'group',
  standalone: false,
})
export class UiInputGroupSelectorPipe implements PipeTransform {
  transform(value: IUiInputGroup[], group: string): any {
    return value?.find((f) => f?.group === group);
  }
}
